import React, { Component } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import withStyles from "@material-ui/core/styles/withStyles";

export class RichTextField extends Component {
  render() {
    const { id,value="", onChange, label, required=false, placeholder="Type here..." } = this.props;
    const {labelRoot} = this.props.classes||{};
    return (
      <div>
        <label className={labelRoot}>{label + (required ? "*": "")}</label>
        <div style={{marginBottom: "0.5em",marginTop: "0.5em"}}>
          <CKEditor
            editor={ClassicEditor}
            data={value}
            config= {{
                placeholder: placeholder,
                toolbar: ["heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "blockQuote", "insertTable", "undo", "redo"]
            }}
            onInit={editor => {
              editor.setData(value);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              event.target = {value: data, required: required, id: id};
              onChange && onChange(event);
            }}
          />
        </div>
      </div>
    );
  }
}

export default withStyles({})(RichTextField);
