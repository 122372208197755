import {
  blackColor,
  hexToRgb,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

const serviceSearchStyle = {
  tooltip: {
    fontSize: "0.875rem",
    fontWeight: "400",
  },
  iconBtn: {
    "@media only screen  and (min-width: 1024px)  and (max-height: 1366px)  and (-webkit-min-device-pixel-ratio: 1.5)": {
      padding: "0px !important",
    },
    "@media (max-width:568px) and (min-width:320px)": {
      padding: "0px !important",
    },
  },
  right: {
    textAlign: "right",
  },
  left: {
    textAlign: "left",
  },
  form: {
    padding: "0 20px",
    position: "relative",
  },
  socialTitle: {
    fontSize: "18px",
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  customFormControlClasses: {
    margin: "0 12px",
  },
  filtersHeading: {
    // marginTop: "1.5em",
    display: "flex",
    margin: 10,
    textAlign: "left",
    fontFamily: "Nunito",
    fontWeight: "600",
    color: "#233750",
    padding: "5px",
    justifyContent: "space-between",

    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      justifyContent: "flex-end !important",
    },
  },
  filtersTitle: {
    // fontWeight: "bold",
  },
  checkboxLabelControl: {
    margin: "0",
  },
  checkboxLabel: {
    marginLeft: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
  },
  questionName: {
    textTransform: "capitalize",
  },
  chip: {
    margin: "0.2em 0.5em",
  },
  em: {
    fontStyle: "normal",
  },
  root: {
    padding: "2px 2px",
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
  },
  exportButton: {
    width: "220px",
    height: "45px",
    backgroundColor: "#3D97D3",
    color: "#FFFFFF",
    padding: "16px",
    // margin: "10px 15px 0px 15px"
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    /* Laptops and Desktops */
    "@media screen and (min-device-width: 992px)": {
      marginBottom: 12,
    }
  },
  flexDisplay: {
    display: "flex",
  },
  attachToBottom: {
    position: "absolute",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    "@media screen and (max-device-width: 600px)": {
      bottom: "0px",
    },
    "@media only screen  and (min-width:1024px) and (max-height: 1366px)": {
      bottom: "0px",
    }
  },
  cardGrid: {
    "@media only screen  and (min-width: 1024px)  and (max-height: 1366px)  and (-webkit-min-device-pixel-ratio: 1.5)": {
      padding: "0px 5px !important",
      maxWidth: "42% !important"
    },
    "@media only screen and (min-width: 600px) and (max-width:1199px)": {
      flexGrow: 0,
      maxWidth: "70%",
      flexBasis: "50%",
    },

    "@media (max-width: 600px) and (min-width: 320px)": {
      // minWidth: "500px"
    },
    cardContainer: {
      "@media only screen  and (min-width: 1024px)  and (max-height: 1366px)  and (-webkit-min-device-pixel-ratio: 1.5)": {
        height: "215px !important",
      },
    },
  },
  cardContainer: {
    height: "250px",
    border: "1px solid #233750",
    width: "380px !important",
    /* Mobile View */
    "@media only screen  and (max-width:1024px)": {
      height: "230px !important",
      width: "350px !important",
      margin: "0px !important",
      "&:hover": {
        transform: "scale(1.03)",
        zIndex: 999
      }
    },
    /* Tablet View and Desktop View*/
    "@media screen and (min-device-width: 768px)": {
      "&:hover": {
        transform: "scale(1.08)",
        zIndex: 999
      }
    },
  },
  cardActions: {
    "@media only screen  and (max-width:1024px)": {
      display: "flex",
      flexDirection: "column",
      width: "85px",
      height: "200px",
      placeContent: "space-evenly"
    },
  },
  subheader: {
    color: "red",
    backgroundColor: "#3d97d3",
  },
  KeysGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  KeysContainer: {
    fontFamily: "Roboto",
    display: "flex",
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: 'space-between',
    '@media (max-width:767px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    '@media (min-width:768px) and (max-width:1104px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    '@media (min-width:1105px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  mapRoot: {
    // marginTop: "12px",
    width: "100%",
    height: "10%",
    // minHeight: "800px",
  },
  tabButtonsRoot: {
    margin: "10px 0px",
  },
  tabButtonLeft: {
    backgroundColor: "#f4f7fc",
    color: "#414042",
    width: "131px",
    margin: 0,
    fontFamily: "Nunito",
    fontSize: "16pt",
    borderRadius: "5px 0px 0px 5px",
    textTransform: "none",
  },
  tabButtonSelectedLeft: {
    background: "#233750",
    color: "#f4f7fc",
    width: "131px",
    margin: 0,
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontSize: "16pt",
    borderRadius: "5px 0px 0px 5px",
    textTransform: "none",
  },
  tabButtonRight: {
    backgroundColor: "#f4f7fc",
    color: "#414042",
    width: "131px",
    margin: 0,
    fontFamily: "Nunito",
    fontSize: "16pt",
    borderRadius: "0px 5px 5px 0px",
    textTransform: "none",
  },
  tabButtonSelectedRight: {
    background: "#233750",
    color: "#f4f7fc",
    width: "131px",
    margin: 0,
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontSize: "16pt",
    borderRadius: "0px 5px 5px 0px",
    textTransform: "none",
  },

  typoStyle: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: "#233750",
    fontSize: "0.875rem",
    fontWeight: "bold"
  },

  typoFilter: {
    fontFamily: "nunito",
    color: "#233750",
    fontSize: "10px"
  },

  circle: {
    width: "20px",
    height: "20px",
    borderRadius: "250px",
    fontSize: "13px",
    color: "#fff",
    lineHeight: "500px",
    textAlign: "center",
    background: "#3D97D3",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginRight: "5px",
  },

  cardContent: {
    padding: "10px",
    paddingLeft: "10px",
    paddingRight: "0px"
  },

  cardsDiv: {
    /* Tablet View - Portrait)*/
    "@media screen and (min-device-width: 768px) and (max-device-width: 1024px)": {
      justifyContent: "center",
    }
  },

  expandCardsSection: {
    /* Tablet View */
    "@media screen and (min-device-width: 768px) and (max-device-width: 1024px)": {
      display: "none !important"
    },

    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      display: "none !important"
    },

    /* Laptops and Desktops */
    "@media screen and (min-width: 992px)": {
      display: "flex",
      justifyContent: "flex-end",
      cursor: "pointer",
    },
  },

  cardsContainer: {
    overflow: "auto",

    /* Tablet View */
    "@media screen and (min-device-width: 768px) and (max-device-width: 1024px)": {
      maxHeight: "100%",
    },

    /* Laptops and Desktops */
    "@media screen and (min-device-width: 992px)": {
      margin: "0px 20px 0px 20px",
      padding: "0px 20px 0px 20px",
      maxHeight: "100%",
    }
  },

  filterContainer: {
    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      display: "flex",
      justifyContent: "center"
    },
  },
  sortByOptionsDiv: {
    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      marginTop: "15px",
      display: "flex",
      justifyContent: "center"
    },

    /* Tablet View */
    "@media screen and (min-device-width: 768px) and (max-device-width: 1024px)": {
      display: "none"
    },

    /* Laptops and Desktops */
    "@media screen and (min-device-width: 992px)": {
      display: "none"
    }
  },
  downloadDataDiv: {
    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      marginTop: "15px",
      display: "flex",
      padding: "14px",
      justifyContent: "center"
    },
    /* Tablet, Laptops and Desktops */
    "@media screen and (min-device-width: 768px)": {
      display: "flex",
      justifyContent: "end"
    },
  },
  collapseIcon: {
    position: "absolute",
    zIndex: "1",
    marginLeft: "15px",
    borderRadius: "0 0 15px 0",
    backgroundColor: "#233750",

    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      display: "none !important"
    },
  },
  filterGrid: {
    /* Tablet View */
    "@media screen and (min-device-width: 768px) and (max-device-width: 1024px)": {
      overflow: "auto",
      maxHeight: "2800px",
    },
    /* Laptops and Desktops */
    "@media screen and (min-device-width: 992px)": {
      overflow: "auto",
      maxHeight: "1800px",
    }
  },
  filtersContainer: {
    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      display: "none"
    },
  },
  filterBtn: {
    /* Laptops and Desktops */
    "@media screen and (min-device-width: 992px)": {
      display: "none"
    }
  },
  disableInMobileView: {
    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      display: "none"
    },
  },
  onlyMobileView: {
    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      marginTop: "15px"
    },
    /* Tablet View */
    "@media screen and (min-device-width: 768px) and (max-device-width: 1024px)": {
      display: "none"
    },
    /* Laptops and Desktops */
    "@media screen and (min-device-width: 992px)": {
      display: "none",
    }
  }
};

export default serviceSearchStyle;
