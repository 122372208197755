import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import api from "../../../../utils/api";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

class AdditionalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: {},
      attachments: [],
      contact_persons: [],
      // service_type: ""
      contact_person_email: "",
      contact_person_name: "",
      contact_person_email_2: "",
      contact_person_name_2: ""    
    };
  }
  getCategory = (category = 4) => {
    api.get(
      `/categories/${category}`,
      data => {
        this.setState({ category: data });
      },
      () => {}
    );
  };


  componentDidMount() {
    
    this.props.categoryID && this.getCategory(this.props.categoryID);
    const { contact_persons = [] } = this.props.data || {};
    const { contact_person_email = "" } = this.props.data || {};
    const { contact_person_name = "" } = this.props.data || {};

    const { contact_person_email_2 = "" } = this.props.data || {};
    const { contact_person_name_2 = "" } = this.props.data || {};
    
    const temp = contact_persons.map(el => {
      return {
        value: el.id,
        label: el.full_name
      };
    });
    this.setState({ contact_persons: temp, contact_person_email, contact_person_name, contact_person_email_2, contact_person_name_2 });
  }

  change(event, stateName, type, stateNameEqualTo) {
    let value = null;
    switch (type) {
      case "length":
        value = event.target.value;
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "image":
        value = stateNameEqualTo;
        break;
      case "switch":
        value = event.target.checked;
        break;
      default:
        value = event.target.value;
        break;
    }
    this.setState({ [stateName]: value });
  }
 
  isValidated = () => {
    return true;
  };
  sendState = () => {
    let data = { ...this.state };
    // data.service_type = this.state.service_type;
    
    data.contact_person_email = this.state.contact_person_email;
    data.contact_person_name = this.state.contact_person_name;
    data.contact_person_email_2 = this.state.contact_person_email_2;
    data.contact_person_name_2 = this.state.contact_person_name_2;

    data.contact_persons = this.state.contact_persons.map(user => user.value);
    return data;
  };
  // handleChange = (name, value) => {
  //   debugger;
  //   this.setState({ [name]: value });
  //   debugger;
  // };

  handleChange = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;    
    this.setState({ [name]: value });
  };
  
  handleServiceTypeChange = evt => {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
  };
  render() {
    return (
      <div>
          
        {/* <GridContainer>
          {category.allow_contact_persons ? (
            <GridItem xs={12}>
              <UsersAutoComplete
                name="contact_persons"
                id="contact_persons"
                label="Contact Persons"
                userRole="service_admin"
                isMulti={true}
                options={[]}
                isRemote={true}
                placeholder="Select contact persons..."
                value={this.state.contact_persons}
                onChange={this.handleChange}
              />
            </GridItem>
          ) : (
            "Contact persons are not required"
          )}
        </GridContainer>
        
 */}
            <GridItem xs={12} md={6}>
              <CustomInput
                success={this.state.contact_person_nameState === "success"}
                error={this.state.contact_person_nameState === "error"}
                labelText={<span>contact_person_name</span>}
                id="contact_person_name"
                value={this.state.contact_person_name}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: this.handleChange,
                  value: this.state.contact_person_name
                }}
              />
            </GridItem>

            <GridItem xs={12} md={6}>
              <CustomInput
                success={this.state.contact_person_emailState === "success"}
                error={this.state.contact_person_emailState === "error"}
                labelText={<span>contact_person_email</span>}
                id="contact_person_email"
                value={this.state.contact_person_email}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: this.handleChange,
                  value: this.state.contact_person_email
                }}
              />
            </GridItem>
            
            <GridItem xs={12} md={6}>
              <CustomInput
                success={this.state.contact_person_name_2_State === "success"}
                error={this.state.contact_person_nameState_2 === "error"}
                labelText={<span>contact_person_name</span>}
                id="contact_person_name_2"
                value={this.state.contact_person_name_2}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: this.handleChange,
                  value: this.state.contact_person_name_2
                }}
              />
            </GridItem>

            <GridItem xs={12} md={6}>
              <CustomInput
                success={this.state.contact_person_email_2_State === "success"}
                error={this.state.contact_person_email_2_State === "error"}
                labelText={<span>contact_person_email</span>}
                id="contact_person_email_2"
                value={this.state.contact_person_email_2}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: this.handleChange,
                  value: this.state.contact_person_email_2
                }}
              />
            </GridItem>
       
      </div>
    );
  }
}
export default withStyles({}, { theme: true })(AdditionalInfo);
