import api from "../utils/api";
import {
  GET_TIMEINFO,
  INSERT_TIMEINFO,
  UPDATE_TIMEINFO,
  DELETE_TIMEINFO
} from "../utils/types";

export const getTimeInfo = (id, ...extra) => ({
  type: GET_TIMEINFO,
  payload: api.get(`/timeInfos/${id}`, null, ...extra)
});
export const insertTimeInfo = (data, ...extra) => ({
  type: INSERT_TIMEINFO,
  payload: api.post(`/timeInfos`, data, ...extra)
});
export const updateTimeInfo = (data, ...extra) => ({
  type: UPDATE_TIMEINFO,
  payload: api.put(`/timeInfos/${data.id}`, data, ...extra)
});
export const deleteTimeInfo = (id, ...extra) => ({
  type: DELETE_TIMEINFO,
  payload: api.delete(`/timeInfos/${id}`, null, ...extra)
});
