export const QUESTION_SWITCH = "SWITCH";
export const QUESTION_TEXT = "TEXT";
export const QUESTION_LINK = "LINK";

export const FULFILLED = "_FULFILLED";
export const REJECTED = "_REJECTED";
export const PENDING = "_PENDING";

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_ME = "AUTH_ME";


// services actions
export const GET_SERVICES = "GET_SERVICES";
export const GET_SERVICE = "GET_SERVICE";
export const GET_PUBLIC_SERVICES = "GET_PUBLIC_SERVICES";

// services actions
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_PUBLIC_CATEGORIES = "GET_PUBLIC_CATEGORIES";
export const GET_PUBLIC_AGENCIES = "GET_PUBLIC_AGENCIES";
export const GET_CATEGORY = "GET_CATEGORY";
export const INSERT_CATEGORY = "INSERT_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

// services actions
export const GET_FEATURES = "GET_FEATURES";
export const GET_FEATURE = "GET_FEATURE";
export const INSERT_FEATURE = "INSERT_FEATURE";
export const UPDATE_FEATURE = "UPDATE_FEATURE";
export const DELETE_FEATURE = "DELETE_FEATURE";

// services actions
export const GET_SERVICE_TYPES = "GET_SERVICE_TYPES";
export const GET_SERVICE_TYPE = "GET_SERVICE_TYPE";
export const INSERT_SERVICE_TYPE = "INSERT_SERVICE_TYPE";
export const UPDATE_SERVICE_TYPE = "UPDATE_SERVICE_TYPE";
export const DELETE_SERVICE_TYPE = "DELETE_SERVICE_TYPE";

// services actions
export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_QUESTION = "GET_QUESTION";
export const INSERT_QUESTION = "INSERT_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";

// services actions
export const GET_TIMEINFOS = "GET_TIMEINFOS";
export const GET_TIMEINFO = "GET_TIMEINFO";
export const INSERT_TIMEINFO = "INSERT_TIMEINFO";
export const UPDATE_TIMEINFO = "UPDATE_TIMEINFO";
export const DELETE_TIMEINFO = "DELETE_TIMEINFO";

// services actions
export const GET_SLOTINFOS = "GET_SLOTINFOS";
export const GET_SLOTINFO = "GET_SLOTINFO";
export const INSERT_SLOTINFO = "INSERT_SLOTINFO";
export const UPDATE_SLOTINFO = "UPDATE_SLOTINFO";
export const DELETE_SLOTINFO = "DELETE_SLOTINFO";
export const GET_PUBLIC_SLOTS = "DELETE_SLOTINFO";


// services actions
export const GET_AGENCIES = "GET_AGENCIES";
export const GET_AGENCIES_FOR_MAP = "GET_AGENCIES_FOR_MAP";
export const DELETE_AGENCY = 'DELETE_AGENCY';
export const GET_AGENCY = 'GET_AGENCY';
export const INSERT_AGENCY = 'INSERT_AGENCY';
export const UPDATE_AGENCY = 'UPDATE_AGENCY';

// search services
export const SEARCH_GET_SERVICES = 'SEARCH_GET_SERVICES';

//quick search
export const GET_QUICK_SEARCH_LIST = 'GET_QUICK_SEARCH_LIST';
export const GET_QUICK_SEARCH_MAP = 'GET_QUICK_SEARCH_MAP';

//get all hotlines
export const GET_HOTLINES = 'GET_HOTLINES';
export const DELETE_HOTLINE = 'DELETE_HOTLINE';

//get all hotline categories
export const GET_HOTLINE_CATEGORIES = 'GET_HOTLINE_CATEGORIES';
