import {
  formatAddress,
  formatPhoneNumber,
  getAccreditations,
  isServiceOpenToday,
  getTimingsText,
  getSlotInfo,
} from "./serviceUtils";

export const formatMapAPIResponse = (data) => {
  // creating new array for map pins and pushing only required fields into it
  // so that agencies.map function stays simple
  // It separates services in their location is different from their agency
  const agencyMarkers = [];
  // to use it as key when doing array.map
  let markerCount = 0;
  if (data) {
    data.forEach((agency) => {
      const currentAgencyMarkers = [];
      agency.services.forEach((service) => {
        // check if we have agencyMarker at service's location
        let agencyMarker = currentAgencyMarkers.find(
          (agencyMarker) =>
            agencyMarker.lat === Number(service.lat) &&
            agencyMarker.lng === Number(service.lng)
        );
        if (!agencyMarker) {
          agencyMarker = {
            key: markerCount++,
            name: agency.name,
            lat: Number(service.lat),
            lng: Number(service.lng),
            isOpen: false,
            accreditations: getAccreditations(agency.accreditations),
            services: [],
          };
          currentAgencyMarkers.push(agencyMarker);
        }
        // find todays open and close time
        const todaysHours = service.service_hours.find(
          (timeInfo) => timeInfo.pivot.day === new Date().getDay()
        );

        // if even one service is open then agency is open
        if (
          !agencyMarker.isOpen &&
          todaysHours &&
          isServiceOpenToday(todaysHours)
        ) {
          agencyMarker.isOpen = true;
        }

        const [openingText, closingText] = getTimingsText(todaysHours);
        agencyMarker.services.push({
          id: service.id,
          name: service.name,
          address: formatAddress(service.address),
          website: service.website,
          phoneNumber: formatPhoneNumber(service.phone_number),
          slotInfo: getSlotInfo(service.slot_infos, service.updated_at),
          openingText,
          closingText,
        });
      });
      agencyMarkers.push(...currentAgencyMarkers);
    });
  }
  return agencyMarkers;
};

export const getMilesBetweenLatLng = (lat1, lng1, lat2, lng2) => {
  let R = 6371; // Radius of the earth in km
  let dLat = deg2rad(lat2 - lat1); // deg2rad below
  let dLng = deg2rad(lng2 - lng1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLng / 2) *
    Math.sin(dLng / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let distanceInMile = R * c * 0.621371; // Distance in km
  return distanceInMile.toFixed(2);
};

export const getDistanceStringLong = (lat1, lng1, lat2, lng2) => {
  if (lat1 && lng1 && lat2 && lng2)
    return `${getMilesBetweenLatLng(lat1, lng1, lat2, lng2)} miles away`;
};

export const getDistanceStringShort = (lat1, lng1, lat2, lng2) => {
  if (lat1 && lng1 && lat2 && lng2)
    return `${getMilesBetweenLatLng(lat1, lng1, lat2, lng2)} mi`;
};

const deg2rad = (deg) => deg * (Math.PI / 180);
