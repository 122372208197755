import {
    GET_SLOTINFO,
    INSERT_SLOTINFO,
    UPDATE_SLOTINFO,
    DELETE_SLOTINFO,
    FULFILLED,
    REJECTED,
    PENDING
  } from "../utils/types";
  const initialState = {
    data: {},
    getError: false,
    updateError: false,
    deleteError: false,
    insertError: false,
    getLoading: false,
    updateLoading: false,
    deleteLoading: false,
    insertLoading: false
  };
  
  export default function slotReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      // GET
      case `${GET_SLOTINFO + PENDING}`:
        return { ...state, getError: false, getLoading: true };
      case `${GET_SLOTINFO + FULFILLED}`:
        return { ...state, data: payload, getError: false, getLoading: false };
      case `${GET_SLOTINFO + REJECTED}`:
        return { ...state, getError: true, getLoading: false, data: {} };
      // UPDATE
      case `${UPDATE_SLOTINFO + PENDING}`:
        return { ...state, updateError: false, updateLoading: true };
      case `${UPDATE_SLOTINFO + FULFILLED}`:
        return {
          ...state,
          data: payload,
          updateError: false,
          updateLoading: false
        };
      case `${UPDATE_SLOTINFO + REJECTED}`:
        return { ...state, updateError: true, updateLoading: false };
      // DELETE
      case `${DELETE_SLOTINFO + PENDING}`:
        return { ...state, deleteError: false, deleteLoading: true };
      case `${DELETE_SLOTINFO + FULFILLED}`:
        return {
          ...state,
          data: payload,
          deleteError: false,
          deleteLoading: false
        };
      case `${DELETE_SLOTINFO + REJECTED}`:
        return { ...state, deleteError: true, deleteLoading: false};
      // INSERT
      case `${INSERT_SLOTINFO + PENDING}`:
        return { ...state, insertError: false, insertLoading: true };
      case `${INSERT_SLOTINFO + FULFILLED}`:
        return {
          ...state,
          data: payload,
          insertError: false,
          insertLoading: false
        };
      case `${INSERT_SLOTINFO + REJECTED}`:
        return { ...state, insertError: true, insertLoading: false};
      default:
        return state;
    }
  }
  