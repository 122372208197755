import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import api from "../../utils/api";
import { Helmet } from "react-helmet";
import { injectIntl } from "react-intl";
import { withSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";

import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import AboutStep from "./WizardSteps/ServiceEdit/AboutStep";
import TimeInfoStep from "./WizardSteps/ServiceEdit/TimeInfoStep";
import FeaturesStep from "./WizardSteps/ServiceEdit/FeaturesStep";
import AdditionalInfoStep from "./WizardSteps/ServiceEdit/AdditionalInfoStep";

import moment from "moment";

const intl = window.intl;

class ServiceEditPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryID: "",
      serviceID: "",
      agencyID: "",
      id: "",
      loadingServiceDetails: true,
      foundService: true,
      service: null
    };
  }
  getServiceDetails = async serviceID => {
    await api.get(
      `/services/${serviceID}`,
      data => {
        let time_info_data = {};
        (data.time_info_data || []).forEach(info => {
          let existing = time_info_data[info.id] || [];
          let pivot = info.pivot;
          pivot.start = moment(pivot.start, "HH:mm:ss");
          pivot.end = moment(pivot.end, "HH:mm:ss");
          const { id, active, start, end, time_info_id, day } = pivot;
          existing.push({ id, active, start, end, time_info_id, day });
          time_info_data[info.id] = existing;
        });
        data.time_info_data = time_info_data;
       
        let question_data = {};
        (data.question_data || []).forEach(info => {
          let pivot = info.pivot;
          const { id, answer } = pivot;
          question_data[info.id] = { id, answer };
        });
        data.question_data = question_data;
        this.setState({
          service: data,
          categoryID: data.category.id,
          
          agencyID: data.agency,
          loadingServiceDetails: false,
          foundService: true
        });
      },
      error => {
        this.setState({
          service: null,
          loadingServiceDetails: false,
          foundService: false
        });
      }
    );
  };
  componentWillMount() {
    const params = new URLSearchParams(this.props.location.search);
    const id = (this.props.match.params || {}).serviceID;
    const categoryID = params.get("category");
    
    const agencyID = params.get("agency");
    let requiredParamsFound = id || (agencyID && categoryID);
    if (!requiredParamsFound) {
      this.setState({ requiredParamsFound, loadingServiceDetails: false });
      return;
    }
    this.setState({
      id,
      categoryID,
      agencyID,
      requiredParamsFound,
      foundService: true
    });
    if (id) {
      this.getServiceDetails(id);
    } else {
      this.setState({
        loadingServiceDetails: false,
        foundService: true,
        service: {}
      });
    }
  }

  saveService = allStates => {
    let data = 
      {
      ...allStates.about,
      timeInfo: allStates.timeInfo,
      questionData: allStates.features,
      ...allStates.additionalInfo 
      
      // ...allStates.about,
      // ...allStates.timeInfo,
      // ...allStates.questionData,
      // ...allStates.additionalInfo 
      
    };
    data.agency = this.state.agencyID.id;
    data.category = this.state.categoryID;
    // debugger;
    if(this.state.id) {
      // debugger;
      var tempId = this.state.id;
      tempId = tempId.split('&')[0];
      // debugger;
      api.put(`/services/${tempId}`, data, data => {
        this.props.history.goBack();
      });
    } else {
      data.agency = this.state.agencyID;
      api.post(`/services`, data, data => {
        this.props.history.goBack();
      });
    }
  };
  render() {
    const {
      loadingServiceDetails,
      requiredParamsFound = true,
      foundService = true,
      categoryID,      
      agencyID,
      service
    } = this.state;

    const { classes } = this.props;
    
    return (
      <div>
        {loadingServiceDetails && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress className={classes.progress} color="secondary" />
            <p>Loading details</p>
          </div>
        )}
        {!requiredParamsFound && <p>Agency and Service details not found</p>}
        {!foundService && <p>Service details not found</p>}
        {foundService && service && (
          <GridContainer justify="center">
            <Helmet>
              <meta charSet="utf-8" />
              <title>
                {intl.formatMessage({
                  id: "service"
                }) +
                  " | " +
                  intl.formatMessage({
                    id: "title"
                  })}
              </title>
              <link rel="canonical" href={window.location.href} />
            </Helmet>
            <GridItem xs={12} sm={8}>
              <Wizard
                validate={true}
                
                steps={[
                  {
                    stepName: "About",
                    stepComponent: AboutStep,
                    stepId: "about",
                    agencyID: agencyID,
                    serviceID: service.id,
                    categoryID: categoryID,
                    data: service || {service_type:  (service || {}).service_type},
                                      
                  },
                  {
                    stepName: "Time Information",
                    stepComponent: TimeInfoStep,
                    stepId: "timeInfo",
                    categoryID: categoryID,
                    data: service.time_info_data
                  },
                  {
                    stepName: "Features",
                    stepComponent: FeaturesStep,
                    stepId: "features",
                    categoryID: categoryID,
                    data:service.question_data
                  },
                  {
                    stepName: "Service Contacts",
                    stepComponent: AdditionalInfoStep,
                    stepId: "additionalInfo",
                    categoryID: categoryID,
                    // data: { contact_persons: (service || {}).contact_persons, service_type:  (service || {}).service_type}
                    // data: { contact_persons: (service || {}).contact_persons, contact_person_email: (service || {}).contact_person_email}
                    data: { contact_person_email: (service || {}).contact_person_email, contact_person_name: (service || {}).contact_person_name, contact_person_email_2: (service || {}).contact_person_email_2, contact_person_name_2: (service || {}).contact_person_name_2}
                  }
                ]}
                title="Create a service"
                subtitle="This information will let us know more about your service."
                finishButtonClick={this.saveService}
                adminScrollToTop={this.props.adminScrollToTop}
                color="primary"
              />
            </GridItem>
          </GridContainer>
        )}
      </div>
    );
  }
}

export default injectIntl(
  withStyles({}, { withTheme: true })(withSnackbar(ServiceEditPage))
);
