import { combineReducers } from "redux";
import auth from "./reducer_auth";
import services from "./reducer_services";
import service from "./reducer_service";
import categories from "./reducer_categories";
import category from "./reducer_category";
import features from "./reducer_features";
import feature from "./reducer_feature";
import serviceType from "./reducer_serviceType";
import agencies from "./reducer_agencies";
import searchStore from "./reducer_search";
import questions from "./reducer_question";
import slots from "./reducer_slot";
import quickSearchListData from "./reducer_quickSearchList";
import quickSearchMapData from "./reducer_quickSearchMap";
import hotlines from "./reducer_hotlines";
import hotlinecategories from "./reducer_hotline_categories";

export default combineReducers({
  auth,
  agencies,
  services,
  service,
  categories,
  category,
  features,
  feature,
  serviceType,
  searchStore,
  questions,
  slots,
  quickSearchListData,
  quickSearchMapData,
  hotlines,
  hotlinecategories,
});
