import React, { Component } from "react";
import classNames from "classnames";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NoSsr from "@material-ui/core/NoSsr";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import Paper from "@material-ui/core/Paper";
import api from "../../utils/api";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  input: {
    display: "flex",
    padding: 0
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}
function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}
function Option(props) {
  const { label = "" } = props;
  const parts = label.split(" ");
  let avatarText = "";
  if (parts.length > 1) {
    avatarText = parts[0].charAt(0) + parts[1].charAt(0);
  } else {
    avatarText = parts[0].substr(0, 2);
  }
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      <ListItemIcon>
        <Avatar>{avatarText}</Avatar>
      </ListItemIcon>
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}
function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

export class UsersAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      options: props.options || []
    };
  }
  componentDidMount = () => {
    this.setState({ value: this.props.value });
  };
  componentWillUpdate = (nextProps, nextState) => {
    if ((this.props.value||{}).value !== (nextProps.value||{}).value) {
      this.setState({ value: nextProps.value });
    }
  };

  getUsers = async () => {
    const role = this.props.userRole ||"";
    await api.get(
      "/modelSearch/users?role="+role,
      (data = []) => {
        this.setState({
          options: data.map(user => {
            return {
              value: user.id,
              label: `${user.first_name} ${user.last_name}`
            };
          }),
          value: this.props.value
        });
      },
      e => {
        console.log(e);
      }
    );
  };
  componentDidMount = () => {
    if (this.props.isRemote) {
      this.getUsers();
    } else {
      this.setState({value:this.props.value})
    }
  };

  handleChange = name => value => {
    this.setState({
      [name]: value
    });
    this.props.onChange && this.props.onChange(this.props.name, value);
  };

  render() {
    const { classes, theme, placeholder, label = "", isMulti=false } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit"
        }
      })
    };
    const { options = [] } = this.state;
    return (
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          isMulti={isMulti}
          textFieldProps={{
            label: label,
            InputLabelProps: {
              shrink: true
            }
          }}
          options={options}
          components={components}
          value={this.state.value}
          onChange={this.handleChange("value")}
          placeholder={placeholder}
        />
      </NoSsr>
    );
  }
}
export default withStyles(styles, { withTheme: true })(UsersAutoComplete);
