import api from '../utils/api';
import {GET_CATEGORIES, GET_CATEGORY, GET_PUBLIC_CATEGORIES} from '../utils/types';


export const getCategories = ({nameLike="",page=1, limit=9}, ...extra) => ({
    type: GET_CATEGORIES,
    payload: api.get(`/categories?nameLike=${nameLike}&page=${page}&limit=${limit}`,null,...extra)
});
export const getPublicCategories = ({nameLike="",page=1, limit=9}, ...extra) => ({
    type: GET_PUBLIC_CATEGORIES,
    payload: api.get(`/modelSearch/categories?nameLike=${nameLike}&page=${page}&limit=${limit}`,null,...extra)
});

export const getCategory = (id, ...extra) => ({
    type: GET_CATEGORY,
    payload: api.get(`/categories/${id}`,null,...extra)
});