import moment from "moment";

export const formatAddress = (address) => {
  if (address && address[1] === ",") return address.substr(2);
  return address;
};

export const formatPhoneNumber = (phoneNumberString) => {
  if (phoneNumberString && phoneNumberString.length >= 10) {
    // remove all spaces from the string
    const phoneNumber = phoneNumberString.replace(/[-\s()]/g, "");
    const str1 = phoneNumber.substring(0, 3);
    const str2 = phoneNumber.substring(3, 6);
    const str3 = phoneNumber.substr(6, 9);
    return "(" + str1 + ") " + str2 + "-" + str3;
  }
  return phoneNumberString;
};

export const getSlotInfo = (slotInfo, updated_at, return_slot_number_only = false) => {
  let last_updated_time = moment(updated_at);

  // if time is in daylight saving, add an hour
  if (last_updated_time.isDST()) {
    last_updated_time.add(1, "hours");
  }
  const last_updated_str = `(Last updated ${last_updated_time.fromNow()})`;

  if (slotInfo && slotInfo.length > 0) {
    let slots = 0;
    let wait_time = 0;
    let walk_in;

    // slotinfo.length should be 1 but we're looping here for
    // backward compatibility
    slotInfo.forEach((infoObj) => {
      // console.log("Information Object",infoObj)
      slots = slots + parseInt(infoObj.availability);
      wait_time = wait_time + parseInt(infoObj.wait_time);
      walk_in = infoObj.walk_in
    });

    if (return_slot_number_only) {
      const last_updated = `(updated ${last_updated_time.fromNow()})`;
      return { slots: slots, wait_time: wait_time, last_updated_str: last_updated, walk_in: walk_in };
    }
    else {
      if (wait_time === 1)
        return `Current wait time is about 1 day 
        ${last_updated_str}`;

      if (wait_time > 1)
        return `Current wait time is about ${wait_time} days 
        ${last_updated_str}`;

      if (slots === 1)
        return `${slots} slot is available 
        ${last_updated_str}`;

      if (slots > 1)
        return `${slots} slots are available 
        ${last_updated_str}`;

      if (slots === 0 && wait_time === 0)
        return `0 slots are available 
        ${last_updated_str}`;
    }
  }
  return "";
};

export const getAccreditations = (accreditations) => {
  let accreditationsString = "";
  if (accreditations.length > 0 && accreditations[0] === ";") {
    accreditationsString = accreditations.replace(";", "");
  }
  accreditationsString = accreditationsString.trim();
  accreditationsString = accreditationsString.replace(
    new RegExp(String.fromCharCode(160), "g"),
    ""
  ); //&nbsp
  accreditationsString = accreditationsString.replace(
    new RegExp(";", "gi"),
    ", "
  );
  return accreditationsString;
};

// check if service is open or closed today
export const isServiceOpenToday = (todaysHours) => {
  const openTime = new moment(todaysHours.pivot.start, "HH:mm:ss");
  const closeTime = new moment(todaysHours.pivot.end, "HH:mm:ss");
  if (
    moment().diff(openTime, "minutes") > 0 &&
    moment().diff(closeTime, "minutes") < 0
  ) {
    return true;
  }
  return false;
};

export const getTimingsText = (todaysHours) => {
  if (todaysHours) {
    const openTime = new moment(todaysHours.pivot.start, "HH:mm:ss");
    const closingTime = new moment(todaysHours.pivot.end, "HH:mm:ss");
    if (moment().diff(openTime, "minutes") < 0) {
      let opensAt = openTime
        .format("hh:mm a")
        .toString()
        .toUpperCase();
      let closesAt = closingTime
        .format("hh:mm a")
        .toString()
        .toUpperCase();
      return [" ", opensAt + " - " + closesAt];
    }
    else if (moment().diff(closingTime, "minutes") > 0) {
      return ["", "Closed for today"]
    }
    else {
      let closesAt = closingTime
        .format("hh:mm a")
        .toString()
        .toUpperCase();
      return ["OPEN -", " Closes " + closesAt];
    }
  }
  return ["", ""];
};

export const getStateMap = () => {
  let map = new Map();
  map.set("Alabama", "AL");
  map.set("Alaska", "AK");
  map.set("American Samoa", "AS");
  map.set("Arizona", "AZ");
  map.set("Arkansas", "AR");
  map.set("Armed Forces Americas", "AA");
  map.set("Armed Forces Europe", "AE");
  map.set("Armed Forces Pacific", "AP");
  map.set("California", "CA");
  map.set("Colorado", "CO");
  map.set("Connecticut", "CT");
  map.set("Delaware", "DE");
  map.set("District Of Columbia", "DC");
  map.set("Florida", "FL");
  map.set("Georgia", "GA");
  map.set("Guam", "GU");
  map.set("Hawaii", "HI");
  map.set("Idaho", "ID");
  map.set("Illinois", "IL");
  map.set("Indiana", "IN");
  map.set("Iowa", "IA");
  map.set("Kansas", "KS");
  map.set("Kentucky", "KY");
  map.set("Louisiana", "LA");
  map.set("Maine", "ME");
  map.set("Marshall Islands", "MH");
  map.set("Maryland", "MD");
  map.set("Massachusetts", "MA");
  map.set("Michigan", "MI");
  map.set("Minnesota", "MN");
  map.set("Mississippi", "MS");
  map.set("Missouri", "MO");
  map.set("Montana", "MT");
  map.set("Nebraska", "NE");
  map.set("Nevada", "NV");
  map.set("New Hampshire", "NH");
  map.set("New Jersey", "NJ");
  map.set("New Mexico", "NM");
  map.set("New York", "NY");
  map.set("North Carolina", "NC");
  map.set("North Dakota", "ND");
  map.set("Northern Mariana Islands", "NP");
  map.set("Ohio", "OH");
  map.set("Oklahoma", "OK");
  map.set("Oregon", "OR");
  map.set("Pennsylvania", "PA");
  map.set("Puerto Rico", "PR");
  map.set("Rhode Island", "RI");
  map.set("South Carolina", "SC");
  map.set("South Dakota", "SD");
  map.set("Tennessee", "TN");
  map.set("Texas", "TX");
  map.set("US Virgin Islands", "VI");
  map.set("Utah", "UT");
  map.set("Vermont", "VT");
  map.set("Virginia", "VA");
  map.set("Washington", "WA");
  map.set("West Virginia", "WV");
  map.set("Wisconsin", "WI");
  map.set("Wyoming", "WY");
  return map;
};

export const getUpdatedAddress = (address) => {
  var updatedAdd = "";
  let stateMap = getStateMap();
  stateMap && stateMap.forEach((value, key) => {
    if (address.includes(key)) {
      var regex = new RegExp('\\b' + key + '\\b');
      updatedAdd = address.replace(regex, value);
    }
  });
  // If state not found then returns existing state address else returns updated address
  return updatedAdd !== "" ? updatedAdd : address;
};