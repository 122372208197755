import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { getHotlines } from "../../../actions/action_hotlines";
import { deleteHotline } from "../../../actions/action_hotlines";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import { withSnackbar } from "notistack";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Grid,
  Paper,
  IconButton,
  InputBase,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import hotlinesStyle from "assets/jss/material-dashboard-pro-react/views/hotlinesStyle.jsx";
import { getPageTitle } from "utils/adminUtils";



import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import PropTypes from "prop-types";
import Paginations from "components/Pagination/Pagination.jsx";
import EditIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import Switch from '@material-ui/core/Switch';
import api from "../../../utils/api";
import PopupView from "../PopupView";

const intl = window.intl;
class Hotlines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      deleteId: "",
      deleteName: ""
    };
  }

  componentDidMount() {
    this.searchHotlines();
  }

  searchHotlines = (pageNo = 1, sortby = "name", issorting = 1) => {
    this.props.getHotlines({ nameLike: this.state.search, page: pageNo, sortBy: sortby, isSorting: issorting });
  };

  createHotlineBtn = () => {
    const { classes = {} } = this.props;
    return (
      <Grid xs={1} item>
        <Tooltip
          title={intl.formatMessage({
            id: "view.hotlines.addHotline",
          })}
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
        >
          <Link to={`/admin/edit-hotline`}>
            <IconButton
              className={classes.iconButton}
              aria-label={intl.formatMessage({
                id: "view.hotlines.addHotline",
              })}
            >
              <AddCircleIcon color="primary" fontSize="large" />
            </IconButton>
          </Link>
        </Tooltip>
      </Grid>
    );
  };


  editHotlineBtn = (drole, classes, hotline) => {
    if (drole !== "serviceadmin") {
      return <Tooltip
        title={intl.formatMessage({
          id: "view.hotlines.editHotline"
        })}
        placement="bottom"
        classes={{ tooltip: classes.tooltip }}
      >
        <Link to={`/admin/hotline/${hotline.id}`}>
          <IconButton
            color="primary"
            aria-label={intl.formatMessage({
              id: "view.hotlines.editHotline"
            })}
          >
            <EditIcon />
          </IconButton>
        </Link>
      </Tooltip>
    }
  }

  getConfirmPopUp = (message) => {
    return (
      <PopupView
        openPopup = {this.state.openPopup} 
        callbackFun = {this.callbackFun}
        updateProps = {this.updateProps}
        message = {message}
      />  
    );
  };

  updateProps = (name, value) => {
    this.setState({[name] : value});
  }

  callbackFun = () => {
    this.deleteHotline(this.state.deleteId, this.state.deleteName);
  }

  callDeletePopup = (id, name) => {
    this.setState({
      deleteName : name,
      deleteId : id,
      openPopup: true,
    });
  }

  deleteHotline = (id, name) => {
    // if (!window.confirm("Are you sure you want to delete '" + name + "'")) {
    //   return false;
    // }
    this.props.deleteHotline(
      id,
      () => {
        this.props.enqueueSnackbar("Hotline deleted successfully", {
          variant: "success"
        });
        this.searchHotlines();
      },
      () => {
        this.props.enqueueSnackbar("Unable to delete '" + name + "'", {
          variant: "error"
        });
        this.searchHotlines();
      }
    );
  };


  deleteHotlineBtn = (drole, classes, hotline) => {
    if (drole === "admin") {
      return <Tooltip
        title="Delete"
        placement="bottom"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="Delete"
          className={classes.margin}
          onClick={() =>
            this.callDeletePopup(hotline.id, hotline.name)
          }
        >
          <DeleteIcon
            color="secondary"
            className={classes.margin}
          />
        </IconButton>
      </Tooltip>;
    }

  }


  enableHotlines = (drole, classes, hotline) => {
    if (drole === "admin") {
      return (<Switch
        checked={hotline.active === 1}
        onChange={() => this.handleHotlineSwitch(hotline)}
        value="checkedB"
        color="primary"
      />)
    }
  }

  handleHotlineSwitch = (hotline) => {
    api.put(
      `/hotlinestatus/${hotline.id}`,
      {
        "id": hotline.id,
        "active": hotline.active,
      },
      (res) => {
        this.props.enqueueSnackbar("Hotline updated successfully", {
          variant: "success",
        });
        this.searchHotlines();
        // this.props.history.replace("/admin/hotlines");
        return false;
      },
      (error) => {
        this.props.enqueueSnackbar("Error occured while updating hotline", {
          variant: "error",
        });
        return false;
      }
    );
  }

  getEnteredPage = (evt) => {
    this.searchHotlines(evt);
    let html = document.getElementsByTagName("html");
    let body = document.getElementsByTagName("body");
    if (body && body[0]) {
      body[0].scrollTop = 0;
    }
    if (html && html[0]) {
      html[0].scrollTop = 0;
    }
  }


  getPagination = () => {
    const { hotlines = {} } = this.props;
    const { last_page = 1, current_page = 1 } = hotlines;
    if (last_page <= 1) {
      return null;
    }
    let pages = Array(last_page)
      .fill(0)
      .map((e, i) => {
        return {
          onClick: this.gotoPage,
          text: i + 1,
          active: current_page === i + 1
        };
      });
    pages.unshift({
      text: intl.formatMessage({ id: "prev" }),
      onClick: this.gotoPrevPage
    });
    pages.push({
      text: intl.formatMessage({ id: "next" }),
      onClick: this.gotoNextPage
    });

    pages.push({
      text: intl.formatMessage({ id: `${current_page} of ${last_page}` }),
    });

    return <Paginations pages={pages} color="info" currentPage={current_page} lastPage={last_page} gotoEnteredPage={this.getEnteredPage} />;
  };

  gotoPage = evt => {
    this.searchHotlines(evt.target.innerText);
  };

  gotoNextPage = () => {
    const { hotlines = {} } = this.props;
    const { last_page = 1, current_page = 1 } = hotlines;
    if (current_page < last_page) {
      this.searchHotlines(current_page + 1);
    }
  };
  gotoPrevPage = () => {
    const { hotlines = {} } = this.props;
    const { current_page = 1 } = hotlines;
    if (current_page > 1) {
      this.searchHotlines(current_page - 1);
    }
  };


  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.searchHotlines(1, "name", 0);
    }, 500);
  };

  render() {

    const { classes, hotlines = {} } = this.props;
    const { search, deleteName, openPopup} = this.state;
    const { data = {}, error = false, loading = false } = hotlines;
    //const baseURL = (process.env.NODE_ENV !== 'production') ? `http://localhost:8000/api/v1` : `/api/v1`;
    const drugrole = sessionStorage.getItem("drughelp-role");
    return (
      <Grid
        container
        spacing={24}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{getPageTitle("hotlines")}</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Grid item xs={11} sm={9}>
          <Paper className={classes.root} elevation={1}>
            <IconButton className={classes.iconButton} aria-label="Menu" />
            <InputBase
              id="search"
              autoFocus
              value={this.state.search}
              onChange={this.handleChange}
              className={classes.input}
              placeholder={intl.formatMessage({
                id: "view.hotlines.searchHotlines",
              })}
            />
            <IconButton
              className={classes.iconButton}
              aria-label={intl.formatMessage({ id: "view.hotlines.search" })}
            >
              {loading ? <CircularProgress /> : <SearchIcon />}
            </IconButton>
          </Paper>
        </Grid>
        {this.createHotlineBtn(drugrole, classes)}
        {!loading && (
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={8}>
              {/* Error */}
              {error && (
                <SnackbarContent
                  message={intl.formatMessage({
                    id: "view.hotlines.searchError"
                  })}
                  color="danger"
                />
              )}
              {/* No agencies found */}
              <Typography variant="subtitle1" gutterBottom align="center">
                {!error &&
                  data &&
                  data.length === 0 &&
                  (search.length > 0
                    ? intl.formatMessage({
                      id: "view.hotlines.noResultsFound"
                    })
                    : intl.formatMessage({
                      id: "view.hotlines.noHotlinesAvailable"
                    }))}
              </Typography>
            </Grid>
            {/* Found agencies */}
            {!error && data.length > 0 && (
              <GridContainer
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem
                  xs={12}
                  sm={6}
                  style={{ textAlign: "left" }}
                  align="center"
                >
                  {search &&
                    intl.formatMessage(
                      {
                        id: "view.hotlines.searchResult"
                      },
                      { count: data.length, term: search }
                    )}
                </GridItem>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start"  >
                  {data.map(hotline => {
                    return (
                      <GridItem item xs={12} sm={2} md={4} key={hotline.id}>
                        <Slide in={true} direction="left">
                          <Card className={classes.card} style={{ marginBottom: "1em" }}>
                            <CardHeader
                              title={hotline.name}
                            >
                            </CardHeader>
                            <CardContent>
                              <Typography component="p" style={{ maxHeight: '84px', overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: hotline.description }}>
                              </Typography>
                            </CardContent>

                            <CardActions className={classes.actions}
                              disableActionSpacing
                            >
                              {this.editHotlineBtn(drugrole, classes, hotline)}
                              {this.deleteHotlineBtn(drugrole, classes, hotline)}
                              {this.enableHotlines(drugrole, classes, hotline)}
                            </CardActions>
                          </Card>
                        </Slide>
                      </GridItem>
                    );
                  })}
                </Grid>
                <GridItem xs={12} style={{ textAlign: "center" }}>
                  {this.getPagination()}
                </GridItem>
              </GridContainer>
            )}
          </Grid>
        )}
        <GridItem>
          {openPopup ? this.getConfirmPopUp("Are you sure you want to delete '" + deleteName + "' ?") : null}
        </GridItem>
      </Grid>
    );
  }
}


Hotlines.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    hotlines: state.hotlines,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHotlines: bindActionCreators(getHotlines, dispatch),
    deleteHotline: bindActionCreators(deleteHotline, dispatch)
    // getAgencies: bindActionCreators(getAgencies, dispatch),
    // deleteAgency: bindActionCreators(deleteAgency, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(hotlinesStyle)(withSnackbar(Hotlines))));