import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import api from "../../../../utils/api";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Switch from "@material-ui/core//Switch";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import TextField from "@material-ui/core/TextField";
import StandaloneSearchBox from "react-google-maps/lib/components/places/StandaloneSearchBox";

class AboutStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      secondary_name: "",
      description: "",
      website: "",
      active: true,
      unregistered: false,
      email_frequency: false,
      is_home_visit: false,
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      lat: 0,
      lng: 0,
      phone_number: "",
      error: {},
      agency: {},
      service_type: "",
      category: {},
    };
  }
  getCategory = (category = 4) => {
    api.get(
      `/categories/${category}`,
      (data1) => {
        this.setState({ category: data1 });
      },
      () => { }
    );
  };
  setAgencyAddress = (data = {}) => {
    const {
      address1,
      address2,
      city,
      state,
      zip,
      lat,
      lng,
      website,
      phone_number,
    } = data;
    this.setState({
      address1,
      address2,
      city,
      state,
      zip,
      lat: parseFloat(lat),
      lng: parseFloat(lng),
      website,
      phone_number,
      agency: data,
    });
  };
  componentDidMount() {
    this.setInitialData(this.props.data);
    this.props.categoryID && this.getCategory(this.props.categoryID);

    if (this.props.agencyID && !this.props.serviceID) {
      api.get(
        `/agencies/${this.props.agencyID}`,
        (data) => {
          !this.props.serviceID && this.setAgencyAddress(data);
        },
        () => {
          console.log("error while fetching agency");
        }
      );
    }

    const { service_type = "" } = this.props.data || {};
    this.setState({ service_type });
  }
  setInitialData = (data) => {
    let {
      name,
      secondary_name,
      description,
      website,
      active,
      unregistered,
      email_frequency,
      is_home_visit,
      address1,
      address2,
      city,
      state,
      zip,
      lat = 0,
      lng = 0,
      phone_number = "",
      service_type,
    } = data || {};
    if (!email_frequency || email_frequency === "daily") {
      email_frequency = false;
    }
    else {
      email_frequency = true;
    }
    this.setState({
      name,
      secondary_name,
      description,
      website,
      active,
      unregistered,
      email_frequency,
      is_home_visit,
      address1,
      address2,
      city,
      state,
      zip,
      lat: parseFloat(lat),
      lng: parseFloat(lng),
      phone_number,
      service_type,
    });
  };
  componentWillReceiveProps(nextProps) {
    const oldName = (this.props.data || {}).name;
    const name = (nextProps.data || {}).name;
    if (name !== oldName) {
      this.setInitialData(nextProps.data);
    }
  }

  isValidated = () => {
    const { name, address1, phone_number, website } = this.state;
    if (!name) {
      this.setState({ nameState: "error" });
      return false;
    }
    // if (!description) {
    //   this.setState({ descriptionState: "error" });
    //   return false;
    // }
    if (!address1) {
      this.setState({ address1State: "error" });
      return false;
    }
    if (!phone_number) {
      this.setState({ phone_numberState: "error" });
      return false;
    }
    if (!website) {
      this.setState({ websiteState: "error" });
      return false;
    }
    return true;
  };
  // this function is mandatory for the Wizard
  sendState = () => {
    let data = { ...this.state };
    if (data.email_frequency) {
      data.email_frequency = "weekly";
    } else {
      data.email_frequency = "daily";
    }
    delete data.agency;
    // delete data.category;
    // let data1 = {};
    data.service_type = this.state.service_type;
    return data;
    // && data1;
  };
  handleServiceTypeChange = (evt) => {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
  };
  validate = (target) => {
    let value = target.value;
    const name = target.id;
    if (target.type === "text") {
      if (target.required && !value) {
        this.setState({ [name + "State"]: "error" });
      } else if (target.minLength && value.length < target.minLength) {
        this.setState({ [name + "State"]: "error" });
      } else {
        this.setState({ [name + "State"]: "success" });
      }
    }
  };

  handleChange = (event) => {
    const target = event.target;
    let value = "";
    const name = target.id;
    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      value = target.value;
      this.validate(event.target);
    }
    this.setState({ [name]: value });
  };
  getComponentLongName = (address_components = [], name = "") => {
    return (
      (address_components.find((comp) => comp.types.includes(name)) || {})
        .long_name || ""
    );
  };
  onPlacesChanged = () => {
    const places = this.locationSearchBox.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const {
        address_components = [],
        geometry = {},
        formatted_phone_number = "",
      } = place;
      const location = geometry.location;
      const lat = location.lat();
      const lng = location.lng();
      const address1 =
        this.getComponentLongName(address_components, "street_number") +
        " " +
        this.getComponentLongName(address_components, "route");
      const address2 = "";
      const city = this.getComponentLongName(address_components, "locality");
      const state = this.getComponentLongName(
        address_components,
        "administrative_area_level_1"
      );
      const country = this.getComponentLongName(address_components, "country");
      const zip = this.getComponentLongName(address_components, "postal_code");
      const website = place.website;
      this.setState({
        lat,
        lng,
        address1,
        address2,
        city,
        state,
        country,
        zip,
        website,
        phone_number: formatted_phone_number.replace(/\D/g, ""),
      });
      this.address2Ref && this.address2Ref.focus();
    }
  };

  render() {
    const { category = {}, service_type } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Typography variant="subheading">Basic Information</Typography>
          <Divider light={true} />
        </GridItem>

        <GridItem xs={12}>
          <FormControl>
            <InputLabel
              htmlFor="service_type"
              style={{ marginTop: "5px", marginLeft: "8px" }}
            >
              Service Type
            </InputLabel>
            <Select
              value={service_type}
              onChange={this.handleServiceTypeChange}
              fullWidth={true}
              style={{ marginTop: "30px", marginLeft: "8px" }}
              inputProps={{
                name: "service_type",
                id: "service_type",
                required: true,
                fullWidth: true,
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {(category.service_types || []).map((cat) => (
                <MenuItem key={cat.id} value={cat.id}>
                  {cat.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>

        <GridItem xs={12}>
          <GridContainer style={{ paddingLeft: 8 }}>
            <GridItem xs={12} md={6} p={2}>
              <CustomInput
                success={this.state.nameState === "success"}
                error={this.state.nameState === "error"}
                labelText={<span>Name</span>}
                autoFocus={true}
                id="name"
                value={this.state.name}
                formControlProps={{
                  fullWidth: true,
                  required: true,
                }}
                inputProps={{
                  onChange: this.handleChange,
                  value: this.state.name,
                  required: true,
                  inputProps: {
                    minLength: 3,
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <CustomInput
                success={this.state.secondary_nameState === "success"}
                error={this.state.secondary_nameState === "error"}
                labelText={<span>Secondary Name</span>}
                id="secondary_name"
                value={this.state.secondary_name}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: this.handleChange,
                  value: this.state.secondary_name,
                }}
              />
            </GridItem>

            <GridItem xs={12}>
              <CustomInput
                success={this.state.secondary_nameState === "success"}
                error={this.state.secondary_nameState === "error"}
                labelText={<span>Description</span>}
                id="description"
                placeholder="Provide description here..."
                value={this.state.description}
                required={false}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: this.handleChange,
                  value: this.state.description,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.active}
                    value={this.state.active}
                    onChange={(event) =>
                      this.handleChange(event, "active", "switch")
                    }
                    color="primary"
                    id="active"
                  />
                }
                label="Active"
              />
              <Tooltip
                style={{ marginBottom: "-5px" }}
                title="If you inactive the service It will inactive all the related featues."
                placement="top"
              >
                <InfoIcon />
              </Tooltip>
            </GridItem>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.unregistered}
                    value={this.state.unregistered}
                    onChange={(event) =>
                      this.handleChange(event, "unregistered", "switch")
                    }
                    color="primary"
                    id="unregistered"
                  />
                }
                label="Unregister"
              />
              <Tooltip
                style={{ marginBottom: "-5px" }}
                title="This treatment service is unregistered or has not been updated in over a year."
                placement="top"
              >
                <InfoIcon />
              </Tooltip>
            </GridItem>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.email_frequency}
                    value={this.state.email_frequency}
                    onChange={(event) =>
                      this.handleChange(event, "email_frequency", "switch")
                    }
                    color="primary"
                    id="email_frequency"
                  />
                }
                label="Weekly Email"
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} style={{ marginTop: 2 * 8 }}>
          <Typography variant="subheading">Contact Information</Typography>
          <Divider light={true} />
        </GridItem>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.is_home_visit}
                    value={this.state.is_home_visit}
                    onChange={this.handleChange}
                    color="primary"
                    id="is_home_visit"
                  />
                }
                label="Are services provided in patient's home?"
              // Is home visit?
              />
            </GridItem>
            {!this.state.is_home_visit && (
              <GridItem xs={12} container>
                <GridItem xs={12} sm={12} style={{ marginBottom: "30px" }}>
                  <SearchLocation
                    onSearchBoxMounted={(ref) => {
                      this.locationSearchBox = ref;
                    }}
                    onPlacesChanged={this.onPlacesChanged}
                    containerElement={<div />}
                    mapElement={<div />}
                    loadingElement={<div />}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCa952cWXECVQi36D1luAAcsaxuZuymIgI&libraries=places"
                  />
                </GridItem>
                <GridItem xs={12} sm={5}>
                  <RegularMap
                    lat={this.state.lat}
                    lng={this.state.lng}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCa952cWXECVQi36D1luAAcsaxuZuymIgI&libraries=places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={
                      <div
                        style={{
                          height: `380px`,
                          borderRadius: "6px",
                          overflow: "hidden",
                        }}
                      />
                    }
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <GridItem container>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={<span>Building Address</span>}
                        id="address1"
                        formControlProps={{
                          required: true,
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: this.state.address1,
                          onChange: this.handleChange,
                          required: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={<span>Apartment/Suite/Floor</span>}
                        id="address2"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          inputRef: (ref) => (this.address2Ref = ref),
                          value: this.state.address2,
                          onChange: this.handleChange,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={<span>City(Not editable)</span>}
                        id="city"
                        formControlProps={{
                          required: true,
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.city,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={<span>State(Not editable)</span>}
                        id="state"
                        formControlProps={{
                          required: true,
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.state,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={<span>ZIP Code(Not editable)</span>}
                        id="zip"
                        formControlProps={{
                          required: true,
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.zip,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        success={this.state.phone_numberState === "success"}
                        error={this.state.phone_numberState === "error"}
                        labelText={<span>Phone Number</span>}
                        id="phone_number"
                        formControlProps={{
                          required: true,
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: this.state.phone_number,
                          onChange: this.handleChange,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        success={this.state.websiteState === "success"}
                        error={this.state.websiteState === "error"}
                        labelText={<span>Website</span>}
                        id="website"
                        formControlProps={{
                          required: true,
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: this.handleChange,
                          value: this.state.website,
                        }}
                      />
                    </GridItem>
                  </GridItem>
                </GridItem>
              </GridItem>
            )}
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

const SearchLocation = withScriptjs(
  withGoogleMap((props) => (
    <StandaloneSearchBox
      ref={props.onSearchBoxMounted}
      onPlacesChanged={props.onPlacesChanged}
    >
      <TextField
        fullWidth
        id="outlined-uncontrolled"
        defaultValue=""
        placeholder="Search location here"
        margin="normal"
        helperText="Search address here. This will auto populate below."
        // Please select address from here. This auto populate below address
        variant="outlined"
      />
    </StandaloneSearchBox>
  ))
);

const RegularMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
      center={{ lat: props.lat || 40.748817, lng: props.lng || -73.985428 }}
    >
      {props.lat && props.lng && (
        <Marker position={{ lat: props.lat, lng: props.lng }} />
      )}
    </GoogleMap>
  ))
);

export default withStyles({}, { theme: true })(AboutStep);
