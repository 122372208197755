const homePageStyle = (theme) => ({
  mainHeaderContainer: {},
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "57vh",
    padding: "0 10% 0 10%",
    "@media (max-width: 959px)": {
      alignItems: "center",
      minHeight: "unset",
      textAlign: "center",
    },
  },

  rightContainer: {
    "@media (min-width: 960px)": {
      padding: "calc(50px + 1vh) calc(36px + 1vh) 0 0",
    },
  },

  videoContainer: {
    display: "flex",
    flex: "1",
    "@media (max-width: 959px)": {
      padding: "0 10% 0 10%",
    },
  },
  youTubeResponsive: {
    width: "100%",
    height: "100%",
    "@media only screen and (max-width: 959px)": {
      height: "35vh",
    },
  },
  headerTextContainer: {
    padding: "10% 0 10% 0",
    display: "flex",
    flexDirection: "column",
  },
  welcomeText: {
    color: "#3D97D3",
    fontWeight: "bold",
    fontFamily: "Calibri",
    fontSize: "calc(15px + 1.5vw)",
    "@media (max-width: 959px)": {
      textAlign: "left",
      marginTop: "25px",
      marginBottom: "15px",
    },
  },
  findTreatmentText: {
    fontSize: "calc(20px + 1.5vw)",
    fontWeight: "bold",
    color: "#233750",
    fontFamily: "Nunito",
    lineHeight: 1.05,
    "@media (max-width: 959px)": {
      textAlign: "left",
      marginBottom: "5px",
    },
  },
  quickSearchText: {
    width: "230px",
    padding: "15px 0 0 10px",
    fontWeight: "400",
    color: "#233750",
    fontSize: "16px",
    minHeight: "50px",
    fontFamily: "Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif",
    "@media (max-width: 959px)": {
      margin: "auto",
      textAlign: "left",
      padding: "20px 0 35px 0",
    },
  },
  videoGrid: {
    backgroundColor: "#FFFFFF",
    margin: "auto",
  },
  videoIconsContainer: {
    padding: "0",
    margin: "0",
  },
  videoIconsul: {
    padding: "0",
    width: "100%",
    textAlign: "center",
    height: "100%",
  },
  listBottom: {
    color: "#D9552C",
    fontWeight: "600",
    fontFamily: "Nunito",
    fontSize: "16px",
  },
  listBottomText: {
    padding: "10px 0 0 10px",
    width: "230px",
    fontWeight: "400",
    color: "#233750",
    fontSize: "16px",
    minHeight: "50px",
    fontFamily: " Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif",
    "@media (max-width: 959px)": {
      margin: "auto",
      textAlign: "left",
      padding: "0 0 25px 0",
    },
  },
  mainSubContainer: {
    minHeight: "30vh",
    "@media (max-width: 768px)": {
      display: "list-item",
    },
    backgroundColor: "#f5f5f7",
  },
  subConatainer: {
    "@media (min-width: 960px)": {
      padding: "0 0 0 60px",
    },
    "@media (max-width: 768px)": {
      maxWidth: "100%",
      padding: "5px 30px",
    },
    "@media (max-width: 959px)": {
      textAlign: "center",
    },
  },
  otherResource: {
    margin: "10px 0 0 0",
    padding: "5px 0 0 30px",
    color: "#414042",
    fontWeight: "bold",
    fontFamily: "Calibri",
    fontSize: "calc(15px + 1vw)",
    "@media (max-width: 768px)": {
      padding: "15px 0 0 0",
      textAlign: "center",
      marginBottom: "5px",
    },
  },
  bottomBtn: {
    width: "230px",
    height: "45px",
    backgroundColor: "#3D97D3",
    color: "#f4f7fc",
    margin: "10px 0px",
    fontWeight: "bold",
    fontFamily: "Nunito ,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif",
    "@media (max-width: 768px)": {
      maxWidth: "100%",
    },
  },
  mainButton: {
    fontWeight: "bold",
    fontFamily: "Nunito ,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif",
    width: "230px",
    height: "45px",
    backgroundColor: "#D9552C",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#345578",
    },
    "&:active": {
      backgroundColor: "#345578",
    },
    "@media (max-width: 768px)": {
      maxWidth: "100%",
    },
  },
  naloxoneBtn: {
    width: "220px",
    height: "45px",
    backgroundColor: "#D9552C",
    color: "#f4f7fc",
    margin: "10px 0px",
    fontWeight: "bold",
    fontFamily: "Nunito ,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif",
    "@media (max-width: 768px)": {
      maxWidth: "100%",
    },
    "&:hover": {
      backgroundColor: "#345578",
    },
    "&:active": {
      backgroundColor: "#345578",
    },
  },
  videoSlider: {
    fontSize: "18px",
  },
  comingSoon: {
    fontStyle: "italic",
    margin: "-30px 0 6px 0",
    "@media (max-width: 768px)": {
      margin: "0 0 0 0",
    },
  },
  mobileViewButtons: {
    width: "100%",
    textAlign: "center",
    "@media (min-width: 960px)": {
      display: "none",
    },
  },
  desktopViewButtons: {
    minWidth: "450px",
    "@media (max-width: 959px)": {
      display: "none",
    },
  },
});

export default homePageStyle;
