import React from 'react';
import { withStyles } from "@material-ui/core";
import registerPageStyle from "../../assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import GridItem from "../../components/Grid/GridItem.jsx";
import Card from "../../components/Card/Card.jsx";
import CardContent from "@material-ui/core/CardContent";
import DefaultPicture from "../../assets/img/DefaultPicture.png"

export const ProfileCard = (props) => {
  const maxWidthMobile = {
    maxWidth:"50%"
  };
  const { classes, name, title, year, imageSrc, description } = props;
  return (
    <GridItem xs={3} className={classes.flexDisplay} style={maxWidthMobile}>
      <Card
        className={classes.card}
        style={{ width: "14rem", height: "26rem", boxShadow : "none", textAlign:"center" }}
      >
        <CardContent>
          <img
            className={`card-img-top ${classes.contentstyle} `+ "about-image"}
            src={imageSrc ? imageSrc : DefaultPicture}
            alt={name}
            style={{ objectFit: "cover" }}
          />
          <div className="card-body">
            <h5 className="card-title">
              <div style={{ fontWeight: "bold" }}>{name}</div>
              <div
                className="small text-muted"
                style={{ fontWeight: "bold", color: "grey" }}
              >
                {title}
              </div>
            </h5>
            <p className="card-text">
              {description}
            </p>
            <center className="card-text">
              {year}
            </center>
          </div>
        </CardContent>
      </Card>
    </GridItem>
  )

};

export default withStyles(registerPageStyle)(ProfileCard);
