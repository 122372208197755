import {
  blackColor,
  hexToRgb,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

const quickSearchResultsStyle = {
  tooltip: {
    fontSize: "0.875rem",
    fontWeight: "400",
  },
  iconBtn: {
    "@media only screen  and (min-width: 1024px)  and (max-height: 1366px)  and (-webkit-min-device-pixel-ratio: 1.5)": {
      padding: "0px !important",
    },
    "@media (max-width:568px) and (min-width:320px)": {
      padding: "0px !important",
    },
  },
  right: {
    textAlign: "right",
  },
  left: {
    textAlign: "left",
  },
  form: {
    padding: "0 20px",
    position: "relative",
  },
  socialTitle: {
    fontSize: "18px",
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  customFormControlClasses: {
    margin: "0 12px",
  },
  filtersTitle: {
    fontWeight: "bold",
  },
  checkboxLabelControl: {
    margin: "0",
  },
  checkboxLabel: {
    marginLeft: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
  },
  questionName: {
    textTransform: "capitalize",
  },
  chip: {
    margin: "0.2em 0.5em",
  },
  em: {
    fontStyle: "normal",
  },
  root: {
    padding: "2px 2px",
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
  },
  exportButton: {
    backgroundColor: "#3D97D3",
    width: "177px",
    height: "40px",
    color: "#FFFFFF",
    fontSize: "0.875rem",
    "@media only screen and (max-width: 600px)": {
      padding: "11px",
      fontSize: "0.675rem",
    },
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    color: "#233750",
    fontWeight: "normal",
    fontSize: 16,
    fontFamily: "Calibri",

    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      marginBottom: "0px !important"
    },
  },
  attachToBottom: {
    position: "absolute",
    "@media only screen  and (min-width:1024px) and (max-height: 1366px)": {
      bottom: "0px",
    },
  },
  cardActionsContainer: {
    "@media only screen  and (min-width:1024px) and (max-height: 1366px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  cardGrid: {
    "@media only screen  and (min-width: 1024px)  and (max-height: 1366px)  and (-webkit-min-device-pixel-ratio: 1.5)": {
      padding: "0px 5px !important",
      maxWidth: "42% !important"
    },
    "@media only screen and (min-width: 600px) and (max-width:1199px)": {
      flexGrow: 0,
      maxWidth: "70%",
      flexBasis: "50%",
    },
    cardContainer: {
      "@media only screen  and (min-width: 1024px)  and (max-height: 1366px)  and (-webkit-min-device-pixel-ratio: 1.5)": {
        height: "215px !important",
      },
    },
  },
  cardContainer: {
    height: "250px",
    border: "1px solid #233750",
    width: "450px",
    "@media only screen  and (max-width:480px)": {
      height: "230px !important",
      width: "350px !important",
      margin: "0px !important",
      "&:hover": {
        transform: "scale(1.03)",
        zIndex: 999
      }
    },
    /* Tablet View and Desktop View*/
    "@media screen and (min-device-width: 768px) and (max-width:1024px)": {
      height: "240px !important",
      width: "350px !important",
      "&:hover": {
        transform: "scale(1.08)",
        zIndex: 999
      }
    }
  },
  cardActions: {
    "@media only screen  and (max-width:1023px)": {
      display: "flex",
      flexDirection: "column",
      width: "80px",
      height: "200px",
      placeContent: "space-evenly"
    },
  },
  cardHeader: {
    borderRadius: "10px 10px 0px 0px !important",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "12px",
  },
  KeysGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  KeysContainer: {
    fontFamily: "Roboto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  mapRoot: {
    width: "100%",
    height: "10%",
  },
  tabButtonsRoot: {
    margin: "10px 0px",
    "@media only screen and (max-width: 960px)": {
      justifyContent: "center",
    },
  },
  viewOptions: {
    "@media only screen and (max-width: 599px)": {
      order: 2,
    },
  },
  otherOptions: {
    "@media only screen and (max-width: 599px)": {
      order: 1,
    },
  },
  quickSearchContainer: {
    display: "flex",
    "@media only screen and (max-width: 490px)": {
      justifyContent: "center"
    }
  },
  tabButtonLeft: {
    backgroundColor: "#f4f7fc",
    color: "#414042",
    width: "131px",
    // margin: 0,
    fontFamily: "Nunito",
    fontSize: "16pt",
    borderRadius: "5px 0px 0px 5px",
    textTransform: "none",
  },
  tabButtonSelectedLeft: {
    background: "#233750",
    color: "#f4f7fc",
    width: "131px",
    // margin: 0,
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontSize: "16pt",
    borderRadius: "5px 0px 0px 5px",
    textTransform: "none",
  },
  tabButtonRight: {
    backgroundColor: "#f4f7fc",
    color: "#414042",
    width: "131px",
    // margin: 0,
    marginLeft: 0,
    fontFamily: "Nunito",
    fontSize: "16pt",
    borderRadius: "0px 5px 5px 0px",
    textTransform: "none",
  },
  tabButtonSelectedRight: {
    background: "#233750",
    color: "#f4f7fc",
    width: "131px",
    // margin: 0,
    marginLeft: 0,
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontSize: "16pt",
    borderRadius: "0px 5px 5px 0px",
    textTransform: "none",
  },
  circle: {
    width: "25px",
    height: "25px",
    borderRadius: "250px",
    fontSize: "15px",
    color: "#fff",
    lineHeight: "500px",
    textAlign: "center",
    background: "#3D97D3",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginRight: "5px",
    marginBottom: "5px"
  },
  cardContent: {
    padding: "15px",
    paddingLeft: "7px",
    paddingRight: "0px"
  },
  typoStyle: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: "#233750",
    fontSize: "0.875rem",
    fontWeight: "bold"
  },
  filtersHeading: {
    // marginTop: "1.5em",
    // marginLeft: 10,
    // textAlign: "left",
    // fontFamily: "Nunito",
    // fontWeight: "600",
    // color: "#233750",

    display: "flex",
    margin: "10px 0px 10px 10px",
    textAlign: "left",
    fontFamily: "Nunito",
    fontWeight: "600",
    color: "#233750",
    padding: "5px",
    justifyContent: "space-between",

    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      justifyContent: "flex-end !important",
    },
  },
  sorting: {
    marginTop: "10px",
    paddingLeft: "5px",
    paddingRight: "5px",

    //Mobile View
    "@media screen and (max-device-width: 600px)": {
      justifyContent: "center !important",
    }
  },
  cardsContainer: {
    overflow: "auto",

    /* Tablet View */
    "@media screen and (min-device-width: 768px) and (max-device-width: 1024px)": {
      maxHeight: "1000px",
    },

    /* Laptops and Desktops */
    "@media screen and (min-device-width: 992px)": {
      margin: "0px 20px 0px 20px",
      padding: "0px 20px 0px 20px",
      maxHeight: "100%"
    }
  },
  expandCardsSection: {
    /* Tablet View */
    "@media screen and (min-device-width: 768px) and (max-device-width: 1024px)": {
      display: "none !important"
    },

    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      display: "none !important"
    },

    /* Laptops and Desktops */
    "@media screen and (min-width: 992px)": {
      display: "flex",
      justifyContent: "flex-end",
      cursor: "pointer",
    },
  },

  cardsDiv: {
    /* Tablet View - Portrait)*/
    "@media screen and (min-device-width: 768px) and (max-device-width: 1024px)": {
      justifyContent: "center",
    }
  },
  filterGrid: {
    /* Tablet View */
    "@media screen and (min-device-width: 768px) and (max-device-width: 1024px)": {
      overflow: "auto",
      maxHeight: "2800px",
    },
    /* Laptops and Desktops */
    "@media screen and (min-device-width: 992px)": {
      overflow: "auto",
      maxHeight: "2367px",
    }
  },
  onlyMobileView: {
    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      // marginTop: "15px"
    },
    /* Tablet View */
    "@media screen and (min-device-width: 768px) and (max-device-width: 1024px)": {
      display: "none"
    },
    /* Laptops and Desktops */
    "@media screen and (min-device-width: 992px)": {
      display: "none",
    }
  },
  filtersContainer: {
    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      display: "none"
    },
  },
  collapseIcon: {
    position: "absolute",
    zIndex: "1",
    marginLeft: "15px",
    borderRadius: "0 0 15px 0",
    backgroundColor: "#233750",

    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      display: "none !important"
    },
  },
  disableInMobileView: {
    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      display: "none"
    },
  },
  sortTitle: {
    fontWeight: "normal",
    fontFamily: "Helvetica",
    alignItems: "center",
    color: "#1E1E1",

    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      fontSize: "14px",
    },
    /* Tablet View */
    "@media screen and (min-device-width: 768px) and (max-device-width: 1024px)": {
      fontSize: "14px",
    },
    /* Laptops and Desktops */
    "@media screen and (min-device-width: 992px)": {
      fontSize: "15px",
    }
  },
  sortTitleGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",

    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      fontSize: "14px",
    },
    /* Tablet View */
    "@media screen and (min-device-width: 768px) and (max-device-width: 1024px)": {
      fontSize: "14px",
    },
    /* Laptops and Desktops */
    "@media screen and (min-device-width: 992px)": {
      fontSize: "15px",
    }
  },
  sortAndOptionsContainer: {
    display: "flex",
    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      justifyContent: "center"
    },
    /* Tablet View, Laptop and Desktop */
    "@media screen and (min-device-width: 768px)": {
      justifyContent: "flex-start"
    }
  },
  DownloadBtnGrid: {
    padding: "5px",
    display: "flex",
    /* Mobile Phones View */
    "@media screen and (max-device-width: 480px)": {
      justifyContent: "center"
    },
    /* Tablet View, Laptop and Desktop */
    "@media screen and (min-device-width: 768px)": {
      justifyContent: "flex-end"
    },
  }
};

export default quickSearchResultsStyle;
