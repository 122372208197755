import React, { useEffect, useState } from "react";
import api from "utils/api";
import {
    Grid,
    Paper,
    Typography,
    withStyles,
    Button,
    Snackbar,
    IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 1.5,
        paddingBottom: theme.spacing.unit * 1.5,
        margin: "10px 0px 10px 0px",
        width: "65%",
    },
    fab: {
        margin: theme.spacing.unit,
    },
});

function outOfDateServices(props) {
    const { classes } = props;
    const [outOfDateService, setOutofDateService] = useState([]);
    const [open, setOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("Loading");
    let isMounted = true;

    const outdatedServices = async () => {
        try {
            const response = await api.get("services");
            if (isMounted) {
                setOutofDateService(response);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        outdatedServices();
        return () => {
            isMounted = false;
        };
    }, []);

    function openSnackBar() {
        setOpen(true);
    }

    function closeSnackBar(reason) {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    }

    const handleSwitchChange = async (id) => {
        try {
            openSnackBar();
            const response = await api.put('moveOutofDateToUnregistered', { service_id: id });
            if (response) {
                setAlertMessage("Updated Successfully")
                openSnackBar();
                outdatedServices();
            }
        } catch (error) {
            console.log("Error updating service:", error);
        }
    }

    return (
        <>
            {outOfDateService && outOfDateService.map((service, i) => (
                <Grid container spacing={16} direction="row" justify="center" alignItems="center" key={service.id}>
                    <Paper className={classes.root} style={{ alignItems: 'center' }}>
                        <Grid container direction="row" spacing={8} alignItems="center">
                            <Grid sm={9} md={9} lg={9} item>
                                <Typography>
                                    {service.name} [{service.updated_at}] - Contact Number ({service.phone_number})
                                </Typography>
                            </Grid>
                            <Grid sm={3} md={3} lg={3} item>
                                <Button onClick={() => handleSwitchChange(service.id)} style={{ border: '1px solid gray' }} color="secondary">
                                    Unregister
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            ))}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                autoHideDuration={500}
                onClose={closeSnackBar}
                ContentProps={{ 'aria-describedby': 'message-id' }}
                message={<span id="message-id">{alertMessage}</span>}
                action={[
                    <IconButton key="close" aria-label="Close" color="inherit" onClick={closeSnackBar}>
                        <CloseIcon />
                    </IconButton>
                ]}
            />
        </>
    )
}

export default withStyles(styles)(outOfDateServices);