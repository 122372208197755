// import { hexToRgb } from "assets/jss/material-dashboard-pro-react.jsx";

const serviceMapViewStyles = {
  mapElement: {
    "@media only screen and (max-height: 700px) and (-webkit-min-device-pixel-ratio: 1.5)": {
      height: "600px",
    },
    height: "700px"
  },
  mapButtonsRoot: {
    position: "relative",
  },
  mapButtonContainer: {
     /* Mobile View */
    "@media screen and (max-device-width: 480px)" : {
      top: "-340px",
      left: "5px",
    },
    "@media only screen and (max-height: 700px) and (-webkit-min-device-pixel-ratio: 1.5)": {
      top: "-300px",
      left: "5px",
    },
    position: "absolute",
    top: "-300px",
    left: "10px",
    display: "flex",
    flexDirection: "column",
  },
  mapButtons: {
    padding: "2px",
  },
  mapIcons: {
    backgroundColor: "#233750",
    color: "#f4f7fc",
    borderRadius: "5px",
  },
  keysGrid: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  keysContainer: {
    fontFamily: "Roboto",
    display: "inline-block",
  },
};

export default serviceMapViewStyles;
