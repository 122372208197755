import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Grid from "@material-ui/core/Grid";

import withStyles from "@material-ui/core/styles/withStyles";
import serviceStyles from "assets/jss/material-dashboard-pro-react/views/serviceStyles.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import Typography from "@material-ui/core/Typography";
import Paginations from "components/Pagination/Pagination.jsx";
import Tooltip from "@material-ui/core/Tooltip";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";

import { injectIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { withSnackbar } from "notistack";
import Button from "components/CustomButtons/Button.jsx";
import api from "../../utils/api";
import defaultImage from "assets/img/default-avatar.png";
import PopupView from "./PopupView";
import { PersonOutlineOutlined } from "@material-ui/icons";

const intl = window.intl;
export class UsersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      usersLoading: true,
      users: [],
      deleteId: "",
      deleteName: ""
    };
  }
  componentDidMount() {
    this.searchUsers();
  }
  searchUsers = (pageNo = 1) => {
    api.get(
      `/users?nameLike=${this.state.search}&page=${pageNo}&limit=${9}`,
      data => {
        this.setState({ users: data, usersLoading: false });
      },
      error => {
        this.setState({ users: {}, usersLoading: false });
        console.error(error);
      }
    );
  };
  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.searchUsers();
    }, 500);
  };
  componentWillUnmount() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
  }

  getEnteredPage = (evt) => {
    this.searchUsers(evt);
    let html = document.getElementsByTagName("html");
    let body = document.getElementsByTagName("body");
    if (body && body[0]) {
      body[0].scrollTop = 0;
    }
    if (html && html[0]) {
      html[0].scrollTop = 0;
    }
  }

  getPagination = () => {
    const { users = {} } = this.state;
    const { last_page = 1, current_page = 1 } = users;
    if (last_page <= 1) {
      return null;
    }
    let pages = Array(last_page)
      .fill(0)
      .map((e, i) => {
        return {
          onClick: this.gotoPage,
          text: i + 1,
          active: current_page === i + 1
        };
      });
    pages.unshift({
      text: intl.formatMessage({ id: "prev" }),
      onClick: this.gotoPrevPage
    });
    pages.push({
      text: intl.formatMessage({ id: "next" }),
      onClick: this.gotoNextPage
    });

    pages.push({
      text: intl.formatMessage({ id: `${current_page} of ${last_page}` }),
    });

    return <Paginations pages={pages} color="info" currentPage={current_page} lastPage={last_page} gotoEnteredPage={this.getEnteredPage} />;
  };

  gotoPage = evt => {
    this.searchUsers(evt.target.innerText);
  };

  gotoNextPage = () => {
    const { users = {} } = this.state;
    const { last_page = 1, current_page = 1 } = users;
    if (current_page < last_page) {
      this.searchUsers(current_page + 1);
    }
  };
  gotoPrevPage = () => {
    const { users = {} } = this.state;
    const { current_page = 1 } = users;
    if (current_page > 1) {
      this.searchUsers(current_page - 1);
    }
  };

  getConfirmPopUp = (message) => {
    return (
      <PopupView
        openPopup={this.state.openPopup}
        callbackFun={this.callbackFun}
        updateProps={this.updateProps}
        message={message}
        icon={<PersonOutlineOutlined />}
      />
    );
  };

  updateProps = (name, value) => {
    this.setState({ [name]: value });
  }

  callbackFun = () => {
    this.deleteUser(this.state.deleteId, this.state.deleteName);
  }

  callDeletePopup = (id, name) => {
    this.setState({
      deleteName: name,
      deleteId: id,
      openPopup: true,
    });
  }

  deleteUser = (id, name) => {
    // if (!window.confirm("Are you sure you want to delete '" + name + "'")) {
    //   return false;
    // }
    api.delete(`/users/${id}`, null, () => {
      this.searchUsers();
      this.props.enqueueSnackbar("User deleted successfully", {
        variant: "success"
      });
    }, () => {
      this.props.enqueueSnackbar("Unable to delete '" + name + "'", {
        variant: "error"
      });
    })
  }
  render() {
    const { classes } = this.props;
    const { search = "", usersLoading = true, users = {}, deleteName, openPopup } = this.state;
    const { data = [], error = false } = users;
    return (
      <div>
        <Grid
          container
          spacing={24}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {intl.formatMessage({
                id: "users"
              }) +
                " | " +
                intl.formatMessage({
                  id: "title"
                })}
            </title>
            <link rel="canonical" href={window.location.href} />
          </Helmet>
          <Grid item xs={11} sm={9}>
            <Paper className={classes.root} elevation={1}>
              <IconButton className={classes.iconButton} aria-label="Menu" />
              <InputBase
                id="search"
                autoFocus
                value={search}
                onChange={this.handleChange}
                className={classes.input}
                placeholder={intl.formatMessage({
                  id: "view.users.searchUsers"
                })}
              />
              <IconButton
                className={classes.iconButton}
                aria-label={intl.formatMessage({ id: "view.users.search" })}
              >
                {usersLoading ? <CircularProgress /> : <SearchIcon />}
              </IconButton>
            </Paper>
          </Grid>
          <Grid xs={1} item>
            <Tooltip
              title={intl.formatMessage({
                id: "view.users.addUser"
              })}
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
            >
              <Link to={`/admin/edit-user`}>
                <IconButton
                  className={classes.iconButton}
                  aria-label={intl.formatMessage({
                    id: "view.users.addUser"
                  })}
                >
                  <AddCircleIcon color="primary" fontSize="large" />
                </IconButton>
              </Link>
            </Tooltip>
          </Grid>
          {!usersLoading && (
            <Grid
              item
              xs={12}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={8}>
                {/* Error */}
                {error && (
                  <SnackbarContent
                    message={intl.formatMessage({
                      id: "view.users.searchError"
                    })}
                    color="danger"
                  />
                )}
                {/* No users found */}
                <Typography variant="subtitle1" gutterBottom align="center">
                  {!error &&
                    data &&
                    data.length === 0 &&
                    (search.length > 0
                      ? intl.formatMessage({
                        id: "view.users.noResultsFound"
                      })
                      : intl.formatMessage({
                        id: "view.users.noUsersAvailable"
                      }))}
                </Typography>
              </Grid>
              {/* Found users */}
              {!error && data.length > 0 && (
                <GridContainer
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <GridItem
                    xs={12}
                    sm={6}
                    style={{ textAlign: "left" }}
                    align="center"
                  >
                    {search &&
                      intl.formatMessage(
                        {
                          id: "view.users.searchResult"
                        },
                        { count: data.length, term: search }
                      )}
                  </GridItem>
                  <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    {data.map(user => {
                      const avatar = user.picture || defaultImage;
                      return (
                        <GridItem xs={12} sm={12} md={4} key={user.id}>
                          <Card profile>
                            <CardAvatar profile>
                              <a href="#pablo" onClick={e => e.preventDefault()}>
                                <img src={avatar} alt={user.full_name} />
                              </a>
                            </CardAvatar>
                            <CardBody profile>
                              <h6 className={classes.cardCategory}>{user.roles}</h6>
                              <h4 className={classes.cardTitle}>{user.full_name}</h4>
                              <p className={classes.description}>
                                Contact Number: {user.phone_number}
                              </p>
                              <Button color="danger" round onClick={() => this.callDeletePopup(user.id, user.full_name)}>
                                Delete
                              </Button>
                              <Link to={`/admin/edit-user/${user.id}`}><Button color="primary" round>
                                Edit
                              </Button></Link>
                            </CardBody>
                          </Card>
                        </GridItem>
                      );
                    })}
                  </Grid>
                  <GridItem xs={12} style={{ textAlign: "center" }}>
                    {this.getPagination()}
                  </GridItem>
                </GridContainer>
              )}
            </Grid>
          )}
        </Grid>
        <Grid>
          {openPopup ? this.getConfirmPopUp("Are you sure you want to delete '" + deleteName + "' ?") : null}
        </Grid>
      </div>
    );
  }
}

export default injectIntl(
  withStyles(serviceStyles, { withTheme: true })(withSnackbar(UsersPage))
);
