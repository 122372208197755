import api from "../utils/api";
import {
  GET_QUESTION,
  INSERT_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION
} from "../utils/types";

export const getQuestion = (id, ...extra) => ({
  type: GET_QUESTION,
  payload: api.get(`/questions/${id}`, null, ...extra)
});
export const insertQuestion = (data, ...extra) => ({
  type: INSERT_QUESTION,
  payload: api.post(`/questions`, data, ...extra)
});
export const updateQuestion = (data, ...extra) => ({
  type: UPDATE_QUESTION,
  payload: api.put(`/questions/${data.id}`, data, ...extra)
});
export const deleteQuestion = (id, ...extra) => ({
  type: DELETE_QUESTION,
  payload: api.delete(`/questions/id`, null, ...extra)
});
