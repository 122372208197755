import {SEARCH_GET_SERVICES, FULFILLED, REJECTED, PENDING} from '../utils/types';
const initialState = {
    servicesInfo:{
        loading: false,
        result:{},
        error: ""
    },
    filterInfo: {
        loading: false,
        result:[],
        error: ""
    }
};

export default function searchReducer(state = initialState, action) {
    const {type} = action;
    switch (type) {
        case `${SEARCH_GET_SERVICES+PENDING}`:
            return {...state, servicesInfo: {...state.servicesInfo, loading: true, result:{}, error: "" }};
        case `${SEARCH_GET_SERVICES+FULFILLED}`:
            return {...state, servicesInfo: {...state.servicesInfo, loading: false, result: action.payload}};
        case `${SEARCH_GET_SERVICES+REJECTED}`:
            return {...state, servicesInfo: {...state.servicesInfo, loading: false, error: action.payload}};
        default:
            return state;
    }
}