import React from "react";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Grid from "@material-ui/core/Grid";

import withStyles from "@material-ui/core/styles/withStyles";

import serviceStyles from "assets/jss/material-dashboard-pro-react/views/serviceStyles.jsx";

import { connect } from "react-redux";
import { getCategories } from "../../actions/action_categories";
import { deleteCategory } from "../../actions/action_category";
import { bindActionCreators } from "redux";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardActions from "@material-ui/core/CardActions";
import CategoryIcon from "@material-ui/icons/Category";
import EditIcon from "@material-ui/icons/Create";
import Typography from "@material-ui/core/Typography";
import Paginations from "components/Pagination/Pagination.jsx";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";

import { injectIntl } from "react-intl";
import { withSnackbar } from "notistack";
import PopupView from "./PopupView";

const intl = window.intl;
class CategoriesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      openPopup: false,
      deleteId: "",
      deleteName: ""
    };
  }
  componentDidMount() {
    this.searchServices();
  }
  searchServices = (pageNo = 1) => {
    this.props.getCategories({ nameLike: this.state.search, page: pageNo });
  };
  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.searchServices();
    }, 500);
  };
  componentWillUnmount() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
  }

  getEnteredPage = (evt) => {
    this.searchServices(evt);
    let html = document.getElementsByTagName("html");
    let body = document.getElementsByTagName("body");
    if (body && body[0]) {
      body[0].scrollTop = 0;
    }
    if (html && html[0]) {
      html[0].scrollTop = 0;
    }
  }

  getPagination = () => {
    const { categories = {} } = this.props;
    const { last_page = 1, current_page = 1 } = categories;
    if (last_page <= 1) {
      return null;
    }
    let pages = Array(last_page)
      .fill(0)
      .map((e, i) => {
        return {
          onClick: this.gotoPage,
          text: i + 1,
          active: current_page === i + 1
        };
      });
    pages.unshift({
      text: intl.formatMessage({ id: "prev" }),
      onClick: this.gotoPrevPage
    });
    pages.push({
      text: intl.formatMessage({ id: "next" }),
      onClick: this.gotoNextPage
    });

    pages.push({
      text: intl.formatMessage({ id: `${current_page} of ${last_page}` }),
    });

    return <Paginations pages={pages} color="info" currentPage={current_page} lastPage={last_page} gotoEnteredPage={this.getEnteredPage} />;
  };

  gotoPage = evt => {
    this.searchServices(evt.target.innerText);
  };

  gotoNextPage = () => {
    const { categories = {} } = this.props;
    const { last_page = 1, current_page = 1 } = categories;
    if (current_page < last_page) {
      this.searchServices(current_page + 1);
    }
  };
  gotoPrevPage = () => {
    const { categories = {} } = this.props;
    const { current_page = 1 } = categories;
    if (current_page > 1) {
      this.searchServices(current_page - 1);
    }
  };

  getConfirmPopUp = (message) => {
    return (
      <PopupView
        openPopup={this.state.openPopup}
        callbackFun={this.callbackFun}
        updateProps={this.updateProps}
        message={message}
        icon={<CategoryIcon />}
      />
    );
  };

  updateProps = (name, value) => {
    this.setState({ [name]: value });
  }

  callbackFun = () => {
    this.deleteCategory(this.state.deleteId, this.state.deleteName);
  }

  deleteCategory = (id, name) => {
    // if (!window.confirm("Are you sure you want to delete '" + name + "'")) {
    //   return false;
    // }
    this.props.deleteCategory(
      id,
      () => {
        this.props.enqueueSnackbar("Category deleted successfully", {
          variant: "success"
        });
        this.searchServices();
      },
      () => {
        this.props.enqueueSnackbar("Unable to delete '" + name + "'", {
          variant: "error"
        });
        this.searchServices();
      }
    );
  };

  addCategory = () => {
    if (this.props.categories.data && this.props.categories.data.length < 3) {
      this.props.history.push('/admin/edit-category');
    } else {
      this.props.enqueueSnackbar("Categories are limmited to 3, If you want create new, Delete the existed category.", {
        variant: "warning",
        close: "true"
      });
    }
    //to={`/admin/edit-category`}
  }

  callDeletePopup = (id, name) => {
    this.setState({
      deleteName: name,
      deleteId: id,
      openPopup: true,
    });
  }

  render() {
    const { classes, categories = {} } = this.props;
    const { search, deleteName, openPopup } = this.state;
    const { data = [], error = false, categoriesLoading = false } = categories;
    return (
      <Grid
        container
        spacing={24}
        direction="row"
        justify="center"
        alignItems="center"
      >
        {/* <Helmet>
            <meta charSet="utf-8" />
            <title>{intl.formatMessage({
                id: "categories"
              }) + " | " + intl.formatMessage({
                id: "title"
              })}</title>
        </Helmet> */}
        <Grid item xs={11} sm={9}>
          <Paper className={classes.root} elevation={1}>
            <IconButton className={classes.iconButton} aria-label="Menu" />
            <InputBase
              id="search"
              value={search}
              onChange={this.handleChange}
              className={classes.input}
              placeholder={intl.formatMessage({
                id: "view.categories.searchCategories"
              })}
            />
            <IconButton
              className={classes.iconButton}
              aria-label={intl.formatMessage({ id: "view.categories.search" })}
            >
              {categoriesLoading ? <CircularProgress /> : <SearchIcon />}
            </IconButton>
          </Paper>
        </Grid>
        <Grid xs={1} item>
          <Tooltip
            title={intl.formatMessage({
              id: "view.categories.addCategory"
            })}
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            {/* <Link to={location => ({ ...location, pathname: "/edit-category" })}> */}
            <button onClick={() => { this.addCategory(); }} style={{ background: 'none', border: 'none', padding: '0', cursor: 'pointer' }}>
              <IconButton
                className={classes.iconButton}
                aria-label={intl.formatMessage({
                  id: "view.categories.addCategory"
                })}
              >
                <AddCircleIcon color="primary" fontSize="large" />
              </IconButton>
            </button>
            {/* </Link> */}
          </Tooltip>
        </Grid>

        {!categoriesLoading && (
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={8}>
              {/* Error */}
              {error && (
                <SnackbarContent
                  message={intl.formatMessage({
                    id: "view.categories.searchError"
                  })}
                  color="danger"
                />
              )}
              {/* No categories found */}
              <Typography variant="subtitle1" gutterBottom align="center">
                {!error &&
                  data &&
                  data.length === 0 &&
                  (search.length > 0
                    ? intl.formatMessage({
                      id: "view.categories.noResultsFound"
                    })
                    : intl.formatMessage({
                      id: "view.categories.noCategoriesAvailable"
                    }))}
              </Typography>
            </Grid>
            {/* Found categories */}
            {!error && data.length > 0 && (
              <GridContainer direction="row"
                justify="center"
                alignItems="center">
                <GridItem xs={12} sm={6} style={{ textAlign: "left" }} align="center">
                  {search &&
                    intl.formatMessage(
                      {
                        id: "view.categories.searchResult"
                      },
                      { count: data.length, term: search }
                    )}
                </GridItem>
                <GridItem xs={12} sm={6} style={{ textAlign: "center" }}>
                  {this.getPagination()}
                </GridItem>
                {data.map(service => {
                  return (
                    <GridItem item xs={12} sm={2} md={4} key={service.id}>
                      <Slide in={true} direction="left">
                        <Card>
                          <CardHeader color="primary">
                            <CardIcon color="rose">
                              <CategoryIcon />
                            </CardIcon>
                            <Typography
                              className={classes.cardTitle}
                              variant="h6"
                              color="inherit"
                              noWrap
                            >
                              {service.name}
                            </Typography>
                            <p>{service.secondary_name}</p>
                          </CardHeader>
                          <CardBody textJustify>
                            {service.description.length > 256
                              ? service.description.substring(0, 256) + "..."
                              : service.description.substring(0, 256)}
                          </CardBody>
                          <CardActions>
                            <Link to={`/admin/categories/${service.id}`} >
                              <Button size="small" color="primary">
                                {intl.formatMessage({
                                  id: "view.categories.viewCategory"
                                })}
                              </Button>
                            </Link>
                            <Tooltip
                              title={intl.formatMessage({
                                id: "view.categories.editCategory"
                              })}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Link to={`/admin/edit-category/${service.id}`}>
                                <IconButton
                                  color="primary"
                                  aria-label={intl.formatMessage({
                                    id: "view.categories.editCategory"
                                  })}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Link>
                            </Tooltip>
                            <Tooltip
                              title="Delete"
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                aria-label="Delete"
                                className={classes.margin}
                                onClick={() =>
                                  this.callDeletePopup(service.id, service.name)
                                }
                              >
                                <DeleteIcon
                                  color="secondary"
                                  className={classes.margin}
                                />
                              </IconButton>
                            </Tooltip>
                          </CardActions>
                        </Card>
                      </Slide>
                    </GridItem>
                  );
                })}
                <GridItem xs={12} style={{ textAlign: "center" }}>
                  {this.getPagination()}
                </GridItem>
                <Grid>
                  {openPopup ? this.getConfirmPopUp("Are you sure you want to delete '" + deleteName + "' ?") : null}
                </Grid>
              </GridContainer>
            )}
          </Grid>
        )}
      </Grid>
    );
  }
}

CategoriesPage.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    categories: state.categories
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getCategories: bindActionCreators(getCategories, dispatch),
    deleteCategory: bindActionCreators(deleteCategory, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(serviceStyles)(withSnackbar(CategoriesPage))));
