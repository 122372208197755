import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  insertQuestion,
  updateQuestion,
  deleteQuestion
} from "../../actions/action_question";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Select from "@material-ui/core/Select";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Typography from "@material-ui/core/Typography";
import { withSnackbar } from "notistack";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
// icons
import QuestionIcon from "@material-ui/icons/LocalActivity";

import Switch from "@material-ui/core//Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// core components

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import api from "../../utils/api";
import { Link } from "react-router-dom";
import { questionTypes } from "../../utils/helper";

const intl = window.intl;
class QuestionEditPage extends React.Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    this.state = {
      questionID: props.match.params.questionID,
      question: "",
      type: "SWITCH",
      feature: params.get("feature"),
      active: true,
      hint: "",
      placeholder: "",
      order: 100,
      hideFeature: params.has("feature"),
      questionNotFound: false,
      error: {},
      mandatory: { question: true, type: true, active: true, order: true },
      modified: false,
      questionTypes: questionTypes
    };
  }
  getQuestion = async id => {
    const question = await api.get(`/questions/${id}`);
    if (question && Object.keys(question).length > 0) {
      this.setState({
        ...question,
        feature: (question.feature || {}).id,
        questionNotFound: false
      });
    } else {
      this.setState({ questionNotFound: true });
    }
  };
  getCategories = async id => {
    const categories = await api.get(`/modelSearch/categories`);
    this.setState({ categories });
  };

  componentDidMount() {
    const { questionID, feature } = this.state;
    if (!questionID && !feature) {
      this.props.enqueueSnackbar("Please select feature", {
        variant: "info"
      });
      this.props.history.push("/admin/categories");
      return;
    }
    questionID && this.getQuestion(questionID);
    this.getCategories();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data = {} } = nextProps.question || {};

    if (data.name !== this.state.name) {
      const {
        name,
        description,
        allow_attachments,
        allow_contact_persons
      } = data;
      this.setState({
        name,
        description,
        allow_attachments,
        allow_contact_persons
      });
    }
  }
  validateField = (name, value) => {
    const { error, mandatory } = this.state;
    if (mandatory[name]) {
      error[name] = value === undefined || value.length === 0;
    }
    this.setState({ error });
    return !error[name];
  };

  handleSwitchChange = name => event => {
    this.setState({ [name]: event.target.checked, modified: true });
  };
  handleTextChange = name => event => {
    this.validateField(name, event.target.value);
    this.setState({ [name]: event.target.value, modified: true });
  };
  onSubmit = () => {
    const {
      questionID,
      question,
      type,
      feature,
      active,
      hint,
      placeholder,
      order,
      modified,
      error = {}
    } = this.state;
    let valid = true;
    if (!modified) {
      this.props.enqueueSnackbar("No information is updated", {
        variant: "info"
      });
      return false;
    } else {
      valid = Object.keys(error).every(name => {
        return !error[name];
      });
    }
    if (!valid) {
      this.props.enqueueSnackbar("Please provide all required details", {
        variant: "error"
      });
      return false;
    }
    let data = {
      id: questionID,
      question,
      type,
      feature,
      active,
      hint,
      placeholder,
      order
    };
    if (questionID) {
      data.id = questionID;
      this.props.updateQuestion(data, () => {
        this.props.enqueueSnackbar("Question updated successfully", {
          variant: "success"
        });
        this.props.history.goBack();
      });
    } else {
      this.props.insertQuestion(data, () => {
        this.props.enqueueSnackbar("Question saved successfully", {
          variant: "success"
        });
        this.props.history.goBack();
      });
    }
  };
  render() {
    const { classes } = this.props;
    const {
      questionID,
      questionNotFound,
      error = {},
      modified,
      questionTypes = [],
      mandatory = {}
    } = this.state;
    return (
      <React.Fragment>
        {questionID && questionNotFound ? (
          <Card>
            <CardBody>
              <Typography variant="subtitle1" gutterBottom align="center">
                {intl.formatMessage({
                  id: "view.questionEdit.questionNotFound"
                })}{" "}
                <Link to="/admin/edit-question">
                  {intl.formatMessage({
                    id: "createNew"
                  })}
                </Link>
              </Typography>
            </CardBody>
          </Card>
        ) : (
          <GridContainer
            container
            spacing={24}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <QuestionIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle} color="primary">
                    {(questionID ? "Update" : "Create") + " Question"}
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText="Question"
                        id="question"
                        error={error["question"]}
                        formControlProps={{
                          required: mandatory["question"],
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.question,
                          onChange: this.handleTextChange("question")
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <FormControl
                        className={classes.formControl}
                        fullWidth={true}
                      >
                        <InputLabel
                          fullWidth={true}
                          required={mandatory["type"]}
                          htmlFor="age-label-placeholder"
                        >
                          Question Type
                        </InputLabel>
                        <Select
                          id="type"
                          label="Question Type"
                          className={classes.textField}
                          value={this.state.type}
                          onChange={this.handleTextChange("type")}
                          fullWidth={true}
                          inputProps={{
                            fullWidth: true
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {questionTypes.map(opt => (
                            <MenuItem value={opt.value}>{opt.text}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    
                    {(this.state.type === "LINK") && (
                      <GridItem xs={12}>
                      <CustomInput
                        labelText="URL"
                        id="hint"
                        formControlProps={{
                          required: mandatory["hint"],
                          fullWidth: true
                        }}
                        inputProps={{
                          multiline: true,
                          rows: 3,
                          placeholder: "Please enter the url in 'http://www.google.com' format",
                          value: this.state.hint,
                          onChange: this.handleTextChange("hint")
                        }}
                      />
                    </GridItem>
                    )}

                    {(this.state.type === "SWITCH" || this.state.type === "TEXT") && (
                      <GridItem xs={12}>
                      <CustomInput
                        labelText="Hint"
                        id="hint"
                        formControlProps={{
                          required: mandatory["hint"],
                          fullWidth: true
                        }}
                        inputProps={{
                          multiline: true,
                          rows: 3,
                          placeholder: "Please provide helper text here",
                          value: this.state.hint,
                          onChange: this.handleTextChange("hint")
                        }}
                      />
                    </GridItem>
                    )}
                    {this.state.type === "TEXT" && (
                      <GridItem xs={12}>
                        <CustomInput
                          labelText="Placeholder"
                          id="placeholder"
                          formControlProps={{
                            required: mandatory["placeholder"],
                            fullWidth: true
                          }}
                          inputProps={{
                            multiline: true,
                            rows: 3,
                            placeholder: "Please provide placeholder text here",
                            value: this.state.placeholder,
                            onChange: this.handleTextChange("placeholder")
                          }}
                        />
                      </GridItem>
                    )}
                    <GridItem xs={12}>
                      <CustomInput
                        labelText="Order"
                        id="order"
                        error={error["order"]}
                        formControlProps={{
                          required: mandatory["order"],
                          type: "number",
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          value: this.state.order,
                          onChange: this.handleTextChange("order")
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.active}
                            value={this.state.active}
                            onChange={this.handleSwitchChange("active")}
                            color="primary"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="Active"
                      />
                    </GridItem>
                  </GridContainer>
                  <Button
                    color="primary"
                    disabled={!modified}
                    className={classes.updateProfileButton}
                    onClick={this.onSubmit}
                  >
                    {`${questionID ? "Update" : "Save"} Question`}
                  </Button>
                  <Clearfix />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </React.Fragment>
    );
  }
}
QuestionEditPage.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    question: state.question
  };
};
const mapDispatchToProps = dispatch => {
  return {
    insertQuestion: bindActionCreators(insertQuestion, dispatch),
    updateQuestion: bindActionCreators(updateQuestion, dispatch),
    deleteQuestion: bindActionCreators(deleteQuestion, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(userProfileStyles)(withSnackbar(QuestionEditPage))));
