import api from '../utils/api';
import {GET_FEATURES} from '../utils/types';


export const getFeatures = ({nameLike="",page=1, limit=9}, ...extra) => ({
    type: GET_FEATURES,
    payload: api.get(`/features?nameLike=${nameLike}&page=${page}&limit=${limit}`,null,...extra)
});
export const getFeaturesByCategory = (id, ...extra) => ({
    type: GET_FEATURES,
    payload: api.get(`/categories/${id}/features`,null,...extra)
});