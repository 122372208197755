import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card.jsx";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CallIcon from "@material-ui/icons/Call";
import WebIcon from "@material-ui/icons/Language";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import check_icon from "../../../assets/img/check_icon.png";
import mapInfoWindowStyle from "assets/jss/material-dashboard-pro-react/views/mapInfoWindow";

import { getDistanceStringLong } from "../../../utils/mapUtils";

const ServiceSearchInfoWindow = ({ agency, userLat, userLng, classes }) => {
  const [selectedService, setSelectedService] = useState(0);
  const servicesCount = agency.services.length;

  const handleForwardClick = (event) => {
    event.preventDefault();
    setSelectedService(selectedService + 1);
  };

  const handleBackClick = (event) => {
    event.preventDefault();
    setSelectedService(selectedService - 1);
  };

  const getTitleClasses = () => {
    if (agency.name.length < 15) return classes.titleLarge;
    else if (agency.name.length < 25) return classes.titleMedium;
    else return classes.titleSmall;
  };

  return (
    <Grid className={classes.root}>
      {servicesCount > 1 ? (
        <IconButton
          disableRipple
          className={classes.arrowBack}
          color="primary"
          disabled={selectedService === 0}
          onClick={(e) => handleBackClick(e, "Back")}
        >
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>
      ) : (
        <div style={{width: "20px"}}></div>
      )}
      <Card className={classes.card}>
        <Typography className={getTitleClasses()}>{agency.name}</Typography>
        <Typography className={classes.serviceName}>
          {agency.services[selectedService].name}
        </Typography>
        <Typography className={classes.slotInfo}>
          {agency.services[selectedService].slotInfo}
        </Typography>
        <div style={{ paddingTop: "5px" }}>
          <span className={classes.agencyOpenText}>
            {agency.services[selectedService].openingText
              ? agency.services[selectedService].openingText
              : ""}
          </span>
          <span className={classes.agencyClosedText}>
            {agency.services[selectedService].closingText
              ? agency.services[selectedService].closingText
              : ""}
          </span>
          <Typography className={classes.distance}>
            {userLat && userLng
              ? getDistanceStringLong(agency.lat, agency.lng, userLat, userLng)
              : ""}
          </Typography>
        </div>
        <div className={classes.addressContainer}>
          <Typography className={classes.address}>
            {agency.services[selectedService].address}
          </Typography>
        </div>
        <div className={classes.accreditationsContainer}>
          {agency.accreditations ? (
            <Typography
              style={{ color: "#233750", fontWeight: 700, fontSize: 12 }}
            >
              <img
                class="card-img-top"
                src={check_icon}
                alt="Accreditations"
                style={{ width: "1rem", height: "1rem" }}
              />{" "}
              {agency.accreditations}
            </Typography>
          ) : (
            ""
          )}
        </div>
        <CardActions className={classes.cardActions}>
          <Tooltip
            title={agency.services[selectedService].phoneNumber}
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
            disableFocusListener
          >
            <a href={`tel:${agency.services[selectedService].phoneNumber}`}>
              <IconButton className={classes.iconBtn} color="primary">
                <CallIcon />
              </IconButton>
            </a>
          </Tooltip>

          <Tooltip
            title={"Open Website"}
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
            disableFocusListener
          >
            <a
              href={agency.services[selectedService].website}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton className={classes.iconBtn} color="primary">
                <WebIcon />
              </IconButton>
            </a>
          </Tooltip>
        </CardActions>
        {servicesCount > 1 ? (
          <ul className={classes.carouselIndicatorsRoot}>
            {agency.services.map((service) => (
              <li
                key={service.id}
                className={
                  service.id === agency.services[selectedService].id
                    ? classes.carouselIndicatorSelected
                    : classes.carouselIndicators
                }
              />
            ))}
          </ul>
        ) : (
          ""
        )}
      </Card>
      {servicesCount > 1 ? (
        <IconButton
          disableRipple
          className={classes.arrowForward}
          color="primary"
          disabled={selectedService === agency.services.length - 1}
          onClick={(e) => handleForwardClick(e)}
        >
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      ) : (
        <div style={{width: "20px"}}></div>
      )}
    </Grid>
  );
};

export default withStyles(mapInfoWindowStyle)(ServiceSearchInfoWindow);
