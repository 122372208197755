import { container } from "assets/jss/material-dashboard-pro-react.jsx";

const viewServiceStyle = {
  container: {
    ...container,
    position: "relative",
    zIndex: "3",
    minHeight: "100%",
  },
  loadingText: {
    color: "#233750",
  },
  subContainer: {
    margin: "0px 15px",
  },
  serviceInfoContainer: {
    background: "#eeeeee",
    borderRadius: "5px",
    marginTop: "10px",
  },
  serviceTitleContainer: {
    display: "inline-block",
    float: "left",
  },
  titleCard: {
    background: "transparent",
    boxShadow: "none",
  },
  callWebCard: {
    background: "transparent",
    boxShadow: "none",
    marginBottom: "0px",
  },
  serviceName: {
    color: "#d9552c",
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontSize: "16pt",
  },
  agencyName: {
    color: "#233750",
    fontSize: 16,
    fontFamily: "Nunito",
    fontWeight: "bold",
  },
  address: {
    color: "#233750",
    fontWeight: "normal",
    fontSize: 16,
    fontFamily: "Calibri",
  },
  iconButton: {
    padding: "0px 5px 0px 0px",
    color: "#3D97D3",
  },
  matInfoUL: {
    "list-style-type": "none",
    margin: "0px",
    paddingLeft: "20px",
  },
  additionalInfo: {
    color: "#d9552c",
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontSize: "16pt",
    margin: "10px 0px 0px 15px",
  },
  additionalInfoContainer: {
    background: "#eeeeee",
    borderRadius: "5px",
    padding: "15px",
    margin: "10px 30px 15px 15px",
  },
  bottomHeadingContainer: {
    display: "inline-block",
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },
  bottomHeading: {
    color: "#3D97D3",
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontSize: "16pt",
  },
};

export default viewServiceStyle;
