const data = {
    service: "Servicio",
    services: "Servicios",
    agency: "Agencia",
    agencies: "Agencias",
    category: "Categoría",
    categories: "Categorías",
    feature: "Característica",
    features: "Caracteristicas",
    user: "Usuario",
    users: "Usuarios",
    timeInfo: "Información de tiempo",
    timeInfos: "Información de tiempos",
    routes: {
        dashboard: "Tablero",
        login: "Iniciar sesión",
        availabilityStatus: "Estado de Disponibilidad"
    },
    view: {
        service: {
            searchServices: "Search Services"
        }
    }
}
export default data;