import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import Switch from "@material-ui/core//Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RichTextField from "../../../Components/RichTextField";
import UsersAutoComplete from "../../../Components/UsersAutoComplete";
import TagsInput from "react-tagsinput";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

export class AboutStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      active: true,
      accreditations: "",
      description: "",
      admin: {},
      tags: [],
      logo: ""
    };
  }
  componentDidMount = () => {
    const data = this.props.data || {};
    this.setState({
      name: data.name || "",
      active: data.active,
      accreditations: data.accreditations || "",
      description: data.description || "",
      admin: data.admin || {},
      logo: data.logo || ""
    });
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    // const { data = {} } = nextProps.agency || {};

    // if (data.name !== this.state.name) {
    //   const {

    //     active
    //   } = data;
    //   this.setState({

    //     active
    //   });
    // }
  }



  sendState = () => {
    return this.state;
  };
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  handleSwitchChange = name => event => {
    this.setState({ [name]: event.target.checked, modified: true });
  };
  change(event, stateName, type, stateNameEqualTo) {
    let value = null;
    switch (type) {
      case "length":
        value = event.target.value;
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "image":
        value = stateNameEqualTo;
        break;
      case "switch":
        value = event.target.checked;
        break;
      default:
        value = event.target.value;
        break;
    }
    this.setState({ [stateName]: value });
  }
  handleChange = (name, value) => {
    this.setState({ [name]: value })
  }
  isValidated = () => {
    return true;
  };
  handleTags = regularTags => {
    this.setState({ accreditations: regularTags });
  };


  render() {
    const { classes = {} } = this.props;
    const drugrole = sessionStorage.getItem("drughelp-role");


    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Let's start with the basic information
          </h4>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <ImageUpload
            label="logo"
            value={this.state.logo}
            onChange={(evt, data) => this.change(evt, "logo", "image", data)}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            success={this.state.nameState === "success"}
            error={this.state.nameState === "error"}
            labelText={<span>Name</span>}
            id="name"
            value={this.state.name}
            formControlProps={{
              required: true,
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "name", "length", 3),
              value: this.state.name
            }}
          />
          {(drugrole === "admin" && <UsersAutoComplete
            name="admin"
            id="admin"
            label="Admin"
            options={[]}
            isRemote={true}
            placeholder="Select admin..."
            value={this.state.admin}
            onChange={this.handleChange}
          />)}
          <FormControlLabel
            control={
              <Switch
                checked={this.state.active}
                value={this.state.active}
                onChange={this.handleSwitchChange(
                  "active"
                )}
                color="primary"
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  icon: classes.switchIcon,
                  iconChecked: classes.switchIconChecked,
                  bar: classes.switchBar
                }}
              />
            }
            classes={{
              label: classes.label
            }}
            label="Active"
          />
          <div>
            <label>Accreditations</label>
            <div>
              <TagsInput
                value={this.state.accreditations || []}
                onChange={this.handleTags}
                tagProps={{ className: "react-tagsinput-tag primary" }}
                inputProps={{ placeholder: "Add more..." }}
              />
            </div>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <RichTextField
            label="Description"
            id="description"
            placeholder="Provide description here..."
            value={this.state.description}
            onChange={event => this.change(event, "description", "lenth", "10")}
            required={true}
          />
        </GridItem>
      </GridContainer>
    );
  }
}
const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};
export default connect(mapStateToProps)(withStyles(style)(AboutStep));
