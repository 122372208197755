import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, matchPath } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "dhRoutes.js";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";

//import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/drughelp.png";

import { connect } from "react-redux";
import { getLoggedInUser } from "../actions/action_auth";
import { bindActionCreators } from "redux";

//Internationalization
import { IntlProvider, addLocaleData } from "react-intl";

import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";

import messages from "../locale/index";

import { flattenMessages } from "../utils/helper";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { "light": "rgba(61, 151, 211, 1)", "main": "rgba(35, 55, 80, 1)", "dark": "rgba(61, 151, 211, 1)", "contrastText": "#fff" },
    secondary: { "light": "rgba(234, 165, 49, 1)", "main": "rgba(217, 85, 44, 1)", "dark": "rgba(234, 165, 49, 1)", "contrastText": "#fff" },
    text: { "primary": "rgba(35, 55, 80, 1)", "secondary": "rgba(217, 85, 44, 1)", "disabled": "rgba(65, 64, 66, 1)", "hint": "rgba(65, 64, 66, 1)" },
  },
  typography: {
    useNextVariants: true,

  },
  CardHeader: {
    useNextVariants: false,
  }
});

addLocaleData([...en, ...es]);


var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      color: "purple",
      bgColor: "F5F5F5",
      hasImage: true,
      fixedClasses: "dropdown",
      isMounted: false,
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  componentWillMount() {
    if (!this.props.auth.user) {
      this.props.getLoggedInUser(null, () => {
        sessionStorage.setItem("drughelp-authenticated", false);
        sessionStorage.setItem("drughelp-role", "");
        this.props.history.push("/auth/login-page");
      });
    }
  }

  componentDidMount() {
    this.state.isMounted = true;
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentWillUnmount() {
    this.state.isMounted = false;
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (!this.state.isMounted) return;
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen && this.state.isMounted) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  adminScrollToTop() {
    if (ps && ps.element) {
      ps.element.scrollTop = 0;
    }
  };

  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleBgColorClick = bgColor => {
    this.setState({ bgColor: bgColor });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/admin/full-screen-maps";
  }
  getActiveRoute = routes => {

    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        const match = matchPath(window.location.pathname, {
          path: routes[i].layout + routes[i].path,
          exact: true
        });
        if (
          match
        ) {
          return window.intl.formatMessage({ id: routes[i].name });
        }
      }
    }
    return activeRoute;
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {

      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        let Comp = prop.component;
        let brandText = prop.brandText;
        return (
          <Route
            path={prop.layout + prop.path}
            exact
            component={(props) => {
              return <Comp adminScrollToTop={this.adminScrollToTop} brandText={brandText} {...props} />;
            }}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    const user = this.props.auth.user || {};
    if (user && Object.keys(user).length > 0) {

      var role = user.roles;
      role = role.replace("_", "");
      sessionStorage.setItem("drughelp-role", role);
    }

    const lang = user.lang || "en";
    return (
      <MuiThemeProvider theme={theme} >
        <IntlProvider locale={lang} messages={flattenMessages(messages[lang])}>
          <div className={classes.wrapper}>
            <Sidebar
              routes={routes}
              logoText={"Drughelp.care"}
              logo={logo}
              image={this.state.image}
              handleDrawerToggle={this.handleDrawerToggle}
              open={this.state.mobileOpen}
              color={this.state.color}
              bgColor={"white"}
              miniActive={this.state.miniActive}
              {...rest}
            />
            <div className={mainPanel} ref="mainPanel">
              <AdminNavbar
                sidebarMinimize={this.sidebarMinimize.bind(this)}
                miniActive={this.state.miniActive}
                brandText={this.getActiveRoute(routes)}
                handleDrawerToggle={this.handleDrawerToggle}
                {...rest}
              />
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {this.getRoute() ? (
                <div className={classes.content}>
                  <div className={classes.container}>
                    <Switch>{this.getRoutes(routes)}</Switch>
                  </div>
                </div>
              ) : (
                <div className={classes.map}>
                  <Switch>{this.getRoutes(routes)}</Switch>
                </div>
              )}
              {this.getRoute() ? <Footer fluid /> : null}
            </div>
          </div>
        </IntlProvider>
      </MuiThemeProvider>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getLoggedInUser: bindActionCreators(getLoggedInUser, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(appStyle, { withTheme: true })(Dashboard));
