import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import {connect} from 'react-redux';
import {login, getLoggedInUser} from '../../actions/action_auth';
import {bindActionCreators} from 'redux';

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }
  componentDidMount() {
    if(this.props.auth.loggedIn && localStorage.getItem('drughelp-token')) {
      this.props.history.push("/admin/dashboard");
    }
    this.props.getLoggedInUser();
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUpdate(nextProps){
    if(!this.props.auth.loggedIn && nextProps.auth.loggedIn) {
      this.props.history.push("/admin/dashboard");
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  login = () => {
    const {email,password} = this.state;
    this.props.login({email,password});
  }

  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
         
        </GridContainer>
      </div>
    );
  }
}

ContactUs.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    ...state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    login: bindActionCreators(login, dispatch),
    getLoggedInUser: bindActionCreators(getLoggedInUser, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(ContactUs));
