import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import api from '../../utils/api';
import { connect } from "react-redux";
import { login, getLoggedInUser } from "../../actions/action_auth";
import { bindActionCreators } from "redux";
import { Timeline, TimelineItem } from "vertical-timeline-component-for-react";

class News extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      newsData: [],
      formattedDates: [],
      tweets: [],
      currentIndex: 0
    };
  }
  componentDidMount() {
    if (this.props.auth.loggedIn && localStorage.getItem("drughelp-token")) {
      this.props.history.push("/admin/dashboard");
    }
    this.props.getLoggedInUser();
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      1000
    );
    this.getNewsData();
  }

  componentWillUpdate(nextProps) {
    if (!this.props.auth.loggedIn && nextProps.auth.loggedIn) {
      this.props.history.push("/admin/dashboard");
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  login = () => {
    const { email, password } = this.state;
    this.props.login({ email, password });
  };

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  getNewsData = () => {
    api.get(
      `getNewsData`,
      (res) => {
        this.setState({ newsData: res })
      },
      (error) => {
        console.log(error);
      }
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <div style={{ backgroundColor: '#f4f7fc', padding: '50px' }}>
          <h1 style={{ color: '#233750', textAlign: 'center', textTransform: "uppercase" }}>
            <strong>News</strong>
          </h1>
          <Timeline lineColor={'#d9552c'}>
            {this.state.newsData.map((result) => {
              if (!result.event) {
                return (
                  <TimelineItem
                    key={result.news_id}
                    dateText={result.created_at}
                    style={{ color: '#3d97d3' }}
                    dateInnerStyle={{ background: '#233750', color: '#f4f7fc', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)' }}
                    bodyContainerStyle={{
                      background: '#f4f7fc',
                      padding: '20px',
                      borderRadius: '8px',
                      boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <a href={result.news_link} style={{
                      color: "#3d97d3",
                      textDecoration: "none",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      transition: "color 0.3s ease-in-out",
                    }}
                      onMouseOver={(e) => {
                        e.target.style.color = "#eaa531";
                      }}
                      onMouseOut={(e) => {
                        e.target.style.color = "#233750";
                      }}>
                      {result.title}
                    </a>
                    <p style={{ color: '#414042', fontSize: '14px', margin: '10px 0' }}>Updated at {result.updated_at}</p>
                    <p style={{ color: '#414042', fontSize: '16px' }}>{result.Content}</p>
                  </TimelineItem>
                );
              } else {
                return null;
              }
            })}
          </Timeline>
        </div>

      </div>
    );
  }
}

News.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: bindActionCreators(login, dispatch),
    getLoggedInUser: bindActionCreators(getLoggedInUser, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(loginPageStyle)(News));
