import {AUTH_LOGIN, AUTH_ME, REJECTED, FULFILLED} from '../utils/types';
const initialState = {
    loggedIn: false,
    loginFailed: false,
    token: '',
    refreshToken: ''
};

export default function authReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case `${AUTH_LOGIN+FULFILLED}`:
            localStorage.setItem('drughelp-authenticated', true);
            localStorage.setItem("drughelp-token", payload.token);
            localStorage.setItem("drughelp-refresh-token", payload['refresh-token']);
            return {...state, loggedIn: true, ...payload};
        case `${AUTH_LOGIN+REJECTED}`:
            return {...state, loggedIn: false, loginFailed: true, ...payload};
        case `${AUTH_ME+FULFILLED}`:
            return {...state, loggedIn: true, user:payload};
        case `${AUTH_ME+REJECTED}`:
            return {...state, loggedIn: false, ...payload};
        default:
            return state;
    }
}