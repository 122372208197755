import {GET_FEATURES, FULFILLED, REJECTED, PENDING} from '../utils/types';
const initialState = {
    data: [],
    error: false,
    featuresLoading: false
};

export default function featuresReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case `${GET_FEATURES+PENDING}`:
            return {...state, error: false, featuresLoading: true};
        case `${GET_FEATURES+FULFILLED}`:
            return {...state, ...payload,data:payload, error: false, featuresLoading: false};
        case `${GET_FEATURES+REJECTED}`:
            return {...state, error: true, featuresLoading: false, data:[]};
        default:
            return state;
    }
}