import {
  GET_QUICK_SEARCH_MAP,
  FULFILLED,
  REJECTED,
  PENDING,
} from "utils/types";

import { formatMapAPIResponse } from "utils/mapUtils";

const initialState = {
  loading: false,
  result: {},
  error: "",
};

export default function quickSearchMapReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${GET_QUICK_SEARCH_MAP + PENDING}`:
      return { ...state, loading: true, data: [], error: "" };
    case `${GET_QUICK_SEARCH_MAP + FULFILLED}`:
      return {
        ...state,
        loading: false,
        data: [...formatMapAPIResponse(payload) ],
      };
    case `${GET_QUICK_SEARCH_MAP + REJECTED}`:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
