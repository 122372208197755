import {
    GET_HOTLINES,
    FULFILLED,
    REJECTED,
    PENDING,
  } from "../utils/types";
  
  const initialState = {
    data: [],
    error: false,
    loading: false,
  };
  
  export default function hotlinesReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case `${GET_HOTLINES + PENDING}`:
        return { ...state, error: false, loading: true, data: [] };
      case `${GET_HOTLINES + FULFILLED}`:
        return { ...state, ...payload , error: false, loading: false };
      case `${GET_HOTLINES + REJECTED}`:
        return { ...state, error: action.payload, loading: false };
      default:
        return state;
    }
  }
  