import {
  GET_QUESTION,
  INSERT_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
  FULFILLED,
  REJECTED,
  PENDING
} from "../utils/types";
const initialState = {
  data: {},
  getError: false,
  updateError: false,
  deleteError: false,
  insertError: false,
  getLoading: false,
  updateLoading: false,
  deleteLoading: false,
  insertLoading: false
};

export default function questionReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    // GET
    case `${GET_QUESTION + PENDING}`:
      return { ...state, getError: false, getLoading: true };
    case `${GET_QUESTION + FULFILLED}`:
      return { ...state, data: payload, getError: false, getLoading: false };
    case `${GET_QUESTION + REJECTED}`:
      return { ...state, getError: true, getLoading: false, data: {} };
    // UPDATE
    case `${UPDATE_QUESTION + PENDING}`:
      return { ...state, updateError: false, updateLoading: true };
    case `${UPDATE_QUESTION + FULFILLED}`:
      return {
        ...state,
        data: payload,
        updateError: false,
        updateLoading: false
      };
    case `${UPDATE_QUESTION + REJECTED}`:
      return { ...state, updateError: true, updateLoading: false };
    // DELETE
    case `${DELETE_QUESTION + PENDING}`:
      return { ...state, deleteError: false, deleteLoading: true };
    case `${DELETE_QUESTION + FULFILLED}`:
      return {
        ...state,
        data: payload,
        deleteError: false,
        deleteLoading: false
      };
    case `${DELETE_QUESTION + REJECTED}`:
      return { ...state, deleteError: true, deleteLoading: false};
    // INSERT
    case `${INSERT_QUESTION + PENDING}`:
      return { ...state, insertError: false, insertLoading: true };
    case `${INSERT_QUESTION + FULFILLED}`:
      return {
        ...state,
        data: payload,
        insertError: false,
        insertLoading: false
      };
    case `${INSERT_QUESTION + REJECTED}`:
      return { ...state, insertError: true, insertLoading: false};
    default:
      return state;
  }
}
