import React from "react";
import { Helmet } from "react-helmet";
import { withSnackbar } from "notistack";
import { injectIntl } from "react-intl";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Wizard from "components/Wizard/Wizard.jsx";
import HotlineInfo from "views/Pages/Hotlines/WizardSteps/HotlineInfo.jsx";
import TimeAndCategories from "views/Pages/Hotlines/WizardSteps/TimeAndCategories.jsx";
import Features from "views/Pages/Hotlines/WizardSteps/Features.jsx";
import { getPageTitle } from "utils/adminUtils";
import api from "../../../utils/api";

// const intl = window.intl;
class EditHotline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.hotlineID,
      hotline: {},
      timeInfo: [],
      isLoading: true,
      categories: [],
    };
  }

  componentDidMount = () => {
    if (!this.state.id) {
      this.setState({ isLoading: false });
    } else {
      this.getHotline();
    }
  };

  getHotline = () => {
    api.get(
      "/hotline/" + this.state.id,
      (data) => {
        let hotline = { ...data };
        let question_data = {};
        (data.question_data || []).forEach((question) => {
          let pivot = question.pivot;
          const { id, answer } = pivot;
          question_data[question.id] = { id, answer };
        });
        hotline.question_data = question_data;

        this.setState({
          isLoading: false,
          hotline,
          timeInfo: data.timeinfos,
          categories: data.hotlinecategory,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  insertHotline = (hotline) => {
    api.post(
      "/hotline",
      hotline,
      () => {
        this.props.enqueueSnackbar("Hotline created successfully", {
          variant: "success",
        });
        this.props.history.replace("/admin/hotlines");
        return false;
      },
      (error) => {
        this.props.enqueueSnackbar("Error occured while creating hotline", {
          variant: "error",
        });
        return false;
      }
    );
  };
  updateHotline = (hotline) => {
    api.put(
      "/hotline",
      hotline,
      () => {
        this.props.enqueueSnackbar("Hotline updated successfully", {
          variant: "success",
        });
        this.props.history.replace("/admin/hotlines");
        return false;
      },
      (error) => {
        this.props.enqueueSnackbar("Error occured while updating hotline", {
          variant: "error",
        });
        return false;
      }
    );
  };

  saveHotline = (data) => {
    const hotline = {
      ...data.hotlineInfo,
      timeInfo: data.timeInfo,
      questionData: data.features,
      id: this.state.id,
    };
    if (hotline.id) {
      this.updateHotline(hotline);
    } else {
      // Checking whether the hotline name is empty/Undefined
      if (hotline.name !== "" && typeof (hotline.name) != "undefined") {
        this.insertHotline(hotline);
      }
      else {
        this.props.enqueueSnackbar("Kindly enter a valid hotline name", {
          variant: "error",
        });
      }
    }
  };

  render() {
    const {
      isLoading = false,
      hotline = {},
      timeInfo = [],
      categories = [],
    } = this.state;
    const timeAndCategories = {
      timeInfo,
      categories,
    };

    return (
      <>
        <Helmet>
          <title>{getPageTitle("view.hotlines.addHotline")}</title>
        </Helmet>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8}>
            {isLoading ? (
              "Loading"
            ) : (
              <Wizard
                validate={true}
                steps={[
                  {
                    stepName: "Hotline Information",
                    stepComponent: HotlineInfo,
                    stepId: "hotlineInfo",
                    data: hotline,
                  },
                  {
                    stepName: "Hours and Categories",
                    stepComponent: TimeAndCategories,
                    stepId: "timeInfo",
                    data: timeAndCategories,
                  },
                  {
                    stepName: "Features",
                    stepComponent: Features,
                    stepId: "features",
                    data: hotline.question_data,
                  },
                ]}
                title="Create a hotline"
                subtitle={"Enter Hotline Information Below"}
                finishButtonClick={this.saveHotline}
                adminScrollToTop={this.props.adminScrollToTop}
                color="primary"
              />
            )}
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default injectIntl(withSnackbar(EditHotline));
