// import {
//     container,
//     defaultFont,
//     primaryColor,
//     defaultBoxShadow,
//     infoColor,
//     successColor,
//     warningColor,
//     dangerColor,
//     boxShadow,
//     drawerWidth,
//     transition,
//     whiteColor,
//     grayColor,
//     blackColor,
//     hexToRgb
//   } from "assets/jss/material-dashboard-pro-react.jsx";

const mainNavBarStyle = (theme) => ({
  navBar: {
    backgroundColor: "#eeeeee",
    },
  menuItems: {
    margin: "auto",
    "@media (max-width: 1024px)": {
      textAlign: "center",
      display: "block",
    },
    "@media (min-width: 768px)":{
      display:"flex",
      columnGap :"10px",
    }
  },
  mainlogo: {
    padding: "6px 30px",
    "@media (max-width: 640px)": {
      minWidth: "130px",
      //   padding:"10px 0px !important",
    },
    "@media only screen  and (min-width: 768px)  and (max-height: 1024px)": {
      //   padding:"10px 0px !important",
    },
  },
  imageProps: {
    maxWidth: "120px",
  },
  submenu: {
    color: "#233750",
    fontWeight: "bold",
    fontSize: "14px",
    fontFamily: "Nunito, Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen-Sans,Ubuntu,Cantarell,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",
    textTransform: "capitalize",
    padding: "15px 0px",
    "&:hover": {
      backgroundColor: "initial",
    },
    "@media (max-width: 768px)": {
      paddingTop: "5px",
    },
  },
  rightMenu: {
    display: "flex",
    float: "right",
    marginRight: "32px",
    padding: "15px 0px",
    fontWeight: "bold",
    marginLeft : "auto",
    fontFamily: "Nunito, Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen-Sans,Ubuntu,Cantarell,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",
  },
  devWarning: {
    textAlign: "center",
    padding: "15px 0px",

  },
});

export default mainNavBarStyle;
