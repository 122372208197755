// import {
//     defaultFont,
//     container,
//     containerFluid,
//     primaryColor,
//     whiteColor,
//     grayColor
//   } from "assets/jss/material-dashboard-pro-react.jsx";
  
const footerNavBarStyle = theme => ({
    footerMain: {
        // marginLeft: '5px',
    },
    gridContainer: {
        flexGrow: 1,
        backgroundColor: "#233750",
       // position:"absolute",
        //bottom:"0",
        width:"100%",
        "@media (max-width: 768px)": {
            textAlign: "center"
        },
    },
    footerLeftMenu: {
        color: "#fff",
        padding: "0px 5px",
        "@media (max-width: 768px)": {
            display:"inline-block",
            listStyle:"none",
          },
    },
    footerLastMenu: {
        color: "#fff",
        // padding: "12px 5px",
        border: "1px solid #fff",
        borderRadius: "0px !important",
        float: "right",
        right: "30px",
        padding: "5px 5px",
    },
    firstGrid: {
        padding: "12px 30px",
        /* Tablet View - Portrait)*/
        "@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)" :{
            display:"flex",
            justifyContent:"center",
            alignItems: "center",
        },
    },
    lastGrid: {
        textAlign: "right",
        padding: "0px 20px",
        "@media (max-width: 768px)": {
            textAlign: "center"
        },   
        /* Tablet View - Portrait)*/
        "@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)" :{
            display:"flex",
            justifyContent:"center",
            alignItems: "center"
        }
    },
    socialmedia: {
        fontSize:"16px",
        color:"#fff",
    },
    copyrightdiv:{
        color:"#FFFFFF",
        padding:"12px", 
        textAlign:"center",
        /* Tablet View - Portrait)*/
        "@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)" :{
            display:"flex",
            justifyContent:"center",
            alignItems: "center"
        }
    },
    iconsAlignment:{
        padding: "15px 20px"
    }
})

export default footerNavBarStyle;
  