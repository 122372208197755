import React, { useEffect, useState, useCallback } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  OverlayView,
  Marker,
  InfoWindow,
} from "react-google-maps";
import mapMarkerOpen from "assets/img/mapMarkerOpen.svg";
import mapMarkerClosed from "assets/img/mapMarkerClosed.svg";
import userLocation from "assets/img/userLocation.png";
import serviceMapViewStyles from "assets/jss/material-dashboard-pro-react/components/serviceMapViewStyles";

const Map = (props) => {
  const [state, setState] = useState({
    selectedAgency: null,
    centerLat: props.userLat || 41.502369,
    centerLng: props.userLng || -81.675461,
    zoom: 12,
  });
  const { showServiceOnMap } = props;
  const { classes, agencies: { data } } = props;
  let mapRef;

  useEffect(() => {
    if (data) {
      const tempAgency = data.find(ele => ele.name === showServiceOnMap);
      if (tempAgency) {
        // on click on card zoom in location
        setState({
          ...state,
          centerLat: tempAgency.lat,
          centerLng: tempAgency.lng,
          zoom: 14,
        });

      }
    }
  }, [showServiceOnMap, data]);

  const handleOverlayClick = useCallback((agency) => {
    setState(prevState => ({
      ...prevState,
      selectedAgency: agency,
      centerLat: Number(agency.lat),
      centerLng: Number(agency.lng),
      zoom: 16,
    }));
  }, []);

  const handleMyLocationClick = () => {
    setState({
      ...state,
      centerLat: props.userLat || 41.502369,
      centerLng: props.userLng || -81.675461,
    });
  };

  const setMapRef = (ref) => {
    mapRef = ref;
  };

  const zoomIn = () => {
    setState({
      ...state,
      centerLat: mapRef.getCenter().lat(),
      centerLng: mapRef.getCenter().lng(),
      zoom: state.zoom + 1,
    });
  };

  const zoomOut = () => {
    setState({
      ...state,
      centerLat: mapRef.getCenter().lat(),
      centerLng: mapRef.getCenter().lng(),
      zoom: state.zoom - 1,
    });
  };

  const handleDragEnd = () => {
    setState({
      ...state,
      centerLat: mapRef.getCenter().lat(),
      centerLng: mapRef.getCenter().lng(),
    });
  };

  const renderMapButtons = () => (
    <div className={classes.mapButtonsRoot}>
      <div className={classes.mapButtonContainer}>
        <IconButton
          disableRipple
          onClick={zoomIn}
          className={classes.mapButtons}
        >
          <AddIcon className={classes.mapIcons} fontSize={"large"} />
        </IconButton>
        <IconButton
          disableRipple
          className={classes.mapButtons}
          onClick={zoomOut}
        >
          <RemoveIcon className={classes.mapIcons} fontSize={"large"} />
        </IconButton>
        <IconButton
          disableRipple
          className={classes.mapButtons}
          onClick={handleMyLocationClick}
        >
          <MyLocationIcon className={classes.mapIcons} fontSize={"large"} />
        </IconButton>
      </div>
    </div>
  );

  const renderAgencyMarkers = () =>
    data &&
    data.map((agency) => (
      <OverlayView
        key={agency.key}
        position={{ lat: Number(agency.lat), lng: Number(agency.lng) }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div
          onClick={() => handleOverlayClick(agency)}
          style={{
            width: '44px', // You can adjust the width and height
            height: '63px', // based on your icon size
            backgroundImage: `url(${agency.isOpen ? mapMarkerOpen : mapMarkerClosed})`,
            backgroundSize: 'cover',
            cursor: 'pointer',
          }}
        />
      </OverlayView>
    ));

  const renderUserLocation = () =>
    props.acceptedLocation &&
    props.userLat &&
    props.userLng && (
      <Marker
        key={"userLocation"}
        position={{ lat: props.userLat, lng: props.userLng }}
        icon={{
          url: userLocation,
          scaledSize: new window.google.maps.Size(80, 80),
        }}
      />
    );

  const renderInfoWindow = () =>
    state.selectedAgency && (
      <InfoWindow
        onCloseClick={() => {
          setState({
            ...state,
            selectedAgency: null,
            centerLat: mapRef.getCenter().lat(),
            centerLng: mapRef.getCenter().lng(),
          });
        }}
        position={{
          lat: Number(state.selectedAgency.lat),
          lng: Number(state.selectedAgency.lng),
        }}
      >
        {React.cloneElement(props.infowindow, {
          agency: state.selectedAgency,
          showSlotCount: props.showSlotCount,
          userLat: props.userLat,
          userLng: props.userLng,
        })}
      </InfoWindow>
    );

  return (
    <>
      {renderMapButtons()}
      <GoogleMap
        ref={setMapRef}
        zoom={state.zoom}
        center={{
          lat: state.centerLat,
          lng: state.centerLng,
        }}
        onDragEnd={handleDragEnd}
        options={{
          disableDefaultUI: true,
          scaleControl: true, // allow scale controle
          zoomControl: false,
        }}
      >
        {renderAgencyMarkers()}
        {renderUserLocation()}
        {renderInfoWindow()}
      </GoogleMap>
    </>
  );
};

const MapContainer = withScriptjs(withGoogleMap(Map));

const ServicesMapView = (props) => {
  const MAP_API_KEY = `AIzaSyCa952cWXECVQi36D1luAAcsaxuZuymIgI`;
  const MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&libraries=places`;

  return (
    <MapContainer
      googleMapURL={MAP_URL}
      loadingElement={<div style={{ height: '100%' }} />}
      containerElement={<div style={{ height: '350px' }} />}
      mapElement={<div style={{ height: '100%' }} />}
      {...props}
    />
  );
};

export default withStyles(serviceMapViewStyles)(ServicesMapView);
