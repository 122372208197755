import React from "react";
import { withRouter } from "react-router";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import quickSearchStyle from "assets/jss/material-dashboard-pro-react/views/quickSearchStyle";
import { getQuestions, stepNames, genderAnswers } from "./questions";
import Speech from "speak-tts";
import { FaVolumeUp } from "react-icons/fa";

const speech = new Speech();
speech
  .init({
    volume: 1,
    rate: 0.9,
    lang: "en-US",
    voice: "Google US English",
    splitSentences: true,
  })
  .then((data) => {
    // The "data" object contains the list of available voices and the voice synthesis params
    console.log("Speech is ready, voices are available", data);
  })
  .catch((e) => {
    console.error("An error occured while initializing : ", e);
  });

class QuickSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      currQueNum: 0,
      selectedRadio: null,
      selectedCheckBoxes: [],
      progress: 100 / getQuestions().length,
      genderResponse: false,
    };
    this.questions = getQuestions();
    // find id for male answer
    let genderQuestion = this.questions.find(
      (question) => question.step === stepNames.GENDER
    );
    let maleAnswer = genderQuestion.answerOptions.find(
      (answer) => answer.answerText === genderAnswers.MALE
    );
    this.MALE_ANSWER_ID = maleAnswer.id;
    this.reloadFunction = this.reloadFunction.bind(this);
  }

  componentDidMount() {
    window.addEventListener("popstate", this.reloadFunction);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.reloadFunction);
  }

  reloadFunction() {
    if (window.location.pathname === '/auth/quick-search') {
      window.location.reload(false);
    }
  }

  handleVoice = () => {
    speech
      .speak({
        text:
          this.questions[this.state.currQueNum].isMultiSelect ?
            this.questions[this.state.currQueNum].questionText + " you can choose more than one " +
            " and the options are: " +
            this.questions[this.state.currQueNum].answerOptions.map(
              (option) => " " + option.answerText
            ) :
            this.questions[this.state.currQueNum].questionText +
            " and the options are: " +
            this.questions[this.state.currQueNum].answerOptions.map(
              (option) => " " + option.answerText
            ),
      })
      .then(() => {
        console.log("Success !");
      })
      .catch((e) => {
        console.error("An error occurred :", e);
      });
  };

  toggleModalState = (event) => {
    event.preventDefault();
    this.questions = getQuestions();
    this.setState({
      selectedRadio: null,
      selectedCheckBoxes: [],
      currQueNum: 0,
      isModalOpen: !this.state.isModalOpen,
      progress: 100 / getQuestions().length,
    });
  };

  handleCheckBoxSelect = (event) => {
    const id = Number(event.target.id);
    // there is a problem in mental health concerns - duplicate ids
    const { selectedCheckBoxes } = this.state;
    let newSelectedCheckBoxes = selectedCheckBoxes.includes(id)
      ? selectedCheckBoxes.filter((c) => c !== id)
      : [...selectedCheckBoxes, id];
    this.setState({
      selectedCheckBoxes: newSelectedCheckBoxes,
    });
  };

  handleRadioSelect = (event) => {
    this.setState({
      selectedRadio: Number(event.target.id),
    });
  };

  handleBackClick = () => {
    let html = document.getElementById("quicksearchcontent");
    // code added for quick search pop-up to scroll to top when backButton() is clicked
    if (html) {
      html.scrollTop = 0;
    }
    if (this.state.genderResponse && this.questions[3]) {
      this.setState({ progress: this.state.progress - 28.6, genderResponse: false });
    }
    else {
      this.setState({ progress: this.state.progress - 14.3 });
    }
    const currQueDetails = this.questions[this.state.currQueNum];
    // save current selection
    if (currQueDetails.isMultiSelect) {
      currQueDetails["selectedOptions"] = [...this.state.selectedCheckBoxes];
    } else {
      currQueDetails["selectedOptions"] = [this.state.selectedRadio];
    }
    // restore previous question into current state
    let prevQueNumber = this.state.currQueNum - 1;
    // if selected gender is male, skip pregnancy step
    if (
      prevQueNumber > 1 &&
      this.questions[prevQueNumber - 1].step === stepNames.GENDER &&
      this.questions[prevQueNumber - 1].selectedOptions[0] ===
      this.MALE_ANSWER_ID
    ) {
      prevQueNumber = prevQueNumber - 1;
    }
    let previousQueDetails = this.questions[prevQueNumber];
    let prevRadioSelection = null,
      prevCheckboxSelection = [];
    if (previousQueDetails.isMultiSelect) {
      prevCheckboxSelection = [...previousQueDetails.selectedOptions];
    } else {
      prevRadioSelection = previousQueDetails.selectedOptions[0];
    }
    this.setState({
      selectedCheckBoxes: prevCheckboxSelection,
      selectedRadio: prevRadioSelection,
      currQueNum: prevQueNumber,
    })
  };

  handleNext = () => {
    const currQueDetails = this.questions[this.state.currQueNum];
    let nextQueNumber = this.state.currQueNum + 1;
    // save current selection
    if (currQueDetails.isMultiSelect) {
      currQueDetails["selectedOptions"] = [...this.state.selectedCheckBoxes];
    } else {
      currQueDetails["selectedOptions"] = [this.state.selectedRadio];
    }
    // call api since this is the last step
    if (currQueDetails.step === stepNames.TRANSPORTATION) {
      // submit api request
      this.handleSubmit();
    } else {
      // scroll to top
      let html = document.getElementById("quicksearchcontent");
      if (html) {
        html.scrollTop = 0;
      }
      // if selected gender is male, skip and clear pregnancy step
      if (
        currQueDetails.step === stepNames.GENDER &&
        this.state.selectedRadio === this.MALE_ANSWER_ID
      ) {
        this.setState({ progress: this.state.progress + 28.6, genderResponse: true });
        // clear selected options for pregnancy question
        const pregnancyQueDetails = this.questions[nextQueNumber];
        pregnancyQueDetails.selectedOptions = [];
        // skip pregnancy question
        nextQueNumber = nextQueNumber + 1;
      }
      else {
        this.setState({ progress: this.state.progress + 14.3 });
      }

      // load existing selection if user is coming back to this question
      const nextQueDetails = this.questions[nextQueNumber];
      let nextRadioSelection = null,
        nextCheckBoxSelection = [];
      if (
        nextQueDetails.selectedOptions &&
        nextQueDetails.selectedOptions.length > 0
      ) {
        if (nextQueDetails.isMultiSelect) {
          nextCheckBoxSelection = [...nextQueDetails.selectedOptions];
        } else {
          nextRadioSelection = nextQueDetails.selectedOptions[0];
        }
      }
      this.setState({
        selectedCheckBoxes: nextCheckBoxSelection,
        selectedRadio: nextRadioSelection,
        currQueNum: nextQueNumber,
      });
    }
  };

  handleSubmit() {
    let requestData = [];
    this.questions.forEach((question) => {
      let selectedFilters = [];
      if (
        question.selectedOptions &&
        question.selectedOptions.length > 0 &&
        question.selectedOptions[0] !== null
      ) {
        question.selectedOptions.forEach((index) => {
          selectedFilters.push(question.answerOptions[index]);
        });
        requestData.push({
          [question.step]: selectedFilters,
        });
      }
    });
    if (this.props.location.pathname === "/auth/quick-search") {
      this.setState({
        isModalOpen: false,
        currQueNum: 0,
        selectedRadio: null,
        selectedCheckBoxes: [],
      });
      this.props.history.push({
        pathname: "/auth/quick-search",
        state: { userSelection: requestData },
      });
      if (this.props.searchServices) {
        // searchServices function takes this format:
        // this.props.searchServices(pageNumber, userSelection);
        this.props.searchServices(1, requestData);
      }
    } else {
      this.props.history.push({
        pathname: "/auth/quick-search",
        state: { userSelection: requestData },
      });
    }
  }

  renderOptions = (currQueDetails) => {
    const { classes } = this.props;
    if (currQueDetails.isMultiSelect) {
      return currQueDetails.answerOptions.map((option) => (
        <FormControlLabel
          key={option.id}
          control={
            <Checkbox
              id={String(option.id)}
              checked={this.state.selectedCheckBoxes.includes(option.id)}
              value={String(option.id)}
              onChange={this.handleCheckBoxSelect}
              key={option.answerText}
              classes={{
                root: classes.checkbox,
                checked: classes.checkboxChecked,
              }}
            />
          }
          label={option.answerText}
          className={classes.formControlLabel}
        />
      ));
    }
    return (
      <RadioGroup
        aria-label={this.state.currQueNum}
        value={String(this.state.selectedRadio)}
        name={currQueDetails.name}
        onChange={this.handleRadioSelect}
      >
        {currQueDetails.answerOptions.map((option) => (
          <FormControlLabel
            key={option.id}
            value={String(option.id)}
            control={
              <Radio
                id={String(option.id)}
                classes={{ root: classes.radio, checked: classes.radioChecked }}
              />
            }
            label={option.answerText}
            className={classes.formControlLabel}
          />
        ))}
      </RadioGroup>
    );
  };

  renderButtons() {
    const { classes } = this.props;
    const currQueDetails = this.questions[this.state.currQueNum];
    return (
      <Grid container className={classes.buttonsGrid}>
        <FaVolumeUp
          onClick={this.handleVoice}
          className={classes.audioButton}
        />
        {currQueDetails.step !== stepNames.AGE && (
          <Button
            onClick={this.handleBackClick}
            variant="outlined"
            color="primary"
            className={classes.backButton}
          >
            Back
          </Button>
        )}
        <Button
          onClick={this.handleNext}
          variant="outlined"
          color="primary"
          className={classes.nextButton}
        >
          {currQueDetails.step === stepNames.TRANSPORTATION ? "Submit" : "Next"}
        </Button>
      </Grid>
    );
  }

  texthightlight() {
    const { classes } = this.props;
    const currQueDetails = this.questions[this.state.currQueNum];
    return (
      <>
        {currQueDetails.isMultiSelect ?
          <>
            <FormLabel
              classes={{
                root: classes.multiAnswerQuestion,
                focused: classes.questionFocused,
              }}
            >
              {currQueDetails.questionText}
            </FormLabel>
            <Typography classes={{
              root: classes.questionHint,
            }}>
              (You can choose more than one)
            </Typography>
          </> :
          <FormLabel
            classes={{
              root: classes.question,
              focused: classes.questionFocused,
            }}
          >
            {currQueDetails.questionText}
          </FormLabel>}
      </>
    )
  }

  render() {
    const { classes } = this.props;
    const currQueDetails = this.questions[this.state.currQueNum];
    return (
      <>
        <Button
          variant="contained"
          onClick={this.toggleModalState}
          color="primary"
          className={this.props.className}
        >
          {this.props.buttonText || "Quick Search"}
        </Button>
        <Dialog
          open={this.state.isModalOpen}
          onClose={this.toggleModalState}
          fullWidth
          maxWidth="sm"
          BackdropProps={{ style: { backgroundColor: "#233750", opacity: 0.8 } }}
        >
          <Typography component="div" align="right">
            <CloseIcon
              onClick={this.toggleModalState}
              className={classes.closeIcon}
            />
          </Typography>
          <LinearProgress classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} style={{ width: 130, alignSelf: "center", height: 8, color: "secondary", borderRadius: 8 }} variant="determinate" value={this.state.progress} />
          <DialogTitle disableTypography className={classes.titleContainer}>
            <Typography variant="h4" className={classes.title}>
              Quick Search
            </Typography>
          </DialogTitle>
          <DialogContent id="quicksearchcontent">
            <FormControl className={classes.questionContainer}>
              {this.texthightlight()}
              {this.renderOptions(currQueDetails)}
            </FormControl>
            {currQueDetails.step === stepNames.AGE && (
              <p className={classes.desclaimerText}>
                <i>
                  Drughelp.care does not store your search activity. Your
                  answers to these questions will only be used to match you to
                  the best treatment services.
                </i>
              </p>
            )}
            {this.renderButtons()}
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withStyles(quickSearchStyle)(withRouter(QuickSearch));
