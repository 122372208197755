import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  insertCategory,
  updateCategory,
  deleteCategory
} from "../../actions/action_category";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Typography from "@material-ui/core/Typography";
import { withSnackbar } from 'notistack';

// icons
import CategoryIcon from "@material-ui/icons/Category";

import Switch from "@material-ui/core//Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// core components

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import api from "../../utils/api";
import { Link } from "react-router-dom";

const intl = window.intl;
class CategoryEditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryID: props.match.params.categoryID,
      name: "",
      description: "",
      allow_contact_persons: true,
      allow_attachments: true,
      categoryNotFound: false,
      error: {},
      mandatory: { name: true, description: true },
      modified: false,
      snackbarMessage: null
    };
  }
  getCategory = async id => {
    const category = await api.get(`/categories/${id}`);
    if (category && Object.keys(category).length > 0) {
      this.setState({ ...category, categoryNotFound: false });
    } else {
      this.setState({ categoryNotFound: true });
    }
  };

  componentDidMount() {
    const { categoryID } = this.state;
    categoryID && this.getCategory(categoryID);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data = {} } = nextProps.category || {};

    if (data.name !== this.state.name) {
      const {
        name,
        description,
        allow_attachments,
        allow_contact_persons
      } = data;
      this.setState({
        name,
        description,
        allow_attachments,
        allow_contact_persons
      });
    }
  }
  validateField = (name, value) => {
    const { error, mandatory } = this.state;
    if (mandatory[name]) {
      error[name] = value === undefined || value.length === 0;
    }
    this.setState({ error });
    return !error[name];
  };

  handleSwitchChange = name => event => {
    this.setState({ [name]: event.target.checked, modified: true });
  };
  handleTextChange = name => event => {
    this.validateField(name, event.target.value);
    this.setState({ [name]: event.target.value, modified: true });
  };
  onSubmit = () => {
    const {
      categoryID,
      name,
      description,
      allow_attachments,
      allow_contact_persons,
      modified,
      error={}
    } = this.state;
    let valid = true;
    if (!modified) {
      this.props.enqueueSnackbar("No information is updated", {variant: 'info'});
      return false;
    } else {
      valid = Object.keys(error).every((name)=>{
        return !error[name];
      });
    }
    if(!valid) {
      this.props.enqueueSnackbar("Please provide all required details", {variant: 'error'});
      return false;
    }
    let data = {
      name,
      description,
      allow_attachments,
      allow_contact_persons
    };
    if (categoryID) {
      data.id = categoryID;
      this.props.updateCategory(data, () => {
        this.props.enqueueSnackbar("Category updated successfully", {variant: 'success'});
        this.props.history.goBack();
      });
    } else {
      this.props.insertCategory(data, () => {
        this.props.enqueueSnackbar("Category saved successfully", {variant: 'success'});
        this.props.history.goBack();
      });
    }
  };
  render() {
    const { classes } = this.props;
    const { categoryID, categoryNotFound, error = {},modified } = this.state;
    return (
      <React.Fragment>
        {categoryID && categoryNotFound ? (
          <Card>
            <CardBody>
              <Typography variant="subtitle1" gutterBottom align="center">
                {intl.formatMessage({
                  id: "view.categoryEdit.categoryNotFound"
                })}{" "}
                <Link to="/admin/edit-category">
                  {intl.formatMessage({
                    id: "createNew"
                  })}
                </Link>
              </Typography>
            </CardBody>
          </Card>
        ) : (
          <GridContainer
            container
            spacing={24}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <CategoryIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle} color="primary">
                    {(categoryID ? "Update" : "Create") + " Category"}
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText="Name"
                        id="name"
                        error={error["name"]}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.name,
                          onChange: this.handleTextChange("name")
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText="Description"
                        id="description"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          multiline: true,
                          rows: 5,
                          value: this.state.description,
                          onChange: this.handleTextChange("description")
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.allow_attachments}
                            value={this.state.allow_attachments}
                            onChange={this.handleSwitchChange(
                              "allow_attachments"
                            )}
                            color="primary"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="Allow attachments"
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.allow_contact_persons}
                            value={this.state.allow_contact_persons}
                            onChange={this.handleSwitchChange(
                              "allow_contact_persons"
                            )}
                            color="primary"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="Allow contact persons"
                      />
                    </GridItem>
                  </GridContainer>
                  <Button
                    color="primary"
                    disabled={!modified}
                    className={classes.updateProfileButton}
                    onClick={this.onSubmit}
                  >
                    {`${categoryID ? "Update" : "Save"} Category`}
                  </Button>
                  <Clearfix />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </React.Fragment>
    );
  }
}
CategoryEditPage.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    category: state.category
  };
};
const mapDispatchToProps = dispatch => {
  return {
    insertCategory: bindActionCreators(insertCategory, dispatch),
    updateCategory: bindActionCreators(updateCategory, dispatch),
    deleteCategory: bindActionCreators(deleteCategory, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(userProfileStyles)(withSnackbar(CategoryEditPage))));
