import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "./utils/intl";
// import includes from 'core-js/features/array/includes.js';
import 'url-search-params-polyfill';

//import 'core-js/es/set';
//import 'core-js/es/map';

// import AuthLayout from "layouts/AuthOld.jsx";
import AuthLayout from "layouts/Auth.jsx";

// import RtlLayout from "layouts/RTL.jsx";
import AdminLayout from "layouts/Admin.jsx";
import HomePage from "./views/Pages/HomePage/HomePage.jsx";
import PrivateRoute from "./views/Components/PrivateRoute";

import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";
import { Provider } from "react-redux";
import { getStore } from "./store/configureStore";
import { SnackbarProvider } from "notistack";
const store = getStore();
const hist = createBrowserHistory();

ReactDOM.render(
  <SnackbarProvider
    anchorOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
  >
    <Provider store={store}>
      <Router history={hist}>
        <Switch>
          {/* <Route path="/rtl" component={RtlLayout} /> */}
          <Route path="/auth" component={AuthLayout} />
          <Route path="/home" component={HomePage} />
          <PrivateRoute path="/admin" component={AdminLayout} />
          {/* <Redirect exact from="/search" to="/auth/search" /> */}
          <Redirect exact from="/home" to="/auth/home" />
          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
      </Router>
    </Provider>
  </SnackbarProvider>,
  document.getElementById("root")
);