import React from "react";
import { Route, Redirect } from "react-router-dom";
const PrivateRoute = ({loggedIn, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem('drughelp-authenticated') === "true" ? (
        <Component {...props} />
      ) : (
        
        <Redirect to="/auth/home" />
      )
    }
  />
);
export default PrivateRoute;
