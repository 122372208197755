import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import authStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle.jsx";
import routes from "routes.js";
import MainNavBar from "components/Navbars/MainNavBar.jsx";
import FooterNavBar from "components/Footer/FooterNavBar.jsx";
import ChatBot from "../views/Pages/ChatBot/ChatBot.jsx";
//images
import register from "assets/img/register.jpeg";
import login from "assets/img/login3.jpg";
import lock from "assets/img/lock.jpeg";
import error from "assets/img/clint-mckoy.jpg";
import pricing from "assets/img/bg-pricing.jpeg";

class AuthLayout extends React.Component {
  componentDidMount() {
    document.body.style.overflow = "unset";
  }

  getRoutes = (routes) => {
    const result = [];
    routes.forEach((route, index) => {
      if (route.collapse) {
        result.push(...this.getRoutes(route.views));
      } else if (route.layout === "/auth") {
        result.push(
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={index}
          />
        );
      }
    });
    return result;
  };

  getBgImage = () => {
    switch (window.location.pathname) {
      case "/auth/register-page":
        return register;
      case "/auth/login-page":
      case "/auth/search":
      case "/auth/service-page":
      case "/auth/about-page":
      case "/auth/privacy-policy-page":
      case "/auth/news-page":
      case "/auth/contactus-page":
        return login;
      case "/auth/pricing-page":
        return pricing;
      case "/auth/lock-screen-page":
        return lock;
      case "/auth/error-page":
        return error;
      default:
        return null;
    }
  };

  getActiveRoute = (routes) => {
    const currentPath = window.location.pathname;
    const activeRoute = routes.find((route) =>
      currentPath.startsWith(`${route.layout}${route.path}`)
    );
    return activeRoute ? activeRoute.name : "Drughelp.care";
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.authContainer}>
          <MainNavBar brandText={this.getActiveRoute(routes)}  {...this.props} />
          <div className={classes.wrapper} ref="wrapper">
            <div className={`${classes.fullPage} full-body`}>
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
            <FooterNavBar white className={classes.containerFluid} />
          </div>
        </div>
        <ChatBot history={this.props.history} />
      </>
    );
  }
}

AuthLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(authStyle)(AuthLayout);