import {GET_CATEGORIES,GET_PUBLIC_CATEGORIES, FULFILLED, REJECTED, PENDING} from '../utils/types';
const initialState = {
    data: [],
    error: false,
    categoriesLoading: false
};

export default function categoriesReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case `${GET_CATEGORIES+PENDING}`:
            return {...state, error: false, categoriesLoading: true};
        case `${GET_CATEGORIES+FULFILLED}`:
            return {...state, ...payload, error: false, categoriesLoading: false};
        case `${GET_CATEGORIES+REJECTED}`:
            return {...state, error: true, categoriesLoading: false, data:[]};
        case `${GET_PUBLIC_CATEGORIES+PENDING}`:
            return {...state, error: false, categoriesLoading: true};
        case `${GET_PUBLIC_CATEGORIES+FULFILLED}`:
            return {...state, data:payload, error: false, categoriesLoading: false};
        case `${GET_PUBLIC_CATEGORIES+REJECTED}`:
            return {...state, error: true, categoriesLoading: false, data:[]};
        default:
            return state;
    }
}