const data = {
  title: "Drughelp.care",
  prev: "PREV",
  next: "NEXT",
  createNew: "Create new",
  service: "Service",
  services: "Services",
  agency: "Agency",
  agencies: "Agencies",
  user: "User",
  users: "Users",
  category: "Category",
  categories: "Categories",
  hotline: "Hotline",
  hotlines: "Hotlines",
  hotlinecategories: "Hotline Categories",
  feature: "Feature",
  features: "Features",
  timeInfo: "Time Information",
  timeInfos: "Time Informations",
  search: "Search",
  changePassword: "Change Password",
  routes: {
    dashboard: "Dashboard",
    login: "Login",
    availabilityStatus: "Availability Status",
  },
  view: {
    services: {
      search: "Search",
      searchServices: "Search Services",
      searchResult: '{count} result(s) found for "{term}"',
      openWebSite: "Open Website",
      call: "Call",
      searchError: "Error occured while searching for services",
      noResultsFound: "No matching results found for given search",
      noServicesAvailable: "No services available at this moment",
      viewService: "View service",
      unregisteredService:
        "This treatment service is unregistered or has not been updated in over a year.",
    },
    categories: {
      search: "Search",
      searchCategories: "Search Categories",
      searchResult: '{count} result(s) found for "{term}"',
      openWebSite: "Open Website",
      searchError: "Error occured while searching for categories",
      noResultsFound: "No matching results found for given search",
      noCategoriesAvailable: "No categories available at this moment",
      viewCategory: "View category",
      editCategory: "Edit category",
      addCategory: "Add new category",
    },
    agencies: {
      search: "Search",
      searchAgencies: "Search Agencies",
      searchResult: '{count} result(s) found for "{term}"',
      openWebSite: "Open Website",
      searchError: "Error occured while searching for agencies",
      noResultsFound: "No matching results found for given search",
      noAgenciesAvailable: "No agencies available at this moment",
      viewAgency: "View agency",
      editAgency: "Edit agency",
      addAgency: "Add new agency",
    },
    hotlines: {
      search: "Search",
      searchHotlines: "Search Hotlines",
      searchResult: '{count} result(s) found for "{term}"',
      searchError: "Error occured while searching for hotlines",
      viewHotline: "View Hotline",
      editHotline: "Edit Hotline",
      addHotline: "Add New Hotline",
      noResultsFound: "No matching results found for given search",
      noHotlinesAvailable: "No hotlines available at this moment",
    },
    hotlinecategories: {
      Error: "Error occured while loading the page",
    },
    users: {
      search: "Search",
      searchUsers: "Search Users",
      searchResult: '{count} result(s) found for "{term}"',
      openWebSite: "Open Website",
      searchError: "Error occured while searching for users",
      noResultsFound: "No matching results found for given search",
      noUsersAvailable: "No users available at this moment",
      viewUser: "View user",
      editUser: "Edit user",
      addUser: "Add new user",
    },
    categoryEdit: {
      categoryNotFound: "Category not found.",
    },
    features: {
      search: "Search",
      searchFeatures: "Search Features",
      searchResult: '{count} result(s) found for "{term}"',
      openWebSite: "Open Website",
      searchError: "Error occured while searching for Features",
      noResultsFound: "No matching results found for given search",
      noFeaturesAvailable: "No Features available at this moment",
      viewFeature: "View feature",
      editFeature: "Edit feature",
    },
    searchService: {
      searchServices: "Search by Agency Name/Service Name",
      search: "Search",
    },
    filterOptions: {
      assessment: "A friendly interview that is the first step in getting help",
      outpatient: "Individual or group counseling",
      intensiveoutpatientprogram: "Group counseling several days each week",
      partialhospitalization: "Day treatment for those with higher needs",
      residential: "You sleep here and get treatment during the day",
      inpatient:
        "Like a hospital for those who require 24/7 medical monitoring",
      "recoveryhousing/soberliving": "A sober, supportive place to live",
      "peersupport/recovery": "Peer Support/Recovery",
      communityadvocates: "Community Advocates",
      "recoverymeetings/groups": "Recovery Meetings/Groups",
      familysupportgroups: "Family Support Groups",
      "hiv/hepatitistesting": "HIV/Hepatitis Testing",
      obtainingnarcan: "Obtaining Narcan",
      needleexchange: "Needle Exchange",
      drugeducation: "Drug Education",
      pregnancyprevention: "Pregnancy Prevention",
      fentanyltestingstrips: "Fentanyl Testing Strips",
      unuseddrugdisposal: "Unused Drug Disposal",
    },
    defaultText: {
      default: "Default Content",
    },
  },
};
export default data;
