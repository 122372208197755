import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { bindActionCreators } from "redux";
import { hotlinesByCategory } from "actions/action_hotlines";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { Grid, IconButton } from "@material-ui/core";
import hotlinesStyle from "assets/jss/material-dashboard-pro-react/views/hotlinesStyle.jsx";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { getHotlineCategories } from "../../../actions/action_hotline_categories";
import Button from "components/CustomButtons/Button.jsx";
import api from "utils/api";
//added after review
import CardMedia from "@material-ui/core/CardMedia";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import CallIcon from "@material-ui/icons/CallRounded";
import ClockIcon from "@material-ui/icons/AccessTimeRounded";
import Tooltip from "@material-ui/core/Tooltip";

/*const CardHeader = {
    height: "70px"
};

const Card = {
  marginBottom: "1em", 
  textAlign: "center", 
  height:"350px", 
  borderRadius:"10px", 
  border:"1px outset", 
  boxShadow: "2px 3px lightgray" 
};
 const CardActions = {
    justifyContent: "center"
 };

 const CardContent = {
    minHeight: "70px"
 };

const styles = {
  CardHeader,
  Card,
  CardContent,
  CardActions,
};*/

const intl = window.intl;
class HotlineCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      frequentHotlines: [],
      loadRanks: true,
      ranks: {},
      frequentlyUsedHotlines: [],
    };
    this.frequently_used = 10;
  }

  componentDidMount() {
    this.searchHotlineCategories();
  }

  searchHotlineCategories = () => {
    let data = {
      filters: [],
      limit: 12
    }
    this.props.getHotlineCategories();
    this.props.hotlinesByCategory(this.frequently_used, data);
  };

  componentWillReceiveProps = (nextProps) => {

    if (this.state.loadRanks && nextProps['hotlines']['data'].length > 0) {
      api.get(
        "getHotlinesRank",
        (response) => {
          let ranks = {};
          response.forEach((ele) => {
            ranks[ele.hotline_id] = ele.hotline_rank;
          });
          let frequentlyUsedHotlines = this.props.hotlines.data.map(hotline => {
            return { ...hotline, hotline_rank: ranks[hotline["id"]] ? ranks[hotline["id"]] : "" }
          })
          frequentlyUsedHotlines.sort((a, b) => {
            if (a.hotline_rank > b.hotline_rank) return 1;
            if (a.hotline_rank < b.hotline_rank) return -1;
            if (a.hotline_rank === b.hotline_rank) return 0;
          })

          this.setState({ loadRanks: false, ranks, frequentlyUsedHotlines });
        },
        (reject) => {
          console.log("error", reject);
          this.setState({ loadRanks: false });
        }
      );
    }

  }

  /*
    Made changes to the below function
      a. Changed loop to interate till 8 from 7
  */
  printDaySequence = (dayInfo) => {
    let days = [
      "",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    dayInfo[7] = dayInfo[0];
    let start = "";
    let end = "";
    let output = "";
    let day1 = "";
    for (let i = 1; i < 8; i++) {
      if (!dayInfo[i].active) {
        dayInfo[i].start = "CLOSED";
        dayInfo[i].end = "CLOSED";
      }
      if (!start && !end) {
        start = dayInfo[i].start;
        end = dayInfo[i].end;
        day1 = days[i];
        continue;
      }
      if (dayInfo[i].start === start && dayInfo[i].end === end) {
        if (i === 8 || i === 7) {
          output =
            output +
            (day1 === days[i] ? day1 : day1 + " - " + days[i]) +
            " : " +
            this.milToStandard(start) +
            (end === "CLOSED" ? "" : " to " + this.milToStandard(end)) +
            "\n";
          break;
        } else continue;
      }
      if (i !== 8) {
        output =
          output +
          (day1 === days[i - 1] ? day1 : day1 + " - " + days[i - 1]) +
          " : " +
          this.milToStandard(start) +
          (end === "CLOSED" ? "" : " to " + this.milToStandard(end)) +
          "\n";
        start = dayInfo[i].start;
        end = dayInfo[i].end;
        day1 = days[i];
      }
      /*
        Added this section to render the timings for sundays
      */
      if (days[i] === days[7]) {
        output =
          output +
          (day1 === days[i] ? day1 : day1 + " - " + days[i]) +
          " : " +
          this.milToStandard(start) +
          (end === "CLOSED" ? "" : " to " + this.milToStandard(end)) +
          "\n";
      }
    }
    return output;
  };

  milToStandard(value) {
    if (value !== null && value !== undefined && value !== "CLOSED") {
      //If value is passed in
      if (value.indexOf("AM") > -1 || value.indexOf("PM") > -1) {
        //If time is already in standard time then don't format.
        return value;
      } else {
        if (value.length === 8) {
          // isn't it sort of a hard-coding
          //If value is the expected length for military time then process to standard time.
          var hour = value.substring(0, 2); //Extract hour
          var minutes = value.substring(3, 5); //Extract minutes
          var identifier = "AM"; //Initialize AM PM identifier

          if (Number(hour) === 12) {
            //If hour is 12 then should set AM PM identifier to PM
            identifier = "PM";
          }
          if (Number(hour) === 0) {
            //If hour is 0 then set to 12 for standard time 12 AM
            hour = 12;
          }
          if (hour > 12) {
            //If hour is greater than 12 then convert to standard 12 hour format and set the AM PM identifier to PM
            hour = hour - 12;
            identifier = "PM";
          }
          return hour + ":" + minutes + " " + identifier; //Return the constructed standard time
        } else {
          //If value is not the expected length than just return the value as is
          return value;
        }
      }
    }
    return "CLOSED";
  }

  //render tooltip for time information of frequently used hotlines
  getTimeInfoTooltip = (timeInfo) => {
    const { classes } = this.props;
    /*
      Added CSS 
        display : "inline-block"
        whiteSpace: "pre-line"
      because without using these, text in the tooltip was not wrapping on new line and the whole tooltip
      text is displayed as a single line
    */
    return (
      <Typography
        variant="subtitle2"
        component="p"
        className={classes.timeToolTip}
      >
        {this.printDaySequence(timeInfo)}
      </Typography>
    );
  };

  //check if the hotline is available 24 hour or limited hours
  formatTimeInfo = (timeInfo) => {
    let sum = 0;
    timeInfo.map((time) => {
      let start = new Date("01/01/2022 " + time.start);
      let end = new Date("01/01/2022 " + time.end);
      let hours = end - start;
      hours = Math.ceil(hours / 60 / 60 / 1000);
      sum = sum + hours;
    });
    //changed 24*7 to 24*8 because of the change in printDaySequence function as days[] array contains 8 index
    if (sum === 192) {
      return "24/7";
    } else if (sum === 0) {
      return "Not Available";
    } else {
      return "Limited hours";
    }
  };

  render() {
    const { classes, hotlinecategories = {}, hotlines } = this.props;
    const categories = hotlinecategories.data.filter(
      (category) => category.id !== this.frequently_used
    );
    // const frequentlyUsedHotlines = hotlines.data;
    const { frequentlyUsedHotlines } = this.state;
    if (hotlinecategories.loading || hotlines.loading) {
      return (
        <Grid item md={12} align="center">
          <Typography variant="subtitle1" gutterBottom align="center">
            Loading Categories... Please wait...
          </Typography>
        </Grid>
      );
    }

    if (hotlinecategories.error || hotlines.error) {
      return (
        <SnackbarContent
          message={intl.formatMessage({
            id: "view.hotlinecategories.Error",
          })}
          color="danger"
        />
      );
    }

    /*
      1. margin added to the grid container to have margin from all sides to have spacing from broders of the screen
          added to all grid contianers in this file to be consistent. 
    */

    /*
      2. added style for Typography at line 73, Background color makes the card color to the given value
          style = color: white for text, padding: 10px for the box to be have more width  
    */
    return (
      <Grid container className={classes.parentGrid} spacing={8}>
        <Grid item xs={12} sm={12} md={12}>
          <Card style={{ backgroundColor: "#233750", maxWidth: "100%" }}>
            <Typography
              variant="h4"
              align="center"
              className={classes.bannerText}
            // style={{ color: "white", fontFamily: "Calibri", padding: "20px" }}
            >
              Crisis Hotlines
            </Typography>
          </Card>
        </Grid>

        {/*
            Added A new grid container to contain the cards and frequently used section in single grid
            CSS: 
              a. increased margin to 5px from 0px to align the borders of CRISIS hotline section and the below grid
              b. added border to match the UI document(outset to get a border look coming out)
              c. borderRadius: to make the border curved for container to match with UI documents
              d. padding: to give space on all the 4 sides
              e. Increased spacing to 16 from 8 as discussed in the meeting.
          */}
        <Grid
          container
          className={classes.HotlineCategoryContainer}
          spacing={16}
        >
          <Grid item xs={12} sm={12} md={9}>
            {/*
                  added Justify Content to center to center the grid items to match with UI documents as without 
                  adding this, contents seems to flow to left giving a lot of space between cards and frequently
                  used section
                */}
            <Grid
              container
              className={classes.categorySubContainer}
              // style={{ margin: "0px", justifyContent: "center" }}
              spacing={32}
            >
              {categories.map((category) => {
                return (
                  /*
                          Added maxwidth to the Grid items(cards) to make the cards match with the UI documents.
                        */
                  <Grid
                    item
                    xs={12}
                    sm={9}
                    md={6}
                    key={category.id}
                    style={{ maxWidth: "275px" }}
                  >
                    {/* 
                            added height to the card element to have the uniform size for cards irrespective of content. 
                            After review:
                              a. Added borderRadius to make card border rounded
                              b. Added border to the cards
                              c. Added boxShadow for the cards to match with UI documents 
                          */}
                    <Card
                      style={{
                        marginBottom: "1em",
                        textAlign: "center",
                        height: "350px",
                        borderRadius: "10px",
                        border: "0px outset",
                        boxShadow: "1px 1px 2px 2px lightgray",
                      }}
                    >
                      {/*
                                added Material UI card Api inbuilt titleTypographyprops which have multiple option fot title
                                used here to make the cardheader title bold

                                added height to card header to to be consistent with the cards because if the category name 
                                is long, it was breaking the responsiveness of card content
                              */}
                      <CardHeader
                        title={category.name}
                        titleTypographyProps={{
                          variant: "title",
                          classes: {
                            title: classes.categoryTitle,
                          }
                        }}
                        style={{ height: "70px" }}
                      />
                      {/* 
                                added style for card media image
                                borderRadius = to make the image circular 
                                height = to adjust the size of card media inside a card
                              */}
                      <CardMedia
                        component="img"
                        className={classes.media}
                        style={{ borderRadius: "50%" }}
                        height="120"
                        image={require("assets/img/hotlinecategories/" +
                          category.name.replace(/[^a-zA-Z0-9]/g, "") +
                          ".svg")}
                        title={category.name}
                      />
                      {/* 
                              added height to the card element to have the uniform size for cards irrespective of content. 
                              This is creating a little responsive issue inside the card when we decrease the size because of 
                              the long description and categories name.
                              To be dicussed. 
                              */}
                      <CardContent style={{ minHeight: "70px" }}>
                        <Typography component="p" style={{
                          fontFamily: "Nunito",
                          overflow: "auto"
                        }}>
                          {category.description}
                        </Typography>
                      </CardContent>
                      <CardActions style={{ justifyContent: "center" }}>
                        {/* <a href={`/auth/categories/${category.id}/hotlines`}> */}
                        {/*
                                      added background color to button to match the UI document
                                      material UI have limited color options for typography in-built as what i understood.

                                      Increased button size from small to medium as per the UI document
                                    */}
                        <Button
                          type="button"
                          size="sm" // or "lg"
                          style={{
                            backgroundColor: "#3d97d3",
                            fontFamily: "Calibri",
                            fontWeight: "1000",
                            fontSize: "15px",
                          }}
                          onClick={() =>
                            this.props.history.push({
                              pathname: `/auth/categories/${category.id}/hotlines`,
                              state: { category: category.name },
                            })
                          }
                        >
                          Find Help
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            {/*
                Moved the typography out of the container because for small screens 
                direction row was breaking for this.
              */}
            <Typography
              variant="subtitle1"
              className={classes.frequentHotlineContainer}
            >
              Frequently Used
            </Typography>
            {/*
                  Added flexDirection to the container with 
                    xs: as row
                    sm: as column
                    md: column
                */}
            <Grid
              container
              className={classes.frequentHotlineList}
            >
              {/*
                    Added style for typography
                    1. color: MaterialUI have some default colors what i found. So used color to match UI Document
                    2. fontweight: 600  // to make text bold
                    3. textTransform: none // Text was coming as All-CAP but UI documents says to use First letter as capital 
                  */}

              {frequentlyUsedHotlines.map((hotline) => {
                return (
                  <Grid item xs={12} sm={6} md={12} key={hotline.id}>
                    <Card style={{ border: "none", boxShadow: "none" }}>
                      <CardContent>
                        <Tooltip title={hotline.description} placement="bottom">
                          {
                            /*
                              Added noWrap to Typography so that on small screen for a particular size, frequently used
                              hotline UI do not look weird.
                            */
                          }
                          <Typography

                            className={classes.frequentlyUsedTitle}
                          >
                            {hotline.name}
                          </Typography>
                        </Tooltip>
                        {
                          hotline.hotline_phone_dictionary &&
                          hotline.hotline_phone_dictionary.map((phone, index) => {
                            return (
                              <Typography
                                key={index}
                                noWrap
                                component="p"
                                className={classes.frequentlyUsedPhone}
                              >
                                <a href={`tel:${phone.phone_number}`}>
                                  <IconButton
                                    className={classes.iconBtn}
                                    aria-label={"Text Line"}
                                    color="primary"
                                    style={{ padding: "0px" }}
                                  >
                                    <CallIcon
                                      fontSize="small"
                                      style={{ margin: "0px 10px 0px 10px" }}
                                    />
                                  </IconButton>
                                </a>
                                {phone.phone_number_display}
                              </Typography>
                            );
                          })
                        }
                        {hotline.time_infos &&
                          <Tooltip
                            title={hotline.time_infos.length > 0 ? this.getTimeInfoTooltip(hotline.time_infos) : ''}
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Typography
                              noWrap

                              className={classes.frequentlyUsedInfo}
                            >
                              <IconButton
                                className={classes.iconBtn}
                                aria-label={"Available Time"}
                                color="primary" style={{ padding: "0px" }}
                              >
                                <ClockIcon fontSize="small"
                                  style={{ margin: "0px 10px 0px 10px" }}
                                />
                              </IconButton>
                              {this.formatTimeInfo(hotline.time_infos)}
                            </Typography>
                          </Tooltip>
                        }
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

HotlineCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    hotlinecategories: state.hotlinecategories,
    hotlines: state.hotlines,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHotlineCategories: bindActionCreators(getHotlineCategories, dispatch),
    hotlinesByCategory: bindActionCreators(hotlinesByCategory, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(hotlinesStyle)(withSnackbar(HotlineCategories)));
