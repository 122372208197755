const hotlinesStyle = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: "8px",
    flex: "1px",
  },
  iconButton: {
    padding: "10px",
  },
  divider: {
    width: "1px",
    height: "28px",
    margin: "4px",
  },
  categoryTitle: {
    color: "#233750",
    fontFamily: "Calibri",
    fontWeight: "bolder",
    fontSize: "23px",
  },
  parentGrid: {
    maxWidth: "1200px",
    margin: "auto",
    paddingTop: "30",
    "@media (min-width: 1600px)": {
      width: "100%",
      maxWidth: "1200px",
      margin: "auto",
    },
  },

  hotlineViewRoot: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  fab: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  filterBox: {
    padding: "0px 30px 0px 30px",
  },
  mainGrid: {
    width: "100%",
    maxWidth: "1550px",
    margin: "auto",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
  },
  resultAndTime: {
    padding: "0px 30px 0px 30px",
  },
  openClose: {
    justifyContent: "end",
    marginBottom: "15px",
    "@media (max-width: 600px)": {
      justifyContent: "start",
    },
  },
  hotlineViewContainer: {
    padding: "5px",
    margin: "0px",
  },
  categoryBanner: {
    backgroundColor: "#233750",
    maxWidth: "100%",
    marginBottom: "30px",
  },
  categoryName: {
    color: "white",
    padding: "20px",
  },
  cardsGrid: {
    // padding: "3px",
    // rowGap: 15,
    margin: "0px",
  },

  header: {
    color: "white",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "14px",
  },
  cardHeader: {
    backgroundColor: "#233750",
    color: "white",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "px",
    paddingLeft: "13px",
    height: "430px",
  },
  parentCard: {
    maxWidth: "390px",
    marginBottom: "1em",
    height: "410px",
    position: "relative",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
  },
  tooltip: {
    fontSize: "0.875rem",
    fontWeight: "380",
  },
  iconBtn: {
    "@media only screen  and (minWidth: 1024px)  and (maxHeight: 1366px)  and (WebkitMinDevicePixelRatio: 1.5)": {
      padding: "0px !important",
    },
    "@media (maxWidth:568px) and (minWidth:320px)": {
      padding: "0px !important",
    },
  },
  iconHeight: { height: "35px", fontSize: "14" },
  iconWebsite: { height: "35px", fontSize: "13", fontStyle: "italic" },

  cardTitle: {
    color: "#d9552c",
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontSize: "16pt",
    textTransform: "capitalize",
  },

  cardDescription: {
    maxHeight: "100px",
    overflow: "auto",
    color: "#233750",
    marginTop: "2px",
  },

  cardIcons: {
    position: "absolute",
    paddingBottom: "10px",
    bottom: "0px",
    left: "0px",
  },
  VideoIcon: {
    width: "1em",
    height: "1em",
    color: "#233750",
    display: "inline-block",
    fontSize: "24px",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "user-select": "none",
    "flex-shrink": 0,
  },
  chatBotIconDiv: {
    width: "45px",
    height: "40px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  HotlineCategoryContainer: {
    margin: "5px",
    marginTop: "20",
    border: "0px outset",
    borderRadius: "10px",
    padding: "20px",
    boxShadow: "1px 1px 2px 2px lightgray"
  },
  frequentHotlineContainer: {
    padding: "15px 15px 0px 15px",
    color: "#d9552c",
    fontWeight: "bolder",
    textTransform: "none",
    fontFamily: "Calibri",
    fontSize: "22px"
  },
  frequentHotlineList: {
    margin: "0px",
    flexDirection: { xs: "row", sm: "column", md: "column" },
    maxHeight: "1100px",
    overflow: "auto"
  },
  frequentlyUsedTitle: {
    color: "#233750",
    fontFamily: "Nunito",
    fontWeight: "700",
    textTransform: "none",
    fontSize: "17px"
  },
  frequentlyUsedPhone: {
    color: "#233750", 
    fontFamily: "Nunito",
    fontWeight: "400", 
    fontSize: "17px"
  },

  frequentlyUsedInfo: {
    whiteSpace: "pre-line",
    color: "#3d97d3",
    fontFamily: "Nunito",
    fontSize: "17px",
    fontWeight: "900",
    textTransform: "none"
  },
  categorySubContainer : {
    margin: "0px", justifyContent: "center"
  },
  timeToolTip:{
    backgroundColor: "white",
    display: "inline-block",
    whiteSpace: "pre-line",
  },
  bannerText: {
    color: "white", fontFamily: "Calibri", padding: "20px"
  }
});

export default hotlinesStyle;
