/* step name each question is used to identify questions and to send
   data to backend
*/
export const stepNames = {
  AGE: "Age",
  GENDER: "Gender",
  PREGNANCY: "Pregnancy",
  VERTERAN: "VETERAN STATUS",
  INSURANCE: "Insurance",
  MENTAL_HEALTH: "MentalHealth",
  DRUG_USE: "DrugUse",
  DRUG_CONSUMPTION: "DrugConsumption",
  TRANSPORTATION: "TransportationAssistance"
};

export const ageAnswers = {
  YES: "Yes",
  NO: "No",
};

export const genderAnswers = {
  FEMALE: "Female",
  MALE: "Male",
  OTHER: "Other",
};

export const pregnancyAnswers = {
  YES: "Yes",
  NO: "No",
};

export const insuranceAnswers = {
  NO_INSURANCE: "No insurance",
  MEDICARE: "Medicare",
  MEDICAID: "Medicaid",
  PRIVATE_INSURANCE: "Private insurance",
  VA_INSURANCE: "VA insurance",
  COURT_ORDERED: "Court ordered",
  I_DONT_KNOW: "I don't know",
};

const mentalHealthAnswers = {
  ANXIETY: "Anxiety/Depression",
  // DEPRESSION: "Depression",
  BIPOLAR_DISORDER: "Bipolar Disorder",
  PERSONALITY_DISRODER: "Personality Disorders",
  OCD: "Obsessive Compulsive Disorder (OCD)",
  SCHIZOPHRENIA: "Schizophrenia",
  EATING_DISORDER: "Eating Disorders",
  PTSD: "PTSD",
  ADHD: "ADHD",
  NONE_OF_THESE: "None/I don't know",
  // OTHER: "Other/I'm not sure",
};

const drugUseAnswers = {
  OPIOIDS:
    "Heroin, Pain pills, Fentanyl and/or other Opioids",
  ALCOHOL: "Alcohol",
  // METH: "Meth",
  BENZOS: "Benzos, Valium, Xanax, Ativan",
  MARIJUANA: "Marijuana",
  COCAINE: "Cocaine and/or Meth",
  OTHER: "Other/I don't know",
};

// const drugConsumptionAnswers = {
//   SNORT: "Snort",
//   SMOKE: "Smoke",
//   NEEDLE: "Needle",
//   SWALLOW: "Swallow",
//   DONT_KNOW: "Don't know",
// };

export const transportationAssistance = {
  YES: "Yes",
  NO: "No",
}

export const veteranAnswers = {
  YES: "Yes",
  NO : "No",
  FAMILY : "Family member of a veteran/military service member",
  OTHER: "I don't know"
}
/*
  if answer is not compound, isCompound will be false and filters will only have filter key
  if answer is compound, isCompund will be true and there will be 'andOp' and 'orOp'
*/
export const getQuestions = () => {
  const questions = [
    {
      step: stepNames.AGE,
      questionText: "Are you 18 years old or older?",
      answerOptions: [
        {
          id: 0,
          answerText: ageAnswers.YES,
          filters: {
            isCompound: false,
            filter: null,
          },
          selected:false,
          question_id:null,
        },
        {
          id: 1,
          answerText: ageAnswers.NO,
          filters: {
            isCompound: true,
            //andOp: [43,44],
            andOp: [],
            orOp: [43, 44],
          },
          selected:false,
          question_id:null,
        },
      ],
      isMultiSelect: false,
      isAnswered: false,
    },
    {
      step: stepNames.GENDER,
      questionText: "What is your gender?",
      answerOptions: [
        {
          id: 0,
          answerText: genderAnswers.FEMALE,
          filters: {
            isCompound: true,
            //andOp: [38,145],
            andOp: [],
            orOp: [38, 145],
          },
          selected:false,
          question_id:null,
        },
        {
          id: 1,
          answerText: genderAnswers.MALE,
          filters: {
            isCompound: true,
            //andOp: [37,145],
            andOp: [],
            orOp: [37, 145],
          },
          selected:false,
          question_id:null,
        },
        {
          id: 2,
          answerText: genderAnswers.OTHER,
          filters: {
            isCompound: true,
            andOp: [54, 145],
            orOp: [],
          },
          selected:false,
          question_id:null,
        },
      ],
      isMultiSelect: false,
      isAnswered: false,
      skipPregnancyInquiry: false,
    },
    {
      step: stepNames.PREGNANCY,
      questionText: "Are you pregnant?",
      answerOptions: [
        {
          id: 0,
          answerText: pregnancyAnswers.YES,
          filters: {
            isCompound: false,
            filter: 39,
          },
          selected:false,
          question_id:null,
        },
        {
          id: 1,
          answerText: pregnancyAnswers.NO,
          filters: {
            isCompound: false,
            filter: null,
          },
          selected:false,
          question_id:null,
        },
      ],
      isMultiSelect: false,
      isAnswered: false,
      isPregAnswered: false,
      stopPregnancyInquiry: false,
    },
    {
      step: stepNames.VERTERAN,
      questionText: "Are you a veteran?",
      answerOptions : [
        {
          id: 0,
          answerText: veteranAnswers.YES,
          filters: {
            isCompound: false,
            filter: null,
          },
          selected:false,
          question_id:null,
        },
        {
          id: 1,
          answerText: veteranAnswers.NO,
          filters: {
            isCompound: false,
            filter: null,
          },
          selected:false,
          question_id:null,
        },
        {
          id: 2,
          answerText: veteranAnswers.FAMILY,
          filters: {
            isCompound: false,
            filter: null,
          },
          selected:false,
          question_id:null,
        },
        {
          id: 3,
          answerText: veteranAnswers.OTHER,
          filters: {
            isCompound: false,
            filter: null,
          },
          selected:false,
          question_id:null,
        },
      ],
      isMultiSelect: false,
      isAnswered: false,

    },
    {
      step: stepNames.INSURANCE,
      questionText: "What kind of insurance do you have?",
      answerOptions: [
        {
          id: 0,
          answerText: insuranceAnswers.NO_INSURANCE,
          filters: {
            isCompound: true,
            andOp: [232, 8],
            orOp: [],
          },
          selected:false,
          question_id:null,
        },
        {
          id: 1,
          answerText: insuranceAnswers.MEDICARE,
          filters: {
            isCompound: false,
            filter: 121,
          },
          selected:false,
          question_id:null,
        },
        {
          id: 2,
          answerText: insuranceAnswers.MEDICAID,
          filters: {
            isCompound: false,
            filter: 120,
          },
          selected:false,
          question_id:null,
        },
        {
          id: 3,
          answerText: insuranceAnswers.PRIVATE_INSURANCE,
          filters: {
            isCompound: false,
            filter: 122,
          },
          selected:false,
          question_id:null,
        },
        {
          id: 4,
          answerText: insuranceAnswers.VA_INSURANCE,
          filters: {
            isCompound: false,
            filter: 119,
          },
          selected:false,
          question_id:null,
        },
        {
          id: 5,
          answerText: insuranceAnswers.COURT_ORDERED,
          filters: {
            isCompound: false,
            filter: 124,
          },
          selected:false,
          question_id:null,
        },
        {
          id: 6,
          answerText: insuranceAnswers.I_DONT_KNOW,
          filters: {
            isCompound: false,
            filter: 60,
          },
          selected:false,
          question_id:null,
        },
      ],
      isMultiSelect: false,
      isAnswered: false,
    },
    {
      step: stepNames.MENTAL_HEALTH,
      questionText: "Do you have any of these concerns?",
      answerOptions: [
        {
          id: 0,
          answerText: mentalHealthAnswers.ANXIETY,
          filters: {
            isCompound: false,
            filter: 128,
          },
          selected:false,
          question_id:null,
        },
        // {
        //   id: 1,
        //   answerText: mentalHealthAnswers.DEPRESSION,
        //   filters: {
        //     isCompound: false,
        //     filter: 129,
        //   },
        // },
        {
          id: 1,
          answerText: mentalHealthAnswers.BIPOLAR_DISORDER,
          filters: {
            isCompound: false,
            filter: 130,
            selected:false,
            question_id:null,
          },
        },
        {
          id: 2,
          answerText: mentalHealthAnswers.PERSONALITY_DISRODER,
          filters: {
            isCompound: false,
            filter: 131,
            selected:false,
            question_id:null,
          },
        },
        {
          id: 3,
          answerText: mentalHealthAnswers.OCD,
          filters: {
            isCompound: false,
            filter: 132,
            selected:false,
            question_id:null,
          },
        },
        {
          id: 4,
          answerText: mentalHealthAnswers.SCHIZOPHRENIA,
          filters: {
            isCompound: false,
            filter: 133,
            selected:false,
            question_id:null,
          },
        },
        {
          id: 5,
          answerText: mentalHealthAnswers.EATING_DISORDER,
          filters: {
            isCompound: false,
            filter: 134,
            selected:false,
            question_id:null,
          },
        },
        {
          id: 6,
          answerText: mentalHealthAnswers.PTSD,
          filters: {
            isCompound: false,
            filter: 234,
            selected:false,
            question_id:null,
          },
        },
        {
          id: 7,
          answerText: mentalHealthAnswers.ADHD,
          filters: {
            isCompound: false,
            filter: 235,
            selected:false,
            question_id:null,
          },
        },
        {
          id: 8,
          answerText: mentalHealthAnswers.NONE_OF_THESE,
          filters: {
            isCompound: false,
            filter: [],
            selected:false,
            question_id:null,
          },
        },
        // {
        //   id: 9,
        //   answerText: mentalHealthAnswers.OTHER,
        //   filters: {
        //     isCompound: false,
        //     filter: 129,
        //   },
        // },
      ],
      isMultiSelect: true,
      isAnswered: false,
    },
    {
      step: stepNames.DRUG_USE,
      questionText:
        "Which drug do you misuse/abuse?",
      answerOptions: [
        {
          id: 0,
          answerText: drugUseAnswers.OPIOIDS,
          filters: {
            isCompound: true,
            andOp: ["Assessment", "Outpatient", "Intensive Outpatient Program"],
            orOp: ["Inpatient", "Residential"],
          },
          selected:false,
          question_id:null,
        },
        {
          id: 1,
          answerText: drugUseAnswers.ALCOHOL,
          filters: {
            isCompound: true,
            andOp: ["Assessment", "Outpatient", "Intensive Outpatient Program"],
            orOp: ["Inpatient", "Residential"],
          },
          selected:false,
          question_id:null,
        },
        // {
        //   id: 2,
        //   answerText: drugUseAnswers.METH,
        //   filters: {
        //     isCompound: true,
        //     andOp: ["Assessment", "Outpatient"],
        //     orOp: [],
        //   },
        // },
        {
          id: 2,
          answerText: drugUseAnswers.BENZOS,
          filters: {
            isCompound: true,
            andOp: ["Assessment", "Outpatient", "Intensive Outpatient Program"],
            orOp: ["Inpatient", "Residential"],
          },
          selected:false,
          question_id:null,
        },
        {
          id: 3,
          answerText: drugUseAnswers.MARIJUANA,
          filters: {
            isCompound: true,
            andOp: ["Assessment", "Outpatient"],
            orOp: [],
          },
          selected:false,
          question_id:null,
        },
        {
          id: 4,
          answerText: drugUseAnswers.COCAINE,
          filters: {
            isCompound: true,
            andOp: ["Assessment", "Outpatient"],
            orOp: [],
          },
          selected:false,
          question_id:null,
        },
        {
          id: 5,
          answerText: drugUseAnswers.OTHER,
          filters: {
            isCompound: true,
            andOp: ["Assessment", "Outpatient"],
            orOp: [],
          },
          selected:false,
          question_id:null,
        },
      ],
      isMultiSelect: true,
      isAnswered: false,
    },
    // {
    //   step: stepNames.DRUG_CONSUMPTION,
    //   questionText: "How do you use? (You can choose more than one)",
    //   answerOptions: [
    //     {
    //       id: 0,
    //       answerText: drugConsumptionAnswers.SNORT,
    //       filters: {
    //         isCompound: true,
    //         andOp: [
    //           "Assessment",
    //           "Partial Hospitalization",
    //           "Intensive Outpatient Program",
    //         ],
    //         orOp: [],
    //       },
    //     },
    //     {
    //       id: 1,
    //       answerText: drugConsumptionAnswers.SMOKE,
    //       filters: {
    //         isCompound: true,
    //         andOp: [
    //           "Assessment",
    //           "Partial Hospitalization",
    //           "Intensive Outpatient Program",
    //         ],
    //         orOp: [],
    //       },
    //     },
    //     {
    //       id: 2,
    //       answerText: drugConsumptionAnswers.NEEDLE,
    //       filters: {
    //         isCompound: true,
    //         andOp: ["Assessment", "Residential"],
    //         orOp: [],
    //       },
    //     },
    //     {
    //       id: 3,
    //       answerText: drugConsumptionAnswers.SWALLOW,
    //       filters: {
    //         isCompound: true,
    //         andOp: ["Assessment", "Outpatient"],
    //         orOp: [],
    //       },
    //     },
    //     {
    //       id: 4,
    //       answerText: drugConsumptionAnswers.DONT_KNOW,
    //       filters: {
    //         isCompound: true,
    //         andOp: ["Assessment", "Intensive Outpatient Program"],
    //         orOp: [],
    //       },
    //     },
    //   ],
    //   isMultiSelect: true,
    // },
    {
      step: stepNames.TRANSPORTATION,
      questionText: "Will you need a ride to your appointment?",
      answerOptions: [
        {
          id: 0,
          answerText: transportationAssistance.YES,
          filters: {
            isCompound: false,
            filter: 27,
          },
          selected:false,
          question_id:null,
        },
        {
          id: 1,
          answerText: transportationAssistance.NO,
          filters: {
            isCompound: false,
            filter: null,
          },
          selected:false,
          question_id:null,
        }
      ],
      isMultiSelect: false,
      isAnswered: false,
    }
  ];
  return questions;
};
