import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    Typography,
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import CardIcon from "components/Card/CardIcon.jsx";
import { LocationOnOutlined } from "@material-ui/icons";
import popupStyle from "assets/jss/material-dashboard-pro-react/components/popupStyle.jsx";

class ZipCodePopUp extends React.Component {

    handlePopUp = () => {
        const { openZipCode, updateProps } = this.props;
        if (openZipCode) {
            updateProps("openZipCode", false);
        } else {
            updateProps("openZipCode", true);
        }
    };

    handleChange = (event) => {
        const { updateProps } = this.props;
        updateProps(event.target.id, event.target.value);
        // this.props.setState({ [event.target.id]: event.target.value });
    };

    render() {
        let { classes, openZipCode, zipCode, callbackFun } = this.props;
        return (
            <div className={classes.modalContainer}>
                <Dialog
                    open={openZipCode}
                    onClose={() => this.handlePopUp()}
                    fullWidth
                    maxWidth="xs"
                    className={classes.dialogContainer}
                    BackdropProps={{ style: { backgroundColor: "#233750", opacity: 0.8 } }}
                >
                    <DialogTitle disableTypography className={classes.dialogTitlee}>
                        <Grid container>
                            <Grid sx={2} xs={2} md={2} lg={2}>
                                <CardIcon color="rose" className={classes.dialogTitleIcon}>
                                    <LocationOnOutlined style={{ fontSize: "28px" }} />
                                </CardIcon>
                            </Grid>
                            <Grid sx={8} xs={8} md={8} lg={8} className={classes.title}>
                                <Typography
                                    variant="h5"
                                    className={classes.popUpTitle}
                                    align="left"
                                >
                                    Zip Code
                                </Typography>
                            </Grid>
                            <Grid sx={2} xs={2} md={2} lg={2}>
                                <Typography component="div" align="right">
                                    <CloseIcon
                                        onClick={() => this.handlePopUp()}
                                        className={classes.popUpCloseIcon}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText id="alert-dialog-description">
                            <FormControl className={classes.modalContainer} variant="outlined">
                                <Grid container>
                                    <span
                                        className={classes.popUpFontStyle}
                                        style={{ margin: "0px" }}
                                    >
                                    </span>
                                    <Grid sx={10} xs={10} md={10} lg={10}>
                                        <input
                                            id="zipCode"
                                            className={classes.popUpInput}
                                            value={zipCode}
                                            placeholder="Enter Zip Code"
                                            onChange={(event) => {
                                                this.handleChange(event);
                                            }}
                                        />
                                    </Grid>
                                    <Grid sx={2} xs={2} md={2} lg={2}>
                                        <Button
                                            onClick={() => {
                                                this.handlePopUp();
                                                callbackFun();
                                            }}
                                            className={classes.popupBtn + " " + classes.agreeBtn}
                                        >
                                            <Typography className={classes.popupTypography}>
                                                <SearchIcon />
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                                {/* <Grid sx={12} xs={12} md={12} lg={12}>
                                    {innerDiv}
                                </Grid> */}
                            </FormControl>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

ZipCodePopUp.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(popupStyle)(ZipCodePopUp);
