import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  insertFeature,
  updateFeature,
  deleteFeature
} from "../../actions/action_feature";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Typography from "@material-ui/core/Typography";

import { withSnackbar } from "notistack";

// icons
import FeatureIcon from "@material-ui/icons/LocalActivity";

import Switch from "@material-ui/core//Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// core components

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import api from "../../utils/api";
import { Link } from "react-router-dom";
import TextField from '@material-ui/core/TextField';

const intl = window.intl;
class FeatureEditPage extends React.Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    this.state = {
      featureID: props.match.params.featureID,
      name: "",
      description: "",
      category: params.get("category"),
      hideCategory: params.has("category"),
      categories: [],
      active: true,
      order: 100,
      featureNotFound: false,
      error: {},
      mandatory: { name: true, description: true,order: true },
      modified: false
    };
  }
  getFeature = async id => {
    const feature = await api.get(`/features/${id}`);
    if (feature && Object.keys(feature).length > 0) {
      this.setState({ ...feature, category:(feature.category||{}).id, featureNotFound: false });
    } else {
      this.setState({ featureNotFound: true });
    }
  };
  getCategories = async id => {
    const categories = await api.get(`/modelSearch/categories`);
    this.setState({ categories });
  };

  componentDidMount() {
    const { featureID } = this.state;
    featureID && this.getFeature(featureID);
    this.getCategories();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data = {} } = nextProps.feature || {};

    if (data.name !== this.state.name) {
      const {
        name,
        description,
        allow_attachments,
        allow_contact_persons
      } = data;
      this.setState({
        name,
        description,
        allow_attachments,
        allow_contact_persons
      });
    }
  }
  validateField = (name, value) => {
    const { error, mandatory } = this.state;
    if (mandatory[name]) {
      error[name] = value === undefined || value.length === 0;
    }
    this.setState({ error });
    return !error[name];
  };

  handleSwitchChange = name => event => {
    this.setState({ [name]: event.target.checked, modified: true });
  };
  handleTextChange = name => event => {
    this.validateField(name, event.target.value);
    this.setState({ [name]: event.target.value, modified: true });
  };
  onSubmit = () => {
    const {
      featureID,
      name,
      description,
      category,
      active,
      order,
      modified,
      error = {}
    } = this.state;
    let valid = true;
    if (!modified) {
      this.props.enqueueSnackbar("No information is updated", {
        variant: "info"
      });
      return false;
    } else {
      valid = Object.keys(error).every(name => {
        return !error[name];
      });
    }
    if (!valid) {
      this.props.enqueueSnackbar("Please provide all required details", {
        variant: "error"
      });
      return false;
    }
    let data = {
      name,
      description,
      category,
      active,
      order
    };
    if (featureID) {
      data.id = featureID;
      this.props.updateFeature(data, () => {
        this.props.enqueueSnackbar("Feature updated successfully", {
          variant: "success"
        });
        this.props.history.goBack();
      });
    } else {
      this.props.insertFeature(data, () => {
        this.props.enqueueSnackbar("Feature saved successfully", {
          variant: "success"
        });
        this.props.history.goBack();
      });
    }
  };
  render() {
    const { classes } = this.props;
    const { featureID, featureNotFound, error = {}, modified, categories=[], hideCategory=false } = this.state;


    return (
      <React.Fragment>
        {featureID && featureNotFound ? (
          <Card>
            <CardBody>
              <Typography variant="subtitle1" gutterBottom align="center">
                {intl.formatMessage({
                  id: "view.featureEdit.featureNotFound"
                })}{" "}
                <Link to="/admin/edit-feature">
                  {intl.formatMessage({
                    id: "createNew"
                  })}
                </Link>
              </Typography>
            </CardBody>
          </Card>
        ) : (
          <GridContainer
            container
            spacing={24}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <FeatureIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle} color="primary">
                    {(featureID ? "Update" : "Create") + " Feature"}
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText="Name"
                        id="name"
                        error={error["name"]}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.name,
                          onChange: this.handleTextChange("name")
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText="Description"
                        id="description"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          multiline: true,
                          rows: 5,
                          value: this.state.description,
                          onChange: this.handleTextChange("description")
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText="Order"
                        id="order"
                        error={error["order"]}
                        formControlProps={{
                          type: "number",
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          value: this.state.order,
                          onChange: this.handleTextChange("order")
                        }}
                      />
                    </GridItem>
                    {!hideCategory && <GridItem xs={12}>
                      <TextField
                        id="category"
                        select
                        label="Category"
                        className={classes.textField}
                        value={this.state.category}
                        onChange={this.handleTextChange('category')}
                        SelectProps={{
                          native: true,
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                      >
                        <option value=""></option>
                        {categories.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    </GridItem>}
                    <GridItem xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.active}
                            value={this.state.active}
                            onChange={this.handleSwitchChange("active")}
                            color="primary"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="Active"
                      />
                    </GridItem>
                  </GridContainer>
                  <Button
                    color="primary"
                    disabled={!modified}
                    className={classes.updateProfileButton}
                    onClick={this.onSubmit}
                  >
                    {`${featureID ? "Update" : "Save"} Feature`}
                  </Button>
                  <Clearfix />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </React.Fragment>
    );
  }
}
FeatureEditPage.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    feature: state.feature
  };
};
const mapDispatchToProps = dispatch => {
  return {
    insertFeature: bindActionCreators(insertFeature, dispatch),
    updateFeature: bindActionCreators(updateFeature, dispatch),
    deleteFeature: bindActionCreators(deleteFeature, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(userProfileStyles)(withSnackbar(FeatureEditPage))));
