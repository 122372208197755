import api from '../utils/api';
import { GET_QUICK_SEARCH_LIST, GET_QUICK_SEARCH_MAP } from '../utils/types';


export const getQuickSearchListData = (data, ...extra) => ({
  type: GET_QUICK_SEARCH_LIST,
  payload: api.post(`/search/quicksearchlistdata`, data, ...extra)
});

export const getQuickSearchMapData = (data, ...extra) => ({
  type: GET_QUICK_SEARCH_MAP,
  payload: api.post(`/search/quicksearchmapdata`, data, ...extra)
});