import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Switch from "@material-ui/core//Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import api from "../../utils/api";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

export class UsersEditPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLoading: true,
      id: "",
      first_name: "",
      last_name: "",
      email: "",
      lang: "en",
      roles: "agency_admin",
      active: false,
      phone_number: "",
      user: {},
      notFound: true
    };
  }
  componentDidMount() {
    const { match = {} } = this.props;
    const { params = {} } = match;
    const { userID = "" } = params;
    this.setState({ id: userID });
    if (userID) {
      this.getUser(userID);
    }
  }
  getUser = id => {
    api.get(
      `/users/${id}`,
      (user = {}) => {
        const {
          id,
          first_name,
          last_name,
          email,
          phone_number,
          roles,
          active
        } = user;
        this.setState({
          id,
          first_name,
          last_name,
          email,
          phone_number,
          roles,
          active,
          user,
          notFound: !id,
          userLoading: false
        });
      },
      error => {
        this.setState({ user: {}, notFound: true, userLoading: false });
      }
    );
  };
  validatePhoneNumber = (value = "") => {
    return value.replace(/\D/g, "");
  };
  validate = (target, value = "") => {
    const name = target.id;
    if (target.type !== "checkbox") {
      if (target.required && !value) {
        this.setState({ [name + "State"]: "error" });
      } else if (target.minLength && value.length < target.minLength) {
        this.setState({ [name + "State"]: "error" });
      } else {
        this.setState({ [name + "State"]: "success" });
      }
    }
  };
  handleChange = event => {
    const target = event.target;
    let value = "";
    const name = target.id || target.name;
    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      value = target.value;
      if (target.type === "tel") {
        value = this.validatePhoneNumber(value);
      }
      this.validate(event.target, value);
    }
    this.setState({ [name]: value });
  };

  getAvailableRoles = () => {
    const { user = {} } = this.props.auth;
    const roles = user.roles || "agency_admin";
    let availableRoles = [];
    availableRoles.push({ value: "admin", label: "Administrator" });
    availableRoles.push({ value: "agency_admin", label: "Agency Administrator" });
    availableRoles.push({ value: "service_admin", label: "Service Administrator" });
    return availableRoles;
  };

  saveProfile = () => {
    const {
      id,
      first_name,
      last_name,
      email,
      phone_number,
      roles,
      active
    } = this.state;
    const user = {
      id,
      first_name,
      last_name,
      email,
      phone_number,
      roles,
      active
    };
    if (id) {
      api.put(
        `/users/${id}`,
        user,
        data => {
          //this.props.history.replace(`/admin/edit-user/${data.id}`);
          this.props.history.replace(`/admin/users`);
          this.props.enqueueSnackbar("Profile updated successfully", {
            variant: "success"
          });
        },
        () => {
          this.props.enqueueSnackbar("Profile update failed", {
            variant: "error"
          });
        }
      );
    } else {
      api.post(
        `/users`,
        user,
        data => {
          this.props.history.replace(`/admin/users`);
          this.props.enqueueSnackbar("Profile created successfully", {
            variant: "success"
          });
        },
        () => {
          this.props.enqueueSnackbar("Profile create failed", {
            variant: "error"
          });
        }
      );
    }
  };
  resetPassword = () => {
    api.post(
      `/auth/resetPassword`,
      { email: this.state.email },
      ({ message }) => {
        this.props.enqueueSnackbar(message, {
          variant: "success"
        });
      },
      data => {
        this.props.enqueueSnackbar(data.error, {
          variant: "error"
        });
      }
    );
  };
  render() {
    const { classes, theme } = this.props;
    const { id } = this.state;
    const {
      first_name = "",
      last_name = "",
      phone_number = "",
      email = "",
      roles = ""
    } = this.state;

    const { user = {} } = this.props.auth;
    const logginRoles = user.roles || "service_admin";
    const loginID = user.id || "service_admin";
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <PermIdentity />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {id ? "Edit" : "Create"} User
                </h4>
              </CardHeader>
              <CardBody style={{ margin: theme.spacing.unit }}>
                <GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={
                          (this.state.first_nameState || "") === "success"
                        }
                        error={(this.state.first_nameState || "") === "error"}
                        labelText="First Name"
                        id="first_name"
                        formControlProps={{
                          fullWidth: true,
                          required: true
                        }}
                        inputProps={{
                          onChange: this.handleChange,
                          value: first_name,
                          required: true,
                          inputProps: {
                            minLength: 2
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={
                          (this.state.last_nameState || "") === "success"
                        }
                        error={(this.state.last_nameState || "") === "error"}
                        labelText="Last Name"
                        id="last_name"
                        formControlProps={{
                          fullWidth: true,
                          required: true
                        }}
                        inputProps={{
                          onChange: this.handleChange,
                          value: last_name,
                          required: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        success={(this.state.emailState || "") === "success"}
                        error={(this.state.emailState || "") === "error"}
                        labelText="Email address"
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                          required: true
                        }}
                        inputProps={{
                          onChange: this.handleChange,
                          required: true,
                          minLength: 5,
                          type: "email",
                          value: email
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        success={
                          (this.state.phone_numberState || "") === "success"
                        }
                        error={(this.state.phone_numberState || "") === "error"}
                        labelText="Phone Number"
                        id="phone_number"
                        formControlProps={{
                          fullWidth: true,
                          required: true
                        }}
                        inputProps={{
                          onChange: this.handleChange,
                          required: true,
                          inputProps: {
                            minLength: 10
                          },
                          type: "tel",
                          value: phone_number
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="roles">Role</InputLabel>
                        <Select
                          value={roles}
                          onChange={this.handleChange}
                          inputProps={{
                            name: "roles",
                            id: "roles",
                            required: true
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {this.getAvailableRoles().map(role => (
                            <MenuItem key={role.value} value={role.value}>
                              {role.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.active}
                            value={this.state.active}
                            onChange={this.handleChange}
                            color="primary"
                            name="active"
                            id="active"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="Active"
                      />
                    </GridItem>
                  </GridContainer>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    {(logginRoles === "admin" && loginID !== id) && <Button color="rose" onClick={this.resetPassword}>
                      Reset Password
                    </Button>}
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Button
                      color="primary"
                      onClick={this.saveProfile}
                      className={classes.updateProfileButton}
                    >
                      {id ? "Update" : "Create"} Profile
                    </Button>
                  </GridItem>
                </GridContainer>
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};
export default connect(mapStateToProps)(
  withStyles(userProfileStyles, { withTheme: true })(
    withSnackbar(UsersEditPage)
  )
);
