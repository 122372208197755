import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import {connect} from 'react-redux';
import {login, getLoggedInUser} from '../../actions/action_auth';
import {bindActionCreators} from 'redux';

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }
  componentDidMount() {
    if(this.props.auth.loggedIn && localStorage.getItem('drughelp-token')) {
      this.props.history.push("/admin/dashboard");
    }
    this.props.getLoggedInUser();
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUpdate(nextProps){
    if(!this.props.auth.loggedIn && nextProps.auth.loggedIn) {
      this.props.history.push("/admin/dashboard");
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  login = () => {
    const {email,password} = this.state;
    this.props.login({email,password});
  }

  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container} style={{paddingTop : "0px"}}>
        <GridContainer justify="center"  style={{margin: 'auto'}}>
          <GridItem xs={12} mx={4} my={2}>
          <div>
            <h2 style={{ color: "#233750", textAlign: "justify" }}> <strong>Drughelp.care Privacy Policy</strong></h2>
            
            <h4 style={{ color: "#233750", textAlign: "justify" }}>
              <strong>This Privacy Policy is effective for all users. </strong>
            </h4>
            <p style={{ color: "#233750", textAlign: "justify" }}>
              Thank you for visiting <i>drughelp.care</i>, a website designed to enhance access to substance use treatment. Consistent with the provisions of the Internet Security and Privacy Act, the Freedom of Information Law, and the Personal Privacy Protection Law, this policy describes the <i>drughelp.care</i> privacy practices regarding information collected from users of this website. This policy describes what information is collected and how that information is used.
            </p>
            <p style={{ color: "#233750", textAlign: "justify" }}>
           The terms “we”, “us”, and “<i>drughelp.care</i>” refer to <i>drughelp.care</i>, a website being developed by researchers at Cleveland State University in Cleveland, Ohio. When you use the site, you consent to our collection, use, and disclosure of information about you as described in this Privacy Policy.
            </p>

            <h4 style={{ color: "#233750", textAlign: "justify" }}>
              <strong>Information Collected Automatically When You Visit This Website and How We Use It</strong>
            </h4>
            <p style={{ color: "#233750", textAlign: "justify" }}>
              We may collect and store information about you in connection with your use of the site. We will use your computer’s IP address to distinguish among you and other users. We will collect information about the search filters, sorting options, and search terms that you use. We will use this information to personalize what you see on the website based on your history of use, allowing you to access the information that you need more quickly.
            </p>

            <h4 style={{ color: "#233750", textAlign: "justify" }}>
              <strong>Access to Your Location</strong>
            </h4>
            <p style={{ color: "#233750", textAlign: "justify" }}>
              You have the option to opt into location services to allow us to better assist you. We do not automatically collect information about your location. You must allow us to access your location to utilize certain features of this website, including finding services near you. However, the rest of the information on this website is fully functional without allowing us to access your location.
            </p>

            <h4 style={{ color: "#233750", textAlign: "justify" }}>
              <strong>Information Collected When You Create an Agency Account</strong>
            </h4>
            <p style={{ color: "#233750", textAlign: "justify" }}>
              If you create a <i>drughelp.care</i> Agency Account, we may store and use the information you provide during that process, such as your full name, email address, agency name, agency address, and phone number. We may publicly display your agency name, as well as any other content you submit through the registration process, as part of your agency account profile. Your email address and phone number can be used to contact you. If you believe that someone has created an unauthorized account depicting your agency, you can request its removal by contacting us at <a href="mailto:admin@drughelp.care">admin@drughelp.care</a>.
            </p>

            <h4 style={{ color: "#233750", textAlign: "justify" }}>
              <strong>Cookies</strong>
            </h4>
            <p style={{ color: "#233750", textAlign: "justify" }}>
            Cookies are used to distinguish among users of this website. This is a standard practice among Internet websites. Cookies may have unique identifiers, and reside on your computer, mobile device, in emails we send to you, on our web pages, or other places. This website uses cookies to store information about your use of the website, including your history of search filters, sorting options, or terms that you use to search our website.
            </p>

            <h4 style={{ color: "#233750", textAlign: "justify" }}>
              <strong>Information Collected When You Email Us</strong>
            </h4>
            <p style={{ color: "#233750", textAlign: "justify" }}>
            During your visit to this website you may send an e-mail to <i>drughelp.care</i>. Your e-mail address and the contents of your message will be collected. The information collected includes text, audio, video, and graphic information formats included in the message. Your e-mail address and the information included in your message will be used to respond to you, to address issues you identify, and to improve this website.
            </p>

            <h4 style={{ color: "#233750", textAlign: "justify" }}>
              <strong>Security</strong>
            </h4>
            <p style={{ color: "#233750", textAlign: "justify" }}>
            We use various defenses to protect the personal information submitted to us, both during transmission and when we receive it. However, no method of transmission over the Internet or via mobile device is 100% secure. Therefore, we will strive to protect your personal information, but we cannot guarantee its absolute security.
            </p>

            <h4 style={{ color: "#233750", textAlign: "justify" }}>
              <strong>Contact</strong>
            </h4>
            <p style={{ color: "#233750", textAlign: "justify" }}>
              Please <a href="mailto:admin@drughelp.care">contact</a> us if you have any questions/concerns on our Privacy Policy.
            </p>


          </div>
            </GridItem>
        </GridContainer>
      </div>
    );
  }
}

PrivacyPolicy.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    ...state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    login: bindActionCreators(login, dispatch),
    getLoggedInUser: bindActionCreators(getLoggedInUser, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(PrivacyPolicy));
