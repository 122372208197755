import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CategoryIcon from "@material-ui/icons/Category";
import Typography from "@material-ui/core/Typography";
import { withSnackbar } from "notistack";
import Paper from "@material-ui/core/Paper";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import api from "../../utils/api";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { injectIntl } from "react-intl";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";

import FeaturesIcon from "@material-ui/icons/LocalActivity";
import ServiceTypeIcon from "@material-ui/icons/Ballot";
import TimeInfoIcon from "@material-ui/icons/Schedule";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Create";
import ViewIcon from "@material-ui/icons/Visibility";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";

import IconButton from "@material-ui/core/IconButton";
import { deleteFeature } from "../../actions/action_feature";
import { deleteTimeInfo } from "../../actions/action_timeInfo";
import { deleteServiceType } from "../../actions/action_serviceType";
import PopupView from "./PopupView";

const intl = window.intl;
class CategoryViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryID: props.match.params.categoryID,
      activeTab: 0,
      category: {},
      openPopup: false,
      deleteId: "",
      deleteName: "",
      type: ""
    };
  }
  getCategory = async id => {
    const category = await api.get(`/categories/${id}`);
    if (category && Object.keys(category).length > 0) {
      this.setState({ category, categoryNotFound: false });
    } else {
      this.setState({ categoryNotFound: true });
    }
  };
  componentDidMount() {
    const { categoryID } = this.state;
    categoryID && this.getCategory(categoryID);
  }
  handleTabChange = (event, value) => {
    this.setState({ activeTab: value });
  };

  getConfirmPopUp = (message) => {
    return (
      <PopupView
        openPopup={this.state.openPopup}
        callbackFun={this.callbackFun}
        updateProps={this.updateProps}
        message={message}
        icon={<CategoryIcon />}
      />
    );
  };

  updateProps = (name, value) => {
    this.setState({ [name]: value });
  }

  callbackFun = () => {
    const { type, deleteId, deleteName } = this.state;
    switch (type) {
      case "feature":
        this.deleteFeature(deleteId, deleteName);
        break;
      case "time_info":
        this.deleteTimeInfo(deleteId, deleteName);
        break;
      case "service":
        this.deleteServiceType(deleteId, deleteName);
        break;
      default:
        break;
    }
  }

  deleteFeature = (id, name) => {
    // if (!window.confirm("Are you sure you want to delete '" + name + "'")) {
    //   return false;
    // }
    this.props.deleteFeature(
      id,
      () => {
        this.props.enqueueSnackbar("Feature deleted successfully", {
          variant: "success"
        });
        const { categoryID } = this.state;
        categoryID && this.getCategory(categoryID);
      },
      () => {
        this.props.enqueueSnackbar("Unable to delete '" + name + "'", {
          variant: "error"
        });
        const { categoryID } = this.state;
        categoryID && this.getCategory(categoryID);
      }
    );
  };

  deleteTimeInfo = (id, name) => {
    // if (!window.confirm("Are you sure you want to delete '" + name + "'")) {
    //   return false;
    // }
    this.props.deleteTimeInfo(
      id,
      () => {
        this.props.enqueueSnackbar("Time Information deleted successfully", {
          variant: "success"
        });
        const { categoryID } = this.state;
        categoryID && this.getCategory(categoryID);
      },
      () => {
        this.props.enqueueSnackbar("Unable to delete '" + name + "'", {
          variant: "error"
        });
        const { categoryID } = this.state;
        categoryID && this.getCategory(categoryID);
      }
    );
  };

  deleteServiceType = (id, name) => {
    // if (!window.confirm("Are you sure you want to delete '" + name + "'")) {
    //   return false;
    // }
    this.props.deleteServiceType(
      id,
      () => {
        this.props.enqueueSnackbar("Service type deleted successfully", {
          variant: "success"
        });
        const { categoryID } = this.state;
        categoryID && this.getCategory(categoryID);
      },
      () => {
        this.props.enqueueSnackbar("Unable to delete '" + name + "'", {
          variant: "error"
        });
        const { categoryID } = this.state;
        categoryID && this.getCategory(categoryID);
      }
    );
  };

  callDeletePopup = (id, name, type) => {
    this.setState({
      deleteName: name,
      deleteId: id,
      openPopup: true,
      type: type
    });
  }

  render() {
    const { classes } = this.props;
    const { categoryNotFound, category = {}, activeTab = 0, deleteName, openPopup } = this.state;

    return (
      <React.Fragment>
        {categoryNotFound ? (
          <Card>
            <CardBody>
              <Typography variant="subtitle1" gutterBottom align="center">
                {intl.formatMessage({
                  id: "view.categoryEdit.categoryNotFound"
                })}
              </Typography>
            </CardBody>
          </Card>
        ) : (
          <GridContainer
            spacing={24}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Helmet>
              <meta charSet="utf-8" />
              <title>
                {intl.formatMessage({
                  id: "category"
                }) +
                  " - " +
                  category.name +
                  " | " +
                  intl.formatMessage({
                    id: "title"
                  })}
              </title>
              <link rel="canonical" href={window.location.href} />
            </Helmet>
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <CategoryIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle} color="primary">
                    Category
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Typography variant="button">Name</Typography>
                      <Typography variant="body2" gutterBottom>
                        {category.name}
                      </Typography>
                    </GridItem>
                    <GridItem xs={12}>
                      <Typography variant="button">Description</Typography>
                      <Typography variant="body2" gutterBottom>
                        {category.description}
                      </Typography>
                    </GridItem>
                    <GridItem xs={12}>
                      <Typography variant="button">
                        Attachments allowed?
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {category.allow_attachments ? "Yes" : "No"}
                      </Typography>
                    </GridItem>
                    <GridItem xs={12}>
                      <Typography variant="button">
                        Allow contact persons
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {category.allow_contact_persons ? "Yes" : "No"}
                      </Typography>
                    </GridItem>
                    <GridItem xs={12}>
                      <Typography variant="button">Active</Typography>
                      <Typography variant="body2" gutterBottom>
                        {category.active ? "Yes" : "No"}
                      </Typography>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={10}>
              <AppBar position="static">
                <Tabs value={activeTab} onChange={this.handleTabChange}>
                  <Tab
                    label={
                      "Features (" + (category.features || []).length + ")"
                    }
                    icon={<FeaturesIcon />}
                  />
                  <Tab
                    label={
                      "Time Information (" +
                      (category.time_infos || []).length +
                      ")"
                    }
                    icon={<TimeInfoIcon />}
                  />
                  <Tab
                    label={
                      "Service Types (" +
                      (category.service_types || []).length +
                      ")"
                    }
                    icon={<ServiceTypeIcon />}
                  />
                </Tabs>
              </AppBar>
              <Paper component="div" style={{ padding: 8 * 3 }}>
                {activeTab === 0 && (
                  <Slide in direction="left">
                    <div>
                      <div>
                        <Link
                          to={`/admin/edit-feature?category=${category.id}`}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.marginRight}
                          >
                            <AddIcon className={classes.icons} /> Add Feature
                          </Button>
                        </Link>
                      </div>
                      <div>
                        <Table
                          className={classes.table + " " + classes.tableLayoutProps}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell smup="true">Description</TableCell>
                              <TableCell smup="true">Order</TableCell>
                              <TableCell align="center">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(category.features || []).length > 0 ? (
                              (category.features || []).map(feature => {
                                return (
                                  <TableRow key={feature.id}>
                                    <TableCell component="td" scope="row">
                                      {feature.name}
                                    </TableCell>
                                    <TableCell component="td" scope="row">
                                      {feature.description}
                                    </TableCell>
                                    <TableCell component="td" scope="row">
                                      {feature.order}
                                    </TableCell>
                                    <TableCell
                                      component="td"
                                      scope="row"
                                      align="center"
                                    >
                                      <Tooltip
                                        title="Edit"
                                        placement="bottom"
                                        classes={{ tooltip: classes.tooltip }}
                                      >
                                        <Link
                                          to={`/admin/edit-feature/${feature.id
                                            }`}
                                        >
                                          <IconButton
                                            aria-label="Edit"
                                            className={classes.margin}
                                          >
                                            <EditIcon
                                              color="secondary"
                                              className={classes.margin}
                                            />
                                          </IconButton>
                                        </Link>
                                      </Tooltip>
                                      <Tooltip
                                        title="Delete"
                                        placement="bottom"
                                        classes={{ tooltip: classes.tooltip }}
                                      >
                                        <IconButton
                                          aria-label="Delete"
                                          className={classes.margin}
                                          onClick={() =>
                                            this.callDeletePopup(
                                              feature.id,
                                              feature.name,
                                              "feature"
                                            )
                                          }
                                        >
                                          <DeleteIcon
                                            color="secondary"
                                            className={classes.margin}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        title="View"
                                        placement="bottom"
                                        classes={{ tooltip: classes.tooltip }}
                                      >
                                        <Link
                                          to={`/admin/features/${feature.id}`}
                                        >
                                          <IconButton
                                            aria-label="View"
                                            className={classes.margin}
                                          >
                                            <ViewIcon
                                              color="secondary"
                                              className={classes.margin}
                                            />
                                          </IconButton>
                                        </Link>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            ) : (
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  align="center"
                                  colSpan="3"
                                >
                                  No Time information available
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </Slide>
                )}
                {activeTab === 1 && (
                  <Slide in direction="left">
                    <div>
                      <div>
                        <Link
                          to={`/admin/edit-time-info?category=${category.id}`}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.marginRight}
                          >
                            <AddIcon className={classes.icons} /> Add Time
                            Information
                          </Button>
                        </Link>
                      </div>
                      <GridItem xs={12}>
                        <Table className={classes.table + " " + classes.tableLayoutProps}>
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.tableCell}>
                                Name
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                Description
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="center"
                              >
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(category.time_infos || []).length > 0 ? (
                              (category.time_infos || []).map(time_info => {
                                return (
                                  <TableRow key={time_info.id}>
                                    <TableCell className={classes.tableCell}>
                                      {time_info.name}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {time_info.description}
                                    </TableCell>
                                    <TableCell
                                      className={classes.tableCell}
                                      align="center"
                                    >
                                      <Tooltip
                                        title="Edit"
                                        placement="bottom"
                                        classes={{ tooltip: classes.tooltip }}
                                      >
                                        <Link
                                          to={`/admin/edit-time-info/${time_info.id
                                            }`}
                                        >
                                          <IconButton
                                            aria-label="Edit"
                                            className={classes.margin}
                                          >
                                            <EditIcon
                                              color="secondary"
                                              className={classes.margin}
                                            />
                                          </IconButton>
                                        </Link>
                                      </Tooltip>
                                      <Tooltip
                                        title="Delete"
                                        placement="bottom"
                                        classes={{ tooltip: classes.tooltip }}
                                        onClick={() =>
                                          this.callDeletePopup(
                                            time_info.id,
                                            time_info.name,
                                            "time_info"
                                          )
                                        }
                                      >
                                        <IconButton
                                          aria-label="Delete"
                                          className={classes.margin}
                                        >
                                          <DeleteIcon
                                            color="secondary"
                                            className={classes.margin}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            ) : (
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  align="center"
                                  colSpan="3"
                                >
                                  No Time information available
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>

                      </GridItem>
                    </div>
                  </Slide>
                )}
                {activeTab === 2 && (
                  <Slide in direction="left">
                    <div>
                      <div>
                        <Link
                          to={`/admin/edit-service-type?category=${category.id}`}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.marginRight}
                          >
                            <AddIcon className={classes.icons} /> Add Service Type
                          </Button>
                        </Link>
                      </div>
                      <GridItem xs={12}>
                        <Table
                          className={classes.table + " " + classes.tableLayoutProps}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.tableCell}>
                                Name
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                Description
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="center"
                              >
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(category.service_types || []).length > 0 ? (
                              (category.service_types || []).map(serviceType => {
                                return (
                                  <TableRow key={serviceType.id}>
                                    <TableCell className={classes.tableCell}>
                                      {serviceType.name}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {serviceType.description}
                                    </TableCell>
                                    <TableCell
                                      className={classes.tableCell}
                                      align="center"
                                    >
                                      <Tooltip
                                        title="Edit"
                                        placement="bottom"
                                        classes={{ tooltip: classes.tooltip }}
                                      >
                                        <Link
                                          to={`/admin/edit-service-type/${serviceType.id
                                            }`}
                                        >
                                          <IconButton
                                            aria-label="Edit"
                                            className={classes.margin}
                                          >
                                            <EditIcon
                                              color="secondary"
                                              className={classes.margin}
                                            />
                                          </IconButton>
                                        </Link>
                                      </Tooltip>
                                      <Tooltip
                                        title="Delete"
                                        placement="bottom"
                                        classes={{ tooltip: classes.tooltip }}
                                        onClick={() =>
                                          this.callDeletePopup(
                                            serviceType.id,
                                            serviceType.name,
                                            "service"
                                          )
                                        }
                                      >
                                        <IconButton
                                          aria-label="Delete"
                                          className={classes.margin}
                                        >
                                          <DeleteIcon
                                            color="secondary"
                                            className={classes.margin}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            ) : (
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  align="center"
                                  colSpan="3"
                                >
                                  No service types available
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </GridItem>
                    </div>
                  </Slide>
                )}
              </Paper>
            </GridItem>
            <GridItem>
              {openPopup ? this.getConfirmPopUp("Are you sure you want to delete '" + deleteName + "' ?") : null}
            </GridItem>
          </GridContainer>
        )}
      </React.Fragment>
    );
  }
}
CategoryViewPage.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    category: state.category
  };
};
const mapDispatchToProps = dispatch => {
  return {
    deleteFeature: bindActionCreators(deleteFeature, dispatch),
    deleteTimeInfo: bindActionCreators(deleteTimeInfo, dispatch),
    deleteServiceType: bindActionCreators(deleteServiceType, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(userProfileStyles)(withSnackbar(CategoryViewPage))));
