import api from "../utils/api";
import {
  GET_CATEGORY,
  INSERT_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY
} from "../utils/types";

export const getCategory = (id, ...extra) => ({
  type: GET_CATEGORY,
  payload: api.get(`/categories/${id}`, null, ...extra)
});
export const insertCategory = (data, ...extra) => ({
  type: INSERT_CATEGORY,
  payload: api.post(`/categories`, data, ...extra)
});
export const updateCategory = (data, ...extra) => ({
  type: UPDATE_CATEGORY,
  payload: api.put(`/categories/${data.id}`, data, ...extra)
});
export const deleteCategory = (id, ...extra) => ({
  type: DELETE_CATEGORY,
  payload: api.delete(`/categories/${id}`, null, ...extra)
});
