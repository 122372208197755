import React from "react";
import PropTypes from "prop-types";
import {
    Typography,
    withStyles
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import Button from "components/CustomButtons/Button.jsx";
import popupStyle from "assets/jss/material-dashboard-pro-react/components/popupStyle.jsx";
import chatBotStyle from "assets/jss/material-dashboard-pro-react/components/chatBotStyle.jsx";

class ZipcodeWizard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let { classes, zipcode, getLatandLong, handleChange, handlePopUp, handleRequestData } = this.props;
        return (
                <Grid container>
                    <Grid sx={12} xs={12} md={12} lg={12}>
                        <span className={classes.popUpFontStyle} style={{ "margin": "0px" }}></span>
                    </Grid>
                    <Grid sx={10} xs={10} md={10} lg={10}>
                        <input
                            id="zipcode"
                            className={classes.popUpInput}
                            value={zipcode}
                            placeholder="Enter a zipcode"
                            onChange={(event) => {
                                handleChange(event);
                            }}
                        />
                    </Grid>
                    <Grid sx={2} xs={2} md={2} lg={2}>
                        <Button onClick={() => {
                            let requestData = handleRequestData();
                            getLatandLong(zipcode, requestData);
                            handlePopUp();
                        }} className={classes.popupBtn}>
                            <Typography className={classes.popupTypography}>
                                <SearchIcon />
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            );
        }
    }

ZipcodeWizard.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default (withStyles(chatBotStyle, popupStyle)(ZipcodeWizard));