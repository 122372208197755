import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Switch, TextField, Grid, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AddIcon from "@material-ui/icons/ControlPoint";
import RemoveCircleOutline from "@material-ui/icons/RemoveCircleOutlineOutlined";

const style = (theme) => ({
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  inputField: {
    color: "#AAAAAA", fontSize: "14px"
  }
});

const NAME = "name";
const PHONE_NUMBER = "phone_number";
const PHONE_NUMBER_DISPLAY = "phone_number_display";
const HEARING_IMPAIRED_TTY = "hearing_impaired_tty";
const HEARING_IMPAIRED_TTY_DISPLAY = "hearing_impaired_tty_display";
const TEXT_LINE = "text_line";
const VIDEO_PHONE_LINE = "video_line";
const VIDEO_PHONE_DISPLAY = "video_line_display";
const TEXT_LINE_DISPLAY = "text_line_display";
const HOTLINE_TEXT_LINE_DICTIONARY = "hotline_text_line_dictionary";
const DESCRIPTION = "description";
const WEB_SITE = "website";
const LANGUAGE = "language";
const HOTLINE_PHONE_DICTIONARY = "hotline_phone_dictionary";
const CHAT_ON_WEBSITE = "chat_on_website"
const textFields = [
  { id: NAME, label: "Hotline Name", type: "text" },
  // { id: HEARING_IMPAIRED_TTY, label: "Hearing Impaired TTY", type: "text" },
  // {
  //   id: HEARING_IMPAIRED_TTY_DISPLAY,
  //   label: "Hearing Impaired TTY (Display)",
  //   type: "text",
  // },
  // { id: TEXT_LINE, label: "Text Line", type: "text" },
  // { id: TEXT_LINE_DISPLAY, label: "Text Line (Display)", type: "text" },
  // { id: ES_TEXT_LINE, label: "ES Text Line", type: "text" },
  // { id: ES_TEXT_LINE_DISPLAY, label: "ES Text Line (Display)", type: "text" },
  { id: DESCRIPTION, label: "Description", type: "textarea" },
  {
    id: WEB_SITE,
    label: "Hotline Website (Add 'https://' at the start)",
    type: "text",
  },
];

const hearingFields = [
  { id: HEARING_IMPAIRED_TTY, label: "Hearing Impaired TTY", type: "text" },
  {
    id: HEARING_IMPAIRED_TTY_DISPLAY,
    label: "Hearing Impaired TTY (Display)",
    type: "text",
  },
];

const videoPhoneLine = [
  { id: VIDEO_PHONE_LINE, label: "Video Phone Line", type: "tel" },
  { id: VIDEO_PHONE_DISPLAY, label: "Video Phone Line (Display)", type: "tel" }
];

const textLineFields = [
  { id: LANGUAGE, label: "Language", type: "text" },
  { id: TEXT_LINE, label: "Text Line", type: "tel" },
  { id: TEXT_LINE_DISPLAY, label: "Text Line (Display)", type: "text" },
];

const phoneFields = [
  { id: LANGUAGE, label: "Language", type: "text" },
  { id: PHONE_NUMBER, label: "Phone Number", type: "tel" },
  { id: PHONE_NUMBER_DISPLAY, label: "Phone Number (Display)", type: "text" },
];

export class HotlineInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      [NAME]: "",
      [PHONE_NUMBER]: "",
      [PHONE_NUMBER_DISPLAY]: "",
      [HEARING_IMPAIRED_TTY]: "DIAL 711 then ",
      [HEARING_IMPAIRED_TTY_DISPLAY]: "DIAL 711 then ",
      [VIDEO_PHONE_LINE]: "",
      [VIDEO_PHONE_DISPLAY]: "",
      // [TEXT_LINE]: "",
      // [TEXT_LINE_DISPLAY]: "",
      // [ES_TEXT_LINE]: "",
      // [ES_TEXT_LINE_DISPLAY]: "",
      [HOTLINE_TEXT_LINE_DICTIONARY]: [
        { [TEXT_LINE]: "", [TEXT_LINE_DISPLAY]: "", [LANGUAGE]: "" }
      ],
      [DESCRIPTION]: "",
      [WEB_SITE]: "",
      [HOTLINE_PHONE_DICTIONARY]: [
        { [PHONE_NUMBER]: "", [PHONE_NUMBER_DISPLAY]: "", [LANGUAGE]: "" },
      ],
      [CHAT_ON_WEBSITE]: false,
      active: true,
      hearingFieldPresent: false,
    };
  }

  componentDidMount = () => {
    const data = this.props.data || {};
    this.setState({
      ...data,
    });
  };

  isValidated = () => {
    return true;
  };

  sendState = () => {
    return this.state;
  };

  handleInputChange = (event) => {
    const { id, value } = event.target;
    this.setState({ [id]: value });
  };

  handleSwitchChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked, modified: true });
  };

  addTextLine = () => {
    let store1 = [...this.state[HOTLINE_TEXT_LINE_DICTIONARY]];
    store1.push({
      [TEXT_LINE]: "",
      [TEXT_LINE_DISPLAY]: "",
      [LANGUAGE]: "",
    });
    this.setState({ [HOTLINE_TEXT_LINE_DICTIONARY]: store1 });
  };

  addHearingLine = () => {
    this.setState({ hearingFieldPresent: !this.state.hearingFieldPresent })
  }

  removeTextLine = (index) => {
    let store1 = [...this.state[HOTLINE_TEXT_LINE_DICTIONARY]];
    if (store1[index]["delete"] !== undefined) {
      store1[index]["delete"] = true;
    } else {
      store1[index]["delete"] = !store1[index]["delete"];
    }
    if (store1[index]['id'] === undefined && store1[index]["delete"]) {
      store1.splice(index, 1);
    }
    this.setState({ [HOTLINE_TEXT_LINE_DICTIONARY]: store1 });
  };

  addPhoneNumber = () => {
    let store = [...this.state[HOTLINE_PHONE_DICTIONARY]];
    store.push({
      [PHONE_NUMBER]: "",
      [PHONE_NUMBER_DISPLAY]: "",
      [LANGUAGE]: "",
    });
    this.setState({ [HOTLINE_PHONE_DICTIONARY]: store });
  };

  removePhoneNumber = (index) => {
    let store = [...this.state[HOTLINE_PHONE_DICTIONARY]];
    if (store[index]["delete"] !== undefined) {
      store[index]["delete"] = true;
    } else {
      store[index]["delete"] = !store[index]["delete"];
    }
    if (store[index]['id'] === undefined && store[index]["delete"]) {
      store.splice(index, 1);
    }
    this.setState({ [HOTLINE_PHONE_DICTIONARY]: store });
  };

  handleDynamicInputChange = (e, field, index, key) => {
    let store = [...this.state[field]];
    store[index][key] = e.target.value;
    this.setState({ [field]: store });
  };

  handleDynamicInputChangeForTextField = (e, field, index, key) => {
    let store1 = [...this.state[field]];
    store1[index][key] = e.target.value;
    this.setState({ [field]: store1 });
  };

  renderTextLineField = (list) => {
    const { classes } = this.props;
    return list.map((textObj, index) => {
      return (
        <Grid container>
          <Grid alignItems="center" container md={11}>
            {textLineFields.map((textField, index2) => {
              return (
                <Grid item md={4} lg={4}>
                  <TextField
                    key={index + index2}
                    id={HOTLINE_TEXT_LINE_DICTIONARY + index + textField.id}
                    label={textField.label}
                    margin="normal"
                    className={classes.textField}
                    style={{ paddingRight: 15 }}
                    value={
                      this.state[HOTLINE_TEXT_LINE_DICTIONARY][index][textField.id]
                    }
                    InputProps={{ style: { color: "#495057", fontSize: "14px" } }}
                    InputLabelProps={{ style: { color: "#AAAAAA", fontSize: "14px" } }}
                    onChange={(event) =>
                      this.handleDynamicInputChangeForTextField(
                        event,
                        HOTLINE_TEXT_LINE_DICTIONARY,
                        index,
                        textField.id
                      )
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid container direction="row" alignItems="center" justify="center" item xs={1}>
            {index === 0 ? (
              <AddIcon onClick={this.addTextLine} className={classes.icon} />
            ) : (
              <RemoveCircleOutline
                style={{
                  color: this.state[HOTLINE_TEXT_LINE_DICTIONARY][index]["delete"]
                    ? "red"
                    : "#233750",
                }}
                onClick={() => this.removeTextLine(index)}
                className={classes.icon}
              />
            )}
          </Grid>
        </Grid>
      );
    });
  };

  renderPhoneNumbers = (list) => {
    const { classes } = this.props;
    return list.map((phoneObj, index) => {
      return (
        <Grid container>
          <Grid alignItems="center" container md={11}>
            {phoneFields.map((textField, index2) => {
              return (
                <Grid item md={4} lg={4}>
                  <TextField
                    key={index + index2}
                    id={HOTLINE_PHONE_DICTIONARY + index + textField.id}
                    label={textField.label}
                    margin="normal"
                    style={{ paddingRight: 15 }}
                    className={classes.textField}
                    value={
                      this.state[HOTLINE_PHONE_DICTIONARY][index][textField.id]
                    }
                    InputProps={{ style: { color: "#495057", fontSize: "14px" } }}
                    InputLabelProps={{ style: { color: "#AAAAAA", fontSize: "14px" } }}
                    onChange={(event) =>
                      this.handleDynamicInputChange(
                        event,
                        HOTLINE_PHONE_DICTIONARY,
                        index,
                        textField.id
                      )
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid container direction="row" alignItems="center" justify="center" item xs={1}>
            {index === 0 ? (
              <AddIcon onClick={this.addPhoneNumber} className={classes.icon} />
            ) : (
              <RemoveCircleOutline
                style={{
                  color: this.state[HOTLINE_PHONE_DICTIONARY][index]["delete"]
                    ? "red"
                    : "#233750",
                }}
                onClick={() => this.removePhoneNumber(index)}
                className={classes.icon}
              />
            )}
          </Grid>
        </Grid>
      );
    });
  };

  render() {
    const { classes = {} } = this.props;
    const { hotline_phone_dictionary = [] } = this.state;
    const { hotline_text_line_dictionary = [] } = this.state;

    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          <h4 className={classes.infoText}>
            Let's start with the basic information
          </h4>
        </GridItem>
        <GridItem xs={12}>
          {textFields.map((textField, index) => {
            return (
              <CustomInput
                type={textField.type === "textarea" ? null : textField.type}
                labelText={textField.label}
                key={index}
                value={this.state[textField.id]}
                id={textField.id}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: textField.type === "textarea" ? true : false,
                  rows: textField.type === "textarea" ? 3 : null,
                  onChange: this.handleInputChange,
                  value: this.state[textField.id],
                }} 
              />
            );
          })}
          {/* <span style={{ marginBottom: "-10px", fontSize: "14px" }}>
            Text Line
          </span> */}
          <Typography style={{ display: 'flex', flexDirection: "row", alignItems: "center", marginTop: "20px", marginBottom: "20px" }}>
            Hearing Impaired TTY
            {this.state.hearingFieldPresent ? <RemoveCircleOutline onClick={this.addHearingLine} /> : <AddIcon onClick={this.addHearingLine} className={classes.icon} />}
          </Typography>
          <Grid container>
            <Grid alignItems="center" container md={11}>
              {this.state.hearingFieldPresent ? hearingFields.map((hearingField, index) => {
                return (
                  <CustomInput
                    type={hearingField.type}
                    labelText={hearingField.label}
                    key={index}
                    value={this.state[hearingField.id]}
                    id={hearingField.id}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: this.handleInputChange,
                      value: this.state[hearingField.id],
                    }}
                  />
                )
              }) : null}
            </Grid>
          </Grid>
          <Grid container>
            <Grid alignItems="center" container md={11}>
              {videoPhoneLine.map((videoLine, index) => {
                return (
                  <CustomInput
                    type={videoLine.type}
                    labelText={videoLine.label}
                    key={index}
                    value={this.state[videoLine.id]}
                    id={videoLine.id}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: this.handleInputChange,
                      value: this.state[videoLine.id],
                    }}
                  />
                )
              })}
            </Grid>
          </Grid>
          {this.renderTextLineField(hotline_text_line_dictionary)}
          {this.renderPhoneNumbers(hotline_phone_dictionary)}
          <FormControlLabel
            control={
              <Switch
                checked={this.state.chat_on_website}
                value={this.state.chat_on_website}
                onChange={this.handleSwitchChange("chat_on_website")}
                color="primary"
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  icon: classes.switchIcon,
                  iconChecked: classes.switchIconChecked,
                  bar: classes.switchBar,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label="Chat on website"
          />
          <FormControlLabel
            control={
              <Switch
                checked={this.state.active}
                value={this.state.active}
                onChange={this.handleSwitchChange("active")}
                color="primary"
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  icon: classes.switchIcon,
                  iconChecked: classes.switchIconChecked,
                  bar: classes.switchBar,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label="Active"
          />
        </GridItem>
      </GridContainer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(withStyles(style)(HotlineInfo));
