import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import api from "utils/api";
import PropTypes from "prop-types";
import {
  Grid,
  Paper,
  Typography,
  withStyles,
  TextField,
  Fab,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import { bindActionCreators } from "redux";
import { hotlinesByCategory } from "actions/action_hotlines";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: "10px 0px 10px 0px",
    width: "600px",
  },
  fab: {
    margin: theme.spacing.unit,
  },
});

function FrequentlyUsedHotlines(props) {
  const [ranks, setRank] = useState({});
  const { hotlines } = props;
  const { classes } = props;
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Loading..");


  const sortHotlines = (a, b) => {
    if (a.hotline_rank > b.hotline_rank) return 1;
    if (a.hotline_rank === b.hotline_rank) return 0;
    if (a.hotline_rank < b.hotline_rank) return -1;
  };

  function openSnackBar() {
    setOpen(true);
  }

  function closeSnackBar(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  }

  const mapHotlines = React.useMemo(() => {
    if (loading && Object.keys(ranks).length > 0 && hotlines.length > 0) {
      let hotlines = [...props.hotlines];
      hotlines.forEach((ele) => {
        ele["hotline_rank"] = ranks[ele.id] ? ranks[ele.id] : "";
      });
      setLoading(false);
      return hotlines;
    } else {
      return hotlines;
    }
  }, [props.hotlines, ranks]);

  useEffect(() => {
    let data = {
      filters: [],
      limit: 12,
    };

    props.hotlinesByCategory(10, data);
    getRanks();
  }, [hotlinesByCategory]);

  const handleChange = (id) => (event) => {
    let temp = { ...ranks };
    temp[id] = event.target.value;
    setRank(temp);
    // setValues({ ...values, [name]: event.target.value });
  };

  const getRanks = () => {
    api.get(
      "getHotlinesRank",
      (response) => {
        let ranks = {};
        response.forEach((ele) => {
          ranks[ele.hotline_id] = ele.hotline_rank;
        });

        setRank(ranks);
      },
      (reject) => {
        console.log("error", reject);
        setAlertMessage("Error occured");
      }
    );
  };

  const updateRank = (id, rank) => {
    api.post(
      "updateHotlineRank",
      { hotline_id: id, hotline_rank: Number.parseInt(rank) },
      (response) => {
        // getRanks();
        openSnackBar();
        setTimeout(() => {
          window.location.reload();
        }, 1900);
      },
      (reject) => {
        console.log("error", reject);
      }
    );
  };

  return (
    <Grid
      id="sort_hotlines"
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Typography variant="caption" gutterBottom>
        *Note: The limit for frequently used Hotlines is 15.
      </Typography>

      {hotlines &&
        mapHotlines.sort(sortHotlines).map((hotline, index) => {
          return (
            <Grid item sm={8} md={8} lg={8} key={index}>
              <Paper className={classes.root} hotlinevation={1}>
                <Grid container>
                  <Grid item sm={8} md={8} lg={8}>
                    <TextField
                      id="standard-name"
                      label="Rank"
                      className={classes.textField}
                      value={ranks[hotline.id] ? ranks[hotline.id] : ""}
                      onChange={handleChange(hotline.id)}
                      margin="normal"
                      InputLabelProps={{ style: { color: "#AAAAAA" } }}
                    />
                  </Grid>
                </Grid>

                <Typography variant="h5" component="h3">
                  {hotline["name"]}
                </Typography>
                <Typography component="p">{hotline["description"]}</Typography>
                <Typography component="p">{hotline["website"]}</Typography>

                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid item sm={2} md={2} lg={2}>
                    <Fab
                      size="small"
                      color="primary"
                      aria-label="Add"
                      className={classes.fab}
                      onClick={() => updateRank(hotline.id, ranks[hotline.id])}
                    >
                      <CheckCircleOutline />
                    </Fab>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          );
        })}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={closeSnackBar}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{alertMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={closeSnackBar}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Grid>
  );
}

FrequentlyUsedHotlines.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    // hotlinecategories: state.hotlinecategories,
    hotlines: state.hotlines.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hotlinesByCategory: bindActionCreators(hotlinesByCategory, dispatch),
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FrequentlyUsedHotlines)
);
