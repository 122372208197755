import React from "react";
import PropTypes from "prop-types";
// import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";

import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

// const intl = window.intl;
class SlotUpdated extends React.Component {
  // constructor(props) {
  //   super(props);
  //   // we use this to make the card to appear after the page has been rendered
   
  // }
  // componentDidMount() {
   
  // }
 
  render() {   const { classes } = this.props;
 
  

     return ( <div className={classes.container}><GridContainer justify="center" container ><h2 style={{ color: "#233750", textAlign: "center" , fontWeight: 'bold'}}>
        Slot has been updated.
        </h2></GridContainer></div>);
    
  }
}
SlotUpdated.propTypes = {
  classes: PropTypes.object.isRequired
  //service: prototype.object.isRequired
};

const mapStateToProps = state => {
  return {
   

  };
};
const mapDispatchToProps = dispatch => {
  return {
   
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(registerPageStyle)(SlotUpdated));