import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPublicCategories } from "../../actions/action_categories";
import { bindActionCreators } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import {
    insertSlotInfo,
    updateSlotInfo,
    deleteSlotInfo
  } from "../../actions/action_slotInfo";


// icons
import FeatureIcon from "@material-ui/icons/LocalActivity";

import Switch from "@material-ui/core//Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";

import api from "../../utils/api";
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import { Link } from "react-router-dom";
const intl = window.intl;
class SlotEditPublicPage extends React.Component {
    constructor(props) {
      super(props);
      const params = new URLSearchParams(props.location.search);
      this.state = {
        slotID: props.match.params.slotID,
        slotName: "",
        availability: "",
        capacity: "",
        wait_time: "",
        category: props.match.params.categoryID, 
        // params.get("category"),
        hideCategory: params.has("category"),
        service: props.match.params.serviceID,
        // params.get("service"),
        hideService: params.has("service"),
        categories: [],
        active: true,
        slotNotFound: false,
        error: {},
        mandatory: { slotName: true, availability: true, capacity: true },
        modified: true //enabling true
      };
    }
  
    getSlotInfoForEdit = async () => {
        const {slotID} = this.state;
      const slotInfo = await api.get(`/slotInfo/${slotID}`);
      // debugger;
      if (slotInfo && Object.keys(slotInfo).length > 0) {
        this.setState({ ...slotInfo, service:(slotInfo.service||{}).id, slotInfoNotFound: false });
            this.setState({ ...slotInfo, category:(slotInfo.category||{}).id, slotInfoNotFound: false });
      } else {
        this.setState({ slotInfoNotFound: true });
      }
    };

  //   getSlotInfoForEdit = async () => {      
  //   const {slotID, service} = this.state;        
  //   this.setState({ loadingSlotInfos: true });
  //   api.get(
  //     `/slotInfos/${slotID}`,
  //     data => {
  //       let slotInfoData = this.props.data||{};
  //       console.log(slotInfoData);
  //       this.setState({
  //         loadingTimeInfos: false,
  //         slotInfos: data,
  //         slotInfoData
  //       });
  //     },
  //     () => {
  //       this.setState({ loadingslotInfos: false, slotInfos: [] });
  //     }
  //   );
  // };

       
    getCategories = async id => {
      const categories = await api.get(`/modelSearch/categories`);
      this.setState({ categories });
    };
 
    componentDidMount() {
      const { slotID } = this.state;
      slotID && this.getSlotInfoForEdit();
      this.getCategories();
      this.getSlotInfoForEdit();
    }
  
    UNSAFE_componentWillReceiveProps(nextProps) {
      const { data = {} } = nextProps.category  && nextProps.service || {};
  
      if (data.slotName !== this.state.slotName) {
        const {
          slotName,
          availability,
          capacity,
          wait_time,
          // service,
          allow_attachments,
          allow_contact_persons
        } = data;
        this.setState({
          slotName,
          availability,
          capacity,
          wait_time,
          // service,
          allow_attachments,
          allow_contact_persons
        });
      }
    }
    validateField = (slotName, value) => {
      const { error, mandatory } = this.state;
      if (mandatory[slotName]) {
        error[slotName] = value === undefined || value.length === 0;
      }
      this.setState({ error });
      return !error[slotName];
    };
  
    handleSwitchChange = slotName => event => {
      this.setState({ [slotName]: event.target.checked, modified: true });
    };
    handleTextChange = slotName => event => {
      
      if(event.target.value == "")
      { 
         this.setState({ [slotName]: "", modified: true });
      }
      else
      {
          this.validateField(slotName, event.target.value);
          this.setState({ [slotName]: event.target.value, modified: true });
      }
      
      if(slotName == "wait_time" && event.target.value > 0)
      {
        this.state.availability = 0;
      }
      else if(slotName == "availability" && event.target.value > 0)
      {
        this.state.wait_time = 0
      }
    };
    onSubmit = () => {
      const {
        slotID,
        slotName,
        capacity,
        wait_time,
        availability,
        category,
        service,
        active,
        modified,
        error = {}
      } = this.state;
      let valid = true;
      if (!modified) {
        this.props.enqueueSnackbar("No information is updated", {
          variant: "info"
        });
        return false;
      } else {
        valid = Object.keys(error).every(name => {
          return !error[name];
        });
      }
      if (!valid) {
        this.props.enqueueSnackbar("Please provide all required details", {
          variant: "error"
        });
        return false;
      }
      let data = {
        slotName,
        capacity,
        wait_time,
        availability,
        category,
        service,
        active
      };

      // if (slotID) {
      //   data.id = slotID;
      //   console.log(data.id);
      //   console.log(slotID);
      
      //   this.props.updateSlotInfo(data, () => {
      //   //   this.props.enqueueSnackbar("SlotInfo updated successfully", {
      //   //     variant: "success"
      //   //   }
      //   //   );
      //     //this.props.history.goBack();
          
          
      //     this.props.history.push("/auth/slot-update");
      //   }
      //   );
      // } else {
      //   this.props.insertSlotInfo(data, () => {
      //     //debugger;
      //   //   this.props.enqueueSnackbar("SlotInfo saved successfully", {
      //   //     variant: "success"
      //   //   });
      //     //this.props.history.goBack();
          
      //     this.props.history.push("/auth/slot-update");
      //   });
      // }
      if (slotID) {
        data.id = slotID;
        this.props.updateSlotInfo(data, () => {
        
          
          this.props.history.push("/auth/slot-update");
        });
      } else {
        this.props.insertSlotInfo(data, () => {
        
          this.props.history.push("/auth/slot-update");
        });
      }
    };
   
    render() {
      this.props.history.goForward();
      
      const { classes } = this.props;
      const { slotID, slotInfoNotFound, error = {}, modified, categories=[], category, service } = this.state;
      const hideService = service;
      const hideCategory = category;
  
      return (
        <div className={classes.container}>
         
          {slotID && slotInfoNotFound ? (
            <Card>
              <CardBody>
                <Typography variant="subtitle1" gutterBottom align="center">
                  {intl.formatMessage({
                    id: "view.slotInfoEdit.slotInfoNotFound"
                  })}{" "}
                  <Link to={`/admin/edit-slot?service=${service.id}&category=${category.id}`}>
                    {intl.formatMessage({
                      id: "createNew"
                    })}
                  </Link>
                </Typography>
              </CardBody>
            </Card>
          ) : (
            <GridContainer
              container
              spacing={24}
              direction="row"
              justify="center"
              alignItems="center"
            >
              
              <GridItem xs={12} sm={12} md={8}>
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                      <FeatureIcon />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle} color="primary">
                      {(slotID ? "Update" : "Create") + " Slot"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText="slot Name"
                          id="slotName"
                          error={error["slotName"]}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: this.state.slotName,
                            onChange: this.handleTextChange("slotName"),
                            placeholder: "Treatment Service Name"
                          }}
                        />
                      </GridItem>
                      
                      <GridItem xs={12}>
                        <CustomInput
                          labelText="availability"
                          id="availability"
                          formControlProps={{
                            fullWidth: true,
                            type: "number"
                          }}
                          inputProps={{
                            value: this.state.availability,
                            type: "number",
                            onChange: this.handleTextChange("availability"),
                            placeholder: "How many slots are available right now"
                          }}
                        />
                    {!hideCategory &&  <GridItem xs={12}>
                        <TextField
                          id="category"
                          select
                          label="Category"
                          className={classes.textField}
                          value={this.state.category}
                          onChange={this.handleTextChange('category')}
                          SelectProps={{
                            native: true,
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                        >
                          <option value=""></option>
                          {categories.map(option => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </TextField>
                      </GridItem>}
                     
                      {!hideService && <GridItem xs={12}>
                        <TextField
                          id="service"
                          select
                          label="service"
                          className={classes.textField}
                          value={this.state.service}
                          onChange={this.handleTextChange('service')}
                          SelectProps={{
                            native: true,
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                        >
                          <option value=""></option>
                          {categories.map(option => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </TextField>
                      </GridItem>}
                     
                    
                      </GridItem>
                      <GridItem xs={12}>
                      <CustomInput
                        labelText="wait time"
                        id="wait_time"
                        
                        formControlProps={{
                          fullWidth: true,
                          type: "number"
                        }}
                        inputProps={{
                          value: this.state.wait_time,
                          type: "number",
                          onChange: this.handleTextChange("wait_time"),
                          placeholder: "What is the estimated wait time in days?"
                        }}
                      />
                    </GridItem>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText="capacity"
                          id="capacity"
                          
                          formControlProps={{
                            fullWidth: true,
                            type: "number"
                          }}
                          inputProps={{
                            value: this.state.capacity,
                            type: "number",
                            onChange: this.handleTextChange("capacity"),
                            placeholder: "Total number of slots for this service"
                          }}
                        />
                      </GridItem>
                      {false &&<GridItem xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.active}
                              value={this.state.active}
                              onChange={this.handleSwitchChange("active")}
                              color="primary"
                              classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                icon: classes.switchIcon,
                                iconChecked: classes.switchIconChecked,
                                bar: classes.switchBar
                              }}
                            />
                          }
                          classes={{
                            label: classes.label
                          }}
                          label="Active"
                        />
                      </GridItem>}
                    </GridContainer>
                   {/* <Link to={`/auth/view-slot-public/${service}/${category}`}> */}
                    <Button
                      color="primary"
                      disabled={!modified}
                      className={classes.updateProfileButton}
                      onClick={this.onSubmit}
                    >
                      {`${slotID ? "Update" : "Save"} Slot`}
                    </Button>
              {/* </Link> */}
                    <Clearfix />
  
                    
                  </CardBody>
                </Card>
              </GridItem>
          
            </GridContainer>
          )}
          <GridContainer justify="center" container ><h2 style={{ color: "#233750", textAlign: "center" , fontWeight: 'bold'}}>
        <p></p>
        </h2> <p></p> <p></p> <p></p></GridContainer>
        </div>
      );
    }
  }
  SlotEditPublicPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    categories: state.categories,
    service: state.service
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getPublicCategories: bindActionCreators(getPublicCategories, dispatch),
    insertSlotInfo: bindActionCreators(insertSlotInfo, dispatch),
      updateSlotInfo: bindActionCreators(updateSlotInfo, dispatch),
      deleteSlotInfo: bindActionCreators(deleteSlotInfo, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(registerPageStyle)(SlotEditPublicPage));
