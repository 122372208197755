import React from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Typography,
    withStyles
} from "@material-ui/core";
import { LocationOnOutlined } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CardIcon from "components/Card/CardIcon.jsx";
import popupStyle from "assets/jss/material-dashboard-pro-react/components/popupStyle.jsx";
import chatBotStyle from "assets/jss/material-dashboard-pro-react/components/chatBotStyle.jsx";
import ZipcodeWizard from "./ChatBotPopupWizards/ZipcodeWizard";
import CurrLocationWizard from "./ChatBotPopupWizards/CurrLocationWizard";
import CityWizard from "./ChatBotPopupWizards/CityWizard";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class ChatBotZipcodePopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = { value: 0 };
    }

    handleChangeEvent = (event, value) => {
        this.setState({ value });
    };

    render() {
        let { open, zipcode, getLatandLong,
            handleChange, handlePopUp, handleRequestData,
            city, getUserGeoLocation, questions } = this.props;
        const { classes } = this.props;
        const { value } = this.state;

        return (
            <Grid className={classes.modalContainer}>
                <Dialog
                    open={open}
                    onClose={() => handlePopUp()}
                    fullWidth maxWidth="xs"
                    className={classes.dialogContainer}
                    BackdropProps={{ style: { backgroundColor: "#233750", opacity: 0.8 } }}
                >
                    <DialogTitle disableTypography className={classes.dialogTitlee}>
                        <Grid container>
                            <Grid sx={2} xs={2} md={2} lg={2}>
                                <CardIcon color="rose" className={classes.dialogTitleIcon}>
                                    <LocationOnOutlined style={{ fontSize: "28px" }} />
                                </CardIcon>
                            </Grid>
                            <Grid sx={8} xs={8} md={8} lg={8} className={classes.title + " " + classes.center}>
                                <Typography variant="h6" className={classes.popUpTitle} align="left">
                                    Sort by Location
                                </Typography>
                            </Grid>
                            <Grid sx={2} xs={2} md={2} lg={2} className={classes.center}>
                                <Typography component="div" align="right">
                                    <CloseIcon
                                        onClick={() => handlePopUp()}
                                        className={classes.popUpCloseIcon}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText id="alert-dialog-description">
                            <FormControl className={classes.modalContainer} variant="outlined">
                                <Grid justify="center">
                                    <AppBar position="static">
                                        <Tabs value={value} onChange={this.handleChangeEvent}
                                            classes={{
                                                flexContainer: classes.flexContainer,
                                                indicator: classes.indicator
                                            }}>
                                            <Tab label="Location" classes={{ selected: classes.selected }} className={classes.tabWidth} />
                                            <Tab label="Zipcode" classes={{ selected: classes.selected }} className={classes.tabWidth} />
                                            <Tab label="City" classes={{ selected: classes.selected }} className={classes.tabWidth} />
                                        </Tabs>
                                    </AppBar>
                                    {value === 0 && <TabContainer>
                                        <CurrLocationWizard questions={questions}
                                            open={open}
                                            city={city}
                                            zipcode={zipcode}
                                            handleRequestData={handleRequestData}
                                            getLatandLong={getLatandLong}
                                            getUserGeoLocation={getUserGeoLocation}
                                            handlePopUp={handlePopUp}
                                            handleChange={handleChange} /></TabContainer>}

                                    {value === 1 && <TabContainer>
                                        <ZipcodeWizard questions={questions}
                                            open={open}
                                            city={city}
                                            zipcode={zipcode}
                                            handleRequestData={handleRequestData}
                                            getLatandLong={getLatandLong}
                                            getUserGeoLocation={getUserGeoLocation}
                                            handlePopUp={handlePopUp}
                                            handleChange={handleChange} /></TabContainer>}

                                    {value === 2 && <TabContainer>
                                        <CityWizard questions={questions}
                                            open={open}
                                            city={city}
                                            zipcode={zipcode}
                                            handleRequestData={handleRequestData}
                                            getLatandLong={getLatandLong}
                                            getUserGeoLocation={getUserGeoLocation}
                                            handlePopUp={handlePopUp}
                                            handleChange={handleChange} /></TabContainer>}

                                </Grid>
                            </FormControl>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </Grid>
        );
    }
}

ChatBotZipcodePopup.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default (withStyles(chatBotStyle, popupStyle)(ChatBotZipcodePopup));