import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  insertServiceType,
  updateServiceType,
  deleteServiceType
} from "../../actions/action_serviceType";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Typography from "@material-ui/core/Typography";

import { withSnackbar } from "notistack";

// icons
import ServiceTypeIcon from "@material-ui/icons/Ballot";

import Switch from "@material-ui/core//Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// core components

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import api from "../../utils/api";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

const intl = window.intl;
class ServiceTypeEditPage extends React.Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    this.state = {
      serviceTypeID: props.match.params.serviceTypeID,
      name: "",
      description: "",
      category: params.get("category"),
      hideCategory: params.has("category"),
      categories: [],
      active: true,
      serviceTypeNotFound: false,
      error: {},
      mandatory: { name: true, description: true },
      modified: false
    };
  }
  getServiceType = async id => {
    const serviceType = await api.get(`/serviceTypes/${id}`);
    if (serviceType && Object.keys(serviceType).length > 0) {
      this.setState({ ...serviceType, category: (serviceType.category || {}).id, serviceTypeNotFound: false });
    } else {
      this.setState({ serviceTypeNotFound: true });
    }
  };
  getCategories = async id => {
    const categories = await api.get(`/modelSearch/categories`);
    this.setState({ categories });
  };

  componentDidMount() {
    const { serviceTypeID } = this.state;
    serviceTypeID && this.getServiceType(serviceTypeID);
    this.getCategories();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data = {} } = nextProps.serviceType || {};

    if (data.name !== this.state.name) {
      const {
        name,
        description,
        allow_attachments,
        allow_contact_persons
      } = data;
      this.setState({
        name,
        description,
        allow_attachments,
        allow_contact_persons
      });
    }
  }
  validateField = (name, value) => {
    const { error, mandatory } = this.state;
    if (mandatory[name]) {
      error[name] = value === undefined || value.length === 0;
    }
    this.setState({ error });
    return !error[name];
  };

  handleSwitchChange = name => event => {
    this.setState({ [name]: event.target.checked, modified: true });
  };
  handleTextChange = name => event => {
    this.validateField(name, event.target.value);
    this.setState({ [name]: event.target.value, modified: true });
  };
  onSubmit = () => {
    const {
      serviceTypeID,
      name,
      description,
      category,
      active,
      modified,
      error = {}
    } = this.state;
    let valid = true;
    if (!modified) {
      this.props.enqueueSnackbar("No information is updated", {
        variant: "info"
      });
      return false;
    } else {
      valid = Object.keys(error).every(name => {
        return !error[name];
      });
    }
    if (!valid) {
      this.props.enqueueSnackbar("Please provide all required details", {
        variant: "error"
      });
      return false;
    }
    let data = {
      name,
      description,
      category,
      active
    };
    if (serviceTypeID) {
      data.id = serviceTypeID;
      this.props.updateServiceType(data, () => {
        this.props.enqueueSnackbar("ServiceType updated successfully", {
          variant: "success"
        });
        this.props.history.goBack();
      });
    } else {
      this.props.insertServiceType(data, () => {
        this.props.enqueueSnackbar("ServiceType saved successfully", {
          variant: "success"
        });
        this.props.history.goBack();
      });
    }
  };
  render() {
    const { classes } = this.props;
    const { serviceTypeID, serviceTypeNotFound, error = {}, modified, categories = [], category } = this.state;
    const hideCategory = category;
    return (
      <React.Fragment>
        {serviceTypeID && serviceTypeNotFound ? (
          <Card>
            <CardBody>
              <Typography variant="subtitle1" gutterBottom align="center">
                {intl.formatMessage({
                  id: "view.serviceTypeEdit.serviceTypeNotFound"
                })}{" "}
                <Link to="/admin/edit-serviceType">
                  {intl.formatMessage({
                    id: "createNew"
                  })}
                </Link>
              </Typography>
            </CardBody>
          </Card>
        ) : (
          <GridContainer
            container
            spacing={24}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <ServiceTypeIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle} color="primary">
                    {(serviceTypeID ? "Update" : "Create") + " ServiceType"}
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText="Name"
                        id="name"
                        error={error["name"]}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.name,
                          onChange: this.handleTextChange("name")
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText="Description"
                        id="description"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          multiline: true,
                          rows: 5,
                          value: this.state.description,
                          onChange: this.handleTextChange("description")
                        }}
                      />
                    </GridItem>
                    {!hideCategory && <GridItem xs={12}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="roles">Role</InputLabel>
                        <Select
                          value={this.state.category}
                          onChange={this.handleChange}
                          inputProps={{
                            name: "roles",
                            id: "roles",
                            required: true
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {categories.map(cat => (
                            <MenuItem key={cat.id} value={cat.id}>
                              {cat.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>}
                    <GridItem xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.active}
                            value={this.state.active}
                            onChange={this.handleSwitchChange("active")}
                            color="primary"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="Active"
                      />
                    </GridItem>
                  </GridContainer>
                  <Button
                    color="primary"
                    disabled={!modified}
                    className={classes.updateProfileButton}
                    onClick={this.onSubmit}
                  >
                    {`${serviceTypeID ? "Update" : "Save"} ServiceType`}
                  </Button>
                  <Clearfix />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </React.Fragment>
    );
  }
}
ServiceTypeEditPage.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    serviceType: state.serviceType
  };
};
const mapDispatchToProps = dispatch => {
  return {
    insertServiceType: bindActionCreators(insertServiceType, dispatch),
    updateServiceType: bindActionCreators(updateServiceType, dispatch),
    deleteServiceType: bindActionCreators(deleteServiceType, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(userProfileStyles)(withSnackbar(ServiceTypeEditPage))));
