import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getQuickFeaturesByCategory } from "actions/action_quick_features";
import {
  getQuickSearchListData,
  getQuickSearchMapData,
} from "actions/action_quick_search";
import { bindActionCreators } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import quickSearchResultsStyle from "assets/jss/material-dashboard-pro-react/views/quickSearchResultsStyle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ChevronRightRounded";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paginations from "components/Pagination/Pagination.jsx";
import { withSnackbar } from "notistack";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import ChevronLeftIcon from "@material-ui/icons/ChevronRightRounded";
import ChevronRightIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronUpIcon from '@material-ui/icons/ExpandLess';
import Slide from "@material-ui/core/Slide";
import QuickSearch from "./QuickSearch.jsx";
import ServicesMapView from "views/Components/ServicesMapView.jsx";
import QuickSearchInfoWindow from "views/Pages/QuickSearch/QuickSearchInfoWindow.jsx";
import ServiceCard from "views/Pages/QuickSearch/ServiceCard.jsx";
import ZipCodePopUp from "../ZipCodePopUp";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions
} from "@material-ui/core";
import { Close } from "@material-ui/icons";


function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const intl = window.intl;

class QuickSearchResults extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      filterData: [],
      sortInfoFilters: "1",
      lat: "",
      lng: "",
      isUserAcceptedGeoLocation: false,
      geoLocationWarning: null,
      tabValue: 0,
      zipCode: "",
      openZipCode: false,
      openDrawer: true,
      open: false,
      showServiceOnMap: null,
      openMobileViewFilter: false,
      closeMap: true,
      number: 9,
    };
    this.quickSearchUserSelection = this.props.location.state.userSelection;
  }

  componentDidMount() {
    this.getUserGeoLocation();
    this.onLoadServiceSearch();
  }

  renderMaps = () => {
    const { classes, quickSearchMapData } = this.props;
    return (
      <>
        <GridItem xs={12} className={classes.mapRoot}>
          <ServicesMapView
            userLat={this.state.lat}
            userLng={this.state.lng}
            acceptedLocation={this.state.isUserAcceptedGeoLocation}
            agencies={quickSearchMapData}
            infowindow={<QuickSearchInfoWindow />}
            parentComponent={"QuickSearch"}
            showServiceOnMap={this.state.showServiceOnMap}
          />
        </GridItem>
      </>
    );
  };

  closeMaps = () => {
    const { closeMap } = this.state
    this.setState({ "closeMap": !closeMap });
  }

  // start - code related to Filters
  handleFilterChange = (question) => (e) => {
    let filterData = this.state.filterData || [];
    if (e.target.checked) {
      filterData.push({ id: question.id, name: question.question });
    } else {
      filterData = filterData.filter((q) => q.id !== question.id);
    }
    this.setState({ filterData }, () => {
      this.searchServices();
    });
  };

  zoomOnClick = (agency_name) => {
    agency_name && this.setState({ showServiceOnMap: agency_name })
  }

  getUserGeoLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            isUserAcceptedGeoLocation: true,
          }, () => {
            this.searchServices();
          });
        },
        (error) => {
          var errMsg = error.message ? error.message : "";
          errMsg = errMsg.replace(/\s/g, "").toUpperCase();
          if (errMsg === "USERDENIEDGEOLOCATION") {
            this.setState({
              lat: "",
              lng: "",
              isUserAcceptedGeoLocation: false,
            });
          }
        },
        { enableHighAccuracy: true }
      );
    } else {
      this.setState({
        lat: "",
        lng: "",
        isUserAcceptedGeoLocation: false,
      });
    }
  }

  onLoadServiceSearch() {
    this.props.getQuickFeaturesByCategory();
    this.searchServices();
  }

  // Excel Sheet download open //
  getExcel = () => {
    const { quickSearchListData } = this.props;
    const { result = {} } = quickSearchListData;
    const { data = [] } = result;
    if (data.length === 0) {
      // do not download when there is no data available
      return;
    }
    const requiredData = data.map((row) => ({
      AgencyName: row.agency_name,
      ServiceName: row.service_name,
      StreetAddress: row.address,
      City: row.city,
      State: row.state,
      ZipCode: row.zip,
      PhoneNumber: row.phone_number,
      website: row.website,
      Description: this.truncateDesc(row.description),
    }));
    const csvData = this.objectToCsv(requiredData);
    this.download(csvData);
  };

  download = (csvData) => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "SearchService.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  objectToCsv = (data) => {
    const csvRow = [];
    const headers = Object.keys(data[0]);
    csvRow.push(headers.join(","));
    for (const row of data) {
      const values = headers.map((header) => {
        const escapedValues = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escapedValues}"`;
      });
      csvRow.push(values.join(","));
    }
    return csvRow.join("\n");
  };
  // Excel Sheet Download Close //

  getFromZipCode = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        this.setState({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
          isUserAcceptedGeoLocation: true,
        }, () => {
          this.searchServices();
        });
      } else {
        this.setState({
          lat: "",
          lng: "",
          isUserAcceptedGeoLocation: false,
        });
      }
    });
  };

  getZipCodePopUp = () => {
    return (
      <>
        <ZipCodePopUp
          zipCode={this.state.zipCode}
          openZipCode={this.state.openZipCode}
          callbackFun={this.zipCodeCallback}
          updateProps={this.updateProps} />
      </>
    );
  };

  updateProps = (name, value) => {
    this.setState({ [name]: value });
  }

  zipCodeCallback = () => {
    let { zipCode } = this.state;
    if (zipCode) {
      this.getFromZipCode(zipCode);
    }
  }

  handleSortInfoChange = (evt) => {
    const { name, value } = evt.target;
    if (value === "6") {
      this.getUserGeoLocation(() => {
        this.setState({ [name]: value }, () => {
          this.searchServices();
        });
      });
    } else if (value === "7") {
      this.setState({ [name]: value, openZipCode: true }, () => {
        this.getZipCodePopUp();
      });
    } else if (value === "1") {
      this.setState({ [name]: value }, () => {
        this.searchServices();
      });
    }
  };

  searchServices = (
    page = 1,
    userSelection = this.quickSearchUserSelection
  ) => {
    const filters = this.state.filterData.map((f) => f.id);
    this.quickSearchUserSelection = userSelection;
    let obj = {
      data: this.quickSearchUserSelection,
      limit: 9,
      filters,
      page,
      sort_infos: this.state.sortInfoFilters,
      view: this.state.tabValue === 0 ? "list" : "map",
    };
    if (this.state.lat && this.state.lng) {
      obj.lat = this.state.lat;
      obj.lng = this.state.lng;
      this.setState({
        geoLocationWarning: null,
      });
    } else if (
      !this.isUserAcceptedGeoLocation &&
      this.state.sortInfoFilters === "6"
    ) {
      this.setState({
        geoLocationWarning: this.props.enqueueSnackbar(
          "Please 'allow' location to see 'Distance from you' feature.",
          {
            variant: "info",
          }
        ),
      });
      obj.lat = 41.4993;
      obj.lng = -81.6944;
    } else if (
      !this.isUserAcceptedGeoLocation &&
      this.state.sortInfoFilters === "7"
    ) {
      this.setState({
        geoLocationWarning: this.props.enqueueSnackbar(
          "Please 'allow' location to see 'Distance from you' feature.",
          {
            variant: "info",
          }
        ),
      });
    }
    if (this.state.tabValue === 0) {
      this.props.getQuickSearchListData(obj);
      this.props.getQuickSearchMapData({
        data: this.quickSearchUserSelection,
        limit: 10,
        filters,
        page,
        sort_infos: this.state.sortInfoFilters,
        view: "map",
      });
    } else this.props.getQuickSearchMapData(obj);
  };

  getEnteredPage = (evt) => {
    this.searchServices(evt);
    let html = document.getElementsByTagName("html");
    let body = document.getElementsByTagName("body");
    if (body && body[0]) {
      body[0].scrollTop = 0;
    }
    if (html && html[0]) {
      html[0].scrollTop = 0;
    }
  };

  handleOpen = () => {
    this.setState({ open: true, disableToggle: true });
  };

  handleClose = () => {
    this.setState({ open: false, disableToggle: false });
  };

  handleMobileViewFilter = () => {
    const { openMobileViewFilter } = this.state
    this.setState({ "openMobileViewFilter": !openMobileViewFilter });
  };

  getPagination = () => {
    const { quickSearchListData } = this.props;
    const { last_page = 1, current_page = 1 } = quickSearchListData.result;
    if (last_page <= 1) {
      return null;
    }

    let pageLimit;
    if (window && window.screen && window.screen.width <= 480) {
      pageLimit = 5
    }

    let pages = [];
    let limit = pageLimit ? pageLimit : 9;
    let start = 1;
    let end = last_page;
    if (last_page > limit) {
      start = current_page - Math.floor(limit / 2);
      end = current_page + Math.floor(limit / 2);
      if (start <= 0) {
        start = 1;
        end = limit;
      }
      if (end > last_page) {
        end = last_page;
        start = end - limit;
      }
    }
    for (let i = start; i <= end; i++) {
      pages.push({
        onClick: this.gotoPage,
        text: i,
        active: current_page === i,
      });
    }

    pages.unshift({
      text: intl.formatMessage({ id: "prev" }),
      onClick: this.gotoPrevPage,
    });
    pages.push({
      text: intl.formatMessage({ id: "next" }),
      onClick: this.gotoNextPage,
    });

    pages.push({
      text: intl.formatMessage({ id: `${current_page} of ${last_page}` }),
    });

    return (
      <Paginations
        pages={pages}
        color="info"
        currentPage={current_page}
        lastPage={last_page}
        gotoEnteredPage={this.getEnteredPage}
      />
    );
  };

  gotoPage = (evt) => {
    this.searchServices(evt.target.innerText);
    let html = document.getElementsByTagName("html");
    let body = document.getElementsByTagName("body");
    if (body && body[0]) {
      body[0].scrollTop = 0;
    }
    if (html && html[0]) {
      html[0].scrollTop = 0;
    }
  };

  gotoNextPage = () => {
    const { quickSearchListData } = this.props;
    const { last_page = 1, current_page = 1 } = quickSearchListData.result;
    if (current_page < last_page) {
      this.searchServices(current_page + 1);
      // why remove scroll top code from here?
    }
  };
  gotoPrevPage = () => {
    const { quickSearchListData } = this.props;
    const { current_page = 1 } = quickSearchListData.result;
    if (current_page > 1) {
      this.searchServices(current_page - 1);
    }
    // why remove scroll top code from here?
  };

  truncateDesc = (description) => {
    if (description == null) return "";
    description = description.replace(/<[^>]+>/g, "");
    description = description.replace("&nbsp;", "");
    return description;
  };

  getServicesForRender = () => {
    const { quickSearchListData, classes } = this.props;
    const { loading = false, result = {}, error = "" } = quickSearchListData;
    const { data = [], total } = result;

    if (loading) {
      return (
        <Grid item md={12} align="center">
          <Typography variant="subtitle1" gutterBottom align="center">
            Loading services... Please wait...
          </Typography>
        </Grid>
      );
    }
    if (error) {
      return (
        <SnackbarContent
          message={intl.formatMessage({
            id: "view.agencies.searchError",
          })}
          color="danger"
        />
      );
    }

    return (
      <>
        <Grid container direction="row">
          <GridItem xs={7} sm={6} align="left">
            {total >= 10 && (
              <Typography gutterBottom align="left">
                Showing 10 of {total} services
              </Typography>
            )}
            {total < 10 && (
              <Typography gutterBottom align="left">
                Showing {total} of {total} services
              </Typography>
            )}
          </GridItem>
          <GridItem xs={5} sm={6} className={classes.KeysGrid}>
            <div className={classes.KeysContainer}>
              {/* <i style={{ color: "#233750", margin: "10px" }} />
              Items per page
              <FormControl variant="outlined" style={{ textAlign: 'center' }}>
                <Select
                  value={this.state.number}
                  onChange={(event) => {
                    localStorage.setItem('entered_number', event.target.value);
                    this.setState({ number: event.target.value });
                    this.searchServices();
                  }}
                  labelId="results-per-page-label"
                  label="Results per page"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                    getContentAnchorEl: null,
                  }}
                  renderValue={(selected) => (
                    <div style={{ textAlign: 'center' }}>{selected}</div>
                  )}
                >
                  {[3, 6, 9, 12, 15].map((number) => (
                    <MenuItem key={number} value={number}>
                      {number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <i style={{ color: "#233750", marginRight: "5px" }} className="fas fa-circle" /> Open
              <i
                style={{ marginLeft: 15, color: "grey", marginRight: "5px" }}
                className="fas fa-circle"
              />{" "}
              Closed
            </div>
          </GridItem>
        </Grid>

        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={{ padding: "10px", display: "flex", justifyContent: "center" }}
          className={classes.cardsDiv}
        >
          {data.map((service, index) => (
            <ServiceCard
              key={index}
              service={service}
              sortingOption={this.state.sortInfoFilters}
              zoomOnClick_={this.zoomOnClick}
              hasUserAllowedLocation={this.state.isUserAcceptedGeoLocation}
            />
          ))}
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="stretch"
        >
          <Grid item md={9} sm={12} align="center">
            {this.getPagination()}
          </Grid>
        </Grid>
      </>
    );
  };

  renderSortAndSearchOptions = () => {
    const { classes } = this.props;
    const { sortInfoFilters = "" } = this.state;

    return (
      <Grid container direction="row" className={classes.sortAndOptionsContainer}>
        <Grid item sm={3} md={2} className={classes.sortTitleGrid}>
          <Typography className={classes.sortTitle}>
            Sort By: &nbsp;
          </Typography>
        </Grid>
        <Grid item sm={9} md={10}>
          <FormControl className={classes.formControl}>
            <Select
              value={sortInfoFilters}
              onChange={this.handleSortInfoChange}
              inputProps={{
                name: "sortInfoFilters",
                id: "sortInfoFilters",
                required: true,
              }}
              style={{ border: "1px solid grey", paddingLeft: "10px", borderRadius: 5, borderBottom: "none !important" }}
            >
              <MenuItem value="1">
                <em style={{ fontStyle: "normal" }}>Recently Updated</em>
              </MenuItem>
              <MenuItem value="6">
                <em style={{ fontStyle: "normal" }}>Distance From You</em>
              </MenuItem>
              <MenuItem value="7">
                <em style={{ fontStyle: "normal" }}>
                  Distance From Your Zip Code
                </em>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  handleDrawerOpen = () => {
    const { openDrawer } = this.state;
    this.setState({ openDrawer: !openDrawer });
  };

  clearAllResults = () => {
    this.setState({ filterData: [] }, () => {
      this.searchServices();
    });
  };

  renderFilters() {
    const { classes, featuresStore = {} } = this.props;
    const features = featuresStore.data || {};
    const { filterData = [] } = this.state;
    const selectedQuestions = filterData.map((q) => q.id);

    return (
      <Grid className={classes.filterGrid}>
        <Typography
          variant="title"
          gutterBottom
          className={classes.filtersHeading}
        >
          <span className={classes.disableInMobileView}>Filter Your Results</span>
          <button
            style={{
              borderWidth: 1,
              borderRadius: 10,
              backgroundColor: "white",
              color: "#1E1E1",
              padding: "10px",
            }}
            onClick={() => this.clearAllResults()}
          >
            Clear all
          </button>&nbsp;
          <button className={classes.onlyMobileView}
            style={{
              borderWidth: 1,
              borderRadius: 10,
              backgroundColor: "#233750",
              color: "#1E1E1",
              padding: "10px",
            }}
            onClick={() => this.handleMobileViewFilter()}
          >
            Apply
          </button>
        </Typography>
        <div>
          {features.map(
            (feature) =>
              feature.active && (
                <ExpansionPanel key={feature.id} square>
                  <ExpansionPanelSummary
                    expandIcon={
                      <ExpandMoreIcon
                        style={{
                          color: "#233750",
                          fontSize: "30px",
                        }}
                      />
                    }
                    aria-controls="panel1a-content"
                    id={"panel" + feature.id + "-header"}
                  >
                    <Typography className={classes.typoStyle}>
                      {feature.name}
                    </Typography>
                  </ExpansionPanelSummary>

                  <ExpansionPanelDetails>
                    <FormGroup>
                      {(feature.questions || [])
                        .filter(
                          (question) =>
                            question.active &&
                            question.type === "SWITCH"
                        )
                        .map((question) => (
                          <FormControlLabel
                            key={question.id}
                            className={classes.questionName}
                            control={
                              <Checkbox
                                style={{ color: "#3d97d3" }}
                                value={question.id + ""}
                                checked={selectedQuestions.includes(
                                  question.id
                                )}
                                onChange={this.handleFilterChange(
                                  question
                                )}
                                color="primary"
                              />
                            }
                            label={question.question}
                          />
                        ))}
                    </FormGroup>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )
          )}
          <Typography
            style={{ fontWeight: "bold", paddingTop: "25px" }}
            align="center"
          >
            Access More Filters
          </Typography>

          <Grid container justify="center" style={{ marginBottom: "30px" }}>
            <Tooltip
              title={"Search using all available filters"}
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
              style={{ fontFamili: "calibri" }}
            >
              <Button
                variant="outlined"
                onClick={() =>
                  this.props.history.push(
                    "/auth/search-service/3/treatmentservices"
                  )
                }
                color="primary"
                style={{
                  backgroundColor: "#D9552C",
                  color: "#FFFFFF",
                }}
              >
                Advanced Search
              </Button>
            </Tooltip>
          </Grid>
        </div>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;

    const { openDrawer } = this.state;

    return (
      <div
        ref="ss_Ref"
        style={{
          minHeight: "100%",
          width: "100%",
          maxWidth: "1790",
          margin: "auto",
        }}
      >
        <GridContainer
          justify="center"
          container
          style={{ zIndex: "10", margin: "0px" }}
        >
          <GridItem xs={12} sm={12} md={11} style={{ color: "text" }}>
            <Card style={{ backgroundColor: "#233750" }}>
              <CardBody style={{ maxWidth: "100%" }}>
                <Typography
                  variant="h4"
                  align="center"
                  style={{ color: "white" }}
                >
                  {"Quick Search"}
                </Typography>
              </CardBody>
            </Card>
            <Card>
              <Grid container direction="row">
                <Slide
                  direction="right"
                  in={openDrawer}
                  mountOnEnter
                  unmountOnExit
                >
                  <Grid item xs={12} sm={3} md={3} className={classes.filtersContainer}>
                    {this.renderFilters()}
                  </Grid>
                </Slide>
                <Grid item xs={12} sm={openDrawer ? 9 : 12} md={openDrawer ? 9 : 12}>
                  <Grid container direction="column" justify="flex-start">
                    <Grid item>
                      {this.state.closeMap ? <Grid className={classes.collapseIcon}>
                        {
                          openDrawer ?
                            <ChevronRightIcon onClick={this.handleDrawerOpen} style={{ color: "#f4f7fc", fontSize: "45px" }} />
                            :
                            <ChevronLeftIcon onClick={this.handleDrawerOpen} style={{ color: "#f4f7fc", fontSize: "45px" }} />
                        }
                      </Grid> : null}
                      <Grid style={{ position: "absolute", right: 15, backgroundColor: "#233750", zIndex: 1, borderRadius: "10px" }}>
                        {this.state.closeMap ?
                          <ChevronUpIcon style={{ color: '#f4f7fc', fontSize: '45px' }} onClick={this.closeMaps} />
                          :
                          null}
                      </Grid>
                      {this.state.closeMap ? this.renderMaps() : null}
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      className={classes.sorting}
                    >
                      <Grid item xs={12} sm={5} style={{ padding: "5px" }}>{this.renderSortAndSearchOptions()}</Grid>
                      <Grid item xs={12} sm={4} style={{ padding: "5px" }}>
                        <div className={classes.quickSearchContainer}>
                          <QuickSearch
                            searchServices={this.searchServices}
                            buttonText={"Quick Search Again"}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6} className={classes.onlyMobileView}>
                        <button
                          style={{
                            borderWidth: 1,
                            borderRadius: 10,
                            backgroundColor: "#233750",
                            color: "white",
                            padding: "12px",
                          }}
                          className={classes.filterBtn}
                          onClick={() => this.handleMobileViewFilter()}
                        >
                          Filter Your Results
                        </button>
                      </Grid>
                      <Grid item style={{ padding: "15px" }}>
                        <button
                          variant="contained"
                          onClick={(e) => {
                            this.getExcel();
                          }}
                          style={{
                            borderWidth: 1,
                            borderRadius: 10,
                            backgroundColor: "white",
                            color: "#1E1E1",
                            padding: "12px",
                          }}
                        >
                          Download Results
                        </button>
                      </Grid>
                      {!this.state.closeMap && (
                        <div>
                          <button
                            variant="contained"
                            onClick={(e) => {
                              this.closeMaps();
                            }}
                            style={{
                              borderWidth: 1,
                              borderRadius: 10,
                              backgroundColor: 'white',
                              color: '#1E1E1',
                              padding: '10px',
                            }}
                          >
                            Show Map
                          </button>
                        </div>
                      )}
                    </Grid>
                    <Grid item>
                      {this.getServicesForRender()}
                    </Grid>
                    <Grid item>
                      {this.state.openZipCode ? this.getZipCodePopUp() : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </GridItem>
        </GridContainer>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth maxWidth="lg"
          className={classes.dialogContainer}
          BackdropProps={{ style: { backgroundColor: "#233750", opacity: 0.8 } }}
        >
          <DialogTitle disableTypography className={classes.dialogTitlee} id="alert-dialog-title">
            <Typography style={{ color: "white", fontWeight: "bold" }}>
              {/* {(service.service_type || {}).name} */}
            </Typography>
            <Card style={{ backgroundColor: "#233750" }}>
              <CardBody style={{ maxWidth: "100%" }}>
                <Typography
                  variant="h4"
                  align="center"
                  style={{ color: "white" }}
                >
                  {"Quick Search"}
                </Typography>
              </CardBody>
            </Card>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText id="alert-dialog-description">
              <Grid item>{this.getServicesForRender()}</Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Filters for Mobile View */}
        <Dialog
          open={this.state.openMobileViewFilter}
          onClose={this.handleMobileViewFilter}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          BackdropProps={{ style: { backgroundColor: "#233750", opacity: 0.8 } }}
        >
          <Grid style={{
            display: "flex",
            padding: "10px",
            background: "#233750",
            color: "white"
          }}>
            <Grid item xs={10} sm={10} style={{ display: "flex", alignItems: "center" }}>
              <span style={{ fontWeight: "normal", fontSize: "20px" }}>Filter Your Search</span>
            </Grid>
            <Grid item xs={2} sm={10} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
              <Close
                style={{ fontWeight: "normal", fontSize: "20px" }}
                onClick={this.handleMobileViewFilter}
              />
            </Grid>
          </Grid>
          <DialogContent>
            {this.renderFilters()}
          </DialogContent>
        </Dialog>
        {/* Filters for Mobile View */}
      </div>
    );
  }
}

QuickSearchResults.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, prp) => {
  return {
    featuresStore: state.features,
    quickSearchListData: state.quickSearchListData,
    quickSearchMapData: state.quickSearchMapData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getQuickFeaturesByCategory: bindActionCreators(
      getQuickFeaturesByCategory,
      dispatch
    ),
    getQuickSearchListData: bindActionCreators(
      getQuickSearchListData,
      dispatch
    ),
    getQuickSearchMapData: bindActionCreators(getQuickSearchMapData, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(quickSearchResultsStyle)(withSnackbar(QuickSearchResults)));
