const sortByOptionsForTreatmentServices = [
  {
    name: "Recently Updated",
    value: "1",
  },
  {
    name: "Agency Name(A to Z)",
    value: "2",
  },
  {
    name: "Agency Name(Z to A)",
    value: "3",
  },
  {
    name: "Distance From You",
    value: "6",
  },
  {
    name: "Distance From Your Zip Code",
    value: "7",
  },
]

const sortByOptionsForOtherSerivces = [
  {
    name: "Distance From You",
    value: "6",
  },
  {
    name: "Distance From Your Zip Code",
    value: "7",
  },
]

const TREATMENT_SERVICE_ID = "3";

const getSortByOptions = (serviceId) => {
  if(serviceId === TREATMENT_SERVICE_ID) {
    return sortByOptionsForTreatmentServices;
  }
  return sortByOptionsForOtherSerivces;
}

export default getSortByOptions;