import React from "react";
import { bindActionCreators } from "redux";
import { getServiceByCategory } from "actions/action_search_service";
import { Fab, Slide } from "@material-ui/core";
import ChatIcon from "@material-ui/icons/MessageOutlined";
import ChatWindow from "./ChatWindow";
import { connect } from "react-redux";
import { getQuickSearchListData } from "actions/action_quick_search";
import { getQuestions } from "../QuickSearch/questions";


class ChatBot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleChat: false,
      quickSearch: false,
      otherServices: false,
      currQueNum: 0,
      messages: [
        {
          id: "P1",
          text: "Welcome! What can I help you find ?",
          user: "bot",
          options: [],
          pregFlag: false,
        },
        {
          id: "P3",
          text: "",
          user: "bot",
          options: [
            `Treatment Services`,
            "Peer and Family Support",
          ],
          pregFlag: false,
        },
      ],
      peerAndFamily: [],
      peerAndFamilyCount: 0,
      lat: "",
      lng: "",
      quickSearchListData: {},
      userSelection: [],
      isDragging: false,
      bottomPosition: 107,
      rightPosition: 40,
      isUserAcceptedGeoLocation: false
    };
    this.questions = getQuestions();
  }

  toggleChat = () => {
    const { isDragging, toggleChat } = this.state;
    if (!isDragging) {
      this.setState({ toggleChat: !toggleChat });
    }
  };

  updateQuickSearch = (value) => {
    this.setState({ quickSearch: value });
  };

  updateOtherServices = (value) => {
    this.setState({ otherServices: value });
  };

  updateCurrQueNum = (value) => {
    this.setState({ currQueNum: value });
  }

  userinput = (value) => {
    const chat_obj = {
      text: value,
      user: "user",
      options: [],
    };
    this.setState((prevState) => ({ messages: [...prevState.messages, chat_obj] }));
  };

  updateMessages = (messagesObj, reset) => {
    let messages = reset ? [] : this.state && this.state.messages;
    messagesObj &&
      messagesObj.forEach((msg) => {
        messages.push(msg);
      });
    this.setState({ messages: messages });
  };

  componentDidUpdate = () => {
    const { searchStore } = this.props;
    const { servicesInfo } = searchStore;
    const { result } = servicesInfo;
    const { total } = result;
    const { peerAndFamilyCount } = this.state;

    if (result && typeof result === 'object' && Object.keys(result).length > 0 && total !== peerAndFamilyCount) {
      this.setState({ peerAndFamilyCount: total });
    }
  };

  onStop = (e) => {
    setTimeout(() => this.setState({ isDragging: false }), 0);
  }
  onClick = (e) => {
    this.toggleChat();
  }
  onDrag = (e) => {
    this.setState({ isDragging: true })
  }

  getUserGeoLocation = (requestData) => {
    if (!navigator.geolocation) {
      this.setState({
        lat: "",
        lng: "",
        isUserAcceptedGeoLocation: false,
      });
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        this.setState({
          lat: latitude,
          lng: longitude,
          isUserAcceptedGeoLocation: true,
        });

        const obj = {
          sort_infos: "7",
          view: "list",
          data: requestData,
          limit: 12,
          filters: [],
          lat: latitude,
          lng: longitude,
          page: 1,
        };

        this.props.getQuickSearchListData(obj, () => { }, () => { });
      },
      (error) => {
        const errMsg = (error.message || "").replace(/\s/g, "").toUpperCase();
        if (errMsg === "USERDENIEDGEOLOCATION") {
          this.setState({
            lat: "",
            lng: "",
            isUserAcceptedGeoLocation: false,
          });
        }
      }
    );
  };

  getLatandLong = (zipcode, requestData) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: zipcode }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        let obj = {
          sort_infos: "7",
          view: "list",
          data: requestData,
          limit: 12,
          filters: [],
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
          page: 1,
        };
        this.props.history.push({
          pathname: "/auth/quick-search",
          state: { userSelection: requestData },
        });
        this.props.getQuickSearchListData(obj, () => { }, () => { });
        this.setState({
          userSelection: requestData,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
          isUserAcceptedGeoLocation: true,
        });
      } else {
        this.setState({
          lat: "",
          lng: "",
          isUserAcceptedGeoLocation: false,
        });
      }
    });
  };

  getServiceCount = (service) => {
    const currentParamId = "5";
    let cat_desc = this.state.peerAndFamily;
    let index = cat_desc.indexOf(service);

    if (index === -1) {
      cat_desc.push(service);
    } else {
      cat_desc.splice(index, 1);
    }

    let obj = {
      limit: 12,
      category: currentParamId,
      filters: [],
      q: "",
      page: 1,
      cat_desc,
      sort_infos: "",
    };
    this.props.getServiceByCategory(obj, () => { }, () => { });
    this.setState({ peerAndFamily: cat_desc });
  };

  redirectServices = (service_type) => {
    const services = service_type.map((ele) => ({ name: ele }));
    this.props.history.push({
      pathname: "/auth/search-service/5/peerandfamilysupport",
      state: { type: "filter", data: services },
    });
  };

  setDragPositions = (x, y) => {
    const { bottomPosition, rightPosition } = this.state;
    this.setState({
      bottomPosition: x - bottomPosition,
      rightPosition: y - rightPosition,
    });
  };

  render() {
    const {
      messages,
      quickSearch,
      otherServices,
      currQueNum,
      peerAndFamily,
      toggleChat,
      peerAndFamilyCount,
      bottomPosition,
      rightPosition,
    } = this.state;

    const { history } = this.props;

    return (
      <>
        <Slide
          direction="up"
          in={toggleChat}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 1000, exit: toggleChat ? 1 : 900 }}
        >
          <ChatWindow
            messages={messages}
            updateMessages={this.updateMessages}
            getServiceCount={this.getServiceCount}
            history={history}
            userinput={this.userinput}
            toggleChat={this.toggleChat}
            peerAndFamily={peerAndFamily}
            peerAndFamilyCount={peerAndFamilyCount}
            getLatandLong={this.getLatandLong}
            getUserGeoLocation={this.getUserGeoLocation}
            redirectServices={this.redirectServices}
            questions={this.questions}
            quickSearch={quickSearch}
            otherServices={otherServices}
            currQueNum={currQueNum}
            updateQuickSearch={this.updateQuickSearch}
            updateCurrQueNum={this.updateCurrQueNum}
            updateOtherServices={this.updateOtherServices}
            setDragPositions={this.setDragPositions}
            bottomPosition={bottomPosition}
            rightPosition={rightPosition}
          />
        </Slide>

        {!toggleChat && (
          <Fab
            className="dh_chat"
            onClick={this.toggleChat}
            style={{
              zIndex: 1000,
              cursor: "pointer",
              position: "fixed",
              bottom: "50px",
              right: "15px",
              backgroundColor: "#3d97d3",
            }}
            aria-label="Chatbot"
          >
            <ChatIcon />
          </Fab>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchStore: state.searchStore,
    quickSearchListData: state.quickSearchListData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServiceByCategory: bindActionCreators(getServiceByCategory, dispatch),
    getQuickSearchListData: bindActionCreators(
      getQuickSearchListData,
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatBot);
