import {GET_SERVICE, FULFILLED, REJECTED, PENDING} from '../utils/types';
const initialState = {
    data: [],
    error: false,
    servicesLoading: false
};

export default function servicesReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case `${GET_SERVICE+PENDING}`:
            return {...state, error: false, servicesLoading: true};
        case `${GET_SERVICE+FULFILLED}`:
            return {...state, ...payload, error: false, servicesLoading: false};
        case `${GET_SERVICE+REJECTED}`:
            return {...state, error: true, servicesLoading: false, data:[]};
        default:
            return state;
    }
}