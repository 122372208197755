import api from '../utils/api';
import {GET_AGENCIES,GET_PUBLIC_AGENCIES,GET_AGENCIES_FOR_MAP} from '../utils/types';


export const getAgencies = ({nameLike="",page=1, limit=9,sortBy="updated_at",isSorting=1}, ...extra) => ({
    type: GET_AGENCIES,
    payload: api.get(`/agencies?nameLike=${nameLike}&page=${page}&limit=${limit}&sortBy=${sortBy}&isSorting=${isSorting}`,null,...extra)
});

export const getPublicAgencies = ({nameLike="",page=1, limit=9}, ...extra) => ({
    type: GET_PUBLIC_AGENCIES,
    payload: api.get(`/modelSearch/agencies?nameLike=${nameLike}&page=${page}&limit=${limit}`,null,...extra)
});

export const searchAgenciesForMap = (data, ...extra) => ({
  type: GET_AGENCIES_FOR_MAP,
  payload: api.post(`/agencies/locations`, data, null, ...extra)
});