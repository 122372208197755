import React from "react";
import PropTypes from "prop-types";
import {
    Typography,
    withStyles
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import popupStyle from "assets/jss/material-dashboard-pro-react/components/popupStyle.jsx";
import chatBotStyle from "assets/jss/material-dashboard-pro-react/components/chatBotStyle.jsx";

class CurrLocationWizard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let { classes, handlePopUp, handleRequestData, getUserGeoLocation } = this.props;
        return (
            <Grid container>
                <Grid item sx={12} xs={12} md={12} lg={12} className={classes.center}>
                    <Button className={classes.popupBtn} /*style={{ backgroundColor: "#233750" }}*/
                        onClick={() => {
                            let requestData = handleRequestData();
                            getUserGeoLocation(requestData);
                            handlePopUp();
                        }}>
                        <Typography className={classes.popupTypography}>
                            <MyLocationIcon fontSize={"large"} />
                            Current Location
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

CurrLocationWizard.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default (withStyles(chatBotStyle, popupStyle)(CurrLocationWizard));