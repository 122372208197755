import api from "../utils/api";
import {
  GET_SERVICE_TYPE,
  INSERT_SERVICE_TYPE,
  UPDATE_SERVICE_TYPE,
  DELETE_SERVICE_TYPE
} from "../utils/types";

export const getServiceType = (id, ...extra) => ({
  type: GET_SERVICE_TYPE,
  payload: api.get(`/serviceTypes/${id}`, null, ...extra)
});
export const insertServiceType = (data, ...extra) => ({
  type: INSERT_SERVICE_TYPE,
  payload: api.post(`/serviceTypes`, data, ...extra)
});
export const updateServiceType = (data, ...extra) => ({
  type: UPDATE_SERVICE_TYPE,
  payload: api.put(`/serviceTypes/${data.id}`, data, ...extra)
});
export const deleteServiceType = (id, ...extra) => ({
  type: DELETE_SERVICE_TYPE,
  payload: api.delete(`/serviceTypes/${id}`, null, ...extra)
});
