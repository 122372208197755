import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPublicCategories } from "../../actions/action_categories";
import { bindActionCreators } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import { Link } from "react-router-dom";

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {};
  }
  componentDidMount() {
    this.props.getPublicCategories({});
  }
  render() {
    const { classes, categories } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center" container>
          <GridItem xs={12} sm={12} md={10}>
            <h2 style={{ color: "#233750", textAlign: "center" , fontWeight: 'bold'}}>
            What Are You Looking For?
            </h2>
            <GridContainer>
              {(categories.data || []).map(category => (
                <GridItem xs={12} sm={12} md={4}>
                  
                  <Link
                        to={this.props.match.path + "-service/" + category.id + "/" +category.name.replace(/\s/g, '').toLowerCase()}
                      >
                  <Card pricing raised style={{width:300, height:130}}>
                    <CardBody pricing>
                      {/* <h6 style={{color: "#233750"}} className={classes.cardCategory}>Service</h6> */}
                      <h3
                      style={{color: "#d9552c"}}
                        className={`${classes.cardTitle} ${
                          classes.marginTop30
                        }`}
                      >
                        {category.name}
                      </h3>
                      {/* <p
                      style={{color: "#233750"}}
                        className={classes.cardDescription}
                        style={{ maxHeight: "60px", overflow: "hidden" }}
                      >
                        {category.description}
                      </p> */}
                      <Link
                        to={this.props.match.path + "-service/" + category.id + "/" +category.name.replace(/\s/g, '').toLowerCase()}
                      >
                        
                        {/* <Button round color="rose"> */}
                          
                        {/* Find  {category.name} */}
                        <i class="material-icons">
                          search
                        </i>
                        {/* </Button> */}
                      </Link>
                    </CardBody>
                  </Card>
                  </Link>
                </GridItem>
              ))}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
SearchPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    categories: state.categories
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getPublicCategories: bindActionCreators(getPublicCategories, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(registerPageStyle)(SearchPage));
