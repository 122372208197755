const mapInfoWindowStyle = {
  root: {
    width: "250px",
    display: "flex",
    alignItems: "center",
  },
  arrowForward: {
    padding: "0px",
    color: "#233750",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  arrowBack: {
    padding: "0px",
    color: "#233750",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  card: {
    margin: "0px",
    boxShadow: "none",
    padding: "20px 0",
  },
  titleSmall: {
    "@media only screen and (max-width: 450px) and (-webkit-min-device-pixel-ratio: 1.5)": {
      fontSize: "12pt",
    },
    color: "#d9552c",
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontSize: "16pt",
    lineHeight: "normal",
  },
  titleMedium: {
    "@media only screen and (max-width: 450px) and (-webkit-min-device-pixel-ratio: 1.5)": {
      fontSize: "12pt",
    },
    color: "#d9552c",
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontSize: "18pt",
    lineHeight: "normal",
  },
  titleLarge: {
    "@media only screen and (max-width: 450px) and (-webkit-min-device-pixel-ratio: 1.5)": {
      fontSize: "14pt",
    },
    color: "#d9552c",
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontSize: "22pt",
    lineHeight: "normal",
  },
  serviceName: {
    "@media only screen and (max-width: 450px) and (-webkit-min-device-pixel-ratio: 1.5)": {
      fontSize: "12pt",
    },
    color: "#233750",
    fontFamily: "Calibri",
    fontWeight: "bold",
    fontSize: "14pt",
    lineHeight: "normal",
  },
  accreditationsContainer: {
    paddingTop: "5px",
  },
  slotInfo: {
    "@media only screen and (max-width: 450px) and (-webkit-min-device-pixel-ratio: 1.5)": {
      fontSize: "10pt",
    },
    color: "#233750",
    fontFamily: "Calibri",
    fontSize: "11pt",
    lineHeight: "normal",
    paddingTop: "5px",
  },
  addressContainer: {
    paddingTop: "5px",
  },
  address: {
    color: "#233750",
    fontFamily: "Calibri",
    fontSize: "10pt",
    lineHeight: "normal",
  },
  cardActions: {
    paddingLeft: "0px",
  },
  iconBtn: {
    padding: "0px",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  agencyOpenText: {
    color: "#233750",
    fontFamily: "Calibri",
    fontSize: "10pt",
    lineHeight: "normal",
    fontWeight: "bold",
  },
  agencyClosedText: {
    color: "#414042",
    fontFamily: "Calibri",
    fontSize: "10pt",
    lineHeight: "normal",
  },
  distance: {
    color: "#233750",
    fontFamily: "Calibri",
    fontSize: "10pt",
    lineHeight: "normal",
    fontWeight: "bold",
  },
  carouselIndicatorsRoot: {
    display: "flex",
    margin: "auto",
    paddingLeft: "30px",
  },
  carouselIndicators: {
    width: "10px",
    borderRadius: "100%",
    color: "#bdbdbd",
  },
  carouselIndicatorSelected: {
    width: "10px",
    borderRadius: "100%",
    color: "#233750",
  },
};

export default mapInfoWindowStyle;
