import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { withSnackbar } from "notistack";
import { injectIntl } from "react-intl";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import serviceStyles from "assets/jss/material-dashboard-pro-react/views/serviceStyles.jsx";
import AboutStep from "./WizardSteps/AgencyEdit/AboutStep";
import AddressStep from "./WizardSteps/AgencyEdit/AddressStep";
import api from '../../utils/api';


export class AgencyEditPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.agencyID,
      about: {},
      contactInfo: {},
      agencyFetching: true
    }
  }
  componentWillMount = () => {
    if(!this.state.id) {
      this.setState({agencyFetching: false});
    } else {
      this.getAgency();
    }
  }
  
  getAgency = () => {
    api.get("/agencies/"+this.state.id, (data)=> {
      this.setState({
        agencyFetching: false,
        about: {
          name: data.name,
          logo: data.logo,
          active: data.active,
          description: data.description,
          accreditations: (data.accreditations||"").split(";"),
          admin: {
            value: data.admin.id,
            label: `${data.admin.first_name} ${data.admin.last_name}`
          }
        },
        contactInfo: {
          address1: data.address1,
          address2: data.address2,
          city: data.city,
          state: data.state,
          zip: data.zip,
          lat: parseFloat(data.lat),
          lng: parseFloat(data.lng),
          phone_number: data.phone_number,
          website: data.website
        }
      });
    }, (error)=> {

    })
  }
  insertAgency = (agency) => {
    api.post("/agencies", agency, ()=> {
      this.props.enqueueSnackbar("Agency created successfully", {variant: 'success'});
      this.props.history.replace('/admin/agencies');
      return false;
    }, (error)=> {
      this.props.enqueueSnackbar("Error occured while creating agency", {variant: 'error'});
      return false;

    });
  }
  updateAgency = (agency) => {
    api.put("/agencies/"+agency.id, agency, ()=> {
      this.props.enqueueSnackbar("Agency updated successfully", {variant: 'success'});
      this.props.history.replace('/admin/agencies');
      return false;
    }, (error)=> {
      this.props.enqueueSnackbar("Error occured while updating agency", {variant: 'error'});
      return false;

    });
  }
  
  saveAgency = (data) => {
    const agency = {...data.about, ...data.contactInfo, id:this.state.id};
    agency.admin = (agency.admin||{}).value;
    agency.accreditations = (agency.accreditations||[]).join(";");
    if(agency.id) {
      this.updateAgency(agency);
    } else {
      this.insertAgency(agency);  
    }
    // debugger;
  }

  render() {
      const { intl } = this.props
      const { agencyFetching= false, about={}, contactInfo={} } = this.state
    // debugger;
 
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {intl.formatMessage({
              id: "agencies"
            }) +
              " | " +
              intl.formatMessage({
                id: "title"
              })}
          </title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8}>
            {(agencyFetching ? "Loading" :<Wizard
              validate={true}
              steps={[
                {
                  stepName: "About",
                  stepComponent: AboutStep,
                  stepId: "about",
                  data: about
                },
                {
                  stepName: "Contact Information",
                  stepComponent: AddressStep,
                  stepId: "contactInfo",
                  data: contactInfo
                }
              ]}
              title="Create an agency"
              subtitle="This information will let us know more about your agency."
              finishButtonClick={this.saveAgency}
              color="primary"
            />)}
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(serviceStyles)(withSnackbar(AgencyEditPage))));
