import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.jsx";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import api from "../../utils/api";
const uuidv4 = require("uuid/v4");

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.state = {
      uploading: false
    }
  }

  handleImageChange (e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    const value = this.props.value || uuidv4();
    this.setState({uploading: true});
    reader.onloadend = () => {
      let formdata = new FormData();
      // Send Patch request to laravel
      formdata.append('_method', 'PUT');
      formdata.append("image", file);
      api.imageUpload(
        value,
        formdata,
        data => {
          this.setState({uploading: false});
          this.props.onChange && this.props.onChange(e, value);
        },
        error => {
          this.setState({uploading: false});
          console.error(error);
        }
      );
    };
    reader.readAsDataURL(file);
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove(e) {
    this.refs.fileInput.value = null;
    this.props.onChange && this.props.onChange(e, null);
  }
  render() {
    var {
      avatar,
      label,
      addButtonProps,
      changeButtonProps,
      removeButtonProps,
      value
    } = this.props;
    const { uploading } = this.state;
    let imageUrl = this.props.avatar ? defaultAvatar : defaultImage;
    const baseURL = (process.env.NODE_ENV !== 'production') ? `http://localhost:8000/api/v1` : `/api/v1`;
    if(!uploading &&  value) {
      imageUrl = `${baseURL}/assets/file/${value}?r=${Date.now()}`;
    }
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" accept="image/*"/>
        <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
          <img src={imageUrl} alt="..." />
        </div>
        <div>
          {!this.props.value ? (
            <Button {...addButtonProps} onClick={() => this.handleClick()}>
              {avatar
                ? `Add ${label || "Photo"}`
                : `Select ${label || "image"}`}
            </Button>
          ) : (
            <span>
              <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                Change
              </Button>
              {avatar ? <br /> : null}
              <Button
                {...removeButtonProps}
                onClick={() => this.handleRemove()}
              >
                <i className="fas fa-times" /> Remove
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  label: PropTypes.string,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};

export default ImageUpload;
