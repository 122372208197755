import {
  container,
  cardTitle,
  blackColor,
  hexToRgb,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const registerPageStyle = {
  ...customCheckboxRadioSwitch,
  cardTitle: {
    ...cardTitle,
    textAlign: "center",
  },
  container: {
    ...container,
    position: "relative",
    zIndex: "3",
    minHeight: "100%",
    // paddingTop: "23vh"
  },
  gridContainer: {
    "justifyContent": "center",
    "@media only screen and (max-width: 600px)": {
      display: "flex",
      justifyContent: "center"
    }
  },
  FAQ_Title: {
    color: "#233750",
    textAlign: "justify",
    fontFamily: 'Calibri',
    /* Mobile View */
    "@media screen and (max-device-width: 600px)": {
      textAlign: "center !important",
    },
  },
  cardSignup: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 6px 30px 5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    marginBottom: "100px",
    padding: "40px 0px",
    marginTop: "15vh",
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  left: {
    textAlign: "left",
  },
  form: {
    padding: "0 20px",
    position: "relative",
  },
  socialTitle: {
    fontSize: "18px",
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  customFormControlClasses: {
    margin: "0 12px",
  },
  checkboxLabelControl: {
    margin: "0",
  },
  checkboxLabel: {
    marginLeft: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
  },
  chip: {
    margin: "8px",
  },
  parastyle: {
    color: "#233750",
    textAlign: "justify",
    fontSize: "16px"
  },
  contentstyle: {
    width: "12rem",
    height: "12rem",
  },
  clestyle: {
    fontWeight: "bold",
    color: "#233750",
  },
  flexDisplay: {
    /* Tablet View - Portrait)*/
    "@media screen and (min-device-width: 768px)and (max-device-width: 1024px) and (orientation : portrait)": {
      padding: "0px 8px !important",
    },
    /* Tablet View - Landscape)*/
    "@media screen and (min-device-width: 768px)and (max-device-width: 1024px) and (orientation : landscape)": {
      padding: "0px 5px !important",
    },
    /* Mobile Phones View (Portrait) */
    "@media screen and (max-device-width: 480px) and (orientation : portrait)": {
      padding: "0px 5px !important",
    },
    /* Mobile Phones View (Landscape) */
    "@media screen and (max-device-width: 640px) and (orientation : landscape)": {
      padding: "0px 5px !important",
    }
  },
  aboutDiv: {
    marginBottom: "30px"
  }
};

export default registerPageStyle;
