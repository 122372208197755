import api from "../utils/api";
import {
  GET_AGENCY,
  INSERT_AGENCY,
  UPDATE_AGENCY,
  DELETE_AGENCY
} from "../utils/types";

export const getAgency = (id, ...extra) => ({
  type: GET_AGENCY,
  payload: api.get(`/agencies/${id}`, null, ...extra)
});
export const insertAgency = (data, ...extra) => ({
  type: INSERT_AGENCY,
  payload: api.post(`/agencies`, data, ...extra)
});
export const updateAgency = (data, ...extra) => ({
  type: UPDATE_AGENCY,
  payload: api.put(`/agencies/${data.id}`, data, ...extra)
});
export const deleteAgency = (id, ...extra) => ({
  type: DELETE_AGENCY,
  payload: api.delete(`/agencies/${id}`, null, ...extra)
});
