import React from "react";
// import withStyles from "@material-ui/core/styles/withStyles";
import { hotlinesByCategory } from "actions/action_hotlines";
import { withStyles } from "@material-ui/core";
import { bindActionCreators } from "redux";
// import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import {
  Card,
  Grid,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  FormControlLabel,
} from "@material-ui/core";

import hotlinesStyle from "assets/jss/material-dashboard-pro-react/views/hotlinesStyle.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import PropTypes from "prop-types";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import HotlineCard from "./HotlineCards";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import api from "utils/api";
import Paginations from "components/Pagination/Pagination.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { getHotlineCategories } from "../../../actions/action_hotline_categories";

const intl = window.intl;

class Hotlines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      hotlinesdata: [],
      questionData: {},
      featuresData: [],
      hotlineFilters: [],
      categoryName: "",
    };
  }

  componentDidMount() {
    this.searchHotlines();
    this.getFeatures();
    let request = this.props.getHotlineCategories();
    request.then((ele) => {
      if (this.props.match.params.id) {
        if (ele.value) {
          let found = ele.value.find(
            (e) => e.id === +this.props.match.params.id
          );
          if (found) {
            this.setState({ categoryName: found.name });
          }
        }
      }
    });
  }

  getFeatures = () => {
    api.get(
      `hotline/features`,
      (featuresData = []) => {
        let questionData = this.state.questionData;
        featuresData.forEach((feature, index) => {
          featuresData[index]["expanded"] = false;
          questionData[feature["id"]] = {};
          const questions = (feature.questions || []).filter((q) => q.active);
          questions.forEach((question) => {
            questionData[feature["id"]][question["id"]] = {
              flag: false,
              question: question["question"],
            };
          });
        });
        this.setState({ questionData, featuresData });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  searchHotlines = (pageNo = 1, filter = this.state.hotlineFilters) => {
    let data = { filters: filter, limit: "12" };
    this.props.hotlinesByCategory(this.props.match.params.id, pageNo, data);
  };

  handleChange = (panel) => () => {
    let { featuresData } = this.state;
    featuresData[panel]["expanded"] = !featuresData[panel]["expanded"];
    this.setState({ featuresData });
  };

  handleCheckBoxSelect = (filter_id, question_id) => {
    let { questionData, hotlineFilters } = this.state;
    let index = hotlineFilters.indexOf(question_id);
    if (index === -1) {
      hotlineFilters.push(question_id);
    } else {
      hotlineFilters.splice(index, 1);
    }
    questionData[filter_id][question_id]["flag"] = !questionData[filter_id][
      question_id
    ]["flag"];
    this.setState({ questionData, hotlineFilters });
    this.searchHotlines(undefined, hotlineFilters);
  };

  getEnteredPage = (evt) => {
    this.searchHotlines(evt);
    let html = document.getElementsByTagName("html");
    let body = document.getElementsByTagName("body");
    if (body && body[0]) {
      body[0].scrollTop = 0;
    }
    if (html && html[0]) {
      html[0].scrollTop = 0;
    }
  }

  getPagination = () => {
    const { hotlines = {} } = this.props;
    const { last_page = 1, current_page = 1 } = hotlines;
    if (last_page <= 1) {
      return null;
    }
    let pages = Array(last_page)
      .fill(0)
      .map((e, i) => {
        return {
          onClick: this.gotoPage,
          text: i + 1,
          active: current_page === i + 1,
        };
      });
    pages.unshift({
      text: intl.formatMessage({ id: "prev" }),
      onClick: this.gotoPrevPage,
    });
    pages.push({
      text: intl.formatMessage({ id: "next" }),
      onClick: this.gotoNextPage,
    });

    pages.push({
      text: intl.formatMessage({ id: `${current_page} of ${last_page}` }),
    });

    return <Paginations pages={pages} color="info" currentPage={current_page} lastPage={last_page} gotoEnteredPage={this.getEnteredPage} />;
  };

  gotoPage = (evt) => {
    this.searchHotlines(evt.target.innerText);
  };

  gotoNextPage = () => {
    const { hotlines = {} } = this.props;
    const { last_page = 1, current_page = 1 } = hotlines;
    if (current_page < last_page) {
      this.searchHotlines(current_page + 1);
    }
  };
  gotoPrevPage = () => {
    const { hotlines = {} } = this.props;
    const { current_page = 1 } = hotlines;
    if (current_page > 1) {
      this.searchHotlines(current_page - 1);
    }
  };

  filterColumn = () => {
    const { classes } = this.props;
    let { featuresData, questionData } = this.state;
    if (featuresData && featuresData.length > 0) {
      return (
        <Grid className={classes.filterBox} item xs={12} sm={12} md={3}>
          <Typography
            variant="subtitle1"
            className={classes.heading}
            gutterBottom
          >
            {"FILTER BY"}
          </Typography>

          {featuresData.map((ele, index) => {
            return (
              <ExpansionPanel
                key={index}
                expanded={ele["expanded"]}
                onChange={this.handleChange(index)}
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    <b> {ele["name"]} </b>
                  </Typography>
                </ExpansionPanelSummary>
                {ele["questions"] && ele["questions"].length > 0 && (
                  <ExpansionPanelDetails
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {ele["questions"].map((question, index2) => {
                      return (
                        <FormControlLabel
                          key={question.id}
                          control={
                            <Checkbox
                              style={{
                                color: "#3d97d3",
                              }}
                              id={String(question.id)}
                              checked={
                                questionData[ele["id"]][question["id"]]["flag"]
                              }
                              value={String(question.id)}
                              onChange={() =>
                                this.handleCheckBoxSelect(
                                  ele["id"],
                                  question["id"]
                                )
                              }
                              key={index2}
                              classes={{
                                root: classes.checkbox,
                                checked: classes.checkboxChecked,
                              }}
                            />
                          }
                          label={question.question}
                          className={classes.formControlLabel}
                        />
                      );
                    })}
                  </ExpansionPanelDetails>
                )}
              </ExpansionPanel>
            );
          })}
        </Grid>
      );
    }
  };

  render() {
    const { classes, hotlines = {} } = this.props;
    const { search, categoryName } = this.state;
    const { data = {}, error = false, loading = false, total } = hotlines;
    if (loading) {
      return (
        <Grid item md={12} align="center">
          <Typography variant="subtitle1" gutterBottom align="center">
            Loading Hotlines... Please wait...
          </Typography>
        </Grid>
      );
    }
    //const baseURL = (process.env.NODE_ENV !== 'production') ? `http://localhost:8000/api/v1` : `/api/v1`;
    return (
      <Grid container spacing={24} className={classes.hotlineViewContainer}>
        {!loading && (
          <Grid
            item
            xs={12}
            className={classes.mainGrid}
            container
            md={12}
            s={12}
          >
            {categoryName !== "" && (
              <Grid item xs={12} sm={12} md={12}>
                <Card className={classes.categoryBanner}>
                  <Typography
                    variant="h4"
                    align="center"
                    className={classes.categoryName}
                  >
                    {categoryName}
                  </Typography>
                </Card>
              </Grid>
            )}

            <Grid item xs={12} md={12} sm={12}>
              {/* Error */}
              {error && (
                <SnackbarContent
                  message={intl.formatMessage({
                    id: "view.hotlines.searchError",
                  })}
                  color="danger"
                />
              )}
              {/* No agencies found */}
              <Typography variant="subtitle1" gutterBottom align="center">
                {!error &&
                  data &&
                  data.length === 0 &&
                  (search.length > 0
                    ? intl.formatMessage({
                      id: "view.hotlines.noResultsFound",
                    })
                    : intl.formatMessage({
                      id: "view.hotlines.noHotlinesAvailable",
                    }))}
              </Typography>
            </Grid>
            {/* Found agencies */}
            {!error && (
              <Grid container spacing={8}>
                {this.filterColumn()}
                {data.length > 0 && (
                  <Grid style={{ paddingTop: "15px" }} item xs={12} sm={12} md={9}>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={classes.resultAndTime}
                    >
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          style={{ color: "#233750", "textTransform": "none" }}
                          variant="subtitle2"
                        >
                          {total >= 12
                            ?
                            `Showing ${(hotlines.to - hotlines.from) + 1} hotline services of ${total} available`
                            // data.length + " Result Available"
                            : `Showing ${total} hotline services of ${total} available`}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={4}
                        style={{ color: "#808080" }}
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        className={classes.openClose}
                      >
                        <Grid item style={{ color: "#233750" }}>
                          <FiberManualRecordIcon style={{ marginTop: "5px" }} />
                        </Grid>
                        <Grid item>
                          <Typography variant="body1">{"Open"}</Typography>
                        </Grid>

                        <Grid item>
                          <FiberManualRecordIcon style={{ marginTop: "5px" }} />
                        </Grid>
                        <Grid item>
                          <Typography variant="body1">{"Closed"}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      // style={{ padding: "15px", rowGap: 15, margin: 0 }}
                      container
                      direction="row"
                      // justify="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                      item
                      spacing={16}
                      className={classes.cardsGrid}
                    >
                      {data.map((hotline, index) => {
                        return <HotlineCard key={index} hotline={hotline} />;
                      })}
                    </Grid>
                    <GridItem xs={12} style={{ textAlign: "center" }}>
                      {this.getPagination()}
                    </GridItem>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    );
  }
}

Hotlines.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    hotlines: state.hotlines,
    hotlineFeatures: state.featuresData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hotlinesByCategory: bindActionCreators(hotlinesByCategory, dispatch),
    getHotlineCategories: bindActionCreators(getHotlineCategories, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(hotlinesStyle)(Hotlines));
