//Components
import Dashboard from "views/Dashboard/Dashboard.jsx";
import ResetPassword from "views/Pages/ResetPassword.jsx";
// import ServicesPage from "views/Pages/ServicesPage.jsx";
import ServiceEditPage from "views/Pages/ServiceEditPage.jsx";
import AgenciesPage from "views/Pages/AgenciesPage.jsx";
import CategoriesPage from "views/Pages/CategoriesPage.jsx";
import OutOfDateServices from "views/Pages/OutOfDateServices.jsx";
import unRegisteredServices from "views/Pages/unRegisteredServices.jsx";
import newsPage from "views/Pages/NewsPage.jsx";
import CategoryEditPage from "views/Pages/CategoryEditPage.jsx";
import CategoryViewPage from "views/Pages/CategoryViewPage.jsx";
// import FeaturesPage from "views/Pages/FeaturesPage.jsx";
import FeatureEditPage from "views/Pages/FeatureEditPage.jsx";
import ServiceTypeEditPage from "views/Pages/ServiceTypeEditPage.jsx";
import FeatureViewPage from "views/Pages/FeatureViewPage.jsx";
import QuestionEditPage from "views/Pages/QuestionEditPage.jsx";
import SlotEditPage from "views/Pages/SlotEditPage.jsx";
import SlotViewPage from "views/Pages/SlotViewPage";
import TimeInfoEditPage from "views/Pages/TimeInfoEditPage.jsx";
import AgencyEditPage from "views/Pages/AgencyEditPage.jsx";
import AgencyViewPage from "views/Pages/AgencyViewPage.jsx";
import UsersPage from "views/Pages/UsersPage.jsx";
import Hotlines from "views/Pages/Hotlines/Hotlines.jsx";
import EditHotline from "views/Pages/Hotlines/EditHotline";
import UsersEditPage from "views/Pages/UsersEditPage.jsx";
import FrequentlyUsedHotlines from "views/Pages/Hotlines/FrequentlyUsedHotlines.jsx";
//Icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import AgencyIcon from "@material-ui/icons/AccountBalance";
import ServiceIcon from "@material-ui/icons/EnhancedEncryption";
import CategoryIcon from "@material-ui/icons/Category";
import FeaturesIcon from "@material-ui/icons/LocalActivity";
import UsersIcon from "@material-ui/icons/People";
import TimeInfoIcon from "@material-ui/icons/Schedule";
import ServiceTypeIcon from "@material-ui/icons/Ballot";
import ResetPasswordIcon from "@material-ui/icons/LockOpen";
import SortIcon from "@material-ui/icons/FormatListNumberedRtlOutlined"
import Cancelled from '@material-ui/icons/CancelOutlined';
import EventBusy from "@material-ui/icons/EventBusy";
import QuestionIcon from "@material-ui/icons/CheckBox";
import EventIcon from '@material-ui/icons/Event';

var routes = [
  {
    path: "/dashboard",
    name: "routes.dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
    requiredRole: ["admin"]
  },

  // {
  //   path: "/services",
  //   name: "services",
  //   icon: ServiceIcon,
  //   component: ServicesPage,
  //   layout: "/admin",
  //   requiredRole: ["admin", "adgencyAdmin", "serviceAdmin"]
  // },
  {
    path: "/edit-service/:serviceID?",
    name: "services",
    hidden: true,
    icon: ServiceIcon,
    component: ServiceEditPage,
    layout: "/admin",
    requiredRole: ["admin", "agencyadmin", "serviceadmin"]
  },
  {
    path: "/agencies",
    name: "agencies",
    icon: AgencyIcon,
    component: AgenciesPage,
    layout: "/admin",
    requiredRole: ["admin", "agencyadmin", "serviceadmin"]
  },
  {
    path: "/agency/:agencyID?",
    name: "agencies",
    hidden: true,
    icon: AgencyIcon,
    component: AgencyViewPage,
    layout: "/admin",
    requiredRole: ["admin", "agencyadmin"]
  },
  {
    path: "/edit-agency/:agencyID?",
    name: "agencies",
    hidden: true,
    icon: AgencyIcon,
    component: AgencyEditPage,
    layout: "/admin",
    requiredRole: ["admin", "agencyadmin"]
  },
  {
    path: "/categories",
    name: "categories",
    icon: CategoryIcon,
    component: CategoriesPage,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  {
    path: "/categories/:categoryID?",
    name: "category",
    hidden: true,
    icon: CategoryIcon,
    component: CategoryViewPage,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  {
    path: "/edit-category/:categoryID?",
    name: "categories",
    hidden: true,
    icon: CategoryIcon,
    component: CategoryEditPage,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  // {
  //   path: "/features",
  //   name: "features",
  //   icon: FeaturesIcon,
  //   component: FeaturesPage,
  //   layout: "/admin",
  //   requiredRole: ["admin"]
  // },
  {
    path: "/features/:featureID?",
    name: "features",
    icon: FeaturesIcon,
    hidden: true,
    component: FeatureViewPage,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  {
    path: "/edit-feature/:featureID?",
    name: "features",
    hidden: true,
    icon: FeaturesIcon,
    component: FeatureEditPage,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  {
    path: "/edit-service-type/:serviceTypeID?",
    name: "features",
    hidden: true,
    icon: ServiceTypeIcon,
    component: ServiceTypeEditPage,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  {
    path: "/edit-question/:questionID?",
    name: "question",
    hidden: true,
    icon: QuestionIcon,
    component: QuestionEditPage,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  {
    path: "/edit-slot/:slotID?",
    name: "slots",
    hidden: true,
    icon: FeaturesIcon,
    component: SlotEditPage,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  {
    path: "/view-slot/:slotID?",
    name: "slots",
    hidden: true,
    icon: FeaturesIcon,
    component: SlotViewPage,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  // {
  //   path: "/time-info",
  //   name: "timeInfos",
  //   icon: TimeInfoIcon,
  //   component: Dashboard,
  //   layout: "/admin",
  //   requiredRole: ["admin"]
  // },
  {
    path: "/edit-time-info/:timeInfoID?",
    name: "timeInfos",
    hidden: true,
    icon: TimeInfoIcon,
    component: TimeInfoEditPage,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  {
    path: "/hotlines",
    name: "hotlines",
    icon: UsersIcon,
    component: Hotlines,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  {
    path: "/edit-hotline/:hotlineID?",
    name: "hotlines",
    hidden: true,
    component: EditHotline,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  /*added for hotlines view*/
  {
    path: "/hotline/:hotlineID?",
    name: "hotlines",
    hidden: true,
    component: EditHotline,
    layout: "/admin",
    requiredRole: ["admin"]
  },

  /*{
    path: "/hotlinecategories",
    name: "hotlinecategories",
    hidden: true,
    component: HotlineCategories,
    layout: "/admin",
    requiredRole: ["admin"]
  },*/
  {
    path: "/users",
    name: "users",
    icon: UsersIcon,
    component: UsersPage,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  {
    path: "/changePassword",
    name: "changePassword",
    icon: ResetPasswordIcon,
    component: ResetPassword,
    layout: "/admin",
    requiredRole: ["admin", "agencyadmin", "serviceadmin"]
  },
  {
    path: "/sort_hotlines",
    name: "Sort Hotlines",
    icon: SortIcon,
    component: FrequentlyUsedHotlines,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  {
    path: "/edit-user/:userID?",
    name: "users",
    icon: UsersIcon,
    hidden: true,
    component: UsersEditPage,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  {
    path: "/outOfDateServices",
    name: "Out of Date Services",
    icon: EventBusy,
    component: OutOfDateServices,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  {
    path: "/unRegisteredServices",
    name: "Unregistered Services",
    icon: Cancelled,
    component: unRegisteredServices,
    layout: "/admin",
    requiredRole: ["admin"]
  },
  {
    path: "/news",
    name: "News & Events",
    icon: EventIcon,
    component: newsPage,
    layout: "/admin",
    requiredRole: ["admin"]
  },
]
export default routes;