import axios from "axios";
import createAuthRefreshInterceptor from "./axiosInterceptor";

const baseURL = (process.env.NODE_ENV !== 'production') ? `http://localhost:8000/api/v1` : `/api/v1`
const instance = axios.create({
  baseURL: baseURL
});

const refreshAuthLogic = failedRequest =>
  instance
    .post("/auth/refresh", {
      refreshToken: localStorage.getItem("drughelp-refresh-token")
    })
    .then(tokenRefreshResponse => {
      console.log(tokenRefreshResponse)
      localStorage.setItem("drughelp-token", tokenRefreshResponse.token);
      localStorage.setItem(
        "drughelp-refresh-token",
        tokenRefreshResponse["refresh-token"]
      );
      failedRequest.response.config.headers["Authentication"] =
        "Bearer " + tokenRefreshResponse.data.token;
      return Promise.resolve();
    });

createAuthRefreshInterceptor(instance, refreshAuthLogic);

instance.interceptors.request.use(
  reqConfig => {
    reqConfig.headers.authorization =
      "Bearer " + localStorage.getItem("drughelp-token");
    return reqConfig;
  },
  err => Promise.reject(err)
);

instance.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response.data;
  },
  function (error = {}) {
    // Do something with response error
    return Promise.reject(error.data);
  }
);

const get = (url, successCallback, errorCallback) => {
  return instance
    .get(url)
    .then(data => {
      successCallback && successCallback(data);
      return Promise.resolve(data);
    })
    .catch(error => {
      errorCallback && errorCallback(error);
      return Promise.reject(error);
    });
};
const post = (url, data, successCallback, errorCallback) => {
  return instance
    .post(url, data)
    .then(data => {
      successCallback && successCallback(data);
      return Promise.resolve(data);
    })
    .catch(error => {
      errorCallback && errorCallback(error);
      return Promise.reject(error);
    });
};
const put = (url, data, successCallback, errorCallback) => {
  return instance
    .put(url, data)
    .then(data => {
      successCallback && successCallback(data);
      return Promise.resolve(data);
    })
    .catch(error => {
      errorCallback && errorCallback(error);
      return Promise.reject(error);
    });
};
const deleteApi = (url, data, successCallback, errorCallback) => {
  return instance
    .delete(url, data)
    .then(data => {
      successCallback && successCallback(data);
      return Promise.resolve(data);
    })
    .catch(error => {
      errorCallback && errorCallback(error);
      return Promise.reject(error);
    });
};
const imageUpload = (id, data, successCallback, errorCallback) => {
  return instance
    .post(`/assets/imageUpload/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(data => {
      successCallback && successCallback(data);
      return Promise.resolve(data);
    })
    .catch(error => {
      errorCallback && errorCallback(error);
      return Promise.reject(error);
    });
};
const api = {
  get,
  post,
  put,
  delete: deleteApi,
  imageUpload
};
export default api;
export { instance };
