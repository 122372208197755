import api from "../utils/api";
import {
  GET_FEATURE,
  INSERT_FEATURE,
  UPDATE_FEATURE,
  DELETE_FEATURE
} from "../utils/types";

export const getFeature = (id, ...extra) => ({
  type: GET_FEATURE,
  payload: api.get(`/features/${id}`, null, ...extra)
});
export const insertFeature = (data, ...extra) => ({
  type: INSERT_FEATURE,
  payload: api.post(`/features`, data, ...extra)
});
export const updateFeature = (data, ...extra) => ({
  type: UPDATE_FEATURE,
  payload: api.put(`/features/${data.id}`, data, ...extra)
});
export const deleteFeature = (id, ...extra) => ({
  type: DELETE_FEATURE,
  payload: api.delete(`/features/${id}`, null, ...extra)
});
