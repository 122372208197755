import {GET_SERVICES, FULFILLED, REJECTED, PENDING, GET_PUBLIC_SERVICES} from '../utils/types';
const initialState = {
    data: [],
    error: false,
    servicesLoading: false
};

export default function servicesReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case `${GET_SERVICES+PENDING}`:
            return {...state, error: false, servicesLoading: true};
        case `${GET_SERVICES+FULFILLED}`:
            return {...state, ...payload, error: false, servicesLoading: false};
        case `${GET_SERVICES+REJECTED}`:
            return {...state, error: true, servicesLoading: false, data:[]};
            case `${GET_PUBLIC_SERVICES+PENDING}`:
                return {...state, error: false, servicesLoading: true};
            case `${GET_PUBLIC_SERVICES+FULFILLED}`:
                return {...state, data:payload, error: false, servicesLoading: false};
            case `${GET_PUBLIC_SERVICES+REJECTED}`:
                return {...state, error: true, servicesLoading: false, data:[]};
        default:
            return state;
    }
}