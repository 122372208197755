import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import {
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  TextField,
} from '@material-ui/core';

import withStyles from "@material-ui/core/styles/withStyles";
import serviceStyles from "assets/jss/material-dashboard-pro-react/views/serviceStyles.jsx";

import Card from "components/Card/Card.jsx";
import Paginations from "components/Pagination/Pagination.jsx";

import { injectIntl } from "react-intl";
import { withSnackbar } from "notistack";
import Button from "components/CustomButtons/Button.jsx";
import api from "../../utils/api";

const intl = window.intl;
export class ResetPassword extends Component {
  constructor(props) {

    super(props);

    this.state = {

      oldPassword: "",
      password: "",
      confirmPassword: "",


      id: "",
      first_name: "",
      last_name: "",
      email: "",
      lang: "en",
      roles: "service_admin",
      active: false,
      phone_number: "",
      user: {},
      notFound: true

    };
  }
  componentDidMount() {
    const user_id = localStorage.getItem("UserId")

    const { match = {} } = this.props;
    const { params = {} } = match;
    const { userID = user_id } = params;
    this.setState({ id: userID });

    if (userID) {
      this.getUser(userID);
    }

  }

  getUser = id => {
    api.get(
      `/users/${id}`,
      (user = {}) => {

        const {
          id,
          first_name,
          last_name,
          email,
          phone_number,
          roles,
          active
        } = user;
        this.setState({
          id,
          first_name,
          last_name,
          email,
          phone_number,
          roles,
          active,
          user,
          notFound: !id,
          userLoading: false
        });

      },
      error => {
        console.log(error)
        this.setState({ user: {}, notFound: true, userLoading: false });

      }
    );
  };






  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };


  searchUsers = (pageNo = 1) => {
    api.get(
      `/users?nameLike=${this.state.search}&page=${pageNo}&limit=${9}`,
      data => {
        this.setState({ users: data, usersLoading: false });
      },
      error => {
        this.setState({ users: {}, usersLoading: false });
        console.error(error);
      }
    );
  };

  componentWillUnmount() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
  }

  getEnteredPage = (evt) => {
    this.searchUsers(evt);
    let html = document.getElementsByTagName("html");
    let body = document.getElementsByTagName("body");
    if (body && body[0]) {
      body[0].scrollTop = 0;
    }
    if (html && html[0]) {
      html[0].scrollTop = 0;
    }
  }

  getPagination = () => {
    const { users = {} } = this.state;
    const { last_page = 1, current_page = 1 } = users;
    if (last_page <= 1) {
      return null;
    }
    let pages = Array(last_page)
      .fill(0)
      .map((e, i) => {
        return {
          onClick: this.gotoPage,
          text: i + 1,
          active: current_page === i + 1
        };
      });
    pages.unshift({
      text: intl.formatMessage({ id: "prev" }),
      onClick: this.gotoPrevPage
    });
    pages.push({
      text: intl.formatMessage({ id: "next" }),
      onClick: this.gotoNextPage
    });

    pages.push({
      text: intl.formatMessage({ id: `${current_page} of ${last_page}` }),
    });

    return <Paginations pages={pages} color="info" currentPage={current_page} lastPage={last_page} gotoEnteredPage={this.getEnteredPage} />;
  };

  gotoPage = evt => {
    this.searchUsers(evt.target.innerText);
  };

  gotoNextPage = () => {
    const { users = {} } = this.state;
    const { last_page = 1, current_page = 1 } = users;
    if (current_page < last_page) {
      this.searchUsers(current_page + 1);
    }
  };
  gotoPrevPage = () => {
    const { users = {} } = this.state;
    const { current_page = 1 } = users;
    if (current_page > 1) {
      this.searchUsers(current_page - 1);
    }
  };


  changePassword = () => {
    api.post(
      `/auth/changePassword`,

      {
        email: this.state.email,
        old_password: this.state.oldPassword,
        new_password: this.state.confirmPassword,

      },
      ({ message }) => {
        this.props.enqueueSnackbar(message, {
          variant: "success"
        });
      },
      error => {
        this.props.enqueueSnackbar("Email or password is wrong.", {
          variant: "error"
        });
      }
    );

  };


  validateForm() {
    return (
      this.state.oldPassword.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword

    );
  }



  render() {


    return (

      <div style={{ marginLeft: 350 }}>
        <Grid
          justify="center"
          item
          md={7}
          xs={12}
        >
          <Card>
            <form>

              <CardHeader
                subheader="Update password"
                title="Password"
              />
              <Divider />
              <CardContent>

                <TextField
                  fullWidth
                  placeholder="Password"
                  name="oldPassword"
                  id="oldPassword"
                  type="password"
                  variant="outlined"

                  onChange={this.handleChange}
                  value={this.state.oldPassword}

                />

                <TextField
                  fullWidth
                  placeholder="New Password"
                  name="password"
                  id="password"
                  style={{ marginTop: '1rem' }}
                  type="password"

                  variant="outlined"

                  onChange={this.handleChange}
                  value={this.state.password}
                />
                <TextField
                  fullWidth
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  style={{ marginTop: '1rem' }}
                  type="password"
                  id="confirmPassword"

                  variant="outlined"

                  onChange={this.handleChange}
                  value={this.state.confirmPassword}
                />

              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={!this.validateForm()}
                  onClick={this.changePassword}

                >

                  Update
                </Button>
              </CardActions>

            </form>
          </Card>
        </Grid>
      </div>
    );
  }
}



export default injectIntl(
  withStyles(serviceStyles, { withTheme: true })(withSnackbar(ResetPassword))
);






