const quickSearchStyle = (theme) => ({
  quickSearchButton: {
    backgroundColor: "#d9552c",
    color: "#F4F7FC",
    padding: "15px",
    "@media only screen and (max-width: 600px)": {
      padding: "10px",
      fontSize: "0.675rem",
    },
  },
  closeIcon: {
    color: "#605A5A",
    marginTop: "10px",
    marginRight: "10px"
  },
  mobileStepper: {
    justifyContent: "center",
    backgroundColor: "white",
  },
  mobileStepperActive: {
    backgroundColor: "#605A5A",
  },
  questionContainer: {
    marginBottom: "calc(1.5vw)",
  },
  titleContainer: {
    marginBottom: "calc(5px + 0.6vw)",
  },
  title: {
    fontFamily: "Nunito",
    fontWeight: "bold",
    textAlign: "center",
    color: "#233750",
  },
  question: {
    fontFamily: "Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif",
    fontSize: "calc(16px + 0.4vw)",
    fontWeight: "bold",
    color: "black",
    paddingBottom: "calc(10px + 1vw)",
    paddingTop: "calc(5px + 0.4vw)",
  },
  multiAnswerQuestion: {
    fontFamily: "Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif",
    fontSize: "calc(16px + 0.4vw)",
    fontWeight: "bold",
    color: "black",
    paddingBottom: "calc(1px + 0.3vw)",
    paddingTop: "calc(5px + 0.4vw)",
  },
  questionHint: {
    fontFamily: "Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif",
    fontSize: "calc(16px + 0.4vw)",
    color: "black",
    paddingBottom: "calc(1px + 1vw)",
  },
  questionFocused: {
    color: "black !important",
  },
  formControlLabel: {
    marginTop: "-10px",
  },
  radio: {
    "&$radioChecked": {
      color: "#605A5A",
    },
  },
  // don't remove this empty class, it is necessary
  // and matches with '&$radioChecked'
  radioChecked: {},
  checkbox: {
    "&$checkboxChecked": {
      color: "#605A5A",
    },
  },
  // don't remove this empty class, it is necessary
  // and matches with '&$radioChecked'
  checkboxChecked: {},
  desclaimerText: {
    marginBottom: "calc(5px + 2vw)",
  },
  buttonsGrid: {
    justifyContent: "flex-end",
    "@media (max-width: 768px)": {
      justifyContent: "space-evenly",
    },
  },
  nextButton: {
    backgroundColor: "#D9552C",
    color: "#F4F7FC",
    marginLeft: "15px",
    "@media (max-width: 768px)": {
      margin: "0px",
    },
    "&:hover":{
      backgroundColor: "#3d97d3",
    }
  },
  backButton: {
    backgroundColor: "#D9552C",
    color: "#F4F7FC",
    margin: "0 15px 0 15px",
    "@media (max-width: 768px)": {
      margin: "0px",
    },
    "&:hover":{
      backgroundColor: "#3d97d3",
    }
  },
  audioButton: {
    color: "#87CEEB",
    fontSize: "30px",
    margin: "0 15px 0 15px",
    "@media (max-width: 768px)": {
      margin: "0px",
    },
  },
  mainButton: {
    width: "200px",
    height: "45px",
    backgroundColor: "#D9552C",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#808080",
    },
  },
  // color for the quicksearch progress bar in Home page 
  colorPrimary: {
    backgroundColor: "#3d97d3",
  },
  barColorPrimary: {
    backgroundColor: "#233750",
  },
});

export default quickSearchStyle;
