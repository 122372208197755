import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPublicCategories } from "../../actions/action_categories";
import { bindActionCreators } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import {
  insertSlotInfo,
  updateSlotInfo,
  deleteSlotInfo,
} from "../../actions/action_slotInfo";
import { getSlotInfoByService } from "../../actions/action_slotInfo";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import PopupView from "./PopupView";

import api from "../../utils/api";
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import { Link } from "react-router-dom";
const intl = window.intl;
var hideServiceBox = {
  display: 'none'
};

class SlotViewPublicPage extends React.Component {

  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    this.state = {
      serviceID: props.match.params.serviceID,
      categoryID: props.match.params.categoryID,
      slotName: "",
      availability: "",
      wait_time: "",
      capacity: "",
      category: params.get("category"),
      hideCategory: params.has("category"),
      service: params.get("service"),
      hideService: params.has("service"),
      categories: [],
      slotInfos: [],
      services: [],
      active: true,
      slotNotFound: false,
      error: {},
      mandatory: { slotName: true, availability: true, capacity: true },
      modified: false,
      deleteId: "",
      deleteName: ""
    };
  }



  getSlotInfoForService = async () => {
    const { serviceID } = this.state;
    //   serviceID: props.match.params.serviceID,
    //   debugger;
    this.setState({ loadingSlotInfos: true });
    api.get(
      `/services/${serviceID}/slotInfos`,
      data => {
        let slotInfoData = this.props.data || {};
        this.setState({
          loadingTimeInfos: false,
          slotInfos: data,
          slotInfoData
        });
      },
      () => {
        this.setState({ loadingslotInfos: false, slotInfos: [] });
      }
    );
  };


  componentDidMount() {
    this.getSlotInfoForService();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { category, service } = nextProps;
    const { data = {} } = category && service || {};

    if (data.slotName !== this.state.slotName) {
      const {
        slotName,
        availability,
        wait_time,
        capacity,
        allow_attachments,
        allow_contact_persons
      } = data;
      this.setState({
        slotName,
        availability,
        wait_time,
        capacity,
        allow_attachments,
        allow_contact_persons
      });
    }
  }
  validateField = (slotName, value) => {
    const { error, mandatory } = this.state;
    if (mandatory[slotName]) {
      error[slotName] = value === undefined || value.length === 0;
    }
    this.setState({ error });
    return !error[slotName];
  };

  getConfirmPopUp = (message) => {
    return (
      <PopupView
        openPopup={this.state.openPopup}
        callbackFun={this.callbackFun}
        updateProps={this.updateProps}
        message={message}
      // icon = { <CategoryIcon/> }
      />
    );
  };

  updateProps = (name, value) => {
    this.setState({ [name]: value });
  }

  callbackFun = () => {
    this.deleteSlotInfo(this.state.deleteId, this.state.deleteName);
  }

  callDeletePopup = (id, name) => {
    this.setState({
      deleteName: name,
      deleteId: id,
      openPopup: true,
    });
  }

  deleteSlotInfo = (id, slotName) => {
    // if (!window.confirm("Are you sure you want to delete '" + slotName + "'")) {
    //   return false;
    // }
    this.props.deleteSlotInfo(
      id,
      () => {
        // this.props.enqueueSnackbar("Slot deleted successfully", {
        //   variant: "success"
        // });
        this.getSlotInfoForService();
      },
      () => {
        this.props.enqueueSnackbar("Unable to delete '" + slotName + "'", {
          variant: "error"
        });
        const { serviceID } = this.state;
        serviceID && this.getSlotInfoForService(serviceID);
      }
    );
  };
  handleSwitchChange = slotName => event => {
    this.setState({ [slotName]: event.target.checked, modified: true });
  };
  handleTextChange = slotName => event => {
    this.validateField(slotName, event.target.value);
    this.setState({ [slotName]: event.target.value, modified: true });
  };


  onSubmit = () => {
    const {
      serviceID,
      slotName,
      capacity,
      availability,
      wait_time,
      category,
      service,
      active,
      modified,
      error = {}
    } = this.state;
    let valid = true;
    if (!modified) {
      this.props.enqueueSnackbar("No information is updated", {
        variant: "info"
      });
      return false;
    } else {
      valid = Object.keys(error).every(name => {
        return !error[name];
      });
    }
    if (!valid) {
      this.props.enqueueSnackbar("Please provide all required details", {
        variant: "error"
      });
      return false;
    }
    let data = {
      slotName,
      capacity,
      availability,
      wait_time,
      category,
      service,
      active
    };


    if (serviceID) {
      data.id = serviceID;
      this.props.updateSlotInfo(data, () => {
        this.props.enqueueSnackbar("SlotInfo updated successfully", {
          variant: "success"
        });
        this.props.history.goBack();
      });
    } else {
      this.props.insertSlotInfo(data, () => {
        this.props.enqueueSnackbar("SlotInfo saved successfully", {
          variant: "success"
        });
        this.props.history.goBack();
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      serviceID,
      categoryID,
      slotName,
      capacity,
      availability,
      wait_time,
      slotInfos = [],
      slotInfoNotFound,
      deleteName,
      openPopup
    } = this.state;
    return (
      <div className={classes.container} style={{ marginTop: '100px' }}>
        {serviceID && slotInfoNotFound ? (
          <Card>
            <CardBody>
              <Typography variant="subtitle1" gutterBottom align="center">
                {intl.formatMessage({
                  id: "view.slotInfoEdit.slotInfoNotFound"
                })}{" "}
                <Link to={`/admin/view-slot/${serviceID}`}>
                  {/* <Link   to={`/admin/view-slot/service=${service}&category=${category}`}> */}
                  {intl.formatMessage({
                    id: "createNew"
                  })}
                </Link>
              </Typography>
            </CardBody>
          </Card>
        ) : (
          <GridContainer
            container
            spacing={24}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <GridItem xs={12} sm={12} md={10}>


              <Paper component="div" style={{ padding: 8 * 3 }}>

                <Slide in direction="left">
                  <div>
                    <div style={hideServiceBox}>
                      <Link
                        to={`/auth/edit-slot-public/${serviceID}/${categoryID}`}

                      //   to={`/admin/edit-slot?service=${service}&category=${category}`}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.marginRight}
                        >

                          <AddIcon className={classes.icons} /> Add Slot
                        </Button>
                      </Link>
                    </div>
                    <div>

                      {/* ----------------------------------- */}
                      <Table
                        className={classes.table}
                        style={{ tableLayout: "fixed" }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell >Slot Name {slotName}</TableCell>
                            <TableCell smup="true">Capacity {capacity}</TableCell>
                            <TableCell smup="true">Availability {availability}</TableCell>
                            <TableCell smup="true">Wait Time {wait_time}</TableCell>
                            <TableCell align="center">Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {slotInfos.length > 0 ? (
                            (slotInfos).map((slotInfos = {}) => {
                              return (
                                <TableRow key={slotInfos.id}>
                                  <TableCell component="td" scope="row">
                                    {slotInfos.slotName}
                                  </TableCell>
                                  <TableCell component="td" scope="row">
                                    {slotInfos.capacity}
                                  </TableCell>
                                  <TableCell component="td" scope="row">
                                    {slotInfos.availability}
                                  </TableCell>
                                  <TableCell component="td" scope="row">
                                    {slotInfos.wait_time}
                                  </TableCell>

                                  <TableCell
                                    component="td"
                                    scope="row"
                                    align="center"
                                  >
                                    <Tooltip
                                      title="Edit"
                                      placement="bottom"
                                      classes={{ tooltip: classes.tooltip }}
                                    >
                                      <Link
                                        to={`/auth/edit-slot-public/${serviceID
                                          }/${categoryID}/${slotInfos.id}`}
                                      >
                                        <Button size="sm" color="primary">
                                          Update
                                        </Button>

                                      </Link>
                                    </Tooltip>
                                    <Tooltip
                                      title="Delete"
                                      placement="bottom"
                                      classes={{ tooltip: classes.tooltip }} style={hideServiceBox}
                                    >
                                      <IconButton
                                        aria-label="Delete"
                                        className={classes.margin}
                                        onClick={() =>
                                          this.callDeletePopup(
                                            slotInfos.id,
                                            slotInfos.slotName
                                          )
                                        }
                                      >
                                        <DeleteIcon
                                          color="secondary"
                                          className={classes.margin}
                                        />
                                      </IconButton>
                                    </Tooltip>

                                  </TableCell>

                                </TableRow>

                              );
                            })
                          ) : (
                            <TableRow>
                              <TableCell
                                component="td"
                                scope="row"
                                align="center"
                                colSpan="3"
                              >
                                No Slot information available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>

                      </Table>

                    </div>
                  </div>
                </Slide>

              </Paper>

            </GridItem>
            <GridItem>
              {openPopup ? this.getConfirmPopUp("Are you sure you want to delete '" + deleteName + "' ?") : null}
            </GridItem>
          </GridContainer>
        )}
      </div>
    );
  }
}
SlotViewPublicPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    categories: state.categories
  };
};
const mapDispatchToProps = dispatch => {
  return {
    insertSlotInfo: bindActionCreators(insertSlotInfo, dispatch),
    updateSlotInfo: bindActionCreators(updateSlotInfo, dispatch),
    deleteSlotInfo: bindActionCreators(deleteSlotInfo, dispatch),
    getSlotInfoByService: bindActionCreators(getSlotInfoByService, dispatch),
    getPublicCategories: bindActionCreators(getPublicCategories, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(registerPageStyle)(SlotViewPublicPage));
