import React, { useState, useEffect } from 'react';
import {
    Grid,
    Paper,
    FormControlLabel,
    Switch,
    IconButton,
    Typography,
    withStyles,
    TextField,
    Button,
    Snackbar
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Create";
import api from '../../utils/api';
import DeleteIcon from "@material-ui/icons/Delete";

const styles = (theme) => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 1.5,
        paddingBottom: theme.spacing.unit * 1.5,
        margin: "10px 0px 10px 0px",
        width: "65%",
    },
});

function NewsForm() {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [sourceLink, setSourceLink] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [newsData, setNewsData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [news, setNews] = useState(null);
    const [curr_index, setcurr_index] = useState(null);
    const [updateMessage, setUpdateMessage] = useState(false);
    const [DeleteMessage, setDeleteMessage] = useState(false);
    const [isEvent, setIsEvent] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [imageFile, setImageFile] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (title && content && (isEvent ? imageFile : sourceLink)) {
            const formData = new FormData();
            formData.append("title", title);
            formData.append("content", content);
            if(isEvent){
                formData.append("image", imageFile, imageFile.name);
            } else {
                formData.append("sourceLink", sourceLink);
            }
            formData.append("event", isEvent ? 1 : 0);
            try {
                const res = await api.post("/news", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
            } catch (error) {
                console.error(error);
            }
            setTitle("");
            setContent("");
            setSourceLink("");
            if(isEvent){
                setIsEvent(false);
            }
            else{
                setIsEvent(false);
            }
            setUpdateMessage(false);
            setDeleteMessage(false);
            setOpenSnackbar(true);
            getNewsData();
        } else {
            alert("Enter all the details");
        }
    };

    const handleEventToggle = () => {
        setIsEvent(prevState => !prevState);
    };

    const handleImageUpload = (event) => {
        const selectedImage = event.target.files[0];
        setImageFile(selectedImage);
    };


    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    }

    let isMounted = true;

    const getNewsData = async () => {
        try {
            const res = await api.get(`getNewsData`);
            if (isMounted) {
                setNewsData(res);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getNewsData();
        return () => {
            isMounted = false;
        };
    }, []);

    const editNews = (index) => {
        const current_data = newsData[index];
        setcurr_index(current_data);
        setEdit(true);
        setNews(current_data.news_id);
        setTitle(current_data.title);
        setContent(current_data.Content);
        setSourceLink(current_data.news_link);
    }

    const editSubmission = () => {
        if (title || content || sourceLink !== "") {
            api.put(`updateNews`, { news_id: news, title: title, Content: content, news_link: sourceLink }).then(response => {
                setEdit(false);
                setTitle('');
                setContent('');
                setSourceLink("");
                setDeleteMessage(false);
                setUpdateMessage(true);
                setOpenSnackbar(true);
                getNewsData();
            }).catch(error => {
                console.error('Error updating news:', error);
            });
        }
        else {
            alert("Cannot be Updated,since all fields are empty")
            setTitle(curr_index.title);
            setContent(curr_index.Content);
            setSourceLink(curr_index.news_link);
        }
    }

    const deleteNews = (id) => {
        api.delete(`/deleteNews/${id}`, null, () => {
            setDeleteMessage(true);
            setUpdateMessage(false);
            setOpenSnackbar(true);
            getNewsData();
        }, () => {
            console.log("Error Delteing News")
        })
    }

    return (
        <>
            <Typography variant="h3" gutterBottom align="center" id="scroller">
                <strong>News/Events Information</strong>
            </Typography>
            <form
                onSubmit={handleSubmit}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: "800px",
                    margin: "50px auto",
                    padding: "20px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "10px",
                    boxShadow: "0px 3px 15px rgba(0,0,0,0.2)",
                }}
            >
                <TextField
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    variant="outlined"
                    style={{
                        width: "100%",
                        marginBottom: "20px",
                        backgroundColor: "white",
                        borderRadius: "5px",
                    }}
                    InputLabelProps={{
                        style: { color: "#3d97d3" },
                    }}
                    inputProps={{
                        style: { color: "black" },
                    }}
                />
                <TextField
                    label="Content"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    variant="outlined"
                    style={{
                        width: "100%",
                        marginBottom: "20px",
                        backgroundColor: "white",
                        borderRadius: "5px",
                    }}
                    InputLabelProps={{
                        style: { color: "#3d97d3" },
                    }}
                    inputProps={{
                        style: { color: "black" },
                    }}
                    multiline
                    rows={4}
                />
                {!isEvent && (
                    <TextField
                        label="Source Link"
                        value={sourceLink}
                        onChange={(e) => setSourceLink(e.target.value)}
                        variant="outlined"
                        style={{
                            width: "100%",
                            marginBottom: "20px",
                            backgroundColor: "white",
                            borderRadius: "5px",
                        }}
                        InputLabelProps={{
                            style: { color: "#3d97d3" },
                        }}
                        inputProps={{
                            style: { color: "black" },
                        }}
                    />
                )}
                <FormControlLabel
                    control={
                        <Switch
                            checked={isEvent}
                            onChange={handleEventToggle}
                            color="primary"
                        />
                    }
                    label="Is this about an event?"
                    labelPlacement="start"
                    style={{ marginBottom: "20px" }}
                />
                {isEvent && (
                    <div>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            style={{ marginBottom: "20px" }}
                        />
                        {imagePreviewUrl && (
                            <img
                                src={imagePreviewUrl}
                                alt="Event"
                                style={{ width: "100%" }}
                            />
                        )}
                    </div>
                )}
                {edit ? (
                    <Button
                        onClick={() => {
                            editSubmission();
                        }}
                        variant="contained"
                        style={{
                            backgroundColor: "#3d97d3",
                            color: "white",
                            borderRadius: "5px",
                            padding: "10px 20px",
                            fontSize: "16px",
                            cursor: "pointer",
                        }}
                    >
                        Update
                    </Button>
                ) : (
                    <Button
                        type="submit"
                        variant="contained"
                        style={{
                            backgroundColor: "#3d97d3",
                            color: "white",
                            borderRadius: "5px",
                            padding: "10px 20px",
                            fontSize: "16px",
                            cursor: "pointer",
                        }}
                    >
                        Submit
                    </Button>
                )}
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    message={
                        updateMessage
                            ? "News Updated Successfully"
                            : DeleteMessage
                                ? "News Deleted Successfully"
                                : "News saved successfully"
                    }
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                />
            </form>
            {newsData ? (
                newsData.map((newsData, index) => (
                    <Grid container spacing={16} direction="row" justify="center" alignItems="flex-start" style={{ marginTop: "50px" }} key={newsData.news_id}>
                        <Paper style={{ backgroundColor: "#fff", padding: "20px", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", borderRadius: "10px", maxWidth: "800px", width: "100%" }}>
                            <Grid container direction="row" spacing={16} alignItems="flex-start">
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: "10px" }}>
                                       { newsData.event ==0 ? "News Title" : "Event Title"}
                                    </Typography>
                                    <Typography style={{ fontSize: "18px", lineHeight: "28px", marginBottom: "20px" }}>
                                        {newsData.title}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: "10px" }}>
                                    { newsData.event ==0 ? "News Content" : "Event Content"}
                                    </Typography>
                                    <Typography style={{ fontSize: "18px", lineHeight: "28px", marginBottom: "20px" }}>
                                        {newsData.Content}
                                    </Typography>
                                    {
                                        newsData.event ? 
                                        <img
                                                alt={newsData.title}
                                                src={`/images/${newsData.image}`}
                                                style={{ width: "100%", height: "75%", objectFit: "contain" }}
                                         />
                                        :
                                        null
                                    }
                                </Grid>
                                <Grid container direction="row" justify="flex-end" alignItems="center">
                                    <IconButton color="primary" aria-label="Edit News" onClick={() => editNews(index)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton color="primary" aria-label="Delete News" onClick={() => deleteNews(newsData.news_id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                ))) :
                <Typography variant="h5" gutterBottom align="center" style={{ marginTop: "50px" }}>
                    {newsData.length === 0 ? "No News/Events Listed" : ""}
                </Typography>
            }
        </>
    );
}

export default withStyles(styles)(NewsForm);
