import React from "react";

import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core//Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from "@material-ui/core/InputLabel";

import withStyles from "@material-ui/core/styles/withStyles";

import { QUESTION_SWITCH, QUESTION_TEXT, QUESTION_LINK } from "../../utils/types";
const Question = props => {
  const { data = {}, onChange, classes, autoFocus=false, value } = props;
  const { id, question, type, placeholder, hint } = data;
  return (
    <React.Fragment>
      {!id ? (
        <Typography>Question not provided</Typography>
      ) : (
        <div>
          {type === QUESTION_SWITCH && (
            <React.Fragment>
              <FormControlLabel
                control={
                  <Switch
                  value={value||false}
                  checked={value||false}
                  onChange={evt => onChange && onChange(evt, id)}
                  color="primary"
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    icon: classes.switchIcon,
                    iconChecked: classes.switchIconChecked,
                    bar: classes.switchBar
                  }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label={question}
                />
              {hint && (
                <Tooltip
                style={{marginBottom: "-5px"}}
                title={hint}
                placement="top"
                classes={{ tooltip: classes.tooltip }}
                >
                  <InfoIcon  />
                </Tooltip>
              )}
            </React.Fragment>
          )}
          {type === QUESTION_LINK && (
            <React.Fragment>
              <FormControlLabel
                control={
                  <Switch
                  value={value||false}
                  checked={value||false}
                  onChange={evt => onChange && onChange(evt, id)}
                  color="primary"
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    icon: classes.switchIcon,
                    iconChecked: classes.switchIconChecked,
                    bar: classes.switchBar
                  }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label={question}
                />
              {hint && (
                <Tooltip
                style={{marginBottom: "-5px"}}
                title={hint}
                placement="top"
                classes={{ tooltip: classes.tooltip }}
                >
                  <InfoIcon  />
                </Tooltip>
              )}
            </React.Fragment>
          )}
          {type === QUESTION_TEXT && (
                <FormControl
                fullWidth={true}
              >
                <InputLabel
                          fullWidth={true}
                          htmlFor={`question-preview-${id}`}
                        >
                          {question}
                        </InputLabel>
                <Input
                multiline={true}
                autoFocus={autoFocus}
                  id={`question-preview-${id}`}
                  value={value||""}
                  onChange={evt => onChange && onChange(evt, id)}
                  aria-describedby={`helper-text-question-${id}`}
                  inputProps={{
                    'aria-label': {question},
                    placeholder: placeholder,
                    rows: 3
                  }}
                />
      
                {hint && <FormHelperText id={`helper-text-question-${id}`}>{hint}</FormHelperText>}
              </FormControl>
              )}
        </div>
      )}
    </React.Fragment>
  );
};
export default withStyles({})(Question);
