import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import TimeInfoDialog from "../../../Components/TimeInfoDialog";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import {
  FormControlLabel,
  TableBody,
  TableCell,
  Typography,
  Switch,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Create";
import moment from "moment";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import api from "../../../../utils/api";

const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});
const startTime = moment();
startTime.hours(9);
startTime.minutes(0);
startTime.seconds(0);

const endTime = moment();
endTime.hours(17);
endTime.minutes(0);
endTime.seconds(0);
class TimeAndCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeInfo: [],
      categoryInfo: [],
      timeInfoData: [],
      loadingTimeInfos: true,
      isEditPopupOpen: false,
    };
    this.categories = [];
  }

  componentDidMount = () => {
    this.getCategoryForHotline();
    const { timeInfo } = this.props.data;
    const timeData = this.props.data.timeInfo || [];
    timeData.forEach((data) => {
      data.start = moment(data.start, "hh:mm:ss");
      data.end = moment(data.end, "hh:mm:ss");
    });
    this.setState({
      timeInfo,
      timeInfoData: timeData
    });
  };

  getCategoryForHotline = async () => {
    await api.get(
      `hotlinecategories`,
      (categoryData = []) => {
        const hotlinecategories = this.props.data.categories || {};
        categoryData.forEach((data) => {
          data.answer = 0;
          hotlinecategories.forEach((hotlinecategory) => {
            const existing = hotlinecategory.pivot || {};
            const answer = existing.answer === 1;
            if (data.id === hotlinecategory.id) {
              data.answer = answer;
            }
          });
        });
        this.setState({ categoryInfo: categoryData });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  isValidated = () => {
    return true;
  };

  sendState = () => {
    let data = [];
    let category_info = [];
    Object.keys(this.state.timeInfo).forEach((timeInfoID) => {
      let infoData = this.state.timeInfo[timeInfoID] || {};
      let temp = { ...infoData };
      if (temp.start.isValid) {
        temp.start = moment(temp.start, "hh:mm:ss").format("HH:mm:ss");
      }
      if (temp.end.isValid) {
        temp.end = moment(temp.end, "hh:mm:ss").format("HH:mm:ss");
      }
      data.push(temp);
    });

    Object.keys(this.state.categoryInfo).forEach((categoryInfoID) => {
      let temp = this.state.categoryInfo[categoryInfoID] || {};
      let temp1 = {};
      temp1.hotline_category_id = temp.id;
      temp1.answer = temp.answer;
      category_info.push(temp1);
    });
    return [data, category_info];
  };

  editTimeInfo = () => {
    this.setState({ isEditPopupOpen: true });
  };

  handleClose = () => {
    this.setState({ isEditPopupOpen: false });
  };

  handleUpdate = (id, data) => {
    Object.keys(data).forEach((index) => {
      let infoData = data[index] || {};
      let temp = { ...infoData };
      temp.start = moment(temp.start).format("HH:mm:ss");
      temp.end = moment(temp.end).format("HH:mm:ss");
      data[index] = temp;
    });
    this.setState({ timeInfo: [...data], isEditPopupOpen: false });
  };

  handleUpdate247 = (id, data) => {
    this.setState({ timeInfo: [...data], isEditPopupOpen: false });
  };

  handleCategorySelect = (evt, category, index) => {
    let data = this.state.categoryInfo || {};
    let temp = data[index] || {};
    if (evt.target.type === "checkbox") {
      temp.answer = evt.target.checked;
    } else {
      temp.answer = evt.target.value;
    }
    data[index] = temp;
    this.setState({ categoryInfo: data });
  };

  renderTimeInfo(timeInfo) {
    if (timeInfo.length === 0) {
      return <Typography align="center">Information not available</Typography>;
    }
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Weekday</TableCell>
            <TableCell align="left">Start Time</TableCell>
            <TableCell align="left">End Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {timeInfo.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {weekDays[row.day]}
              </TableCell>
              {!row.active && (
                <TableCell align="center" colSpan={2}>
                  Closed
                </TableCell>
              )}
              {row.active && (
                <TableCell align="left">
                  {moment(row.start, "hh:mm:ss").format("hh:mm A")}
                </TableCell>
              )}
              {row.active && (
                <TableCell align="left">
                  {moment(row.end, "hh:mm:ss").format("hh:mm A")}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  rednerCategoryOptions(categoryInfo) {
    return (
      <>
        {categoryInfo.map((category, index) => (
          <GridItem xs={12} key={index}>
            <FormControlLabel
              control={
                <Switch
                  value={category.answer}
                  checked={category.answer}
                  onChange={(event) =>
                    this.handleCategorySelect(event, category, index)
                  }
                  color="primary"
                  name={category.name}
                />
              }
              label={category.name}
            />
          </GridItem>
        ))}
      </>
    );
  }

  render() {
    const {
      loadingTimeInfos = true,
      timeInfo = [],
      categoryInfo = [],
      timeInfoData = [],
      isEditPopupOpen = false,
    } = this.state;
    const { theme = {} } = this.props;
    return (
      <>
        {/* {loadingTimeInfos && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress className={classes.progress} color="secondary" />
            <p>Loading time information</p>
          </div>
        )} */}
        {!!loadingTimeInfos && (
          <>
            <Typography variant="h6">Hours</Typography>
            {/* <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Hours</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails> */}
            <GridContainer>
              {/* <GridItem xs={12}>
                  <Typography variant="body">
                    {"Operating hours of hotline"}
                  </Typography>
                </GridItem> */}
              <GridItem xs={12} style={{ marginTop: "1em" }}>
                {this.renderTimeInfo(timeInfo)}
                <div
                  style={{
                    marginTop: theme.spacing.unit * 3,
                    textAlign: "right",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.editTimeInfo()}
                  >
                    <EditIcon style={{ marginRight: theme.spacing.unit }} />
                    Edit Hours
                  </Button>
                </div>
                {isEditPopupOpen && (
                  <TimeInfoDialog
                    data={timeInfoData}
                    infoID={""}
                    open={true}
                    handleClose={this.handleClose}
                    handleUpdate={this.handleUpdate}
                    handleUpdate247={this.handleUpdate247}
                  // handleUpdate95={this.handleUpdate95}
                  />
                )}
              </GridItem>
            </GridContainer>
            {/* </ExpansionPanelDetails>
            </ExpansionPanel> */}
          </>
        )}
        <GridContainer>
          <GridItem xs={12}>
            <Typography variant="h6">Categories</Typography>
          </GridItem>
          {this.rednerCategoryOptions(categoryInfo)}
        </GridContainer>
      </>
    );
  }
}

export default connect()(
  injectIntl(withStyles(styles, { withTheme: true })(TimeAndCategories))
);
