import api from '../utils/api';
import {AUTH_LOGIN, AUTH_ME} from '../utils/types';


export const login = ({email,password}, ...extra) => ({
    type: AUTH_LOGIN,
    payload: api.post('/auth/login',{email,password},...extra)
});
export const getLoggedInUser = (...extra) => ({
    type: AUTH_ME,
    payload: api.post('/auth/me',{},...extra)
});