import React from "react";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ViewIcon from "@material-ui/icons/Visibility";
import Grid from "@material-ui/core/Grid";

import withStyles from "@material-ui/core/styles/withStyles";

import serviceStyles from "assets/jss/material-dashboard-pro-react/views/serviceStyles.jsx";

import { connect } from "react-redux";
import { getAgencies } from "../../actions/action_agencies";
import { deleteAgency } from "../../actions/action_agency";
import { bindActionCreators } from "redux";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import EditIcon from "@material-ui/icons/Create";
import Typography from "@material-ui/core/Typography";
import Paginations from "components/Pagination/Pagination.jsx";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AgencyIcon from "@material-ui/icons/AccountBalance";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";

import { injectIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { withSnackbar } from "notistack";
import Avatar from "@material-ui/core/Avatar";
import PopupView from "./PopupView";

const intl = window.intl;
const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView();

  return { executeScroll, elRef };
};
class AgenciesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      openPopup: false,
      deleteId: "",
      deleteName: ""
    };
    this.elScroll = utilizeScroll();
  }
  componentDidMount() {
    this.searchServices();
    this.elScroll.executeScroll();
  }
  searchServices = (pageNo = 1, sortby = "updated_at", issorting = 1) => {
    this.props.getAgencies({ nameLike: this.state.search, page: pageNo, sortBy: sortby, isSorting: issorting });
  };
  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.searchServices(1, "name", 0);
    }, 500);
  };
  componentWillUnmount() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
  }

  getEnteredPage = (evt) => {
    this.searchServices(evt);
    let html = document.getElementsByTagName("html");
    let body = document.getElementsByTagName("body");
    if (body && body[0]) {
      body[0].scrollTop = 0;
    }
    if (html && html[0]) {
      html[0].scrollTop = 0;
    }
    this.elScroll.executeScroll();
  }

  getPagination = () => {
    const { agencies = {} } = this.props;
    const { last_page = 1, current_page = 1 } = agencies;
    if (last_page <= 1) {
      return null;
    }
    let pageLimit;
    if (window && window.screen && window.screen.width <= 480) {
      pageLimit = 5
    }

    let pages = [];
    let limit = pageLimit ? pageLimit : 9;
    let start = 1;

    let end = last_page;
    if (last_page > limit) {
      start = current_page - Math.floor(limit / 2);
      end = current_page + Math.floor(limit / 2);
      if (start <= 0) {
        start = 1;
        end = limit;
      }
      if (end > last_page) {
        end = last_page;
        start = end - limit;
      }
    }
    for (let i = start; i <= end; i++) {
      pages.push({
        onClick: this.gotoPage,
        text: i,
        active: current_page === i,
      });
    }

    pages.unshift({
      text: intl.formatMessage({ id: "prev" }),
      onClick: this.gotoPrevPage,
    });
    pages.push({
      text: intl.formatMessage({ id: "next" }),
      onClick: this.gotoNextPage,
    });

    pages.push({
      text: intl.formatMessage({ id: `${current_page} of ${last_page}` }),
    });

    return <Paginations pages={pages} color="info" currentPage={current_page} lastPage={last_page} gotoEnteredPage={this.getEnteredPage} />;
  };

  gotoPage = evt => {
    this.searchServices(evt.target.innerText);
    this.elScroll.executeScroll();
  };

  gotoNextPage = () => {
    const { agencies = {} } = this.props;
    const { last_page = 1, current_page = 1 } = agencies;
    if (current_page < last_page) {
      this.searchServices(current_page + 1);
    }
    this.elScroll.executeScroll();
  };
  gotoPrevPage = () => {
    const { agencies = {} } = this.props;
    const { current_page = 1 } = agencies;
    if (current_page > 1) {
      this.searchServices(current_page - 1);
    }
    this.elScroll.executeScroll();
  };

  getConfirmPopUp = (message) => {
    return (
      <PopupView
        openPopup={this.state.openPopup}
        callbackFun={this.callbackFun}
        updateProps={this.updateProps}
        message={message}
        icon={<AgencyIcon />}
      />
    );
  };

  updateProps = (name, value) => {
    this.setState({ [name]: value });
  }

  callbackFun = () => {
    this.deleteAgency(this.state.deleteId, this.state.deleteName);
  }

  deleteAgency = (id, name) => {
    // if (!window.confirm("Are you sure you want to delete '" + name + "'")) {
    //   return false;
    // }
    this.props.deleteAgency(
      id,
      () => {
        this.props.enqueueSnackbar("Agency deleted successfully", {
          variant: "success"
        });
        this.searchServices();
      },
      () => {
        this.props.enqueueSnackbar("Unable to delete '" + name + "'", {
          variant: "error"
        });
        this.searchServices();
      }
    );
  };

  createAgencyBtn = (drole, classes) => {



    if (drole == "admin") {
      return <Grid xs={1} item>
        <Tooltip
          title={intl.formatMessage({
            id: "view.agencies.addAgency"
          })}
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
        >
          <Link to={`/admin/edit-agency`}>
            <IconButton
              className={classes.iconButton}
              aria-label={intl.formatMessage({
                id: "view.agencies.addAgency"
              })}
            >
              <AddCircleIcon color="primary" fontSize="large" />
            </IconButton>
          </Link>
        </Tooltip>
      </Grid>;
    }

  }

  editAgencyBtn = (drole, classes, service) => {


    if (drole != "serviceadmin") {
      return <Tooltip
        title={intl.formatMessage({
          id: "view.agencies.editAgency"
        })}
        placement="bottom"
        classes={{ tooltip: classes.tooltip }}
      >
        <Link to={`/admin/edit-agency/${service.id}`}>
          <IconButton
            color="primary"
            aria-label={intl.formatMessage({
              id: "view.agencies.editAgency"
            })}
          >
            <EditIcon />
          </IconButton>
        </Link>
      </Tooltip>

    }

  }

  callDeletePopup = (id, name) => {
    this.setState({
      deleteName: name,
      deleteId: id,
      openPopup: true,
    });
  }

  deleteAgencyBtn = (drole, classes, service) => {
    if (drole == "admin") {
      return (
        <>
          <Tooltip
            title="Delete"
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton
              aria-label="Delete"
              className={classes.margin}
              onClick={() => {
                this.callDeletePopup(service.id, service.name);
              }}
            >
              <DeleteIcon
                color="secondary"
                className={classes.margin} />

            </IconButton>
          </Tooltip>
        </>
      );
    }
  }


  render() {
    const { classes, agencies = {} } = this.props;
    const { search, deleteName, openPopup } = this.state;
    const { data = [], error = false, agenciesLoading = false } = agencies;
    const baseURL = (process.env.NODE_ENV !== 'production') ? `http://localhost:8000/api/v1` : `/api/v1`;

    const drugrole = sessionStorage.getItem("drughelp-role");

    return (
      <>
        <div ref={this.elScroll.elRef} />
        <Grid container spacing={3} direction="row" justify="center" alignItems="center">
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {intl.formatMessage({
                id: "agencies"
              }) +
                " | " +
                intl.formatMessage({
                  id: "title"
                })}
            </title>
            <link rel="canonical" href={window.location.href} />
          </Helmet>
          <Grid item xs={12} sm={10} md={9}>
            <Paper className={classes.root} elevation={1}>
              <IconButton className={classes.iconButton} aria-label="Menu" />
              <InputBase
                id="search"
                autoFocus
                value={search}
                onChange={this.handleChange}
                className={classes.input}
                placeholder={intl.formatMessage({
                  id: "view.agencies.searchAgencies"
                })} />
              <IconButton
                className={classes.iconButton}
                aria-label={intl.formatMessage({ id: "view.agencies.search" })}
              >
                {agenciesLoading ? <CircularProgress /> : <SearchIcon />}
              </IconButton>
            </Paper>
          </Grid>
          {this.createAgencyBtn(drugrole, classes)}
          {!agenciesLoading && (
            <Grid item xs={12} container direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={8}>
                {/* Error */}
                {error && (
                  <SnackbarContent
                    message={intl.formatMessage({
                      id: "view.agencies.searchError"
                    })}
                    color="danger" />
                )}
                {/* No agencies found */}
                <Typography variant="subtitle1" gutterBottom align="center">
                  {!error &&
                    data &&
                    data.length === 0 &&
                    (search.length > 0
                      ? intl.formatMessage({
                        id: "view.agencies.noResultsFound"
                      })
                      : intl.formatMessage({
                        id: "view.agencies.noAgenciesAvailable"
                      }))}
                </Typography>
              </Grid>
              {/* Found agencies */}
              {!error && data.length > 0 && (
                <GridContainer direction="row" justify="center" alignItems="center">
                  <GridItem
                    xs={12}
                    sm={6}
                    style={{ textAlign: "left" }}
                    align="center"
                  >
                    {search &&
                      intl.formatMessage(
                        {
                          id: "view.agencies.searchResult"
                        },
                        { count: data.length, term: search }
                      )}
                  </GridItem>
                  <Grid container spacing={3}>
                    {data.map(service => {
                      return (
                        <Grid item xs={12} sm={6} md={4} key={service.id}>
                          <Slide in={true} direction="left">
                            <Card className={classes.card} style={{ marginBottom: "1em" }}>
                              <CardHeader
                                avatar={service.logo ? (<Avatar
                                  alt={service.name}
                                  src={`${baseURL}/assets/file/${service.logo}?r=${Date.now()}`}
                                  className={classes.bigAvatar} />) : (<Avatar>
                                    <AgencyIcon />
                                  </Avatar>)}
                                title={service.name}
                                subheader={service.address} />
                              <CardContent>
                                <Typography component="p" style={{ maxHeight: '84px', overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: service.description }}>
                                </Typography>
                              </CardContent>
                              <CardActions
                                className={classes.actions}
                                disableActionSpacing
                              >
                                <Tooltip
                                  title={intl.formatMessage({
                                    id: "view.agencies.viewAgency"
                                  })}
                                  placement="bottom"
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <Link to={`/admin/agency/${service.id}`}>
                                    <IconButton
                                      color="primary"
                                      aria-label={intl.formatMessage({
                                        id: "view.agencies.viewAgency"
                                      })}
                                    >
                                      <ViewIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                                {this.editAgencyBtn(drugrole, classes, service)}
                                {this.deleteAgencyBtn(drugrole, classes, service)}
                              </CardActions>
                            </Card>
                          </Slide>
                        </Grid>
                      );
                    })}
                    <Grid item xs={12}>
                      {openPopup ? this.getConfirmPopUp("Are you sure you want to delete '" + deleteName + "' ?") : null}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} align="center">
                    {this.getPagination()}
                  </Grid>
                </GridContainer>
              )}
            </Grid>
          )}
        </Grid></>
    );
  }
}

AgenciesPage.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    agencies: state.agencies
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getAgencies: bindActionCreators(getAgencies, dispatch),
    deleteAgency: bindActionCreators(deleteAgency, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(serviceStyles)(withSnackbar(AgenciesPage))));
