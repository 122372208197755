import api from "../utils/api";
import {
  GET_SLOTINFO,
  GET_SLOTINFOS,
  INSERT_SLOTINFO,
  UPDATE_SLOTINFO,
  DELETE_SLOTINFO,
  GET_PUBLIC_SLOTS
} from "../utils/types";

export const getSlotInfo = (id, ...extra) => ({
  type: GET_SLOTINFO,
  payload: api.get(`/slotInfos/${id}`,null,...extra)
  
});
export const getSlotInfos = ({nameLike="",page=1, limit=9}, ...extra) => ({
  type: GET_SLOTINFOS,
  payload: api.get(`/slotInfos?nameLike=${nameLike}&page=${page}&limit=${limit}`,null,...extra)
});

export const getPublicSlots = ({nameLike="",page=1, limit=9}, ...extra) => ({
  type: GET_PUBLIC_SLOTS,
  payload: api.get(`/modelSearch/slotInfos?nameLike=${nameLike}&page=${page}&limit=${limit}`,null,...extra)
});

export const getSlotInfoByCategory = (id, ...extra) => ({
  type: GET_SLOTINFO,
  payload: api.get(`/categories/${id}/slotInfos`,null,...extra)
});
export const getSlotInfoByService = (id, ...extra) => ({
  type: GET_SLOTINFO,
  payload: api.get(`/services/${id}/slotInfos`,null,...extra)
});

export const insertSlotInfo = (data, ...extra) => ({
  type: INSERT_SLOTINFO,
  payload: api.post(`/slotInfo`, data, ...extra)
});
export const updateSlotInfo = (data, ...extra) => ({
  type: UPDATE_SLOTINFO,
  payload: api.put(`/slotInfo/${data.id}`, data, ...extra)
});
export const deleteSlotInfo = (id, ...extra) => ({
  type: DELETE_SLOTINFO,
  payload: api.delete(`/slotInfo/${id}`, null, ...extra)
});
