import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Grid, Tooltip, withStyles } from "@material-ui/core";
import WebIcon from "@material-ui/icons/Language";
import HearingIcon from "@material-ui/icons/HearingRounded";
import CallIcon from "@material-ui/icons/CallRounded";
import TextIcon from "@material-ui/icons/MessageOutlined";
import ExpandMore from "@material-ui/icons/ExpandMoreRounded";
import ExpandLess from "@material-ui/icons/ExpandLessRounded";
import { ReactComponent as ChatBotAvailable } from "../../../assets/img/hotlineDynamicIcons/Chatbot_available.svg";
import { ReactComponent as VideophoneIcon } from "../../../assets/img/hotlineDynamicIcons/Videophone.svg";
import moment from "moment";
import hotlinesStyle from "assets/jss/material-dashboard-pro-react/views/hotlinesStyle.jsx";
import { useState } from "react";
import { useEffect } from "react";

const HotlineCard = (props) => {

  const [viewMore, setViewMore] = useState(false);
  const [iconCount, setIconCount] = useState(0);
  const [expandHeight, setExpandHeight] = useState("410px");

  const {
    text_line_display,
    hearing_impaired_tty,
    hearing_impaired_tty_display,
    name,
    description,
    key,
    website,
    time_infos,
    hotline_phone_dictionary,
    chat_on_website,
    video_line,
    video_line_display,
    hotline_text_line_dictionary,
  } = props.hotline;

  const { classes } = props;

  const updateViewMore = () => {
    setViewMore(!viewMore);
  }

  useEffect(() => {
    let count = 0;
    if (chat_on_website === 1) { count++; }
    if (hearing_impaired_tty !== "DIAL 711 then " && hearing_impaired_tty !== "") { count++; }
    if (video_line !== null && video_line !== "") { count++; }
    if (website !== "") { count++; }
    if (Array.isArray(hotline_phone_dictionary)) {
      if (hotline_phone_dictionary.length === 1 && hotline_phone_dictionary[0]['phone_number'] !== '') { count++; }
      else {
        if (hotline_phone_dictionary.length > 1) {
          count += hotline_phone_dictionary.length;
        }
      }
    }

    if (Array.isArray(hotline_text_line_dictionary)) {
      if (hotline_text_line_dictionary.length === 1 && hotline_text_line_dictionary[0]['text_line'] !== '') {
        count++;
      } else {
        if (hotline_text_line_dictionary.length > 1) {
          count += hotline_text_line_dictionary.length;
        }
      }
    }

    setIconCount(count);
    // new height of card
    if (count > 6) {
      setExpandHeight((410 + ((count - 6) * 40)) + "px");
    }

  }, [])

  let activeDays = 0;

  const icon_container = {
    width: "45px",
    height: "45px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  };

  // const chatIcon_container = {
  //   position: "relative",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   margin: "4 0 0 9",
  //   cursor: "pointer",
  // };

  const textContainer = {
    width: "24px",
    height: "24px",
    color: "#233750",
  };

  const phoneIconText = {
    position: "absolute",
    fontSize: "8px",
    right: "9px",
    fontWeight: "bold",
    top: "11px",
    color: "#233750",
  };

  const TextlineIconText = {
    height: "11px",
    position: "absolute",
    fontSize: "8px",
    right: "11px",
    fontWeight: "bold",
    top: "6px",
    color: "#233750",
    backgroundColor: "white",
  };

  const hours = {
    LIMITED_HOURS_OPEN: "Open (Limited hours)",
    LIMITED_HOURS_CLOSED: "Closed (Limited hours)",
    CONTINOUS_HOURS: "24/7",
  };

  // let text_line_display_split = text_line_display === null ? "" : text_line_display.split(" ");
  // let es_text_line_display_split = es_text_line_display === null ? "" : es_text_line_display.split(" ");
  let tty_split =
    hearing_impaired_tty_display === null
      ? ""
      : hearing_impaired_tty_display.split(" ");

  // const toRender = () => {
  //   return text_line_display_split[1] ? "to" : null;
  // };

  const isContinous = (time_infos) => {
    time_infos.forEach((time_info) => {
      if (
        time_info.active &&
        time_info.start === "00:00:00" &&
        time_info.end === "23:59:00"
      ) {
        activeDays += 1;
      }
    });
    return activeDays;
  };

  isContinous(time_infos);

  const isLimited = () => {
    if (activeDays === 7) {
      return hours.CONTINOUS_HOURS;
    } else {
      const openorclosed = time_infos.map((limited_hours) => {
        if (limited_hours.active) {
          const openTime = new moment(limited_hours.start, "HH:mm:ss");
          const closeTime = new moment(limited_hours.end, "HH:mm:ss");
          if (
            moment().diff(openTime, "minutes") > 0 &&
            moment().diff(closeTime, "minutes") < 0
          ) {
            return hours.LIMITED_HOURS_OPEN;
          } else {
            return hours.LIMITED_HOURS_CLOSED;
          }
        } else {
          return hours.LIMITED_HOURS_CLOSED;
        }
      });
      return openorclosed;
    }
  };
  const timings = isLimited();
  let date = new Date();

  const cardHeaderColor = () => {
    if (timings[date.getDay()] === hours.LIMITED_HOURS_CLOSED) {
      return "#808080";
    } else {
      return "#3c4858";
    }
  };

  // Capitalize the first word
  let description_ = description.charAt(0).toUpperCase() + description.slice(1);
  const name_ = name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );
  return (
    <Grid item xs={12} sm={6} md={4} key={key}>
      {/* <Slide in={true} direction="left"> */}
      <Card style={{ height: viewMore ? expandHeight : "410px" }} className={classes.parentCard}>
        <Grid
          className={classes.header}
          style={{
            backgroundColor: cardHeaderColor(),
          }}
        >
          <span style={{ paddingLeft: "5px" }}>
            {timings === "24/7" ? "24/7" : timings[date.getDay()]}
          </span>
        </Grid>
        <CardContent style={{ overflow: "auto", padding: "11px 16px 16px 16px" }}>
          <Tooltip
            title={name_}
            placement="bottom"
            style={{ fontFamili: "calibri" }}
          >
            <Typography
              variant="h5"
              component="h2"
              className={classes.cardTitle}
            >
              {name_.length > 35 ? name_.slice(0, 35) + "..." : name_}
            </Typography>
          </Tooltip>
          <Tooltip
            title={description_}
            placement="bottom"
            style={{ fontFamili: "calibri" }}
          >
            <Typography className={classes.cardDescription} paragraph>
              {description.length > 170
                ? description_.slice(0, 170) + "..."
                : description_}
            </Typography>
          </Tooltip>
          <div className={classes.cardIcons}>
            {hotline_phone_dictionary &&
              hotline_phone_dictionary.map((phone, i) => {
                if (iconCount > 6 && !viewMore) {
                  if (i !== 0) {
                    // pass do nothing
                    return null;
                  }
                }

                return (
                  phone.phone_number && (
                    <Typography
                      key={i}
                      variant="h6"
                      style={{ fontSize: "14px" }}
                      noWrap={true}
                      className={classes.iconHeight}
                    >
                      <Tooltip
                        title={phone.phone_number}
                        placement="bottom"
                        style={{ fontFamili: "calibri" }}
                      >
                        <a
                          href={`tel:${phone.phone_number}`}
                          style={{ alignItems: "center", display: "flex" }}
                        >
                          <div style={icon_container}>
                            <CallIcon style={textContainer} fontSize="default" />
                            <span style={phoneIconText}>{phone.language}</span>
                          </div>

                          {phone.phone_number_display}
                        </a>
                      </Tooltip>
                    </Typography>
                  )
                );

              })}
            {hotline_text_line_dictionary &&
              hotline_text_line_dictionary.map((text, i) => {

                if (iconCount > 6 && !viewMore) {
                  if (i !== 0) {
                    // pass do nothing
                    return null;
                  }
                }

                return (
                  text.text_line && (
                    <Typography
                      key={i}
                      variant="h6"
                      style={{ fontSize: "14px" }}
                      noWrap={true}
                      className={classes.iconHeight}
                    >
                      <Tooltip
                        title={"Text to find help"}
                        placement="bottom"
                        style={{ fontFamili: "calibri" }}
                      >
                        <a
                          href={`sms:${text.text_line_display};?&body=${text.text_line_display
                            }`}
                          style={{ alignItems: "center", display: "flex" }}
                        >
                          <div style={icon_container}>
                            <TextIcon style={textContainer} fontSize="default" />
                            {
                              text.language !== null && text.language !== "" &&
                              <span style={TextlineIconText}>
                                {text.language}
                              </span>
                            }
                          </div>
                          {text.text_line_display}
                        </a>
                      </Tooltip>
                    </Typography>
                  )
                );
              })}
            {chat_on_website ? (
              <Typography
                variant="h6"
                style={{ fontSize: "14px", "textTransform": "none" }}
                noWrap={true}
                className={classes.iconHeight}
              >
                <Tooltip
                  title={"Chat feature available on website"}
                  placement="bottom"
                  style={{ fontFamily: "calibri" }}
                >
                  <button
                    style={{
                      background: 'none',
                      border: 'none',
                      padding: '0',
                      cursor: 'pointer',
                      alignItems: "center",
                      display: "flex",
                      paddingTop: "5px",
                      color: 'inherit',
                    }}
                  >
                    <div className={classes.chatBotIconDiv}>
                      <ChatBotAvailable className={classes.VideoIcon} />
                    </div>
                    Chat feature available on website
                  </button>
                </Tooltip>
              </Typography>
            ) : null}

            {hearing_impaired_tty && tty_split[3] && (
              <Typography
                variant="h6"
                style={{ fontSize: "14px" }}
                noWrap={true}
                className={classes.iconHeight}
              >
                <Tooltip
                  title={hearing_impaired_tty}
                  placement="bottom"
                  style={{ fontFamili: "calibri" }}
                >
                  <a
                    href={`tel:${hearing_impaired_tty}`}
                    style={{ alignItems: "center", display: "flex" }}
                  >
                    <div style={icon_container}>
                      <HearingIcon style={textContainer} fontSize="default" />
                    </div>
                    {tty_split[3] ? tty_split[3] : ""}
                  </a>
                </Tooltip>
              </Typography>
            )}

            {video_line && tty_split[3] && (
              <Typography
                variant="h6"
                style={{ fontSize: "14px" }}
                noWrap={true}
                className={classes.iconHeight}
              >
                <Tooltip
                  title={video_line}
                  placement="bottom"
                  style={{ fontFamili: "calibri" }}
                >
                  <a
                    href={`tel:${video_line}`}
                    style={{ alignItems: "center", display: "flex" }}
                  >
                    <div style={icon_container}>
                      <VideophoneIcon style={textContainer} fontSize="default" />
                    </div>
                    {video_line_display}
                  </a>
                </Tooltip>
              </Typography>
            )}


            {website && (
              <Typography
                style={{ fontSize: "13px" }}
                noWrap={true}
                className={classes.iconWebsite}
              >
                <Tooltip title={"Open Website"} placement="bottom">
                  <a
                    href={website}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ alignItems: "center", display: "flex" }}
                  >
                    <div style={icon_container}>
                      <WebIcon style={textContainer} fontSize="default" />
                    </div>
                    <div style={{ width: 280 }}>
                      <span style={{ height: 20, overflow: "hidden" }}>
                        {website.length > 35 ? website.slice(0, 35) + "..." : website}
                      </span>
                    </div>
                  </a>
                </Tooltip>
              </Typography>
            )}

            {
              iconCount > 6 ?
                (


                  <Typography
                    style={{ fontSize: "13px" }}
                    noWrap={true}
                    className={classes.iconWebsite}
                  >
                    <div
                      target=""
                      style={{ alignItems: "center", display: "flex" }}
                    >
                      <div onClick={updateViewMore} style={icon_container}>
                        {viewMore ?
                          <ExpandLess style={textContainer} fontSize="default" />
                          :
                          <ExpandMore style={textContainer} fontSize="default" />
                        }
                      </div>
                      <div style={{ height: 20, width: 280, cursor: "pointer" }}>
                        <span onClick={updateViewMore}  >  {viewMore ? "View less" : "View more"} </span>
                      </div>
                    </div>
                  </Typography>

                )
                :
                null
            }

          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default withStyles(hotlinesStyle)(HotlineCard);
