import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import { connect } from "react-redux";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import FeatureIcon from "@material-ui/icons/LocalActivity";
import CheckBoxOutlineIcon from "@material-ui/icons/CheckBox";
import Typography from "@material-ui/core/Typography";
import { withSnackbar } from "notistack";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Create";
import ViewIcon from "@material-ui/icons/Visibility";
import Question from "../../components/Drughelp/Question";

import Divider from "@material-ui/core/Divider";

import IconButton from "@material-ui/core/IconButton";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import api from "../../utils/api";
import { Helmet } from "react-helmet";
import { injectIntl } from "react-intl";
import { questionTypes } from "../../utils/helper";
import PopupView from "./PopupView";

const intl = window.intl;
class FeatureViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      featureID: props.match.params.featureID,
      activeTab: 0,
      feature: {},
      showQuestionPreview: false,
      previewQuestion: {},
      deleteId: "",
      deleteName: ""
    };
  }
  getFeature = async id => {
    const feature = await api.get(`/features/${id}`);
    if (feature && Object.keys(feature).length > 0) {
      this.setState({ feature, featureNotFound: false });
    } else {
      this.setState({ featureNotFound: true });
    }
  };
  componentDidMount() {
    const { featureID } = this.state;
    featureID && this.getFeature(featureID);
  }
  handleTabChange = (event, value) => {
    this.setState({ activeTab: value });
  };

  getConfirmPopUp = (message) => {
    return (
      <PopupView 
        openPopup = {this.state.openPopup} 
        callbackFun = {this.callbackFun}
        updateProps = {this.updateProps}
        message = {message}
        // icon = { <CategoryIcon/> }
      />  
    );
  };

  updateProps = (name, value) => {
    this.setState({[name] : value});
  }

  callbackFun = () => {
    this.deleteQuestion(this.state.deleteId, this.state.deleteName);
  }

  callDeletePopup = (id, name) => {
    this.setState({
      deleteName : name,
      deleteId : id,
      openPopup: true,
    });
  }

  deleteQuestion = (id, name) => {
    // if (!window.confirm("Are you sure you want to delete '" + name + "'")) {
    //   return false;
    // }
    api.delete(`/question/${id}`,null,  ()=> {
      
      //  this.searchUsers();
        this.props.enqueueSnackbar("Question deleted successfully", {
          variant: "success"
        });
        window.location.reload();
      }, () => {
        this.props.enqueueSnackbar("Unable to delete '" + name + "'", {
          variant: "error"
        });
      })
  };

  showQuestionPreview = question => {
    this.setState({ showQuestionPreview: true, previewQuestion: question });
  };
  hideQuestionPreview = () => {
    this.setState({ showQuestionPreview: false, previewQuestion: {} });
  };

  render() {
    const { classes } = this.props;
    const { featureNotFound, feature = {}, deleteName, openPopup } = this.state;
    return (
      <React.Fragment>
        {featureNotFound ? (
          <Card>
            <CardBody>
              <Typography variant="subtitle1" gutterBottom align="center">
                {intl.formatMessage({
                  id: "view.featureEdit.featureNotFound"
                })}
              </Typography>
            </CardBody>
          </Card>
        ) : (
          <GridContainer
            spacing={24}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Helmet>
              <meta charSet="utf-8" />
              <title>
                {intl.formatMessage({
                  id: "feature"
                }) +
                  " - " +
                  feature.name +
                  " | " +
                  intl.formatMessage({
                    id: "title"
                  })}
              </title>
              <link rel="canonical" href={window.location.href} />
            </Helmet>
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <FeatureIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle} color="primary">
                    Feature
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Typography variant="button">Name</Typography>
                      <Typography variant="body2" gutterBottom>
                        {feature.name}
                      </Typography>
                    </GridItem>
                    <GridItem xs={12}>
                      <Typography variant="button">Description</Typography>
                      <Typography variant="body2" gutterBottom>
                        {feature.description}
                      </Typography>
                    </GridItem>
                    <GridItem xs={12}>
                      <Typography variant="button">Category</Typography>
                      <Typography variant="body2" gutterBottom>
                        {(feature.category || {}).name}
                      </Typography>
                    </GridItem>
                    <GridItem xs={12}>
                      <Typography variant="button">Active</Typography>
                      <Typography variant="body2" gutterBottom>
                        {feature.active ? "Yes" : "No"}
                      </Typography>
                    </GridItem>
                    <GridItem xs={12}>
                      <Typography variant="button">Order</Typography>
                      <Typography variant="body2" gutterBottom>
                        {feature.order}
                      </Typography>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          
            <GridItem xs={12} sm={12} md={10}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <CheckBoxOutlineIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle} color="primary">
                    Questions
                  </h4>
                </CardHeader>
                <CardBody>
                  <div style={{ textAlign: "right" }}>
                    <Link to={`/admin/edit-question?feature=${feature.id}`}>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.marginRight}
                      >
                        <AddIcon className={classes.icons} /> Add Question
                      </Button>
                    </Link>
                  </div>
                  <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={this.state.showQuestionPreview}
                    onClose={this.hideQuestionPreview}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    BackdropProps={{ style: { backgroundColor: "#233750", opacity: 0.8 } }}
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Question Preview"}
                      <Divider />
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <Question
                          data={this.state.previewQuestion}
                          autoFocus={true}
                        />
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={this.hideQuestionPreview}
                        color="primary"
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Table
                    padding="none"
                    root={true}
                    style={{ tableLayout: "fixed" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          Name
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Type
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Order
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(feature.questions || []).length > 0 ? (
                        (feature.questions || []).map(question => {
                          return (
                            <TableRow key={feature.id}>
                              <TableCell className={classes.tableCell}>
                                {question.question}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {
                                  (
                                    questionTypes.find(
                                      q => q.value === question.type
                                    ) || {}
                                  ).text
                                }
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {question.order}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="center"
                              >
                                <Tooltip
                                  title="Edit"
                                  placement="bottom"
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <Link
                                    to={`/admin/edit-question/${question.id}`}
                                  >
                                    <IconButton
                                      aria-label="Edit"
                                      className={classes.margin}
                                    >
                                      <EditIcon
                                        color="secondary"
                                        className={classes.margin}
                                      />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                                <Tooltip
                                  title="Delete"
                                  placement="bottom"
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <IconButton
                                    aria-label="Delete"
                                    className={classes.margin}
                                    onClick={()=>this.callDeletePopup(question.id, question.question)}
                                  >
                                    <DeleteIcon
                                      color="secondary"
                                      className={classes.margin}
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="View"
                                  placement="bottom"
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <IconButton
                                    aria-label="View"
                                    className={classes.margin}
                                    onClick={() =>
                                      this.showQuestionPreview(question)
                                    }
                                  >
                                    <ViewIcon
                                      color="secondary"
                                      className={classes.margin}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell
                            component="td"
                            scope="row"
                            align="center"
                            colSpan="3"
                          >
                            No questions available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                
                </CardBody>
              </Card>
            </GridItem>
            <GridItem>
              {openPopup ? this.getConfirmPopUp("Are you sure you want to delete '" + deleteName + "' ?") : null}
            </GridItem>
          </GridContainer>
        )}
      </React.Fragment>
    );
  }
}
FeatureViewPage.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    feature: state.feature
  };
};
const mapDispatchToProps = dispatch => {
  return {
    
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  injectIntl(
    withStyles(userProfileStyles, { withTheme: true })(
      withSnackbar(FeatureViewPage)
    )
  )
);
