import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

import Miyuki from "../../assets/img/Miyuki.PNG";
import RahulReddy from "../../assets/img/RahulReddy.jpg";
import Hari from "../../assets/img/Hari.jpg";
import Abhijeeth from "../../assets/img/Abhijeeth.jpg";
import Patricia from "../../assets/img/Patricia.jpg";
import zhao from "../../assets/img/zhao.png";
import sachin from "../../assets/img/sachin.png";
import parameswara from "../../assets/img/parameswara.png";
import saurabh from "../../assets/img/saurabh.jpg";
import Disha from "../../assets/img/Disha.jpg";
import Fagun from "../../assets/img/Fagunsmall.jpg";
import Courtney from "../../assets/img/Courtney.png";
import kaila from "../../assets/img/kaila.png";
import Oluwatoni from "../../assets/img/Oluwatoni.png";
import Michelle from "../../assets/img/Michelle.jpg";
import Kaitlyn from "../../assets/img/Kaitlyn.jpg";
import kyra_wells from "../../assets/img/kyra_wells.png";
import Kailey_sikora from "../../assets/img/Kailey_sikora.png";
import ashley from "../../assets/img/ashley.jpg";
import lillian from "../../assets/img/lillian.jpg";
import anne from "../../assets/img/anne.jpg";
import gia from "../../assets/img/gia.jpg";
import Madalyn from "../../assets/img/maddie.png";
import { connect } from "react-redux";
import { login, getLoggedInUser } from "../../actions/action_auth";
import { bindActionCreators } from "redux";
import kavya from "../../assets/img/kavyasmall.jpg";
import Melissa from "../../assets/img/Melissa.jpg";
import Alexis from "../../assets/img/Alexissmall.jpg";
import Sydney from "../../assets/img/Sydney.png";
import Larry from "../../assets/img/Larry.jpg";
import SathishKumar from "../../assets/img/SathishKumar.jpg";
import GaurangPatel from "../../assets/img/GaurangPatel.png";
import devinbenko from "../../assets/img/devinbenko.png";
import Mayank from "../../assets/img/Mayank.jpg";
import Guruprasad from "../../assets/img/GuruprasadVenkatraman.jpg";
import Pramod from "../../assets/img/Pramod.jpg";
import Yasharth from "../../assets/img/YasharthSingh.jpg";
import Zachary from "../../assets/img/Zachary.jpg";
import Emma from "../../assets/img/EmmaChurch.jpg";
import Ryan from "../../assets/img/Ryan.png";
import Venu from "../../assets/img/Venu.png";
// import Mariam from "../../assets/img/mariam.jpg";
import ProfileCard from "../Components/ProfileCard";
import MaheshKatakam from "../../assets/img/MaheshKatakam.jpeg";
import Sireesh from "../../assets/img/Sireesh.jpg";
import "../../assets/styles/aboutImage.css";
import {
    Button,
  } from "@material-ui/core";

const Teams = {
  Creators: [
    {
      name: 'Patricia Stoddard Dare',
      title: 'Professor of Social Work',
      year: null,
      imageSrc: Patricia,
      description: `Chemical Dependency Counseling Certificate Program
      Coordinator`
    },

    {
      name: 'Miyuki Fukushima Tedor',
      title: 'Associate Professor of Criminology',
      year: null,
      imageSrc: Miyuki,
      description: `Department of Criminology, Anthropology and Sociology`
    },

  ],
  EngineeringTeam: [
    {
      name: 'Sathish Kumar',
      title: 'Associate Professor of Computer Science',
      year: null,
      imageSrc: SathishKumar,
      description: ` Department of Electrical Engineering and Computer Science
      (2020-Present)`,
    },

    {
      name: 'Wenbing Zhao',
      title: 'Professor of Electrical and Computer Engineering',
      year: null,
      imageSrc: zhao,
      description: 'College of Engineering (2017-2019)',
    },
    {
      imageSrc: Sireesh,
      name: 'Sireesh Devaraj',
      title: 'Software Development Engineer',
      year: '(2024-Present)',
      description: "https://kuuhaku.space",
    }
    ,
    {
      imageSrc: MaheshKatakam,
      name: 'Mahesh Katakam',
      title: 'Software Development Engineer',
      year: '(2022-2023)',
      description: null,
    },
    {
      name: 'Venu Gopal Adimulam',
      title: 'Software Development Engineer',
      year: '(2022-2023)',
      imageSrc: Venu,
      description: null,
    },

    {
      name: 'Pramod Kumar Bathulla',
      title: 'Software Development Engineer',
      year: '(2022-2023)',
      imageSrc: Pramod,
      description: null,
    },

    {
      imageSrc: Guruprasad,
      name: 'Guruprasad Venkatraman',
      title: 'Software Development Engineer',
      year: '(2022-2023)',
      description: null,
    },

    {
      imageSrc: Yasharth,
      name: 'Yasharth Singh',
      title: 'Software Development Engineer',
      year: '(2021)',
      description: null,
    },

    {
      imageSrc: Mayank,
      name: 'Mayank Bhati',
      title: 'Software Development Engineer',
      year: '(2021-2022)',
      description: null,
    },
    {
      imageSrc: GaurangPatel,
      name: 'Gaurang Patel',
      title: 'Software Development Engineer',
      year: '(2020-2022)',
      description: null,
    },
    {
      imageSrc: kavya,
      name: 'Kavya Chowdary Bheemavarapu',
      title: 'Software Development Engineer',
      year: '(2020)',
      description: null,
    },
    {
      imageSrc: Hari,
      name: 'Hari Krishna Kotte',
      title: 'Software Development Engineer',
      year: '(2020)',
      description: null,
    },
    {
      imageSrc: RahulReddy,
      name: 'Rahul Reddy',
      title: 'Software Development Engineer',
      year: '(2020-2021)',
      description: null,
    },
    {
      imageSrc: Abhijeeth,
      name: 'Abhijeet Tupe',
      title: 'Software Development Engineer',
      year: '(2020-2021)',
      description: null,
    },
    {
      imageSrc: Fagun,
      name: 'Fagun Patel',
      title: 'Software Development Engineer',
      year: '(2019-2020)',
      description: null,
    },
    {
      imageSrc: Disha,
      name: 'Disha Patel',
      title: 'Software Development Engineer',
      year: '(2019-2020)',
      description: null,
    },
    {
      imageSrc: saurabh,
      name: 'Saurabh Dhole',
      title: 'Software Development Engineer',
      year: '(2019-2020)',
      description: 'www.saurabhdhole.com',
    },
    {
      imageSrc: parameswara,
      name: 'Parameswara Reddy A',
      title: 'Software Development Engineer',
      year: '(2018-2019)',
      description: null,
    },
    {
      imageSrc: sachin,
      name: 'Sachin Hiriyanna',
      title: 'Software Development Engineer',
      year: '(2017-2018)',
      description: null,
    }

  ],
  RegistrationTeam: [
    {
      imageSrc: Larry,
      name: 'Larry Heller',
      title: 'Community Outreach Consultant',
      year: '(2022-2022)',
      description: null,
    },
    {
      imageSrc: Michelle,
      name: 'Michelle Unangst, MSSA',
      title: 'Community Outreach Consultant',
      year: '(2019-2022)',
      description: null,
    },
    {
      imageSrc: Madalyn,
      name: 'Maddie Karban',
      title: 'Project Manager & Community Specialist',
      year: '(2022-Present)',
      description: null,
    },
    {
      imageSrc: null,
      name: 'Mariam Soliman',
      title: 'Agency Enrollment Specialist',
      year: '(2022-Present)',
      description: null,
    },
    {
      imageSrc: Sydney,
      name: 'Sydney Manderbach',
      title: 'Agency Enrollment Specialist',
      year: '(2022-Present)',
      description: null,
    },
    {
      imageSrc: null,
      name: 'Lynzi Terbanc',
      title: 'Agency Enrollment Specialist',
      year: '(2021-Present)',
      description: null,
    },
    {
      imageSrc: Alexis,
      name: 'Alexis Galvin',
      title: 'Agency Enrollment Specialist',
      year: '(2020-2022)',
      description: null,
    },
    {
      imageSrc: Melissa,
      name: 'Melissa Jackson',
      title: 'Agency Enrollment Specialist',
      year: '(2020-2021)',
      description: null,
    },
    {
      imageSrc: Kailey_sikora,
      name: 'Kailey Sikora',
      title: 'Agency Enrollment Specialist',
      year: '(2019-2021)',
      description: null,
    },
    {
      imageSrc: ashley,
      name: 'Ashley Gallaher',
      title: 'Agency Enrollment Specialist',
      year: '(2019-2021)',
      description: null,
    },
    {
      imageSrc: lillian,
      name: 'Lillian Platten',
      title: 'Agency Enrollment Specialist',
      year: '(2019-2020)',
      description: null,
    },
    {
      imageSrc: Kaitlyn,
      name: 'Kaitlyn Harman',
      title: 'Agency Enrollment Specialist',
      year: '(2019-2020)',
      description: null,
    },
    {
      imageSrc: Oluwatoni,
      name: 'Oluwatoni Kolawole',
      title: 'Agency Enrollment Specialist',
      year: '(2019-2020)',
      description: null,
    },
    {
      imageSrc: kaila,
      name: 'Kaila Griffin',
      title: 'Agency Enrollment Specialist',
      year: '(2017-2019)',
      description: null,
    },
    {
      imageSrc: Courtney,
      name: 'Courtney Dunn',
      title: 'Agency Enrollment Specialist',
      year: '(2017-2019)',
      description: null,
    },

  ],
  GraphicDesignTeam: [
    {
      imageSrc: anne,
      name: 'Anne H. Berry',
      title: 'Assistant Professor of Graphic Design',
      year: 'Department of Art and Design (2019-Present)',
      description: null,
    },
    {
      imageSrc: Ryan,
      name: 'Ryan Kustaborder',
      title: 'Graphic Designer',
      year: '(2022 - Present)',
      description: null,
    },
    {
      imageSrc: null,
      name: 'Emma Splete',
      title: 'Graphic Designer',
      year: '(2022-Present)',
      description: null,
    },
    {
      imageSrc: devinbenko,
      name: 'Devin Benko',
      title: 'Graphic Designer',
      year: '(2021-2022)',
      description: null,
    },
    {
      imageSrc: kyra_wells,
      name: 'Kyra Wells',
      title: 'Graphic Designer',
      year: '(2019-2020)',
      description: null,
    },
    {
      imageSrc: gia,
      name: 'Gia Paulovich',
      title: 'Graphic Designer',
      year: '(2019-2020)',
      description: null,
    },

  ],
  CrisisHotlineTeam: [
    {
      imageSrc: Emma,
      name: 'Emma Church',
      title: 'Research Assistant',
      year: '(2020-2021)',
      description: null,
    },
    {
      imageSrc: Zachary,
      name: 'Zach Dispirito',
      title: 'Research Assistant',
      year: '(2020-2021)',
      description: null,
    },
  ]
};

const aboutTeamArray = [
    {
       name : "Creators",
       description :"Drughelp.care was developed by two professors who are members of Cleveland State University’s (CSU) Center for Behavioral Health Sciences and the Internet of Things (IOT) Collaborative.",
       teamImage :  Teams.Creators
    },
    {  
        name:"Engineering Team",
        description:"Our engineering team is led by two engineering professors and includes graduate students in various fields of engineering.",
        teamImage : Teams.EngineeringTeam
    },
    {  
        name:"Registration Team",
        description:"Our registration team is led by a consultant and includes undergraduate students from the Department of Criminology, Anthropology, and Sociology.",
        teamImage : Teams.RegistrationTeam
    },
    {  
        name:"Graphic Design Team",
        description:"Our graphic design team is led by a graphic design professor and includes graphic design undergraduate students.",
        teamImage : Teams.GraphicDesignTeam
    },
    {  
        name:"Crisis Hotline Team",
        description:"Our Crisis Hotline page was developed by students in the Department of Psychology.",
        teamImage : Teams.CrisisHotlineTeam
    },
]
/*
const aboutButtonsContainer = {
    width : "100%",
    columnGap: "24px",
    display:"flex",
    justifyContent:"center",
    "@media (max-width: 768px)":{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)"
    }
}
*/
const aboutButtons = {
    width: "150px",
    backgroundColor: "#233750",
    color: "#f4f7fc",
    fontFamily: "Roboto, Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif",
    "@media (max-width: 768px)": {
      maxWidth: "100%",
    },
    "&:hover": {
      backgroundColor: "#808080",
    },
    "&:active":{
        backgroundColor: "grey"
    }
}


class About extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      index : 0
    };
  }
  componentDidMount() {
    if (this.props.auth.loggedIn && localStorage.getItem("drughelp-token")) {
      this.props.history.push("/admin/dashboard");
    }
    this.props.getLoggedInUser().catch(error => {
      console.log("Failed to fetch logged in user");
    });
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: ""});
      }.bind(this),
      700
    );
  }
  componentWillUpdate(nextProps) {
    if (!this.props.auth.loggedIn && nextProps.auth.loggedIn) {
      this.props.history.push("/admin/dashboard");
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  login = () => {
    const { email, password } = this.state;
    this.props.login({ email, password });
  };

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };
 
  
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.container}>
          <GridItem xs={12} mx={4} my={2} className={classes.gridContainer}>
            <div>
              <h2 className={classes.parastyle}>
                {" "}
                <strong>About us </strong>
              </h2>
              <p className={classes.parastyle}>
                Created for the community affected by the opioid crisis,
                drughelp.care is a free website that allows drug treatment
                providers to list the number of open treatment slots daily. The
                site is fully searchable, and quickly and efficiently matches
                substance users with the best available treatment services.
              </p>
              <p className={classes.parastyle}>
                Drughelp.care was developed by Dr. Miyuki Fukushima Tedor and
                Dr. Patricia Stoddard-Dare at Cleveland State University (CSU)
                in 2016. They met with over 100 community members over the years
                to develop the site. The technical specifications were designed
                by engineering graduate students with Dr. Wenbing Zhao, and they
                continue to refine and improve drughelp.care.
              </p>
              <p className={classes.parastyle}>
                Thanks to funding from the Woodruff Foundation (2018-2019) and
                the Centers for Disease Control and Prevention (2019-Present),
                a community outreach team led by our Community Outreach Consultant
                registers more and more treatment providers on the site every day.
                Treatment providers can register different types of services,
                including assessment, outpatient treatment, intensive outpatient treatment,
                partial hospitalization, residential treatment, inpatient treatment, sober living,
                harm reduction, and peer and family support.
              </p>
              <p className={classes.parastyle}>
                The multidisciplinary drughelp.care team continues
                to enhance and add features to the website with the help from
                professors Dr. Sathish Kumar and Dr. Anne Berry.
              </p>
              <p className={classes.parastyle}>
                Agencies interested in listing their services can contact{" "}
                <a
                  style={{ color: "#3596d4" }}
                  href="mailto:admin@drughelp.care"
                >
                  <strong>admin@drughelp.care</strong>.
                </a>{" "}
              </p>
              <h3 className={classes.parastyle}>
                <strong>Meet our teams: </strong>
              </h3>

              <p className={classes.parastyle}>
                Our team consists of five professors, undergraduate and graduate
                students at Cleveland State University, and a consultant for
                community outreach.
              </p>
          <div className="aboutButtonsContainer">
            <Button style={aboutButtons} onClick={() => this.setState({index: 0})}>Creators</Button>
            <Button style={aboutButtons} onClick={() => this.setState({index: 1})}>Engineering</Button>
            <Button style={aboutButtons} onClick={() => this.setState({index: 2})}>Registration</Button>
            <Button style={aboutButtons} onClick={() => this.setState({index: 3})}>Graphic Design</Button>
            <Button style={aboutButtons} onClick={() => this.setState({index: 4})}>Crisis Hotline</Button>
          </div>
            
              <h3 className={classes.parastyle}>
              <strong>{aboutTeamArray[this.state.index].name }</strong>
                </h3>  
              <p className={classes.parastyle}>
                {aboutTeamArray[this.state.index].description}
              </p>
            
            </div>
          </GridItem>

          <GridContainer className={classes.gridContainer}>
            {aboutTeamArray[this.state.index].teamImage.map((member, index)=> 
             <ProfileCard 
             key={index} 
             name={member.name} 
             title={member.title} 
             year={member.year} 
             imageSrc={member.imageSrc} 
             description={member.description} />
             )}
          </GridContainer>


          <GridItem className={classes.aboutDiv}>
            <div>
              <h3 className={classes.clestyle}>
                <strong>About Cleveland State University </strong>
              </h3>
              <p className={classes.parastyle}>
                Founded in 1964, Cleveland State University is a public research
                institution that provides a dynamic setting for Engaged
                Learning. With 17,000-plus students, ten colleges and schools,
                and more than 175 academic programs, CSU was again chosen for
                2019 as one of America’s best universities by U.S. News World
                Report. Find more information at{" "}
                <a style={{ color: "#3596d4" }} href="https://www.csuohio.edu/">
                  <strong>www.csuohio.edu</strong>
                </a>
                .
              </p>
            </div>
          </GridItem>
        </div>
      </React.Fragment>
    );
  }
}

About.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: bindActionCreators(login, dispatch),
    getLoggedInUser: bindActionCreators(getLoggedInUser, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(registerPageStyle)(About));