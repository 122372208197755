import React, { useEffect, useState } from "react";
import api from "utils/api";
import {
    Grid,
    Paper,
    Tooltip,
    Typography,
    withStyles,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";

const styles = (theme) => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 1,
        paddingBottom: theme.spacing.unit * 1,
        margin: "10px 0px 10px 0px",
        width: "65%",
    },
    fab: {
        margin: theme.spacing.unit,
    },
});

function UnregisterServices(props) {

    const { classes } = props;

    const [unregisterService, setUnRegisteredServices] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const unRegisteredServices = async () => {
            try {
                const response = await api.get("unRegisteredServices");
                if (isMounted) {
                    setUnRegisteredServices(response || []);
                }
            } catch (error) {
                console.log("error", error);
            }
        };

        unRegisteredServices();

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <>
            {unregisterService.map((service, i) => (
                <Grid
                    container
                    spacing={16}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    key={service.id}
                >
                    <Paper className={classes.root} style={{ alignItems: 'center' }}>
                        <Grid container direction="row" spacing={8}>
                            <Grid sm={9} md={9} lg={9} item>
                                <Typography>
                                    <b>{service.name}</b> - This Service is Unregistered
                                </Typography>
                            </Grid>
                            <Grid sm={3} md={3} lg={3} item>
                                <Tooltip title={'Unregistered Service'}>
                                    <ErrorIcon fontSize="large" style={{ color: "#d0d1d3" }} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            ))}
        </>
    )
}

export default withStyles(styles)(UnregisterServices);