const popupStyle = {
    modalContainer: {
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "400px"
    },
    dialogContainer: {
        borderRadius: "20px"
    },
    dialogTitlee: {
        backgroundColor: "#233750",
        padding: "15px",
        margin: "10px",
        borderRadius: "5px"
    },
    dialogTitleIcon: {
        color: "white",
        padding: "6px !important",
        width: "45px",
        height: "42px",
        display: "flex",
        justifyContent: "center"
    },
    title: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    dialogContent: {
        padding: "15px"
    },
    popUpInput: {
        minWidth: "100%",
        margin: "10px 0px",
        padding: "10px",
        outline: "none",
        border: "2px solid #233750",
        borderRadius: "8px",
    },
    popupBtn: {
        outline: "none",
        margin: "11px 0px 11px 5px",
        padding: "10px",
        textTransform: "none",
        borderRadius: "8px",
        width: "50px",
    },
    cancelBtn: {
        color: "white",
        backgroundColor: "#999"
    },
    agreeBtn: {
        color: "white",
        backgroundColor: "#d9562c",

        "@media (min-width: 959px)": {
            "&:hover": {
                backgroundColor: "#3d97d3"
            }
        }
    },
    popUpTitle: {
        fontWeight: 400,
        color: "white",
        fontSize: "20px",
        textTransform: "none"
    },
    popUpFontStyle: {
        color: "#233750",
        fontWeight: 500,
        fontSize: "15px",
    },
    popUpCloseIcon: {
        color: "white",
        cursor: "pointer"
    },
    popupTypography: {
        fontSize: "15px",
        color: "white",
        fontWeight: "normal",
        fontFamily: "Calibri",
        display: "inline-block",
        whiteSpace: "pre-line"
    }
}

export default popupStyle;