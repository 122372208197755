import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
import promise from "redux-promise-middleware";

const initialState = {};
let store = null;
const config = (state = initialState) => {
  const middlewares = [thunk, promise];
  if(process.env.NODE_ENV === `development`) {
    const { logger } = require(`redux-logger`);
    middlewares.push(logger);
  }
  const tempStore = createStore(
    rootReducer,
    state,
    compose(
      applyMiddleware(...middlewares),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );
  store = tempStore;
  return tempStore;
};

export const initStore = () => {
  return config();
};
export const getStore = () => {
  if (store === null) {
    return initStore();
  }
  return store;
};
