import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import TimeInfoDialog from "../../../Components/TimeInfoDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../../../utils/api";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Create";
import moment from "moment";

const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});
const startTime = moment();
startTime.hours(9);
startTime.minutes(0);
startTime.seconds(0);

const endTime = moment();
endTime.hours(17);
endTime.minutes(0);
endTime.seconds(0);
class TimeInfoStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeInfos: [],
      loadingTimeInfos: true,
      timeInfoData: {},
      editTimeinfoID: ""
    };
  }
  getTimeInfoForCategory = async () => {
    this.setState({ loadingTimeInfos: true });
    api.get(
      `/categories/${this.props.categoryID}/timeInfos`,
      data => {
        let timeInfoData = this.props.data||{};
        // data.forEach(el => {
        //   timeInfoData[el.id] = defaultTimeData;
        // });
        this.setState({
          loadingTimeInfos: false,
          timeInfos: data,
          timeInfoData
        });
      },
      () => {
        this.setState({ loadingTimeInfos: false, timeInfos: [] });
      }
    );
  };
  componentWillMount() {
    this.getTimeInfoForCategory();
  }
  

  isValidated = () => {
    return true;
  };
  sendState = () => {
    let data = [];
    Object.keys(this.state.timeInfoData).forEach((timeInfoID) => {
      let infoData = this.state.timeInfoData[timeInfoID]||[];
      infoData.forEach((info={}) => {
        let temp = {...info};
        temp.start = moment(temp.start).format('HH:mm:ss');
        temp.end = moment(temp.end).format('HH:mm:ss');
        temp.time_info_id = timeInfoID;
        data.push(temp);
      })
    });
    return data;
  };
  editTimeInfo = id => {
    this.setState({ editTimeinfoID: id });
  };
  
  handleClose = () => {
    this.setState({editTimeinfoID:""});
  }

  handleUpdate = (id, data) => {
    let timeInfoData = this.state.timeInfoData;
    data.time_info_id = id;
    timeInfoData[id] = data;
    this.setState({timeInfoData, editTimeinfoID:""})
  }
  handleUpdate247 = (id, data) => {
    let timeInfoData = this.state.timeInfoData;
    data.time_info_id = id;
    timeInfoData[id] = data;
    this.setState({timeInfoData, editTimeinfoID:""})
  }
  handleUpdate95 = (id, data) => {
    let timeInfoData = this.state.timeInfoData;
    data.time_info_id = id;
    timeInfoData[id] = data;
    this.setState({timeInfoData, editTimeinfoID:""})
  }
  render() {
    const {
      loadingTimeInfos = true,
      classes = {},
      timeInfos = [],
      timeInfoData = {},
      editTimeinfoID = ""
    } = this.state;
    const { theme = {} } = this.props;
    return (
      <div>
        {loadingTimeInfos && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress className={classes.progress} color="secondary" />
            <p>Loading time information</p>
          </div>
        )}
        {!loadingTimeInfos &&
          timeInfos.map(info => (
            <ExpansionPanel key={info.id}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>{info.name}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <GridContainer>
                  <GridItem xs={12}>
                    <Typography variant="body">{info.description}</Typography>
                  </GridItem>
                  <GridItem xs={12} style={{ marginTop: "1em" }}>
                    <Table className={classes.table}>
                      <TableHead>
                      {/* <Button variant="contained" color="primary" onClick={()=>this.editTimeInfo(info.id)}>
                        <EditIcon
                          style={{ "margin-right": theme.spacing.unit }}
                        />
                        Edit {info.name}
                      </Button> */}
                        <TableRow>
                          <TableCell>Weekday</TableCell>
                          <TableCell align="left">Start Time</TableCell>
                          <TableCell align="left">End Time</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(timeInfoData[info.id] || []).map(row => (
                          <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                              {weekDays[row.day]}
                            </TableCell>
                            {!row.active  && (
                              <TableCell align="center" colSpan={2}>
                                Closed
                              </TableCell>
                            )}
                            {row.active && (
                              <TableCell align="left">
                                {moment(row.start).format("hh:mm A")}
                              </TableCell>
                            )}
                            {row.active && (
                              <TableCell align="left">
                                {moment(row.end).format("hh:mm A")}
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                        {(timeInfoData[info.id] || []).length === 0 && <TableCell align="center" colSpan={3}>
                                Information not provided
                              </TableCell>}
                      </TableBody>
                    </Table>
                    <div
                      style={{
                        marginTop: theme.spacing.unit * 3,
                        textAlign: "right"
                      }}
                    >
                      <Button variant="contained" color="primary" onClick={()=>this.editTimeInfo(info.id)}>
                        <EditIcon
                          style={{ marginRight: theme.spacing.unit }}
                        />
                        Edit {info.name}
                      </Button>
                    </div>
                    {editTimeinfoID === info.id && 
                    (<TimeInfoDialog 
                      data={timeInfoData[info.id]} 
                      infoID = {info.id} 
                      open={true} 
                      handleClose={this.handleClose} 
                      handleUpdate={this.handleUpdate} 
                      handleUpdate247={this.handleUpdate247} 
                    // handleUpdate95={this.handleUpdate95}
                    />)}
                  </GridItem>
                </GridContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        {/* <TimeInfoDialog open={true} title="Service Hourse" description="Service hours will be displayed here"/> */}
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(TimeInfoStep);
