import api from '../utils/api';
import { GET_HOTLINES, DELETE_HOTLINE } from '../utils/types';

//api to get all hotlines for admin view
export const getHotlines = ({ nameLike = "", page = 1, limit = 9, sortBy = "updated_at", isSorting = 1 }, ...extra) => ({
    type: GET_HOTLINES,
    payload: api.post(`/getallhotlines?nameLike=${nameLike}&page=${page}&limit=${limit}&sortBy=${sortBy}&isSorting=${isSorting}`, null, ...extra)
});

export const deleteHotline = (id, ...extra) => ({
    type: DELETE_HOTLINE,
    payload: api.delete(`/hotline/${id}`, null, ...extra)
});

//api call to get hotline by category for non-admin view
export const hotlinesByCategory = (id, pageNo, data, ...extra) => ({
    type: GET_HOTLINES,
    payload: api.post(`/category/${id}/hotlines?page=${pageNo}`, data, ...extra)
});
