import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import drguhelp_quick_guide_version_3 from '../../assets/files/Drughelp.care_Quick_Guide_version_3.pdf';
import { connect } from 'react-redux';
import { login, getLoggedInUser } from '../../actions/action_auth';
import { bindActionCreators } from 'redux';

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      drughelp_quick_guide: drguhelp_quick_guide_version_3,
      email: '',
      password: '',
    };
  }

  componentDidMount() {
    const { auth, history, getLoggedInUser } = this.props;

    if (auth.loggedIn && localStorage.getItem('drughelp-token')) {
      history.push("/admin/dashboard");
    }

    getLoggedInUser();

    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(() => {
      this.setState({ cardAnimaton: "" });
    }, 700);
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  componentWillUpdate(nextProps) {
    const { auth, history } = this.props;

    if (!auth.loggedIn && nextProps.auth.loggedIn) {
      history.push("/admin/dashboard");
    }
  }

  login = () => {
    const { email, password } = this.state;
    this.props.login({ email, password });
  }

  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.container}>
          <GridItem xs={12} mx={4} my={2}>
            <div style={{ marginBottom: "40px" }}>
              <h2 className={classes.FAQ_Title} style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}><strong>Frequently Asked Questions:</strong></h2>
              <p style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}>
                <strong>How do I search for treatment?</strong>
              </p>
              <p style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}>
                Click here for a <a style={{ color: "#3596d4" }} rel="noopener noreferrer" target="_blank" href={this.state.drughelp_quick_guide}>Quick Guide to Using drughelp.care</a>
              </p>
              <p style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}><strong>How do I get my agency listed on drughelp.care?</strong></p>
              <p style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}>We have a registration team ready to help you. Please email us at admin@drughelp.care. Registration takes about an hour (depending on how many services you provide), and we can help you over the phone or schedule a time to meet with you in person. We provide a Zoom option in addition to phone.</p>
              <p style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}><strong>What does it cost?</strong></p>
              <p style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}>Drughelp.care is free for agencies who want to list their services and free for anyone who wants to search for treatment and resources. It is developed by faculty and students at Cleveland State University to help our community.</p>
              <p style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}><strong>Do I have to be located in Cleveland to be listed on drughelp.care?</strong></p>
              <p style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}>Drughelp.care is developed by faculty and students at Cleveland State University in Cleveland, Ohio. Treatment providers throughout Northern Ohio who serve residents of Cuyahoga County are welcome to register on drughelp.care.&nbsp;&nbsp;&nbsp;</p>
              <p style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}><strong>How often do I have to update my agency&rsquo;s slot information? Why?</strong></p>
              <p style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}>Drughelp.care is valuable because we provide real-time information about available treatment slots for people who want to get help immediately. We ask agencies to take a few seconds every morning to update information about the number of available slots and also wait time if no slot is available.</p>
              <p style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}>
                <strong>How do I update my agency's slot information?</strong>
              </p>
              <p style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}>Click here for a quick tutorial on <a style={{ color: "#3596d4" }} target="_blank" rel="noopener noreferrer" href="http://drughelp.care/slot_update.pdf">How to Update Available Slots</a></p>
              <p style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}><strong>What about my privacy? Will I get in trouble if I search for treatment or a needle exchange?</strong></p>
              <p style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}>Drughelp.care does not collect any personal information from people using the website. Your search is always anonymous.</p>
              <p style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}><strong>Who can I contact if I have more questions?</strong></p>
              <p style={{ color: "#233750", textAlign: "justify", fontFamily: 'Calibri' }}>Please email <a style={{ color: "#3596d4" }} href="mailto:admin@drughelp.care">admin@drughelp.care</a> with any questions you have about our site.</p>
            </div>
          </GridItem>
        </div>
      </React.Fragment>
    );
  }
}

FAQ.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    ...state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    login: bindActionCreators(login, dispatch),
    getLoggedInUser: bindActionCreators(getLoggedInUser, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(registerPageStyle)(FAQ));