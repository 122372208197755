import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { ReactComponent as LeftArrow } from "./Icons/Left_arrow.svg"
import { ReactComponent as RightArrow } from "./Icons/Right_arrow.svg"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import paginationStyle from "assets/jss/material-dashboard-pro-react/components/paginationStyle.jsx";

function Pagination({ classes, pages, color, currentPage, lastPage, gotoEnteredPage }) {

  const pageCount = Array.from({ length: lastPage }, (_, i) => i + 1);

  const enteredPage = (e) => {
    gotoEnteredPage(e.target.value);
  }

  useEffect(() => {
    let select = document.querySelector("select");
    let options = pageCount.map(page => `<option value=${page.toString()}>${page}</option>`).join('\n');
    select.innerHTML = options;
    select.value = currentPage;
  }, [currentPage, pageCount]);

  return (
    <>
      <ul className={classes.pagination}>
        {pages.map((prop, key) => {
          const paginationLink = cx({
            [classes.paginationLink]: true,
            [classes[color]]: prop.active,
            [classes.disabled]: prop.disabled
          });
          const activeButton = prop.active
          return (
            <li className={classes.paginationItem} key={key}>
              {prop.onClick !== undefined ? (
                <Button onClick={prop.onClick} className={paginationLink} style={{ color: activeButton ? "white" : "#233750", backgroundColor: activeButton ? "#233750" : "white" }}>
                  {prop.text === "NEXT" ? currentPage < lastPage ? <RightArrow /> : null : prop.text === "PREV" ? currentPage > 1 ? <LeftArrow /> : null : prop.text || ''}
                </Button>
              ) : (
                <>
                </>
              )}
            </li>
          );
        })}
      </ul>
      <div className={classes.paginationDiv}>
        Jump to Page <select onChange={enteredPage} className={classes.paginationSelect} /> of {lastPage}
      </div>
    </>
  );
}

Pagination.defaultProps = {
  color: "primary"
};

Pagination.propTypes = {
  classes: PropTypes.object.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      disabled: PropTypes.bool,
      text: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(["PREV", "NEXT", "..."])
      ]).isRequired,
      onClick: PropTypes.func
    })
  ).isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(paginationStyle)(Pagination);
