import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import { connect } from 'react-redux';
import { login, getLoggedInUser } from '../../actions/action_auth';
import { bindActionCreators } from 'redux';

class TermsCondition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container} style={{ paddingTop: "0px" }}>
        <GridContainer justify="center" style={{ margin: 'auto' }}>
          <GridItem xs={12} style={{ margin: 'auto' }}>
            <div>
              <h2 className={classes.title}> <strong>Terms and Conditions of Use</strong></h2>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                Welcome to <i>drughelp.care</i>. Our goal is to enhance access to substance use treatment by allowing individuals and treatment providers to more quickly find treatments and services.
              </p>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                These terms and conditions outline the rules and regulations for the use of <i>drughelp.care</i>'s website. <i>drughelp.care</i> is a website being developed by researchers at Cleveland State University in Cleveland, Ohio, United States. By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use <i>drughelp.care</i>'s website if you do not accept all of the terms and conditions stated on this page.
              </p>

              <h4 style={{ color: "#233750", textAlign: "justify" }}>
                <strong>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements:</strong>
              </h4>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                “Client”, “You” and “Your” refers to you, the person accessing this website and accepting the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services/products, in accordance with and subject to, prevailing law of . Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.
              </p>
              <h4 style={{ color: "#233750", textAlign: "justify" }}>
                <strong>Cookies</strong>
              </h4>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                We employ the use of cookies. By using <i>drughelp.care</i>'s website you consent to the use of cookies in accordance with <i>drughelp.care</i>’s privacy policy. Most of the modern-day interactive websites use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those people visiting.
              </p>

              <h4 style={{ color: "#233750", textAlign: "justify" }}>
                <strong>License</strong>
              </h4>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                Unless otherwise stated, <i>drughelp.care</i> and/or its licensors own the intellectual property rights for all material on <i>drughelp.care</i>. All intellectual property rights are reserved. You may view and/or print pages from http://drughelp.care/ for your own personal use subject to restrictions set in these terms and conditions.
              </p>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                You must not:
              </p>
              <ul style={{ color: "#233750", textAlign: "justify" }}>
                <li>Republish material from http://drughelp.care/</li>
                <li>Sell, rent, or sub-license material from http://drughelp.care/</li>
                <li>Reproduce, duplicate, or copy material from http://drughelp.care/</li>
                <li>
                  Redistribute content from <i>drughelp.care</i> (unless content is
                  specifically made for redistribution)
                </li>
              </ul>
              <h4 style={{ color: "#233750", textAlign: "justify" }}>
                <strong>Hyperlinking to our Content</strong>
              </h4>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                The following organizations may link to our website without prior written approval:
              </p>
              <ul style={{ color: "#233750", textAlign: "justify" }}>
                <li>Government agencies;</li>
                <li>Search engines;</li>
                <li>News organizations;</li>
                <li>
                  Online directory distributors when they list us in the directory may link
                  to our website in the same manner as they hyperlink to the websites of other
                  listed businesses; and
                </li>
                <li>
                  Systemwide Accredited Businesses except soliciting non-profit
                  organizations, charity shopping malls, and charity fundraising groups which
                  may not hyperlink to our website.
                </li>
              </ul>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                These organizations may link to our home page, to publications or to other website information so long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.
              </p>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                We may consider and approve in our sole discretion other link requests from other types of organizations. We will approve link requests from organizations if we determine that: (a) the link would not reflect unfavorably on us or our accredited businesses (for example, trade associations or other organizations representing inherently suspect types of business, such as work-at-home opportunities, shall not be allowed to link); (b) the organization does not have an unsatisfactory record with us; (c) the benefit to us from the visibility associated with the hyperlink outweighs the absence of ; and (d) where the link is in the context of general resource information or is otherwise consistent with editorial content in a newsletter or similar product furthering the mission of the organization.
              </p>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                If you are among the organizations listed above and are interested in linking to our website, you must notify us by sending an e-mail to <a href="mailto:admin@drughelp.care">admin@drughelp.care</a>. Please include your name, your organization name, contact information (such as a phone number and/or e-mail address) as well as the URL of your site, a list of any URL's from which you intend to link to our website, and a list of the URL(s) on our site to which you would like to link. Allow 2-3 weeks for a response.
              </p>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                Approved organizations may hyperlink to our website as follows:
              </p>
              <ul style={{ color: "#233750", textAlign: "justify" }}>
                <li>By use of our corporate name; or</li>
                <li>By use of the uniform resource locator (web address) being linked to; or</li>
                <li>
                  By use of any other description of our website or material being linked to
                  that makes sense within the context and format of content on the linking
                  party's site.
                </li>
              </ul>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                No use of <i>drughelp.care</i>’s logo or other artwork will be allowed for linking absent a trademark license agreement.
              </p>


              <h4 style={{ color: "#233750", textAlign: "justify" }}>
                <strong>Cookies</strong>
              </h4>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                Cookies are used to distinguish among users of this website. This is a standard practice among Internet websites. Cookies may have unique identifiers, and reside on your computer, mobile device, in emails we send to you, on our web pages, or other places. This website uses cookies to store information about your use of the website, including your history of search filters, sorting options, or terms that you use to search our website.
              </p>

              <h4 style={{ color: "#233750", textAlign: "justify" }}>
                <strong>Reservation of Rights</strong>
              </h4>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                We reserve the right at any time and in its sole discretion to request that you remove all links or any particular link to our website. You agree to immediately remove all links to our website upon such request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuing to link to our website, you agree to be bound to and abide by these linking terms and conditions.
              </p>

              <h4 style={{ color: "#233750", textAlign: "justify" }}>
                <strong>Removal of links from our website</strong>
              </h4>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                If you find any link on our website or any linked website objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you. Whilst we endeavor to ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is kept up to date.
              </p>

              <h4 style={{ color: "#233750", textAlign: "justify" }}>
                <strong>Content Liability</strong>
              </h4>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                We shall have no responsibility or liability for any content appearing on your website. You agree to indemnify and defend us against all claims arising out of or based upon your website. No link(s) may appear on any page on your website or within any context containing content or materials that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
              </p>

              <h4 style={{ color: "#233750", textAlign: "justify" }}>
                <strong>Disclaimer</strong>
              </h4>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                To the maximum extent permitted by applicable law, we exclude all
                representations, warranties, and conditions relating to our website and the
                use of this website (including, without limitation, any warranties implied by
                law in respect of satisfactory quality, fitness for purpose and/or the use of
                reasonable care and skill). Nothing in this disclaimer will:
              </p>
              <ul style={{ color: "#233750", textAlign: "justify" }}>
                <li>
                  limit or exclude our or your liability for death or personal injury
                  resulting from negligence;
                </li>
                <li>
                  limit or exclude our or your liability for fraud or fraudulent
                  misrepresentation;
                </li>
                <li>
                  limit any of our or your liabilities in any way that is not permitted under
                  applicable law; or
                </li>
                <li>
                  exclude any of our or your liabilities that may not be excluded under
                  applicable law.
                </li>
              </ul>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                The limitations and exclusions of liability set out in this Section and
                elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and
                (b) govern all liabilities arising under the disclaimer or in relation to the
                subject matter of this disclaimer, including liabilities arising in contract,
                in tort (including negligence), and for breach of statutory duty. To the extent
                that the website and the information and services on the website are provided
                free of charge, we will not be liable for any loss or damage of any nature.
              </p>
              <h4 style={{ color: "#233750", textAlign: "justify" }}>
                <strong>Credit</strong>
              </h4>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                This terms and conditions page was created using the help of the termsandconditionstemplate.com generator.
              </p>

              <h4 style={{ color: "#233750", textAlign: "justify" }}>
                <strong>Contact</strong>
              </h4>
              <p style={{ color: "#233750", textAlign: "justify" }}>
                If you have any queries regarding our terms, please <a href="mailto:admin@drughelp.care" target="_top">contact us.</a>
              </p>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: bindActionCreators(login, dispatch),
    getLoggedInUser: bindActionCreators(getLoggedInUser, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(TermsCondition));