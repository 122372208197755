import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import TextField from "@material-ui/core/TextField";
import StandaloneSearchBox from "react-google-maps/lib/components/places/StandaloneSearchBox";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 10px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};
export class AddressStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      lat: "",
      lng: "",
      phone_number: "",
      website: ""
    };
  }
  componentDidMount = () => {
    const data = this.props.data || {};
    this.setState({
      ...data
    })
    
  }
  sendState = () => {
    return this.state;
  };
  isValidated() {
    return true;
  }
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  getComponentLongName = (address_components = [], name = "") => {
    return (
      (address_components.find(comp => comp.types.includes(name)) || {})
        .long_name || ""
    );
  };
  change(event, stateName, type, stateNameEqualTo) {
    let value = null;
    switch (type) {
      case "length":
        value = event.target.value;
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "image":
        value = stateNameEqualTo;
        break;
      case "switch":
        value = event.target.checked;
        break;
      default:
        value = event.target.value;
        break;
    }
    this.setState({ [stateName]: value });
  }
  onPlacesChanged = () => {
    const places = this.locationSearchBox.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const {
        address_components = [],
        geometry = {},
        formatted_phone_number = ""
      } = place;
      const location = geometry.location;
      const lat = location.lat();
      const lng = location.lng();
      const address1 =
        this.getComponentLongName(address_components, "street_number") +
        " " +
        this.getComponentLongName(address_components, "route");
      const address2 = "";
      const city = this.getComponentLongName(address_components, "locality");
      const state = this.getComponentLongName(
        address_components,
        "administrative_area_level_1"
      );
      const country = this.getComponentLongName(address_components, "country");
      const zip = this.getComponentLongName(address_components, "postal_code");
      const website = place.website;
      this.props.allStates.about.website = website;
      this.setState({
        lat,
        lng,
        address1,
        address2,
        city,
        state,
        country,
        zip,
        website,
        phone_number: formatted_phone_number.replace(/\D/g, "")
      });
      this.address2Ref && this.address2Ref.focus();
    }
  };
  render() {
    const { classes = {} } = this.props;
    
    
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>Tell us about your contact information</h4>
        </GridItem>
        <GridItem xs={12} sm={12} style={{ marginBottom: "30px" }}>
          <SearchLocation
            onSearchBoxMounted={ref => {
              this.locationSearchBox = ref;
            }}
            onPlacesChanged={this.onPlacesChanged}
            containerElement={<div />}
            mapElement={<div />}
            loadingElement={<div />}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCa952cWXECVQi36D1luAAcsaxuZuymIgI&libraries=places"
          />
        </GridItem>
        <GridItem xs={12} sm={5}>
          <RegularMap
            lat={this.state.lat}
            lng={this.state.lng}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCa952cWXECVQi36D1luAAcsaxuZuymIgI&libraries=places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{
                  height: `380px`,
                  borderRadius: "6px",
                  overflow: "hidden"
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </GridItem>
        <GridItem xs={12} sm={7}>
          <GridItem container>
            <GridItem xs={12}>
              <CustomInput
                labelText={<span>Building Address</span>}
                id="address1"
                formControlProps={{
                  required: true,
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.address1,
                  onChange: event =>
                  this.change(event, "address1", "length", 1)
                }}
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomInput
                labelText={<span>Apartment/Suite/Floor</span>}
                id="address2"
                formControlProps={{
                  required: true,
                  fullWidth: true
                }}
                inputProps={{
                  inputRef: ref => (this.address2Ref = ref),
                  value: this.state.address2,
                  onChange: event =>
                  this.change(event, "address2", "length", 1)
                }}
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomInput
                labelText={<span>City(Not editable)</span>}
                id="city"
                formControlProps={{
                  required: true,
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: this.state.city
                }}
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomInput
                labelText={<span>State(Not editable)</span>}
                id="state"
                formControlProps={{
                  required: true,
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: this.state.state
                }}
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomInput
                labelText={<span>ZIP Code(Not editable)</span>}
                id="zip"
                formControlProps={{
                  required: true,
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: this.state.zip
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                success={this.state.phone_numberState === "success"}
                error={this.state.phone_numberState === "error"}
                labelText={<span>Phone Number</span>}
                id="phone_number"
                formControlProps={{
                  required: true,
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.phone_number,
                  onChange: event =>
                    this.change(event, "phone_number", "length", 10)
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                success={this.state.websiteState === "success"}
                error={this.state.websiteState === "error"}
                labelText={<span>Website</span>}
                id="website"
                formControlProps={{
                  required: true,
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => this.change(event, "website", "length", 5),
                  value: this.state.website
                }}
              />
            </GridItem>
          </GridItem>
        </GridItem>
      </GridContainer>
    );
  }
}

const SearchLocation = withScriptjs(
  withGoogleMap(props => (
    <StandaloneSearchBox
      ref={props.onSearchBoxMounted}
      onPlacesChanged={props.onPlacesChanged}
    >
      <TextField
        autoFocus={true}
        fullWidth
        id="outlined-uncontrolled"
        defaultValue=""
        placeholder="Search location here"
        margin="normal"
        helperText="Please select address from here. This auto populate below address"
        variant="outlined"
      />
    </StandaloneSearchBox>
  ))
);

const RegularMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
      center={{ lat: props.lat || 40.748817, lng: props.lng || -73.985428 }}
    >
      {props.lat && props.lng && (
        <Marker position={{ lat: props.lat, lng: props.lng }} />
      )}
    </GoogleMap>
  ))
);

export default withStyles(style)(AddressStep);
