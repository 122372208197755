import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPublicCategories } from "../../actions/action_categories";
import { getPublicServices } from "../../actions/action_services";
import { getService } from "../../actions/action_services";
import { getFeaturesByCategory } from "../../actions/action_features";
import { getSlotInfoByService } from "../../actions/action_slotInfo";
import { bindActionCreators } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Typography from "@material-ui/core/Typography";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";

import WebIcon from "@material-ui/icons/Language";
import PhoneIcon from "@material-ui/icons/Phone";
import Tooltip from "@material-ui/core/Tooltip";
import "src/assets/img/TelehealthServicesIcon.svg";
import makeappointment from "assets/img/makeappointmenticon.svg";
import walkinswelcome from "assets/img/walkinswelcomeicon.svg";
import telehealthservices from "assets/img/telehealthservicesicon.svg";
import notelehealthservices from "assets/img/notelehealthservicesicon.svg";
import "src/assets/img/Notelehalth servicesicon.svg";
import viewServiceStyle from "assets/jss/material-dashboard-pro-react/views/viewServiceStyle";
import moment from "moment";

import { formatPhoneNumber } from "utils/serviceUtils";

const intl = window.intl;
class ViewService extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      service: {},
      lat: "",
      lng: "",
      currentDay: new Date(),
    };
  }
  componentDidMount() {
    this.props.getPublicCategories({});
    this.props.getService(this.props.match.params.id);
    const {
      service: { category = {} },
    } = this.props;
    if (category.id) {
      this.props.getFeaturesByCategory(category.id);
    }
    const {
      service: { service = {} },
    } = this.props;
    if (service.id) {
      this.props.getSlotInfoByService(service.id);
    }
    this.props.getSlotInfoByService(this.props.match.params.id);
  }

  componentWillUpdate = (nextProps, nextState) => {
    const {
      service: { category = {} },
    } = this.props;
    const {
      service: { service = {} },
    } = this.props;
    const {
      service: { category: nextCategory = {} },
    } = nextProps;
    const {
      service: { service: nextService = {} },
    } = nextProps;
    if (nextCategory.id !== category.id) {
      this.props.getFeaturesByCategory(nextCategory.id);
    }
    if (nextService.id !== service.id) {
      this.props.getSlotInfoByService(nextService.id);
    }
  };

  getTimeInfoInfo = (timeInfos = []) => {
    return timeInfos.reduce((acc, cur) => {
      let info = acc[cur.name] || { data: [] };
      info.data.push(cur.pivot);
      acc[cur.name] = info;
      return acc;
    }, {});
  };

  getAnswer = (que) => {
    let answer = "";
    if (que.type === "TEXT") {
      if (!que.pivot.answer || !isNaN(que.pivot.answer)) {
        return "";
      }
      answer = que.pivot.answer;
      //showing just answer
      return <strong>{answer}</strong>;
    } else {
      if (que.pivot.answer === "1" && que.active === true) {
        return <strong>{que.question + (answer && " => " + answer)}</strong>;
      } else {
        return "";
      }
    }
  };

  getAnswerUnbold = (que) => {
    let answer = "";
    if (que.type === "TEXT") {
      if (!que.pivot.answer || !isNaN(que.pivot.answer)) {
        return "";
      }
      answer = que.pivot.answer;
      //showing just answer
      return { answer };
    } else {
      if (que.pivot.answer === "1" && que.active === true) {
        return <span>{que.question + (answer && " => " + answer)}</span>;
      } else {
        return "";
      }
    }
  };

  getAnswerText = (question) => {
    if (question.type === "TEXT") {
      if (!question.pivot.answer || !isNaN(question.pivot.answer)) {
        return "";
      }
      return question.pivot.answer;
    } else {
      if (question.pivot.answer === "1") {
        return question.question;
      }
    }
  };

  getQuestionInfo = (questionInfos = []) => {
    return questionInfos.reduce((acc, cur) => {
      let info = acc[cur.feature] || { data: [] };
      info.data.push(cur);
      acc[cur.feature] = info;
      return acc;
    }, {});
  };
  getFeatureInfo = (questionInfos = []) => {
    return questionInfos.reduce((acc, cur) => {
      let info = acc[cur.id] || { data: [] };
      info.data.push(cur);
      acc[cur.id] = info;
      return acc;
    }, {});
  };
  getName = (feat, index) => {
    if (Object.keys(feat).length > 0 && feat[index].data[0].active) {
      return feat[index].data[0].name;
    }
    return "";
  };

  getDayTimeInfo = (pivot) => {
    if (!pivot || !pivot.active) {
      return "Closed";
    }
    return `${moment(pivot.start, "HH:mm:ss").format("hh:mm A")} - ${moment(
      pivot.end,
      "HH:mm:ss"
    ).format("hh:mm A")}`;
  };

  // create a new array of features where each feature has questions related to it
  getFeaturesWithAnswers = (features = [], questions = []) => {
    const featuresWithAnswers = [...features];
    const answersByFeatures = {};

    // group questions by their feature ids in answersByFeatures
    questions.forEach((questionWithAnswer) => {
      if (answersByFeatures[questionWithAnswer.feature])
        answersByFeatures[questionWithAnswer.feature].push(questionWithAnswer);
      else answersByFeatures[questionWithAnswer.feature] = [questionWithAnswer];
    });

    featuresWithAnswers.forEach((feature) => {
      if (answersByFeatures[feature.id])
        feature.answers = [...answersByFeatures[feature.id]];
    });

    return featuresWithAnswers;
  };

  renderInsuranceAccepted = (features) => {
    const featureName = "Insurance Accepted";
    let feature = features.find(
      (feature) => feature.name === featureName && feature.active
    );
    if (feature && feature.answers) {
      const answers = [];

      // get all answers
      feature.answers.forEach((question) => {
        if (question.active && question.pivot.answer === "1") {
          let questionText = question.question;
          // get a single text answer - this 'if' block is a specific exception/requirement
          // and only applies to "Insurance accepted"
          if (question.question === "Uninsured") {
            const textQuestion = feature.answers.find(
              (question) => question.id === 126 && question.pivot.answer
            );
            if (textQuestion) {
              questionText = `${questionText} (${textQuestion.pivot.answer.trim()})`;
            }
          }
          answers.push(questionText);
        }
      });

      return (
        <div>
          <span>{feature.name} - </span>
          <span className={"questionAnswers"}>
            <strong>{answers.join(", ")}</strong>
          </span>
          <div style={{ maxHeight: "5px" }}>&nbsp;</div>
        </div>
      );
    }
    return "";
  };

  renderMATInformation = (features) => {
    const { classes } = this.props;
    const MATfeatures = features.filter(
      (feature) => feature.name.includes("MAT - ") && feature.active
    );

    const MATAnswers = [];
    MATfeatures.forEach((MATfeature) => {
      if (MATfeature.answers) {
        let answer = MATfeature.answers.find(
          (answer) => answer.pivot.answer === "1"
        );
        if (answer) {
          MATAnswers.push({
            name: MATfeature.name.replace("MAT - ", "").trim(),
            answer: answer.question,
          });
        }
      }
    });

    if (MATAnswers.length > 0) {
      return (
        <>
          <span>
            MAT Information: <br />
          </span>
          <ul className={classes.matInfoUL}>
            {MATAnswers.map((matAnswer, index) => (
              <li key={index}>
                <span>
                  <strong>{matAnswer.name}</strong>
                  {` - ${matAnswer.answer}`}
                </span>
                <div style={{ maxHeight: "5px" }}>&nbsp;</div>
              </li>
            ))}
          </ul>
        </>
      );
    }
  };

  printDaySequence = (timeInfoName, dayInfo) => {
    let days = [
      "",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    dayInfo[7] = dayInfo[0];
    let start = "";
    let end = "";
    let output = "";
    let day1 = "";
    for (let i = 1; i <= 7; i++) {
      if (!dayInfo[i].active) {
        dayInfo[i].start = "CLOSED";
        dayInfo[i].end = "CLOSED";
      }

      if (!start && !end) {
        start = dayInfo[i].start;
        end = dayInfo[i].end;
        day1 = days[i];
        continue;
      }
      if (dayInfo[i].start === start && dayInfo[i].end === end) {
        if (i === 7) {
          if (day1 === days[1] && start === "CLOSED" && end === "CLOSED") {
            output = output + "NOT AVAILABLE\n";
          } else {
            output =
              output +
              (day1 === days[i] ? day1 : day1 + " - " + days[i]) +
              " : " +
              this.milToStandard(start) +
              (end === "CLOSED" ? "" : " to " + this.milToStandard(end)) +
              "\n";
          }
          break;
        } else continue;
      }

      if (i !== 7) {
        output =
          output +
          (day1 === days[i - 1] ? day1 : day1 + " - " + days[i - 1]) +
          " : " +
          this.milToStandard(start) +
          (end === "CLOSED" ? "" : " to " + this.milToStandard(end)) +
          "\n";
        start = dayInfo[i].start;
        end = dayInfo[i].end;
        day1 = days[i];
        continue;
      }
      /*output =
        output +
        (day1 === days[i] ? days[i] : day1 + " - " + days[i]) +
        " : " +
        this.milToStandard(start) +
        (end === "CLOSED" ? "" : " to " + this.milToStandard(end)) +
        "\n";*/
    }
    return output;
  };

  printToday = (timeInfoName, dayInfo) => {
    let count = 0;
    for (let i = 0; i <= 6; i++) {
      if (dayInfo[i].start === "CLOSED" && dayInfo[i].end === "CLOSED") {
        count = count + 1;
      }
    }
    if (count === 7) {
      return "NOT AVAILABLE";
    }
    if (dayInfo[this.state.currentDay.getDay()].start === "CLOSED")
      return "CLOSED";
    return (
      this.milToStandard(dayInfo[this.state.currentDay.getDay()].start) +
      " to " +
      this.milToStandard(dayInfo[this.state.currentDay.getDay()].end)
    );
  };

  showDropdownTime(e) {
    document.getElementsByClassName(
      "dropdownTime"
    )[0].hidden = !document.getElementsByClassName("dropdownTime")[0].hidden;
    document.getElementsByClassName(
      "goDown"
    )[0].hidden = !document.getElementsByClassName("goDown")[0].hidden;
    document.getElementsByClassName(
      "goUp"
    )[0].hidden = !document.getElementsByClassName("goUp")[0].hidden;
  }

  milToStandard(value) {
    if (value !== null && value !== undefined && value !== "CLOSED") {
      //If value is passed in
      if (value.indexOf("AM") > -1 || value.indexOf("PM") > -1) {
        //If time is already in standard time then don't format.
        return value;
      } else {
        if (value.length === 8) {
          // isn't it sort of a hard-coding
          //If value is the expected length for military time then process to standard time.
          var hour = value.substring(0, 2); //Extract hour
          var minutes = value.substring(3, 5); //Extract minutes
          var identifier = "AM"; //Initialize AM PM identifier

          if (Number(hour) === 12) {
            //If hour is 12 then should set AM PM identifier to PM
            identifier = "PM";
          }
          if (Number(hour) === 0) {
            //If hour is 0 then set to 12 for standard time 12 AM
            hour = 12;
          }
          if (hour > 12) {
            //If hour is greater than 12 then convert to standard 12 hour format and set the AM PM identifier to PM
            hour = hour - 12;
            identifier = "PM";
          }
          return hour + ":" + minutes + " " + identifier; //Return the constructed standard time
        } else {
          //If value is not the expected length than just return the value as is
          return value;
        }
      }
    }
    return "CLOSED";
  }

  getAdditionalInfoSections = (service) => {
    if (service.category && service.category.id) {
      const categoryId = service.category.id;
      if (categoryId === 3) {
        // treatment services
        return [
          "Eligibility Criteria",
          "Additional Services On-Site",
          "Focuses and Accommodations",
        ];
      } else if (categoryId === 4) {
        // harm reduction
        return ["Payment Methods"];
      } else if (categoryId === 5) {
        // peer and family support
        return [
          "Eligibility Criteria",
          "Focuses and Accommodations",
          "Payment Methods"
        ];
      }
    }
    return [];
  };

  render() {
    const { classes, service = {}, features = [], slots = [] } = this.props;
    const timeInfos = this.getTimeInfoInfo(service.time_info_data) || {};
    const questionInfos = this.getQuestionInfo(service.question_data) || {};
    const featureInfo = this.getFeatureInfo(features.data) || {};

    const featuresWithAnswers = this.getFeaturesWithAnswers(
      features.data,
      service.question_data
    );

    const additionalInfoSections = this.getAdditionalInfoSections(service);

    const regex = /(<([^>]+)>)/ig;

    const agencyDescription = () => {
      let words = service.agency.description.split("<p>");
      let description = words[1].split("</p>");
      let descriptions = description[0].replace(regex, "").replace(/\&nbsp;/g, '');
      return descriptions;
    }

    const serviceDescription = () => {
      let words = service.description;
      let word = words.replace(regex, '').replace(/\&nbsp;/g, '');
      return word;
    }

    if (service.servicesLoading || features.featuresLoading) {
      return (
        <GridContainer justify="center" className={classes.container}>
          <h2 className={classes.loadingText}>Loading...</h2>
        </GridContainer>
      );
    }

    if (!service.active) {
      return (
        <GridContainer justify="center" container className={classes.container}>
          <h2 className={classes.loadingText}>
            Currently service not available...
          </h2>
        </GridContainer>
      );
    }

    if (service.active) {
      return (
        <GridContainer className={classes.container}>
          <GridContainer
            justify="center"
            direction="row"
            alignItems="stretch"
            className={classes.subContainer}
          >
            <GridItem xs={12} sm={8} className={classes.serviceInfoContainer}>
              <GridItem
                xs={12}
                sm={8}
                className={classes.serviceTitleContainer}
              >
                <Card className={classes.titleCard}>
                  <CardContent style={{ padding: "0px" }}>
                    <Typography variant="h5" className={classes.serviceName}>
                      {service.name}
                    </Typography>
                    <Typography className={classes.agencyName}>
                      {service.agency.name}
                    </Typography>
                    <Typography className={classes.address}>
                      <a href={"http://maps.google.com/?q=" + service.address}>
                        {service.address}
                      </a>
                    </Typography>

                    {this.renderInsuranceAccepted(featuresWithAnswers)}

                    {/* Interventions Used */}
                    {(Object.keys(questionInfos) || []).map(
                      (question, index) => {
                        if (
                          "Interventions Used" ===
                          this.getName(featureInfo, question)
                        ) {
                          return (
                            <div key={index}>
                              <span>
                                {this.getName(featureInfo, question)} -{" "}
                              </span>
                              <span className={"questionAnswers"}>
                                {questionInfos[question].data.map((que) => {
                                  return this.getAnswer(que);
                                })}
                              </span>
                              <div style={{ maxHeight: "5px" }}>&nbsp;</div>
                            </div>
                          );
                        }
                        return "";
                      }
                    )}

                    {/* MAT Information */}
                    {this.renderMATInformation(featuresWithAnswers)}

                    {/* Mental Health Concerns */}
                    {(Object.keys(questionInfos) || []).map(
                      (question, index) => {
                        if (
                          "Mental Health Concerns" ===
                          this.getName(featureInfo, question)
                        ) {
                          return (
                            <div key={index}>
                              <span>
                                {this.getName(featureInfo, question)} -{" "}
                              </span>
                              <span className={"questionAnswers"}>
                                {questionInfos[question].data.map((que) => {
                                  return this.getAnswer(que);
                                })}
                              </span>
                              <div style={{ maxHeight: "5px" }}>&nbsp;</div>
                            </div>
                          );
                        }
                        return "";
                      }
                    )}
                    <span>
                      {service.agency.description ? "Agency Description:" : ""} <br />
                      <Typography>
                        {service.agency.description ? <b>{agencyDescription()}</b> : ""}
                      </Typography>
                    </span>
                    <span>
                      {service.description ? "Service Description:" : ""} <br />
                      <Typography>
                        {service.description ? <b>{serviceDescription()}</b> : ""}
                      </Typography>
                    </span>
                  </CardContent>
                </Card>
              </GridItem>

              <GridItem
                xs={12}
                sm={4}
                style={{ display: "inline-block", padding: "0px" }}
              >
                {/* Phone and email */}
                <Card className={classes.callWebCard}>
                  <CardContent style={{ padding: "0px" }}>
                    <Typography className={classes.pos} color="textSecondary">
                      <Tooltip title={service.phone_number} placement="bottom">
                        <a
                          href={"tel:${" + service.phone_number + "}"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <IconButton
                            aria-label=""
                            className={classes.iconButton}
                          >
                            <PhoneIcon />
                          </IconButton>
                          {formatPhoneNumber(service.phone_number)}
                        </a>
                      </Tooltip>
                    </Typography>
                    <Typography
                      className={classes.pos + " link"}
                      color="textSecondary"
                      style={{ color: "#3d97d3" }}
                    >
                      <Tooltip
                        title={
                          intl.formatMessage({
                            id: "view.services.openWebSite",
                          }) +
                          " : " +
                          service.website
                        }
                        placement="bottom"
                      >
                        <a
                          href={service.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <IconButton
                            aria-label={intl.formatMessage({
                              id: "view.services.openWebSite",
                            })}
                            className={classes.iconButton}
                          >
                            <WebIcon />
                          </IconButton>
                          <p className={"linktext"}>{service.website}</p>
                        </a>
                      </Tooltip>
                    </Typography>
                  </CardContent>
                </Card>

                {/* Slot Info        */}
                {(slots.data || []).length > 0 ? (
                  <div style={{ display: "table" }}>
                    {(slots.data || []).map((slot_info) => {
                      return (
                        <div>
                          <strong>Slot Availability : </strong>
                          {slot_info.availability}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div />
                )}
                {(slots.data || []).length > 0 ? (
                  <div style={{ display: "table" }}>
                    {(slots.data || []).map((slot_info) => {
                      if (
                        slot_info.wait_time !== 0 &&
                        slot_info.wait_time == null
                      ) {
                        return (
                          <div>
                            <strong>Wait Time : </strong>
                            {slot_info.wait_time}
                          </div>
                        );
                      }
                      return "";
                    })}
                  </div>
                ) : (
                  <div />
                )}

                {/* DROPDOWN               */}

                <div style={{ marginTop: "30px", marginBottom: "10px" }}>
                  <strong
                    className={"text-blue largeText"}
                    style={{ fontSize: "30px", color: "#3D97D3" }}
                    onClick={this.showDropdownTime}
                  >
                    OPEN<span class="goDown">&#x25BC;</span>
                    <span class="goUp" hidden>
                      &#x25B2;
                    </span>
                  </strong>
                </div>

                <div class="dropdownTime" hidden>
                  <div class="triangle">&#x25B2;</div>
                  {Object.keys(timeInfos).map((timeInfoName) => {
                    const dayInfo = timeInfos[timeInfoName].data.reduce(
                      (acc, cur) => {
                        acc[cur.day] = cur;
                        return acc;
                      },
                      {}
                    );
                    return (
                      <GridItem>
                        <h5 style={{ color: "#d9552c" }}>{timeInfoName}</h5>
                        {/* <p>{this.state.currentDay.getDay()}</p>
                        <p>{this.getDayTimeInfo(dayInfo[2])}</p> */}
                        <p style={{ whiteSpace: "pre-line" }}>
                          {this.printDaySequence(timeInfoName, dayInfo)}
                        </p>
                      </GridItem>
                    );
                  })}
                </div>

                {/* TODAY  */}
                {Object.keys(timeInfos).map((timeInfoName) => {
                  const dayInfo = timeInfos[timeInfoName].data.reduce(
                    (acc, cur) => {
                      acc[cur.day] = cur;
                      return acc;
                    },
                    {}
                  );
                  return (
                    <div>
                      <div>
                        <strong>{timeInfoName} (Today)</strong>
                      </div>
                      <p>{this.printToday(timeInfoName, dayInfo)}</p>
                    </div>
                  );
                })}
              </GridItem>
            </GridItem>

            <GridItem xs={0} sm={4} md={4} style={{ marginTop: "12px" }}>
              {service.lat && service.lng && (
                <RegularMap
                  lat={parseFloat(service.lat)}
                  lng={parseFloat(service.lng)}
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCa952cWXECVQi36D1luAAcsaxuZuymIgI&libraries=places"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={
                    <div
                      style={{
                        borderRadius: "6px",
                        overflow: "hidden",
                        height: "100%",
                      }}
                    />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                />
              )}
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer
            justify="left"
            direction="row"
            alignItems="stretch"
            className={classes.additionalInfoContainer}
          >
            <GridItem xs={12} className={classes.serviceInfoContainer}>
              <Typography variant="h5" className={classes.additionalInfo}>
                Additional Information and Criteria
              </Typography>
            </GridItem>
            <GridItem xs={12} style={{ display: "contents", fontSize: "14px" }}>
              {/* Mental Health Concerns */}
              {(Object.keys(questionInfos) || []).map((question, index) => {
                if (
                  additionalInfoSections.includes(
                    this.getName(featureInfo, question)
                  )
                ) {
                  return (
                    <GridItem
                      xs={12}
                      sm={6}
                      md={3}
                      className={classes.bottomHeadingContainer}
                    >
                      <Typography
                        variant="h5"
                        className={classes.bottomHeading}
                        style={{ fontSize: "15px" }}
                      >
                        {this.getName(featureInfo, question)}
                      </Typography>
                      <span className={"questionAnswersVertical"}>
                        {questionInfos[question].data.map((que) => {
                          return this.getAnswer(que);
                        })}
                      </span>
                    </GridItem>
                  );
                }
                return "";
              })}
            </GridItem>

            <GridItem xs={12} style={{ display: "contents", fontSize: "14px" }}>
              <GridItem
                xs={12}
                sm={6}
                md={3}
                className={classes.bottomHeadingContainer}
              >
                {(Object.keys(questionInfos) || []).map((question, index) => {
                  if (
                    ["Walk-Ins Welcome"].includes(
                      this.getName(featureInfo, question)
                    )
                  ) {
                    return (
                      <span className={"questionAnswersVertical"}>
                        {questionInfos[question].data.map((que) => {
                          let result = this.getAnswerText(que);
                          if (result) {
                            let text =
                              result === "No"
                                ? "Must Make an Appointment"
                                : "Walk-Ins Welcome";
                            let src =
                              result === "No"
                                ? makeappointment
                                : walkinswelcome;
                            return (
                              <strong>
                                <img
                                  src={src}
                                  alt={text}
                                  width="20"
                                  height="15"
                                />
                                {text}
                                <br />
                              </strong>
                            );
                          }
                          return "";
                        })}
                      </span>
                    );
                  }
                  return "";
                })}
                {(Object.keys(questionInfos) || []).map((question, index) => {
                  if (
                    ["Telehealth Services"].includes(
                      this.getName(featureInfo, question)
                    )
                  ) {
                    return (
                      <span className={"questionAnswersVertical"}>
                        {questionInfos[question].data.map((que) => {
                          let result = this.getAnswerText(que);
                          let src =
                            result === "Telehealth Available"
                              ? telehealthservices
                              : notelehealthservices;
                          if (result) {
                            return (
                              <strong>
                                <img
                                  src={src}
                                  alt={result}
                                  width="20"
                                  height="15"
                                />
                                {result}
                              </strong>
                            );
                          }
                          return "";
                        })}
                      </span>
                    );
                  }
                  return "";
                })}
              </GridItem>
            </GridItem>
          </GridContainer>
        </GridContainer>
      );
    }
  }
}
ViewService.propTypes = {
  classes: PropTypes.object.isRequired,
  //service: prototype.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    categories: state.categories,
    features: state.features,
    service: state.service,
    slots: state.slots,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPublicCategories: bindActionCreators(getPublicCategories, dispatch),
    getPublicServices: bindActionCreators(getPublicServices, dispatch),
    getService: bindActionCreators(getService, dispatch),
    getSlotInfoByService: bindActionCreators(getSlotInfoByService, dispatch),
    getFeaturesByCategory: bindActionCreators(getFeaturesByCategory, dispatch),
  };
};

const RegularMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
      center={{ lat: props.lat || 40.748817, lng: props.lng || -73.985428 }}
    >
      {props.lat && props.lng && (
        <Marker position={{ lat: props.lat, lng: props.lng }} />
      )}
    </GoogleMap>
  ))
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(viewServiceStyle)(ViewService));
