
const authStyle = theme => ({
    authContainer: {
        //position: "relative",
        minHeight: "100%",
        top: "0px",
        position: "absolute",
        width: "100%"
    }
})

export default authStyle;