import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";
import messages from "../locale/index";
import { flattenMessages } from "../utils/helper";
import { getStore } from "../store/configureStore";

addLocaleData(...en, ...es);
window.intl = {
  formatMessage: (data) => {
    return data.id;
  }
};
let prevLang = "";
const updateIntlProv = (lang = "en") => {
  const msgs = flattenMessages(messages[lang]);
  const intlProvider = new IntlProvider({ locale: lang, messages: msgs });
  const { intl } = intlProvider.getChildContext();
  window.intl = intl;
  prevLang = lang;
};
updateIntlProv();

const store = getStore();
store.subscribe(() => {
  const state = store.getState();
  const lang = ((state.auth || {}).user || {}).lang || "en";
  if(prevLang !== lang) {
      updateIntlProv(lang);
  }
});

export default window.intl;
