import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Switch from "@material-ui/core//Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import api from "utils/api";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
});

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionData: {},
    };
    this.features = [];
  }

  componentDidMount() {
    this.getFeatures();
  }

  getFeatures = () => {
    api.get(
      `hotline/features`,
      (featuresData = []) => {
        const hotlineQuestionData = this.props.data || {};
        let questionData = this.state.questionData;
        featuresData.forEach((feature) => {
          const questions = (feature.questions || []).filter((q) => q.active);
          questions.forEach((question) => {
            const existing = hotlineQuestionData[question.id] || {};
            const answer = existing.answer === 1;
            questionData[question.id] = {
              feature_id: feature.id,
              answer,
              id: existing.id,
            };
          });
        });
        this.features = [...featuresData];
        this.setState({ questionData });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  isValidated = () => {
    return true;
  };

  sendState = () => {
    const questionData = this.state.questionData;
    return Object.keys(questionData).map((id) => {
      let temp = questionData[id] || {};
      temp.question_id = id;
      return temp;
    });
  };

  handleOnChange = (evt, feature, id) => {
    let questionData = this.state.questionData || {};
    let temp = questionData[id] || {};
    temp.feature_id = feature;
    if (evt.target.type === "checkbox") {
      temp.answer = evt.target.checked;
    } else {
      temp.answer = evt.target.value;
    }
    questionData[id] = temp;
    this.setState({ questionData });
  };

  render() {
    const { questionData = {} } = this.state;

    return (
      <GridContainer>
        {this.features.map((feature = {}) => {
          const questions = (feature.questions || []).filter((q) => q.active);
          if (questions.length > 0) {
            return (
              <GridItem key={feature.id} xs={12}>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography color="primary" variant="body2">
                      {feature.name} <small>(click to expand)</small>
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <GridContainer>
                      {questions.map((question) => (
                        <GridItem xs={12} key={question.id}>
                          <FormControlLabel
                            control={
                              <Switch
                                value={questionData[question.id].answer}
                                checked={questionData[question.id].answer}
                                onChange={(evt) =>
                                  this.handleOnChange(
                                    evt,
                                    feature.id,
                                    question.id
                                  )
                                }
                                color="primary"
                              />
                            }
                            label={question.question}
                          />
                        </GridItem>
                      ))}
                    </GridContainer>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </GridItem>
            );
          } else {
            return null;
          }
        })}
      </GridContainer>
    );
  }
}

export default connect()(
  injectIntl(withStyles(styles, { withTheme: true })(Features))
);
