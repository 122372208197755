import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { CardActions, CardContent, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import chatBotStyle from "assets/jss/material-dashboard-pro-react/components/chatBotStyle.jsx";
import { ExpandMoreRounded } from "@material-ui/icons";
import popupStyle from "assets/jss/material-dashboard-pro-react/components/popupStyle.jsx";
import ChatBotZipcodePopup from "./ChatBotZipcodePopup";

const PeerAndFamilYOptions = ["Peer Support/Recovery",
  "Community Advocates",
  "Recovery Meetings/Groups",
  "Family Support Groups",]

class ChatWindow extends React.Component {
  ShowTheLocationWithRouter = withRouter(ChatWindow);

  constructor(props) {
    super(props);
    this.state = {
      istreatmentService: false,
      isHarmReduction: false,
      selectedOptions: [],
      answeredQuestions: 0,
      displaySelectedAnswers: [],
      flag: true,
      filterByLocation: false,
      resultsFetched: false,
      open: false,
      zipcode: "",
      city: ""
    }
    this.scrollRef = React.createRef();
  }

  quickSearch() {
    const { classes, questions } = this.props;
    return questions.map((question) => (
      <div className={classes.quickSearchDiv}>
        <span className={classes.fontStyle}>{question.questionText}</span>
      </div>
    ));
  }


  // this function alligns/add icon and spacing for chatBot messages 	
  // if you don't wanna put icon beside text message, pass true value as 2nd params while calling	
  chatBotReply(innerDiv, key, noIcon = false) {
    return (
      <Grid container justify="flex-start" key={key}>
        <Grid item sx={12} xs={12} md={12} lg={12}>
          <Grid container justify="flex-start" direction="column" alignItems="flex-end">
            {innerDiv}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  otherServices() {
    const { classes, peerAndFamily, getServiceCount, redirectServices, peerAndFamilyCount } = this.props;

    const serviceOptionDivs = PeerAndFamilYOptions.map((option, index) => {
      const isSelected = peerAndFamily.includes(option);
      const optionClass = isSelected ? classes.otherServicesDiv : classes.unSelectedOption;

      return this.chatBotReply(
        <div
          onClick={() => getServiceCount(option)}
          className={optionClass}
        >
          <span className={classes.unSelectedSpan}>{option}</span>
        </div>, index,
        true
      );
    });

    const servicesFoundDiv = peerAndFamilyCount > 0 && this.chatBotReply(
      <div onClick={() => redirectServices(peerAndFamily)} className={classes.questionsDiv}>
        <span className={classes.questionsSpan}>
          We found {peerAndFamilyCount} service{peerAndFamilyCount > 1 ? 's' : ''}, click here to access.
        </span>
      </div>
    );

    return (
      <>
        {this.chatBotReply(
          <div className={classes.questionsDiv}>
            <span className={classes.questionsSpan}>
              Which of these services would you like information about?
            </span>
          </div>
        )}
        {serviceOptionDivs}
        {servicesFoundDiv}
      </>
    );
  }

  viewAnsweredQuestions = () => {
    let { classes, questions, currQueNum } = this.props;
    return (
      <div className={classes.root}>
        <Grid item sx={12} xs={12} md={12} lg={12}>
          {
            questions.map((question, index) => {
              if (question && question.isAnswered && !question.stopPregnancyInquiry) {
                let answerOptions = question.answerOptions;
                return (
                  <div key={index}>
                    {/* Prints Answered Questions */}
                    <Grid container>
                      <Grid item sx={10} xs={10} md={10} lg={10}>
                        <Grid container justify="flex-start">
                          <div className={classes.questionsDiv}>
                            <span className={classes.questionsSpan}>
                              {question.questionText}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item sx={2} xs={2} md={2} lg={2}></Grid>
                    </Grid>

                    {/* Prints Answered Answers */}
                    <Grid container>
                      <Grid item sx={2} xs={2} md={2} lg={2}></Grid>
                      <Grid container item style={{ "margin": "10px 0" }}
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-end"
                        sx={10} xs={10} md={10} lg={10}>
                        {
                          answerOptions && answerOptions.map((answer, index) => {
                            let selectedAns = answer, currentQuestion = questions[currQueNum],
                              isAnswered = currentQuestion && currentQuestion.isAnswered;
                            return (
                              <div key={index} className={answer.selected ? classes.otherServicesDiv : classes.unSelectedOption}
                                onClick={() => {
                                  if (!isAnswered) isAnswered = true;
                                  this.handleSelectedOptions(selectedAns);
                                }}>
                                <span className={answer.selected ? classes.fontStyle : classes.unSelectedSpan}>
                                  {answer.answerText}
                                </span>
                              </div>
                            );
                          })
                        }
                      </Grid>
                    </Grid>
                  </div>
                );
              } else {
                return null;
              }
            })
          }
        </Grid>
      </div>
    );
  };

  handleSelectedOptions = (selectedAns) => {
    let { messages, questions } = this.props, ansText = selectedAns.answerText, flag = false;
    let { selectedOptions } = this.state, newArray = [];

    //Updates newly selected option in Messages array
    messages && messages.forEach((message) => {
      if (selectedAns && selectedAns.question_id === message.id && selectedAns.selected === false) {
        message.text = ansText;
      }
    });

    //Removes or Adds pregnancy query from Messages Array
    this.filterMesages(ansText);

    questions.forEach((question, index) => {
      if (question && question.answerOptions !== undefined) {
        if ('skipPregnancyInquiry' in question === true && question.skipPregnancyInquiry === true) {
          flag = true;
        }
        this.handlePregnancyQuestion(question, ansText, messages, flag);
        question.answerOptions.forEach((answer) => {
          if (answer !== undefined) {
            if (!answer.question_id) answer.question_id = index;
            //Sets previously selected option to false in questionsArray
            if (!question.isMultiSelect && answer.question_id === selectedAns.question_id && answer.answerText !== ansText) {
              answer.selected = false;
            }
            //Updates newly selected option to true in questionsArray
            if (!question.isMultiSelect && answer.question_id === selectedAns.question_id && answer.answerText === ansText) {
              answer.selected = true;
            }

            if (question.isMultiSelect && answer.question_id === selectedAns.question_id && answer.answerText === ansText) {
              answer.selected = !answer.selected;
            }
          }
        });
      }
    });

    //Updates SelectedOptions array for payload
    if (selectedOptions.length > 0) {
      newArray = selectedOptions.filter(ele => selectedAns.question_id !== ele.question_id);
      newArray.push(selectedAns);
    }

    this.setState({
      flag: !this.state.flag,
      selectedOptions: newArray,
    })
  };

  handlePregnancyQuestion = (question, ansText, messages, flag) => {
    if ('stopPregnancyInquiry' in question === true) {
      //Disables Pregnancy question for Male or Other
      if (ansText === "Male") {
        question.stopPregnancyInquiry = true;
      }
      //Enables Pregnancy question for Females
      if (ansText === "Female" || ansText === "Other") {
        question.stopPregnancyInquiry = false;
        question.isAnswered = true;
      }
    }
  }

  filterMesages = (ansText) => {
    let { messages, updateMessages, currQueNum } = this.props, msgIdArr = [];

    //Removes Pregnancy inquiry question from messages array
    if (ansText === "Male") {
      messages && messages.forEach((message, index) => {
        if (message && message.pregFlag) {
          msgIdArr.push(message.id);
        }
      });

      if (msgIdArr.length > 0) {
        let newMsgsArry = messages.filter(message => msgIdArr.includes(message.id) === false)
        updateMessages(newMsgsArry, true);
      }
    }

    //Adds Pregnancy inquiry question to messages array if not present
    if (ansText === "Female" || ansText === "Other") {
      if (message => messages.includes(message.text !== "Are you pregnant?")) {
        updateMessages(
          [{
            id: null,
            text: "Are you pregnant?",
            user: "user",
            options: [],
            pregFlag: true,
          },
          {
            id: currQueNum,
            text: ansText,
            user: "user",
            options: [],
            pregFlag: true,
          }]
        );
      }
    }
  }

  renderBotMessage = (message) => {
    const { classes } = this.props;

    return (
      <Grid item xs={10} md={10} lg={10}>
        <Grid container justify="flex-start">
          <div className={classes.botMessagesDiv}>
            <span className={classes.botMessagesSpan}>{message.text}</span>
          </div>
        </Grid>
      </Grid>
    );
  };

  renderBotOptions = (options) => {
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={2} md={2} lg={2}></Grid>
        <Grid item style={{ margin: "10px 0" }} xs={10} md={10} lg={10}>
          {options.map((value, index) => (
            <div
              key={index}
              className={classes.unSelectedOption}
              onClick={() => {
                if (value.includes("Treatment Services")) {
                  this.props.updateQuickSearch(true);
                  this.props.updateOtherServices(false);
                } else {
                  this.props.updateQuickSearch(false);
                  this.props.updateOtherServices(true);
                }
              }}
            >
              <span className={classes.unSelectedSpan}>{value}</span>
            </div>
          ))}
        </Grid>
      </Grid>
    );
  };

  content = () => {
    const { messages, classes } = this.props;

    return messages.map((message) => {
      if (message.user === "bot") {
        return (
          <div key={message.id} className={classes.root}>
            <Grid container>
              {message.text !== "" && this.renderBotMessage(message)}
              {message.options.length > 1 && this.renderBotOptions(message.options)}
            </Grid>
          </div>
        );
      }
      return null;
    });
  };

  zipCodeAlertBox = (requestData) => {
    let zipcode = prompt('Enter zip code/City name');
    this.props.getLatandLong(zipcode, requestData)
  }

  componentDidUpdate = () => {
    this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  handleMessages = (currQueNum, questionText, ansText) => {
    let { updateMessages } = this.props, flag = false;

    if (questionText && questionText === "Are you pregnant?") {
      flag = true;
    }

    updateMessages(
      [{
        id: null,
        text: questionText,
        user: "user",
        options: [],
        pregFlag: flag ? true : false,
      },
      {
        id: currQueNum,
        text: ansText,
        user: "user",
        options: [],
        pregFlag: flag ? true : false,
      }]
    );
  }

  filterPregnancyQuestion = (ansText, question) => {
    if (question) {
      if (question.questionText === "Are you pregnant?") {
        question.isPregAnswered = true;
      }

      if ((ansText === "Male") && !question.isPregAnswered) {
        question.skipPregnancyInquiry = true;
      }

      if (ansText === "Female" || ansText === "Other") {
        question.skipPregnancyInquiry = false;
      }
    }
  }

  clearMultiSelectOptions = (question) => {
    question.answerOptions && question.answerOptions.map((answer) => {
      answer.selected = false;
    });
  }

  handlePopUp = () => {
    const { open } = this.state;
    if (open) {
      this.setState({
        open: false,
        zipcode: "",
        city: ""
      })
    } else {
      this.setState({ open: true })
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleRequestData = () => {
    let { questions } = this.props, requestData = [];
    for (let i = 0; i < questions.length; i++) {
      let index = this.state.selectedOptions.findIndex(ele => ele.question_id === i)
      if (index !== -1) {
        requestData.push({
          [questions[i].step]: [this.state.selectedOptions[index]]
        })
      }
    }
    return requestData;
  }

  render() {
    let { classes, questions, quickSearch, otherServices, currQueNum } = this.props,
      ansText = null,
      currentQuestion = questions[currQueNum],
      newArray = [];
    const { bottomPosition, rightPosition } = this.props;
    return (
      <Card className={classes.container}>
        <strong>
          <CardActions className={classes.cardActions}>
            <Grid style={{ bottom: bottomPosition, right: rightPosition }} container direction="row" justify="flex-end">
              <Grid item sx={10} xs={10} md={10} lg={10}>
                <p className={classes.chatBotTitle}>Chatbot</p>
              </Grid>
              <Grid item sx={2} xs={2} md={2} lg={2}>
                <div className="minimize" onClick={() => this.props.toggleChat()} >
                  <ExpandMoreRounded className={classes.closeChat} />
                </div>
              </Grid>
            </Grid>
          </CardActions>
        </strong>
        <CardContent className={classes.content}>
          {this.content()}
          {this.viewAnsweredQuestions()}
          {quickSearch ?
            <Grid item sx={12} xs={12} md={12} lg={12}>
              <Grid container justify={"flex-end"}>
                <Grid item sx={10} xs={10} md={10} lg={10}>
                  {currentQuestion ? <div className={classes.questionsDiv}>
                    <span className={classes.questionsSpan}>
                      <span>{currentQuestion.questionText}</span>
                    </span>
                  </div> : null}
                  {(currQueNum + 1) > 7 ? <div className={classes.questionsDiv}>
                    <span className={classes.questionsSpan} onClick={() => {
                      let requestData = [];
                      for (let i = 0; i < questions.length; i++) {
                        let index = this.state.selectedOptions.filter(ele => ele.question_id === i)
                        if (index.length > 0) {
                          requestData.push({
                            [questions[i].step]: index
                          })
                        }
                      }
                      this.props.history.push({
                        pathname: "/auth/quick-search",
                        state: { userSelection: requestData },
                      })
                      setTimeout(() => {
                        this.setState({ resultsFetched: true });
                      }, 1000);
                    }}>Fetching the results for you. Click here to access the results.
                    </span>
                  </div> : null}
                  {this.state.resultsFetched ? <><div className={classes.questionsDiv}>
                    <span className={classes.questionsSpan}>
                      Would you like to narrow your search based on your location?
                    </span>
                  </div>
                  </> : null}
                </Grid>
                <Grid item sx={2} xs={2} md={2} lg={2}></Grid>

                {/* Prints Answer Options */}
                <Grid item sx={2} xs={2} md={2} lg={2}></Grid>
                <Grid container item sx={10} xs={10} md={10} lg={10}
                  style={{ "margin": "10px 0" }}
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-end">
                  {currentQuestion ?
                    <>
                      {currentQuestion.isMultiSelect && (
                        <p className={classes.multiSelectInfo}>Select All That Apply</p>
                      )}
                      {currentQuestion.answerOptions.map((answer, index) => {
                        if (currentQuestion.stopPregnancyInquiry) {
                          this.props.updateCurrQueNum(currQueNum + 1);
                        }
                        answer.question_id = currQueNum;
                        ansText = answer.answerText;
                        return <div key={index} className={answer.selected ? classes.otherServicesDiv : classes.unSelectedOption} onClick={() => {
                          this.filterPregnancyQuestion(answer.answerText, currentQuestion);
                          if (!currentQuestion.isMultiSelect) {
                            currentQuestion.isAnswered = true;
                            answer.selected = true;
                            answer.answerText === "Male" ? this.props.updateCurrQueNum(currQueNum + 2) : this.props.updateCurrQueNum(currQueNum + 1);
                            this.setState({
                              selectedOptions: [...this.state.selectedOptions, answer],
                            })
                          }
                          if (currentQuestion.isMultiSelect) {
                            answer.selected = !answer.selected;
                            if (answer.selected === false) {
                              newArray = this.state.selectedOptions.filter(ele => answer.answerText !== ele.answerText);
                              this.setState({
                                selectedOptions: newArray,
                              })
                            } else {
                              this.setState({
                                selectedOptions: [...this.state.selectedOptions, answer],
                              })
                            }
                          }
                          this.setState({
                            displaySelectedAnswers: [...this.state.displaySelectedAnswers, answer.answerText],
                            answeredQuestions: this.state.answeredQuestions + 1
                          })
                          this.handleMessages(currQueNum, currentQuestion.questionText, answer.answerText);
                        }}>
                          <span className={classes.unSelectedSpan}> {answer.answerText}</span>
                        </div>
                      })
                      }
                      <div className={classes.otherServicesDiv} onClick={() => {
                        currentQuestion.isAnswered = true;
                        this.filterPregnancyQuestion("Skip", currentQuestion);
                        if (currentQuestion.isMultiSelect) this.clearMultiSelectOptions(currentQuestion);
                        this.props.updateCurrQueNum(currQueNum + 1);
                        this.setState({
                          answeredQuestions: this.state.answeredQuestions + 1,
                        });
                        this.handleMessages(currQueNum, currentQuestion.questionText, "Skip");
                      }}><span className={classes.unSelectedSpan}>Skip</span>
                      </div>
                      {currentQuestion.isMultiSelect ? <>
                        <div className={classes.otherServicesDiv} onClick={() => {
                          currentQuestion.isAnswered = true;
                          this.props.updateCurrQueNum(currQueNum + 1);
                          this.setState({
                            answeredQuestions: this.state.answeredQuestions + 1,
                          });
                        }}><span className={classes.fontStyle}>Submit</span>
                        </div>
                      </> : null}
                    </> : null}
                  {this.state.resultsFetched ?
                    <>
                      <div className={classes.unSelectedOption} onClick={() => {
                        this.setState({
                          filterByLocation: true,
                          open: true
                        });
                      }}><span className={classes.unSelectedSpan}>Sort by Location</span>
                      </div>
                    </> : null}
                  {/* Tabs with Curr Location (or) Zipcode (or) City */}
                  {this.state.filterByLocation ?
                    <>
                      <ChatBotZipcodePopup
                        questions={questions}
                        open={this.state.open}
                        city={this.state.city}
                        zipcode={this.state.zipcode}
                        handleRequestData={this.handleRequestData}
                        getLatandLong={this.props.getLatandLong}
                        getUserGeoLocation={this.props.getUserGeoLocation}
                        handlePopUp={this.handlePopUp}
                        handleChange={this.handleChange} />
                    </>
                    : null}
                </Grid>
              </Grid>
            </Grid>
            : null}
          {otherServices ? this.otherServices() : null}
          <div ref={this.scrollRef} />
        </CardContent>
      </Card>
    );
  }
}

ChatWindow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (withStyles(chatBotStyle, popupStyle)(ChatWindow));