const chatBotStyle = {
  container: {
    maxWidth: "310px",
    position: "fixed",
    bottom: "107px",
    right: "40px",
    backgroundColor: "#233750",
    borderRadius: "30px",
    border: "1px solid #233750",
    zIndex: "1000",
    "@media (max-width: 600px)": {
      maxWidth: "325px",
    },
    "@media (max-width: 540px)": {
      bottom: "10px",
      right: "25px",
    },
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  content: {
    height: "400px",
    backgroundColor: "#f4f7fc",
    overflow: "auto",
    borderRadius: "0px 0px 30px 30px",
    padding: "15px 15px 15px 15px",
  },
  grid: {
    flexGrow: 1,
  },
  quickSearchDiv: {
    borderRadius: "15px",
    margin: "5px 5px 0px 5px",
    padding: "10px 15px",
    backgroundColor: "#d9552c",
    textAlign: "start",
    width: "fit-content",
    "@media (min-width: 959px)": {
      "&:hover": {
        cursor: "pointer",
      },
    }
  },
  fontStyle: {
    fontSize: 17,
    color: "white",
  },
  questionsDiv: {
    borderRadius: "15px 15px 15px 0px",
    margin: "5px 5px 0px 5px",
    padding: "8px 10px",
    backgroundColor: "#3d97d3",
    textAlign: "start",
    width: "fit-content",
    cursor: "pointer",
  },
  questionsSpan: {
    fontSize: 17,
    color: "white",
    display: "inline-block",
    whiteSpace: "pre-line",
  },
  otherServicesDiv: {
    borderRadius: "15px 15px 0px 15px",
    color: "white",
    margin: "5px 5px 0px 5px",
    padding: "5px 10px",
    backgroundColor: "#d9552c",
    border: "1px solid #d9552c",
    textAlign: "start",
    width: "fit-content",
    "@media (min-width: 959px)": {
      "&:hover": {
        cursor: "pointer",
      },
    },
    cursor: "pointer",
  },
  unSelectedOption: {
    borderRadius: "15px 15px 0px 15px",
    margin: "5px 5px 0px 5px",
    padding: "5px 10px",
    backgroundColor: "#f5f7fd",
    textAlign: "start",
    width: "fit-content",
    color: "#d9562c",
    border: "1px solid #d9552c",
    float: "right",
    "@media (min-width: 959px)": {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#d9552c",
        color: "white !important",
      },
    }
  },
  unSelectedSpan: {
    fontSize: 17,
  },
  otherServicesDivGreyout: {
    fontSize: 17,
    color: "black",
    borderRadius: "15px",
    margin: "5px 5px 0px 5px",
    padding: "10px 15px",
    backgroundColor: "#c7c7c7",
    textAlign: "start",
    width: "fit-content",
    cursor: "pointer",
    "@media (min-width: 959px)": {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#d9552c",
        color: "white !important",
      },
    }
  },
  webIconDiv: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: "45px",
    width: "45px",
    backgroundColor: "#3d97d3",
    borderRadius: "30px 30px 0px 30px",
    color: "white",
  },
  botMessagesDiv: {
    borderRadius: "15px 15px 15px 0px",
    margin: "5px 5px 0px 5px",
    padding: "5px 10px",
    backgroundColor: "#3d97d3",
    textAlign: "start",
    width: "fit-content",
  },
  userMessagesDiv: {
    borderRadius: "15px 15px 0px 15px",
    margin: "5px 5px 0px 5px",
    padding: "8px 10px",
    backgroundColor: "white",
    textAlign: "end",
    width: "fit-content",
    "@media (min-width: 959px)": {
      "&:hover": {
        cursor: "pointer",
      },
    }
  },
  botMessagesSpan: {
    fontSize: 17,
    color: "white",
    display: "inline-block",
    whiteSpace: "pre-line",
  },
  userMessagesSpan: {
    fontSize: 17,
    color: "#414042",
    display: "inline-block",
    whiteSpace: "pre-line",
  },
  userIconDiv: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: "45px",
    width: "45px",
    backgroundColor: "#233750",
    borderRadius: "30px 30px 30px 0px",
    color: "white",
  },
  servicesDiv: {
    borderRadius: "15px 15px 0px 15px",
    margin: "5px 5px 0px 5px",
    padding: "10px 15px",
    backgroundColor: "#d9552c",
    textAlign: "start",
    width: "fit-content",
    "@media (min-width: 959px)": {
      "&:hover": {
        cursor: "pointer",
      },
    },
    cursor: "pointer",
    float: "right",
  },
  sericesSpan: {
    fontSize: 17,
    color: "white",
    whiteSpace: "pre-line",
    display: "inline-block",
    textAlign: "justify",
    // textJustify: "inter-word"
  },
  cardActions: {
    height: "60px",
    cursor: "move",
  },
  cardContentDiv: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: "50px",
    width: "50px",
    backgroundColor: "#3d97d3",
    borderRadius: "30px 30px 0px 30px",
    color: "white",
  },
  closeChat: {
    marginRight: "20px",
    cursor: "pointer",
    color: "white",
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    padding: "6px",
    "@media (min-width: 959px)": {
      "&:hover": {
        backgroundColor: "#516682",
      },
    }
  },
  multiSelectInfo: {
    color: "#d9552c",
    padding: "0px",
    fontSize: "14px",
    margin: "0px",
    marginRight: "15px",
  },
  chatBotTitle: {
    margin: "0px",
    fontSize: "30px",
    padding: "10px",
    fontWeight: 100,
    color: "white",
  },
  dialogContainer: {
    borderRadius: "20px",
  },
  dialogTitlee: {
    backgroundColor: "#233750",
    padding: "15px",
    margin: "10px",
    borderRadius: "5px"
  },
  modalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  dialogTitleIcon: {
    color: "white",
    padding: "6px !important",
    width: "45px",
    height: "42px",
    display: "flex",
    justifyContent: "center"
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dialogContent: {
    padding: "15px",
  },
  popUpInput: {
    minWidth: "100%",
    margin: "10px 0px",
    padding: "10px",
    outline: "none",
    border: "2px solid #233750",
    borderRadius: "8px",
  },
  popupBtn: {
    color: "white",
    backgroundColor: "#d9562c",
    outline: "none",
    margin: "11px 0px 11px 5px",
    padding: "10px",
    textTransform: "none",
    borderRadius: "8px",
    "@media (min-width: 959px)": {
      "&:hover": {
        backgroundColor: "#3d97d3"
      }
    }
  },
  popUpTitle: {
    fontWeight: 400,
    color: "white",
    fontSize: "20px",
    textTransform: "none",
  },
  popUpFontStyle: {
    color: "#233750",
    fontWeight: 500,
    fontSize: "15px",
  },
  popUpCloseIcon: {
    color: "white",
    cursor: "pointer",
  },
  popupTypography: {
    color: "white",
    fontWeight: "normal",
    fontSize: "15px",
    fontFamily: "Calibri",
    display: "inline-block",
    whiteSpace: "pre-line",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  indicator: {
    background: "none",
  },
  flexContainer: {
    background: "white",
    color: "black"
  },
  selected: {
    backgroundColor: "#233750",
    color: "white",

    "@media (min-width: 959px)": {
      "&:hover": {
        backgroundColor: "#233750",
        color: "white"
      },
    }
  },
  tabWidth: {
    minWidth: "110px !important",
    textTransform: "none",
    fontSize: "15px"
  },
  tabWidth1: {
    minWidth: "165px !important",
    textTransform: "none",
    fontSize: "15px"
  }
};

export default chatBotStyle;
