import {
  GET_QUICK_SEARCH_LIST,
  FULFILLED,
  REJECTED,
  PENDING,
} from "utils/types";

const initialState = {
  loading: false,
  result: {},
  error: "",
};

export default function quickSearchListReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${GET_QUICK_SEARCH_LIST + PENDING}`:
      return { ...state, loading: true, result: {}, error: "" };
    case `${GET_QUICK_SEARCH_LIST + FULFILLED}`:
      return {
        ...state,
        loading: false,
        result: { ...payload },
      };
    case `${GET_QUICK_SEARCH_LIST + REJECTED}`:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
