import {
  GET_AGENCIES,
  GET_AGENCIES_FOR_MAP,
  FULFILLED,
  REJECTED,
  PENDING,
} from "utils/types";

import { formatMapAPIResponse } from "utils/mapUtils";

const initialState = {
  data: [],
  error: false,
  agenciesLoading: false,
};

export default function agenciesReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${GET_AGENCIES + PENDING}`:
      return { ...state, error: false, agenciesLoading: true, data: [] };
    case `${GET_AGENCIES + FULFILLED}`:
      return { ...state, ...payload, error: false, agenciesLoading: false };
    case `${GET_AGENCIES + REJECTED}`:
      return { ...state, error: true, agenciesLoading: false, data: [] };
    case `${GET_AGENCIES_FOR_MAP + PENDING}`:
      return { ...state, error: false, agenciesLoading: true, data: [] };
    case `${GET_AGENCIES_FOR_MAP + FULFILLED}`:
      return {
        ...state,
        data: [...formatMapAPIResponse(payload)],
        error: false,
        agenciesLoading: false,
      };
    case `${GET_AGENCIES_FOR_MAP + REJECTED}`:
      return { ...state, error: true, agenciesLoading: false, data: [] };
    default:
      return state;
  }
}
