import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    Typography,
} from "@material-ui/core";
import popupStyle from "assets/jss/material-dashboard-pro-react/components/popupStyle.jsx";
import { CheckOutlined, ErrorOutlineOutlined } from "@material-ui/icons";
import CardIcon from "components/Card/CardIcon.jsx";

class PopupView extends React.Component {

    handlePopUp = () => {
        const { openPopup, updateProps } = this.props;
        if (openPopup) {
            updateProps("openPopup", false);
        } else {
            updateProps("openPopup", true );
        }
    };

    handleChange = (event) => {
        const { updateProps } = this.props;
        updateProps(event.target.id, event.target.value );
    };

    render() {
        let { classes, openPopup, callbackFun, message, icon = null } = this.props;
        return (
            <Grid item xs={12} sm={12} md={3}>
                <div className={classes.modalContainer}>
                    <Dialog
                        open={openPopup}
                        onClose={() => this.handlePopUp()}
                        className={classes.dialogContainer}
                        maxWidth="sm"
                        BackdropProps={{ style: { backgroundColor: "#233750", opacity: 0.8 } }}
                    >   
                        <DialogTitle disableTypography className={classes.dialogTitlee}>
                            <Grid container>
                                <Grid sx={2} xs={2} md={2} lg={2}>
                                    <CardIcon color="rose" style={{color:"white", padding : "10px"}}>
                                        {icon ?  icon : <ErrorOutlineOutlined/> }
                                    </CardIcon>
                                </Grid>
                                <Grid sx={8} xs={8} md={8} lg={8}></Grid>
                                <Grid sx={2} xs={2} md={2} lg={2}>
                                    {/* <Typography component="div" align="right">
                                        <CloseIcon
                                            onClick={() => this.handlePopUp()}
                                            className={classes.popUpCloseIcon}
                                        />
                                    </Typography> */}
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <DialogContentText id="alert-dialog-description">
                                <FormControl className={classes.modalContainer} variant="outlined">
                                    <Grid container>
                                        <Grid sx={12} xs={12} md={12} lg={12} style={{marginBottom : "10px"}}>
                                            <Typography className={classes.popUpFontStyle}>
                                                {message}
                                            </Typography>
                                        </Grid>
                                        <Grid sx={8} xs={8} md={8} lg={8}></Grid>
                                        <Grid sx={2} xs={2} md={2} lg={2}>
                                            <Button
                                                onClick={() => {
                                                    this.handlePopUp();
                                                }} 
                                                className={classes.popupBtn + " " + classes.cancelBtn}
                                            >
                                                <CloseIcon/>
                                            </Button>
                                        </Grid>
                                        <Grid sx={2} xs={2} md={2} lg={2}>
                                            <Button
                                                onClick={() => {
                                                    this.handlePopUp();
                                                    callbackFun();
                                                }}
                                                className={classes.popupBtn + " " + classes.agreeBtn}
                                            >
                                                <CheckOutlined/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </div>
            </Grid>
        );
    }
}

PopupView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(popupStyle)(PopupView);