import { QUESTION_SWITCH, QUESTION_TEXT,QUESTION_LINK } from "./types";

export const flattenMessages = (nestedMessages, prefix = "") => {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

export const questionTypes = [
  { value: QUESTION_SWITCH, text: "Yes or No" },
  { value: QUESTION_TEXT, text: "Text" },
  { value: QUESTION_LINK, text: "LINK" }
];