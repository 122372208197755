import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import Switch from "@material-ui/core//Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const startTime = moment();
startTime.hours(9);
startTime.minutes(0);
startTime.seconds(0);

const endTime = moment();
endTime.hours(17);
endTime.minutes(0);
endTime.seconds(0);

const days = [
  { value: 0, label: "Sunday" },
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },

];


const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});
class TimeInfoDialog extends Component {
  isTimeInfoChanged = false;
  constructor(props) {
    super(props);
    this.state = {
      0: this.getDefaultTimeData(0),
      1: this.getDefaultTimeData(1),
      2: this.getDefaultTimeData(2),
      3: this.getDefaultTimeData(3),
      4: this.getDefaultTimeData(4),
      5: this.getDefaultTimeData(5),
      6: this.getDefaultTimeData(6),
    };
  }
  componentDidMount() {
    const data = this.props.data || [];
    data.forEach((el = {}) => {
      let day = el.day || 0;
      let timeInfo = el;
      if (Object.keys(timeInfo).length > 0) {
        timeInfo.start = moment(timeInfo.start);
        timeInfo.end = moment(timeInfo.end);
        this.setState({ [day]: timeInfo });
      } else {
        this.setState({ [day]: this.getDefaultTimeData(day) });
      }
    });
  }

  getDefaultTimeData = (day = 0) => {
    return {
      active: true,
      day: day,
      start: startTime,
      end: endTime
    };
  };

  handleUpdate = () => {
    const data = Object.values(this.state).map(element => {
      return {
        ...element,
        start: element.start.toDate(),
        end: element.end.toDate()
      };
    });
    this.isTimeInfoChanged = false;
    this.props.handleUpdate && this.props.handleUpdate(this.props.infoID, data);
  };
  
  handleUpdate247 = (day = 0) => {
    const st = moment();
    st.hours(24);
    st.minutes(0);
    st.seconds(0);
    const et = moment();
    et.hours(23);
    et.minutes(59);
    et.seconds(0);
    const data = Object.values(this.state).map(element => {
      return {
        ...element,
        start: st,
        end: et,
        active: true
      };
    });
    this.isTimeInfoChanged = true;
    this.props.handleUpdate247 && this.props.handleUpdate247(this.props.infoID, data);
  };
  
  // handleUpdate95 = (day = 0) => {

  //   const st = moment();
  //   st.hours(9);
  //   st.minutes(0);
  //   st.seconds(0);
  //   const et = moment();
  //   et.hours(17);
  //   et.minutes(0);
  //   et.seconds(0);
  //   debugger;
  //   const data = Object.values(this.state).map(element => {
   
  //     return {
  //       ...element,
  //       start: st,
  //       end: et,       
  //       active: false,
      
  //     };
  //   });
  //   this.props.handleUpdate95 && this.props.handleUpdate95(this.props.infoID, data);
  // };
  onChangeTime = (day, type, value) => {
    let data = this.state[day];
    data[type] = value;
    this.isTimeInfoChanged = true;
    this.setState({ [day]: data });
  };
  handleChange = (evt, day) => {
    let data = this.state[day];
    data.active = evt.target.checked;
    this.isTimeInfoChanged = true;
    this.setState({ [day]: data });
  };
  
  render() {
    const {
      title = "Time Information",
      description = "",
      open = false,
      handleClose,
      classes = {}
    } = this.props;
    return (
      <Dialog
        open={open}
        maxWidth="md"
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        BackdropProps={{ style: { backgroundColor: "#233750", opacity: 0.8 } }}
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        {description && (
          <DialogContent>
            <DialogContentText>{description}</DialogContentText>
          </DialogContent>
        )}
        <Table className={classes.table}>
          <TableHead>
            <Button variant="contained" color="primary" onClick={this.handleUpdate247} style={{ marginLeft:"10px"}}>
              24/7
            </Button>           

             {/* <Button variant="contained" color="primary" onClick={this.handleUpdate95}  marginLeft:"10px"}}>
              Mon-Fri 9-5
            </Button>  */}
            <TableRow>
              <TableCell>Weekday</TableCell>
              <TableCell align="left">Start Time</TableCell>
              <TableCell align="left">End Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {days.map(day => (
              <TableRow key={day.value}>
                <TableCell component="th" scope="row">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state[day.value].active}
                        value={this.state[day.value].active}
                        onChange={evt => this.handleChange(evt, day.value)}
                        color="primary"
                        id={day.value}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon: classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar
                        }}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label={day.label}
                  />
                </TableCell>
                <TableCell align="center">
                  <FormControl fullWidth>
                    <Datetime
                      onChange={val =>
                        this.onChangeTime(day.value, "start", val)
                      }
                      dateFormat={false}
                      value={this.state[day.value].start}
                      inputProps={{
                        placeholder: "Pick Start Time",
                        disabled: !this.state[day.value].active
                      }}
                    />
                  </FormControl>
                </TableCell>
                <TableCell align="center">
                  <FormControl fullWidth>
                    <Datetime
                      onChange={val => this.onChangeTime(day.value, "end", val)}
                      value={this.state[day.value].end}
                      dateFormat={false}
                      inputProps={{
                        placeholder: "Pick End Time",
                        disabled: !this.state[day.value].active
                      }}
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>

        {this.isTimeInfoChanged && (<p style={{ 'color': 'red' }}> *Changes will not be saved until you click  <strong> FINISH </strong> on the <strong> Service Contacts tab. </strong>  </p>)}

        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button
            onClick={this.handleUpdate}
            color="primary"
            variant="contained"
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TimeInfoDialog);