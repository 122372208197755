import React from "react";
import { useState, useEffect } from 'react';
import api from '../../utils/api';
import { Typography } from "@material-ui/core";
import { Timeline, TimelineItem } from "vertical-timeline-component-for-react";

const Events = () => {

    const [newsData, setNewsData] = useState([]);
    let isMounted = true;

    const getNewsData = async () => {
        try {
            const res = await api.get(`getNewsData`);
            let arr = res.filter(newsData => newsData.event !== 0);
            if (isMounted) {
                setNewsData(arr);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getNewsData();
        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <>
            <div style={{
                minHeight: "100%",
                padding: "100px 0px",
                zIndex: "4",
            }}>
                <div style={{ backgroundColor: '#f4f7fc', padding: '50px' }}>
                    <h1 style={{ color: '#233750', textAlign: 'center', textTransform: "uppercase" }}>
                        <strong style={{ textTransform: "none" }}>Events</strong>
                    </h1>
                    <Timeline lineColor={'#d9552c'}>
                        {newsData && newsData.length > 0 ? (
                            newsData.map((newsData, index) => (
                                <>
                                    <TimelineItem
                                        key={newsData.news_id}
                                        dateText={newsData.created_at}
                                        style={{ color: '#3d97d3' }}
                                        dateInnerStyle={{ background: '#233750', color: '#f4f7fc', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)' }}
                                        bodyContainerStyle={{
                                            background: '#f4f7fc',
                                            padding: '20px',
                                            borderRadius: '8px',
                                            boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.2)',
                                        }}
                                    >
                                        <a href={newsData.news_link} style={{
                                            color: "#3d97d3",
                                            textDecoration: "none",
                                            fontWeight: "bold",
                                            textTransform: "uppercase",
                                            transition: "color 0.3s ease-in-out",
                                        }}
                                            onMouseOver={(e) => {
                                                e.target.style.color = "#eaa531";
                                            }}
                                            onMouseOut={(e) => {
                                                e.target.style.color = "#233750";
                                            }}>
                                            {newsData.title}
                                        </a>
                                        <p style={{ color: '#414042', fontSize: '14px', margin: '10px 0' }}>Updated at {newsData.updated_at}</p>
                                        <p style={{ color: '#414042', fontSize: '16px' }}>{newsData.Content}</p>
                                        {newsData.image ?
                                            <img
                                                alt={newsData.title}
                                                src={`/images/${newsData.image}`}
                                                style={{ width: "100%", height: "75%", objectFit: "contain" }}
                                            />
                                            : null}
                                    </TimelineItem>
                                </>
                            ))) :
                            <Typography variant="h6" gutterBottom align="center" style={{ marginTop: "60px", textTransform: "none" }}>
                                {newsData.length === 0 ? "No Events Listed" : ""}
                            </Typography>
                        }
                    </Timeline>
                </div>
            </div>
        </>
    );
}

export default Events;