import {
    GET_HOTLINE_CATEGORIES,
    FULFILLED,
    REJECTED,
    PENDING,
  } from "../utils/types";
  
  const initialState = {
    data: [],
    error: false,
    loading: false,
  };
  
  export default function hotlineCategoriesReducer(state = initialState, action) {
    //console.log("logging data in hotline cat")
    const { type, payload } = action;
    switch (type) {
      case `${GET_HOTLINE_CATEGORIES + PENDING}`:
        return { ...state, error: false, loading: true, data: [] };
      case `${GET_HOTLINE_CATEGORIES + FULFILLED}`:
        return { ...state, data:[...payload] , error: false, loading: false };
      case `${GET_HOTLINE_CATEGORIES + REJECTED}`:
        return { ...state, error: true, loading: false };
      default:
        return state;
    }
  }
  