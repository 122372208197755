import api from '../utils/api';
import {GET_SERVICES, GET_SERVICE, GET_PUBLIC_SERVICES} from '../utils/types';


export const getServices = ({nameLike="",page=1, limit=9}, ...extra) => ({
    type: GET_SERVICES,
    payload: api.get(`/services?nameLike=${nameLike}&page=${page}&limit=${limit}`,null,...extra)
});

export const getService = (id, ...extra) => ({
    type: GET_SERVICE,
    payload: api.get(`/services/${id}`,null,...extra)
});
export const getPublicServices = ({nameLike="",page=1, limit=9}, ...extra) => ({
    type: GET_PUBLIC_SERVICES,
    payload: api.get(`/modelSearch/services?nameLike=${nameLike}&page=${page}&limit=${limit}`,null,...extra)
});

export const getServicesByAgency = (id, ...extra) => ({
    type: GET_SERVICE,
    payload: api.get(`/agencies/${id}/services`,null,...extra)
  });